import { HeaderTree, Section } from './virtual-sections.component';

export const findTreeIndex = (tree: HeaderTree | Section['id'], id?: Section['id']): number => {
  if (!id) return 0;

  if (typeof tree === 'number') {
    return tree === id ? tree : -1;
  }
  
  return Object.values(tree)
    .sort((a:any, b:any) => a.order - b.order)
    .findIndex((innerTree: any) => findTreeIndex(innerTree.value, id) !== -1);
};

export const getTreeId = (tree: HeaderTree | Section['id'], index: number): number => {
  if (typeof tree === 'number') {
    return tree;
  }
  const new_tree:any = Object.values(tree).sort((a:any, b:any) => a.order - b.order)[index]
  return getTreeId(new_tree.value, 0);
};

export const checkLastLevel = (tree: HeaderTree): boolean => {
  return !tree
    || ['number', 'string'].includes(typeof tree.value)
    || Object.keys(tree.value).length <= 1 && checkLastLevel(Object.values(tree.value)?.[0] as HeaderTree);
};