import * as React from 'react';

type redsysChallengeComponentProps = {
  acsURL: string,
  CReq: string,
};

export default function RedsysChallengeComponent({acsURL, CReq} : redsysChallengeComponentProps) {

  React.useEffect(() => {
    const form = document.getElementById('threeDSMethodForm') as HTMLFormElement;
    if (form) {
        form.submit();
    }
  }, []);

  return (
    <form id="threeDSMethodForm" action={acsURL} method="post">
      <input type="hidden" name="creq" value={CReq}/>
    </form>
  )
}
