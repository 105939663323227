import PropTypes, {InferProps} from 'prop-types';
import React  from 'react';
import amex from "../../assets/images/card_brand_icons/amex.svg";
import mc from "../../assets/images/card_brand_icons/mc.svg";
import visa from "../../assets/images/card_brand_icons/visa.svg";
import nocard from "../../assets/images/card_brand_icons/nocard.svg";


export function CardIconComponent({brand, style}: CardIconComponentProps) {

    const brands: any = {
        'amex': amex,
        'mc': mc,
        'visa': visa
    };
    const src = brand in brands ? brands[brand] : nocard;
    const defaultStyle = {height: '19px'};
    const computedStyle: any = Object.assign({}, defaultStyle, style);

    return (
        <img alt="" src={src} style={computedStyle} />
    );
}

CardIconComponent.propTypes = {
    brand: PropTypes.string.isRequired,
    style: PropTypes.object
};

type CardIconComponentProps = InferProps<typeof CardIconComponent.propTypes>;