import React, {useContext, useState} from 'react'
import {BannerMaster, PromoConditions, MasterOffer} from './StylesMasterC2PCampaign';
import { Trans, useTranslation } from 'react-i18next';
import { Icon, IconType } from '../../elements';
import { AppContext } from '../../context/AppContext';
import { calculateEuros } from '../../pages/checkout/functions/calculatePrices';
import { PopupType } from '../../context/popups.enum';
import { usePopupNavigation } from '../../shared/utils/usePopupNavigation';
import { MADRE_LIEVITO_POBLE_NOU } from '../../shared/constants/restaurantIds';
import { PopupContext } from '../../context/PopupContext';

type props = {
    outsidePopup?: boolean;
    fixed?: boolean;
    top?: boolean;
    fromComponent?: string;
}

const MasterC2PBanner = ({outsidePopup, fixed, top, fromComponent} : props) => {
    const restaurantId = Number(sessionStorage.getItem('restaurantId'));

    const [bannerOpen, setBannerOpen] = useState(true)
    const {t} = useTranslation();
    const {
        state: {
            checkout: {
                remaining,
            },
            restaurant: {
                masterC2pPromotionCents,
                minMasterC2pPromotionAmountCents
            },
        },
    } = useContext(AppContext);
    const { popup } = useContext(PopupContext);
    const isPopupCheckout: boolean = popup.current === PopupType.Checkout
    const {goToPopup} = usePopupNavigation();
    const discount = Math.round(masterC2pPromotionCents / 100);

    const showMoreInfo = (event: React.MouseEvent) => {
        event.stopPropagation();
        goToPopup(PopupType.MasterPromoInfo);
    }

    if (restaurantId === MADRE_LIEVITO_POBLE_NOU) return <></>;

    return (
        <BannerMaster
            onClick={() => {
                if(fromComponent === 'orderedSteps' && remaining > 0){
                    goToPopup(PopupType.Checkout)
                }
                if ((!fixed && isPopupCheckout) || (fixed)) {
                    return '';
                } else {
                    setBannerOpen(false); 
                }
            }}
            isOpen={bannerOpen}
            top={top}
            outsidePopup={outsidePopup}
            fixed={fixed}
        >
            {(!fixed || fixed === undefined || isPopupCheckout) && !top &&
            <div style={{width: "100%", display: 'flex', justifyContent: 'flex-end', margin: "0 0 -18px 0", paddingTop: "5px"}}>
                <Icon size={10} type={IconType.Close}/>
            </div>
            }
            <div onClick={showMoreInfo} style={{position: 'absolute', top: '12px', right: '12px'}}>
                <Icon type={IconType.Info}/>
            </div>
            <MasterOffer>
                <Trans i18nKey={'do_you_have_c2p_account'} discount={discount}>
                    <strong>Do you have a Click to pay account?</strong><br/>
                    Create it when you pay and <br/>
                    <strong>save {{discount}}€ with your Mastercard</strong>
                </Trans>
            </MasterOffer>
            <PromoConditions>
                <span>*{t('in payments over 15€', {amount: `${minMasterC2pPromotionAmountCents ? parseInt(calculateEuros((minMasterC2pPromotionAmountCents))) : 15}`})}</span>
                <Icon size={20} type={IconType.Master_C2P} />
            </PromoConditions>
        </BannerMaster>
    )
}

export default MasterC2PBanner;