import React from 'react';
import ReactSelect, { StylesConfig, components } from 'react-select';
import StateManagedSelect from 'react-select/dist/declarations/src/stateManager';

const baseStyles: StylesConfig<unknown> = {
  menuPortal: (base) => ({
    ...base,
    zIndex: 99999
  }),
  container: (baseStyles) => ({
    ...baseStyles,
    outline: 'none'
  }),
  control: (baseStyles, state) => ({
    ...baseStyles,
    width: '100%',
    height: '48px',
    color: 'var(--text)',
    backgroundColor: 'var(--input_bg)',
    border: '2px solid var(--input_border)',
    borderRadius: 'var(--radius_sm)',
    outline: state.isFocused ? '2px solid var(--primary)' : 'none',
    outlineOffset: '2px',
    boxShadow: 'var(--shadow_inset_xs)',
  }),
  valueContainer: (baseStyles) => ({
    ...baseStyles,
    padding: '0 16px',
  }),
  singleValue: (baseStyles) => ({
    ...baseStyles,
    color: 'currentColor',
  }),
  placeholder: (baseStyles) => ({
    ...baseStyles,
    color: 'var(--text_a50)',
  }),
  indicatorSeparator: (baseStyles) => ({
    ...baseStyles,
    backgroundColor: 'var(--input_border)',
  }),
  dropdownIndicator: (baseStyles) => ({
    ...baseStyles,
    svg: {
      fill: 'var(--text_a80)',
    }
  }),
  menu: (baseStyles) => ({
    ...baseStyles,
    background: 'var(--background_rised)',
    borderRadius: '0 0 var(--radius_sm) var(--radius_sm)',
    border: '2px solid var(--input_border)',
    margin: '-2px 16px 0',
    boxShadow: 'var(--shadow_dropdown)',
    width: 'calc(100% - 32px)',
    overflow: 'hidden',
  }),
  menuList: (baseStyles) => ({
    ...baseStyles,
    padding: 0,
  }),
  option: (baseStyles, state) => ({
    ...baseStyles,
    color: 'var(--text)',
    backgroundColor: state.isSelected ? 'var(--primary_a40)' : state.isFocused ? 'var(--primary_a20)' : '',
  }),
};
/* eslint-disable react/prop-types */
export const Select: StateManagedSelect = ({components, styles, ...props}) => {
  return (
    <>
      <ReactSelect
        //menuPortalTarget={document.body} //uncommenting this, breaks additional fields, dropdown doesn't work
        placeholder={props.placeholder}
        components={{Option, ...components}}
        styles={{...(baseStyles as unknown as typeof styles), ...styles}}
        {...props}
      />
   
    </>
  );
};

const Option = (props: any) => {
  return (
    <div>
      <components.Option {...props}>
        <span></span>
        <label>{props.label}</label>
      </components.Option>
    </div>
  );
};