import React, { ReactChild, ReactChildren } from 'react';
import styled from 'styled-components';

const AbsoluteFooterContainer = styled.div`
  display: flex;
  justify-content: space-around;
  position: absolute;
  bottom: 20px;
  width: 90%;
  align-items: flex-end;

  @media (orientation: landscape) and (min-width: 769px) {
    width: 25%;

    @media (max-width: 1025px){
      width: 30%;
    }
  }
`;

type AbsoluteFooterProps = {
  children: ReactChild | ReactChildren;
}

const AbsoluteFooter = ({
  children
}: AbsoluteFooterProps): JSX.Element => {
  return (
    <AbsoluteFooterContainer>
      {children}
    </AbsoluteFooterContainer>
  )
};

export default AbsoluteFooter;
