import React, { useContext } from 'react'
import { t } from 'i18next'
import { useLocation, useNavigate } from 'react-router-dom'
import { Button, PaymentLogos } from '../../elements'
import { OrderedStepsContainer } from './OnboardingContainers'
import { AppContext } from '../../context/AppContext'
import BizumDiscountBanner from '../../campaigns/bizum/BizumDiscountBanner'
import { eventTrack } from '../../../useGaTracker'

type Props = {
  step: 'order' | 'choose' | 'pay' | 'order_finalized' | 'make_order'
}

export const OrderedSteps = ({step}: Props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const path = location.pathname;
  const {
    state: {
      restaurant:{
          bizumPromotionCents,
          isMockup,
      },
    },
  } = useContext(AppContext);
  const goToCheckout = () => navigate('/checkout', {state: {from: 'menu', hasSeenPopup: true}});

  const trackEvents = () => {
    if(step === 'order' || step === 'choose'){
      eventTrack("00_onboarding_tooltip_03_basketMenu", "00_onboarding_tooltip_03_basketMenu", "00_onboarding_tooltip_03_basketMenu")
    } else if(step === 'pay'){
      eventTrack("00_onboarding_tooltip_02_payment", "00_onboarding_tooltip_02_payment", "00_onboarding_tooltip_02_payment")
    }
    else{
      eventTrack("00_onboarding_tooltip_01_reward", "00_onboarding_tooltip_01_reward", "00_onboarding_tooltip_01_reward")
    }
  }
  
  return (
    <>
    <OrderedStepsContainer>
      <div>
        {step !== 'order_finalized' &&
          <span>{step === 'pay' ? 2 : 1}</span>
        }
        <div onClick={trackEvents}>
          {step === 'order' &&
            <p>
              {t('order your food')}
            </p>
          }
          {step === 'make_order' &&
            <p>
              {t('select products and add to cart')}
            </p>
          }
          {step === 'order_finalized' &&
            <p style={{ whiteSpace: 'nowrap', padding: '10px 15px' }}>
              {t('sent to kitchen!')}
            </p>
          }
          {step === 'choose' &&
            <p>
              {t('order your food')}
            </p>
          }
          {step === 'pay' &&
            <>
              <p>
                {t('pay the bill here')}
              </p>
              {step === 'pay' &&
                <PaymentLogos/>
              }
            </>
          }
        </div>
      </div>
        {step === 'pay' && path === '/menu' &&
          <Button
            title={t('pay your bill')}
            handleClick={goToCheckout}
          />
        }
      </OrderedStepsContainer>
      {bizumPromotionCents > 0 && step === 'pay' && !isMockup &&
        <BizumDiscountBanner fixed={true} fromComponent={'orderedSteps'}/>
      }
    </>
  )
}