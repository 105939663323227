import styled from 'styled-components';
import React from "react"; 
import { BaseCard } from '../../../elements';

const TotalAmountPurchase = styled.div`
  background-color: #EFF8F4;
  padding: 4px 12px 4px 13px;
  border-radius: 24px;
  color: #404040;
  font-weight: 600;
`;

const PaymentMethodsGlobalContainer = styled.div`
  color: ${({theme}) => theme.text};

  iframe {
    border-radius: ${({theme}) => theme.radius_round};
    overflow: hidden;
  }
  
  &.paywithgoogle, &.applepay {
    iframe {
      background-color: ${({theme}) => theme.default_pay_button_color} !important;
    }
  }

  &.bizumpay {
    iframe {
      background-color:  ${({theme}) => theme.bizum_pay_button_color} !important;
    }
  }
`;

const WebviewAndroidGpayContainer = styled.div`
  color: ${({theme}) => theme.text};
  min-height: 44px;
  flex-shrink: 0;
  
  div{
    min-height: 44px;
    flex-shrink: 0;
    p{
      margin-bottom: 10px;
    }


    iframe {
      border-radius: ${({theme}) => theme.radius_round};
      overflow: hidden;
      height: 100%;
    }
  }
`;

const LandscapePaymentFlow = styled.div`
  width: 100%;
  height: 100%;
  max-width: 100%;
  max-height: 100%;
	display: flex;
	flex-direction: column;
  background-color: ${({theme}) => theme.background_sunken};
  
  > header {
		display: flex;
		justify-content: space-between;
    background-color: ${({theme}) => theme.yumminn_blue};
    color: ${({theme}) => theme.text_light};
    padding: 16px 24px;

    h4 {
      border-left: 2px solid currentColor;
      padding-left: 16px;
      margin: 0 0 0 16px;
    }
  }

  .flow_popup {
    overflow: hidden;

    header {
      display: none;
    }
  }
`;


const GoBackToAppContainer = styled.div`
  height: 100vh;
  background-color: ${({theme}) => theme.background};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 10%;
  color: ${({theme}) => theme.text};
  gap: 10px;
  position: absolute;
  z-index: 999;

  button {
    margin-top: 30px;
  }

  h3 {
    margin: 0
  }
`;

const GoBackToAppReason = styled.p`
  font-weight: 600;
  font-size: 16px;
  text-align: center;
`;

const PaymentMethodsContainer = styled.div`
  display: flex;
  align-items: center;
  height: 3.5rem;
  gap: 8px;
  width: 100%;
  margin-bottom: 24px;
`;

const PaymentMethodsBox = styled(BaseCard).attrs({as: 'button'})<PaymentBoxProps>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 4px;
  font-size: 8px;
  font-weight: 600;
  line-height: 1;
  width: 100%;
  height: 100%;
  padding: 4px;
  color: ${({theme}) => theme.payment_card_icon};
  cursor: ${({selected}) => selected ? 'default' : 'pointer'};

  &:hover {
    filter: ${({selected}) => selected ? '' : 'brightness(1.03)'};
    translate: ${({selected}) => selected ? '0' : '0 -2px'};
  }

  &:active {
    filter: ${({selected}) => selected ? '' : 'brightness(0.97)'};
    translate: ${({selected}) => selected ? '0' : '0 2px'};
  }

  & > * {
    max-width: 100%;
  }

  svg path {
    fill: ${({selected, theme}) => selected ? theme.payment_card_icon_selected : ''};
  }
`;

const PaymentPopup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  color: ${({theme}) => theme.text};
  padding: 16px 24px;

  h3 {
    font-size: ${({theme}) => theme.font_md};
    font-weight: 600;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin: 20px 0 16px;
    
    i {
      color: ${({theme}) => theme.yumminn};
      font-size: ${({theme}) => theme.font_xs};
      font-style: normal;
      text-transform: capitalize;
      font-weight: 400;
    }
  }
`;

type PaymentMethodsBoxesProps = {
  onClick: () => void;
  children: React.ReactNode;
  selected?: boolean;
}

type PaymentBoxProps = {
  selected?: boolean;
  children: React.ReactNode;
  onClick: () => void;
}

const PaymentMethodsBoxes = ({
  onClick,
  children,
  selected
}: PaymentMethodsBoxesProps)=> {
  return (
    <PaymentMethodsBox
      onClick={onClick}
      selected={selected}
    >
      { children }
    </PaymentMethodsBox>
  )
};

export {
  PaymentMethodsContainer,
  PaymentMethodsBoxes,
  PaymentMethodsGlobalContainer,
  PaymentPopup,
  WebviewAndroidGpayContainer,
  GoBackToAppContainer,
  GoBackToAppReason,
  TotalAmountPurchase,
  LandscapePaymentFlow,
};

