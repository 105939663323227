import Manager from '../manager/Manager.api'
import Endpoints from '../endpoints/Endpoints.api'
import useSWR from 'swr'
import ManagerAuth from '../manager/ManagerAuth.api'
import { Menu, Coords } from '../../context/app'
import { useEffect } from 'react'
import { postError } from '../../shared/utils/postError'
import { SECOND } from '../../shared/constants/time'

 
const restaurants = () => Manager.client().get(Endpoints.restaurants.restaurant)
const restaurant = (id: number) => Manager.client().get(`${Endpoints.restaurants.restaurant}${id}`)
const restaurantActives = (id: number) => Manager.client().get(`${Endpoints.restaurants.restaurant}${id}/menus_actives/`)
const restaurantLegalInfo = (id: number) => Manager.client().get(`${Endpoints.restaurants.legal_info}/${id}`)
const table = (id: any) => Manager.client().get(Endpoints.tables.table + `${id}`)
const creditsList = (id: number) => Manager.client().get(`${Endpoints.restaurants.credits}/${id}/`)

const tableContent = (restaurantId: number, tableNumber: string, retryStart = 0,  paymentUuid:any=null, deviceToken:any=null) => {
  let endpoint = `${Endpoints.restaurants.restaurant}table/?table=${tableNumber}&id=${restaurantId}`;
  if (retryStart > 0) {
    endpoint += `&retry_start=${retryStart}`;
  }
  if (paymentUuid != null) {
    endpoint += `&paymentUuid=${paymentUuid}`;
  }
  if (deviceToken){
    endpoint += `&deviceToken=${deviceToken}`;
  }

  return Manager.client().get(endpoint);
};

const initialInfo = (restaurantId: number, tableNumber: any, token: any, type: any) => {
  let url = `${Endpoints.restaurants.restaurant}${restaurantId}/initial_info/`;
  if (tableNumber) {
    url += `?table=${tableNumber}`;
  }
  return ManagerAuth.client(token, type).get(url);
}

const info = (restaurantUuid: string, tableNumber: any, token: any, type: any) => {
  if (!restaurantUuid) return;

  let url = `${Endpoints.restaurants.restaurant}${restaurantUuid}/info?restaurant_uuid=${restaurantUuid}`;
  if (tableNumber) {
    url += `&table=${tableNumber}`;
  }
  return ManagerAuth.client(token, type).post(url, restaurantUuid, tableNumber);
}

const infoWithtableCheck = (restaurantId: number, tableNumber: any, token: any, type: any) => {
  if (!restaurantId) return;

  let url = `${Endpoints.restaurants.restaurant}${restaurantId}/info_with_table_check/`;
  if (tableNumber) {
    url += `?table=${tableNumber}`;
  }
  //console.log("infoWithtableCheck url: " + url);
  return ManagerAuth.client(token, type).post(url, restaurantId, tableNumber);
}

const getQrUUID = (qrUUID: string) =>
  Manager.client().get(`${Endpoints.tables.qr}?qr_uuid=${qrUUID}`);

export const UseRestaurants = () => {

  const { data, error } = useSWR(`${Endpoints.restaurants.restaurant}`, () => restaurants())

  return {
    content: data ? data : [],
    error,
    loading: !error && !data,
  }
}

export const getNearestDelivery = async (data: {restaurant_group: string, user_location: Coords}) => {
  try {
    return await Manager.client().post(Endpoints.restaurants.get_nearest_delivery, data);
  } catch(err: any) {
    postError(err)
    return null;
  }
};

export const UseRestaurant = (id: number) => {

  const { data, error } = useSWR(`${Endpoints.restaurants.restaurant}/${id}`, () => restaurant(id))
   
  const content: any = data ? data.data : []
  const loading: boolean = !error && !data

  return {
    content: content,
    error,
    loading: loading,
  }
}

export const UseRestaurantGroup = (id: string, isLogged: boolean) => {
  const fetcher = (url: string) => {
    if (isLogged) {
      const token = localStorage.getItem("token_yumminn");
      const type = localStorage.getItem("type");
    
      return ManagerAuth.client(token, type).get(url);
    }

    return Manager.client().get(url);
  };

  const { data, error, isValidating, mutate } = useSWR(
    `${Endpoints.restaurants.group}?restaurant_group=${id}`,
    fetcher,
    {
      revalidateOnFocus: false,
      revalidateOnMount: true,
    }
  );

  const content: any = data ? data.data : {};
  const loading: boolean = !error && !data;

  useEffect(() => {
    ['header_image', 'cover_image', 'background_image'].forEach(img => {
      content?.[img] && (content[img] = process.env.REACT_APP_AWS_S3_URL + "/" + content[img]);
    });
  }, [content]);

  useEffect(() => {
    mutate();
  }, [isLogged]);

  return {
    content: content,
    error,
    loading: loading,
    isValidating,
  };
};

export const UseRestaurantActiveMenus = (id: number) => {
  const endpoint =
  id ? `${Endpoints.restaurants.restaurant}${id}/menus_actives/`
  : null;

  const { data, error } = useSWR(endpoint, () => restaurantActives(id))
   

  const content: any = data ? data.data : []
  const loading: boolean = !error && !data

  return {
    content: content as Menu[],
    error,
    loading: loading,
  }
}

const recursivelyRetryGetTableContent = (restaurantId: number, tableNumber: string, resolve: any, reject: any, retryStart = 0, paymentUuid:any=null, deviceToken:any=null) => {
  const promise = tableContent(restaurantId, tableNumber, retryStart, paymentUuid, deviceToken);
  if (retryStart == 0) {
    retryStart = Date.now();
  }
  promise.then(response => {
    if (response.status == 200 && "retry" in response.data && response.data["retry"]) {
      setTimeout(() => {
        recursivelyRetryGetTableContent(restaurantId, tableNumber, resolve, reject, retryStart, paymentUuid, deviceToken);
      }, 2000);
    } else {
      resolve(response);
    }
  }).catch(err => {
    postError(err)
    reject(err)
  });
}

export const getTableContent = (restaurantId: number, tableNumber: string, paymentUuid:any=null, deviceToken:any=null) => {
  return new Promise((resolve, reject) => {
    recursivelyRetryGetTableContent(restaurantId, tableNumber, resolve, reject, 0, paymentUuid, deviceToken);
  });
};


export const UseInitialInfo = (restaurantId: number, tableNumber: any, token: any, type: any) => {
  const { data, error } = useSWR(`${Endpoints.restaurants.restaurant}${restaurantId}/initial_info/`, () =>
    initialInfo(restaurantId, tableNumber, token, type)
  );
  const content: any = data ? data.data : {};
  const loading: boolean = !error && !data;

  return {
    content: content,
    error,
    loading: loading,
  };
};

type UseInfoProps = {
  restaurantUuid?: string
  restaurantId?: number
  tableNumber?: any
  token: any
  type: any
  user?: any
};

export const UseInfo = ({restaurantUuid, restaurantId, tableNumber, token, type}: UseInfoProps) => {
  const endpoint =
    restaurantUuid ? `${Endpoints.restaurants.restaurant}${restaurantUuid}/info/`
    : restaurantId ? `${Endpoints.restaurants.restaurant}${restaurantId}/info_with_table_check/`
    : null;
  
  const callback =
    restaurantUuid ? () => info(restaurantUuid, tableNumber, token, type)
    : restaurantId ? () => infoWithtableCheck(restaurantId, tableNumber, token, type)
    : () => new Promise(() => null);
  
  let { data, error } = useSWR(endpoint, callback, {
    revalidateOnFocus: false,
    shouldRetryOnError: true,
    errorRetryCount: 2,
    errorRetryInterval: 2 * SECOND,
  });

  const content: any = (data as any)?.data || {};
  let loading: boolean = !error && !data;

  if(!restaurantUuid && !restaurantId){
    const errorMessage = 'No restaurantUuid and also not restaurantId'
    const customError = new Error(errorMessage)
    loading = false
    error = customError
    data = {}
  }

  return { content, error, loading };
};

export const getProgramSlots = async (restaurantId: number) => {
  try {
    return await Manager.client().get(`${Endpoints.restaurants.order_program_slots}/${restaurantId}`);
  } catch (err: any){
    postError(err)
    return null;
  }
};

export const UseQRUUID = (qrUUID: string) => {
  return getQrUUID(qrUUID);
};

export const Request = {
  table
};

export const UseRestaurantLegalInfo = (id: number) => {
  const endpoint =
  id ? `${Endpoints.restaurants.legal_info}/${id}`
  : null;

  const { data, error } = useSWR(endpoint, () => restaurantLegalInfo(id))
   

  const content: any = data ? data.data : []
  const loading: boolean = !error && !data

  return {
    content: content as Menu[],
    error,
    loading: loading,
  }
};

export const UseRestaurantTopUpInfo = (id: number) => {
  const endpoint = id ? `${Endpoints.restaurants.credits}/${id}/` : null;

  const { data, error } = useSWR(endpoint, () => creditsList(id));

  const content: any = data ? data.data : [];
  const loading: boolean = !error && !data;

  return {
    content: content,
    error,
    loading: loading,
  };
}