// ///////////////////////
// SERVICES
// ///////////////////////

const REACT_APP_BASE_URL = process.env.REACT_APP_BASE_URL;

function addAuthorizationHeaders(headers: any) {
  const token: any = localStorage.getItem("token_yumminn");
  const type: any = localStorage.getItem("type");
  //console.log("token: " + token);
  //console.log("type: " + type);
  if (token && type && token !== "undefined" && type !== "undefined") {
    headers["Authorization"] = token;
    headers["Authorization-Type"] = type;
  }
  return headers;
}

// ///////////////////////
// PAY
export const createRedsysRedirectPayment = async (data: any) => {
  const headers = addAuthorizationHeaders({"Content-Type": "application/json"});
  const yumminnToken = process.env.REACT_APP_YUMMINN_TOKEN;
  if (yumminnToken) {
    headers["yumminn-token"] = yumminnToken;
  }
  const response = await fetch(`${REACT_APP_BASE_URL}/payment/redsys-r-payment`, {
    method: "POST",
    body: JSON.stringify(data),
    headers
  });
  if (response.ok) {
    const result = await response.json();
    return result;
  }
  throw Error(response.statusText);
}


export const applePaySession = async (restaurantId: number) => {
  const headers = addAuthorizationHeaders({"Content-Type": "application/json"});
  const yumminnToken = process.env.REACT_APP_YUMMINN_TOKEN;
  if (yumminnToken) {
    headers["yumminn-token"] = yumminnToken;
  }
  const response = await fetch(`${REACT_APP_BASE_URL}/payment/apple-pay-session/${restaurantId}`, {
    method: "GET",
    headers
  });
  if (response.ok) {
    return response;
  }
  throw Error(response.statusText);
}
