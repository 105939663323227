import * as React from 'react';
import { useContext } from "react";
import { AppContext } from "../../context/AppContext";
import { toast } from "react-toastify";
import { t } from 'i18next';
import { EventCatalogContext } from '../../context/EventCatalogContext';
import { UserContext } from '../../context/auth/UserContext';
import { useNavigate } from 'react-router';
import { MoneiPay } from '../../elements/monei';
import { eventTrack } from '../../../useGaTracker';
import { useRestaurantData } from '../../shared/utils/useRestaurantData';
import { SquarePay } from '../../elements/square';
import { RedsysPay } from '../../elements/redsys';
import { eventCatalogInitiatePayment, eventInitiatePayment, initiatePayment } from '../../elements/payment/payment.service';
import { postError } from '../../shared/utils/postError';
import RedsysRedirection from '../../elements/redsys/RedsysRedirection';
import { useCommentAndAditionalInfo } from '../../shared/utils/useCommentAndAditionalInfo';
import { Button } from '../../elements';

export function PaymentGatewayHandler({
  myTotalUpdated,
  event,
  setFlow,
  setPaymentResult,
  onOutOfStock,
  restaurantCreditId,
  setRedsysChallenge,
  disabled
}: PaymentGatewayHandlerProps) {
  const {
    state: {
      restaurant: {
        bizumPromotionCents,
      },
      checkout: {
        remaining,
        paymentMethod,
        paymentSplitType,
        otherPayments,
        loyaltyDiscountCents,
        generalDiscountCents,
        productDiscountCents,
        codeCoupon,
        codeCouponCents,
        credit,
        creditCents,
        clientCommission,
        tip: {
          amount: tipAmout,
        },
        zeroSixSelectedVoucher,
        deliveryFeeCents,
        moneiAccountId,
        paymentGateway,
        checkoutFlow,
        selectedItems,
        selectedModifiers,
        orderAndPayItems,
        freeProducts,
        selectedSlot
      },
      addressData
    },
  } = useContext(AppContext);

  const navigate = useNavigate();
  const { restaurantId, tableNumber } = useRestaurantData();
  const { user: { name, phone, email } } = useContext(UserContext);
  const { state: { tickets } } = useContext(EventCatalogContext);

  const [isLoading, setLoading] = React.useState(true);
  const invalidDeliveryFee = Number.isNaN(deliveryFeeCents);
  const serviceCommision = Math.floor(((myTotalUpdated) * clientCommission)/ 100);
  const bizumDiscountCents = paymentMethod === 'bizumpay' && (myTotalUpdated) >= 1500 ? bizumPromotionCents : 0;
  const zeroSixDiscountCents = zeroSixSelectedVoucher.code != "" ? (!zeroSixSelectedVoucher.is_percentage ? zeroSixSelectedVoucher.value * 100 : Math.round((((myTotalUpdated) *  zeroSixSelectedVoucher.value) / 100))) : 0;
  const amountMinusDiscounts = Math.max(myTotalUpdated - generalDiscountCents - productDiscountCents - codeCouponCents, 0);
  const amountMinusDiscountsAndCredit = Math.max(amountMinusDiscounts - creditCents, 0);
  const creditUsed = Math.min(creditCents, amountMinusDiscounts);
  const amountMinusAllDiscounts = Math.max(amountMinusDiscountsAndCredit, 0);
  const finalLoyaltyDiscount = Math.max(loyaltyDiscountCents > amountMinusAllDiscounts ? amountMinusAllDiscounts : loyaltyDiscountCents, 0);
  const finalAmount = Math.max(myTotalUpdated - finalLoyaltyDiscount - generalDiscountCents - productDiscountCents - codeCouponCents - creditCents - bizumDiscountCents - zeroSixDiscountCents, 0) + Number(serviceCommision) + deliveryFeeCents;
  const finalBaseAmount = myTotalUpdated - Number(Number(tipAmout).toFixed(2));
  const date = new Date().toISOString().replace(/T/, '_').replace(/\..+/, '');
  const orderId = date + '-' + restaurantId + '-' + tableNumber;
  const functionality = sessionStorage.getItem('functionality') || "";

  const comment = useCommentAndAditionalInfo();

  const onSuccess = (paymentId: any, paymentUuid: any) => {
    eventTrack("final_payment_success", "final_payment_success", "Final payment successful");

    if (event) {
      navigate(
        `/event-payment-successful?paymentId=${paymentId}`,
        {state: {amount: myTotalUpdated, event: event}},
      );
    } else {
      navigate(
        `/payment-successful?paymentUuid=${paymentUuid}`,
        {state: {leftTopay: remaining, payments: otherPayments, name, myTotalUpdated, restaurantFromNavigator: restaurantId.toString(), tableFromNavigator:tableNumber}},
      );
    }
  };

  const onFailure = (error: string) => {
    eventTrack("final_payment_failure", "final_payment_failure", "Final payment failure");
    toast.error(`${error}`, {
      onClose: closeToastify,
    });
  };

  const closeToastify = () => {
    document.getElementById("modal-toastify-body")?.classList.remove("modal-toastify-in");
    document.getElementById("modal-toastify-body")?.classList.add("modal-toastify-out");
    setTimeout(() => {
      document.getElementById("modal-toastify")?.setAttribute("style", "display: none"), 500
    });
  };

  const onLocalOutOfStock = (outOfStockProducts: any, outOfStockModifiers: any) => {
    eventTrack("final_payment_failure", "final_payment_failure", "Final payment failure");
    onOutOfStock(outOfStockProducts, outOfStockModifiers);
  };

  const tokenHandler = async (
    paymentToken: string,
    sessionId?: string,
    saveCardChecked?: boolean,
    selectedSavedCard?: any,
    redsysOrder?: string,
    googlePayToken?: string,
    applePayData?: string,
    squareVerifyToken?: string,
  ) => {
    if (invalidDeliveryFee) {
      toast.error(t('calculate_delivery_fee_error'));
      setLoading(false);
      return;
    }

    setLoading(true);
    setFlow?.({
      flow_status: paymentMethod === 'zero_payment' ? 'sending_to_pos' : 'processing_payment',
      flow_type: checkoutFlow,
    });

    const paymentData = {
      captureDelayHours: 1,
      base_amount: finalBaseAmount,
      tip: Number(Number(tipAmout).toFixed(2)),
      delivery_fee_cents: deliveryFeeCents || 0,
      split_type: paymentSplitType,
      name: name ? name : `${t("someone")}`,
      user: null,
      client: localStorage.getItem("client_id") != 'undefined' ? localStorage.getItem("client_id") : null,
      yumiwi_client_uuid: localStorage.getItem("yumiwiClientUuid") != 'undefined' ? localStorage.getItem("yumiwiClientUuid") : null,
      amount: {
        currency: 'EUR',
        value: finalAmount
      },
      restaurant: restaurantId,
      table: tableNumber,
      orderId: orderId,
      items: selectedItems,
      modifiers: selectedModifiers,
      origin: window.location.origin,
      general_discount_cents: generalDiscountCents,
      product_discount_cents: productDiscountCents,
      code_coupon: codeCoupon?.code ? codeCoupon.code : null,
      code_coupon_cents: codeCouponCents,
      loyalty_discount_cents: finalLoyaltyDiscount,
      credit_cents: creditUsed,
      credit_id: credit,
      restaurant_credit_id: restaurantCreditId,
      service_commission: serviceCommision,
      monei_token: paymentToken && paymentGateway === "monei" ? paymentToken : null,
      session_id: sessionId,
      redsys_id_oper: paymentToken && paymentGateway === "redsys" ? paymentToken : null,
      redsys_order: redsysOrder,
      google_pay_token: googlePayToken,
      apple_pay_data: applePayData,
      square_token: paymentToken && paymentGateway === "square" ? paymentToken : null,
      square_verify_token: squareVerifyToken,
      type: paymentMethod === 'zero_payment' ? '' : paymentMethod,
      orderAndPayItems: checkoutFlow === 'OP' ? orderAndPayItems : [],
      freeProducts,
      comment: comment,
      client_phone: phone,
      client_name: name,
      client_email: email ? email : null,
      save_card_checked: saveCardChecked,
      selected_card: selectedSavedCard ? selectedSavedCard.id : null,
      bizum_promotion_cents: bizumDiscountCents,
      device_token: localStorage.getItem('deviceToken'),
      zerosix_discount_cents: zeroSixDiscountCents,
      selected_zerosix_voucher_code: zeroSixSelectedVoucher.code != "" ? zeroSixSelectedVoucher.code : null,
      delivery_data: addressData,
      selected_slot: selectedSlot ? selectedSlot.id : null,
    }

    let funcToExecute: any;
    let hasStatusFlowPage = false;

    if (event) {
      funcToExecute = () => eventInitiatePayment(event.uuid, paymentData);
    } else if (checkoutFlow === 'EVENTS') {
      funcToExecute = () => eventCatalogInitiatePayment(paymentData, tickets, setFlow);
    } else {
      hasStatusFlowPage = true;
      funcToExecute = () => initiatePayment(paymentData, setFlow);
    }

    await funcToExecute().then((result: any) => {
      setLoading(false);

      if ("cash_payment" in result && result["cash_payment"]) {
        const order = result && "order_code" in result ? result["order_code"] : 0;
        navigate(`/cash-payment?order=${order}`);
      }
      if ("reason" in result && result["reason"]) {
        if (hasStatusFlowPage) {
          setPaymentResult("status_code" in result ? result["status_code"] : "E101");
        } else {
          toast.error(result["reason"]);
        }
      } else {
        if ("code" in result && result["code"] === 333){
          toast.error(t('Remaining has been updated'));
          setTimeout(() => navigate("/payment-error", {state: {reason: 'updatedRemaining'}}), 2000);
        } else if ("error_code" in result && result["error_code"] === 444) {
          if ("uuid" in result && result["uuid"]) {
            toast.error(t('The restaurant does not accept any more orders'));
            setTimeout(() => navigate('/restaurant_group/'+ result["uuid"]), 2000);
          }
        } else if ("error_code" in result && result["error_code"] === 445) {
            toast.error(t(result["message"]));
            setTimeout(() => navigate('?home/id=' + restaurantId + 'table=0'), 2000);
        } else if ("error_code" in result && result["error_code"] === 446) {
          onOutOfStock(result["out_of_stock_products"], result["out_of_stock_modifiers"]);
        } else {
            const paymentId = result && "paymentId" in result ? result["paymentId"] : null;
            const paymentUuid = result && "uuid" in result ? result["uuid"] : null;
            const loyaltyGrant = result && "loyalty" in result ? result.loyalty["grant"] : 0;
            const orderCode = result && "order_code" in result ? result["order_code"] : 0;
            const orderUuid = result && "order_uuid" in result ? result["order_uuid"] : '';
            const orderGroupCode = result && "order_group" in result ? result["order_group"]["code"] : 0;
            const orderGroupCreatedAt = result && "order_group" in result ? result["order_group"]["created_at"] : 0;
            const newOrderGroup = result && "order_group" in result ? result["order_group"]["new"] : 0;
            const zerosix_points = result && "zerosix_points" in result ? result["zerosix_points"] : 0;
            const last4 = result && "last4" in result ? result["last4"] : null;
            const show_save_card_popup = result && "show_save_card_popup" in result ? result["show_save_card_popup"] : 0;
            sessionStorage.setItem("show_save_card_popup", show_save_card_popup)
            const saved_card = result && "saved_card" in result ? result["saved_card"] : 0;
            sessionStorage.setItem("saved_card", saved_card);
            const delay_popup_seconds = result && "delay_popup_seconds" in result ? result["delay_popup_seconds"] : 0;
            sessionStorage.setItem("delay_popup_seconds", delay_popup_seconds)
            const first_card = result && "first_card" in result ? result["first_card"] : 0;
            sessionStorage.setItem("first_card", first_card);
            if (last4) {
              sessionStorage.setItem("last4", last4);
            }
            sessionStorage.setItem("zerosix_points", zerosix_points);
            sessionStorage.setItem("loyaltyGrant", loyaltyGrant);
            paymentId && paymentId !== 'null' && sessionStorage.setItem("paymentId", paymentId);
            sessionStorage.setItem("orderCode", orderCode);
            sessionStorage.setItem("orderUuid", orderUuid);
            sessionStorage.setItem("orderGroupCode", orderGroupCode);
            sessionStorage.setItem("orderGroupCreatedAt", orderGroupCreatedAt);
            sessionStorage.setItem("newOrderGroup", newOrderGroup);
            sessionStorage.setItem('givenTip', tipAmout.toString());
            if (result["zero_payment"]) {
              if (hasStatusFlowPage) {
                setPaymentResult("E000");
              }
              setTimeout(() => {
                onSuccess(paymentId, paymentUuid);
              }, 1000);
            } else {
              processPaymentResponse(result, paymentId, paymentUuid, hasStatusFlowPage);
            }
        }
      }
    })
    .catch((e: any) => {
      setLoading(false);
      postError(e);
      if (hasStatusFlowPage) {
        setPaymentResult("E101");
      }
    })
    .finally(() => {
      setLoading(false);
    })
  };

  const processPaymentResponse = (paymentRes: any,  paymentId: string, paymentUuid: string, hasStatusFlowPage=false) => {
    const response = paymentRes["response"];
    if (response.next_action && response.next_action.type.includes("CHALLENGE")) {
      window.open(response.next_action.redirect_url, "_self")
    } else  if (response && "Ds_EMV3DS" in response && "creq" in response.Ds_EMV3DS && "acsURL" in response.Ds_EMV3DS) {
      setRedsysChallenge({"acsURL": response.Ds_EMV3DS.acsURL, "CReq": response.Ds_EMV3DS.creq})
    } else {
      if (hasStatusFlowPage) {
        setPaymentResult(response.status_code);
      }
      if (response.status === 'SUCCEEDED') {
        setTimeout(() => {
          onSuccess(paymentId, paymentUuid);
        }, 1000);
      } else {
        if (!hasStatusFlowPage) {
          toast.error("Error performing payment");
        }
      }
    }
  };

  if (['wallet', 'zero_payment', 'cash'].includes(paymentMethod)) return (
    <Button
      title={functionality === 'kiosk' ? t('pay_at_counter') : t('send order')}
      disabled={disabled}
      handleClick={() => tokenHandler('')}
    />
  );

  if (paymentGateway === "monei" && moneiAccountId != "") return (
    <MoneiPay
      myName={name}
      type={paymentMethod}
      amount={myTotalUpdated}
      loyaltyDiscount={loyaltyDiscountCents}
      tip={tipAmout}
      onSuccess={onSuccess}
      onFailure={onFailure}
      onOutOfStock={onLocalOutOfStock}
      howPaySelected={paymentSplitType}
      accountId={moneiAccountId}
      phoneForCollect={phone}
      nameForCollect={name}
      bizumPromotionCents={bizumPromotionCents}
      event={event}
      tokenHandler={tokenHandler}
      setPaymentResult={setPaymentResult}
      isLoading={isLoading}
      setLoading={setLoading}
    />
  );

  if (paymentGateway === "square") return (
    <SquarePay
      type={paymentMethod}
      amount={myTotalUpdated}
      loyaltyDiscount={loyaltyDiscountCents}
      tip={tipAmout}
      bizumPromotionCents={bizumPromotionCents}
      tokenHandler={tokenHandler}
      onFailure={onFailure}
    />
  );

  if (paymentGateway === "redsys") return (
    <RedsysPay
      type={paymentMethod}
      amount={myTotalUpdated}
      loyaltyDiscount={loyaltyDiscountCents}
      tip={tipAmout}
      bizumPromotionCents={bizumPromotionCents}
      tokenHandler={tokenHandler}
    />
  );

  if (paymentGateway === "redsys-redirect") return (
    <RedsysRedirection
      myTotalUpdated={myTotalUpdated}
      restaurantCreditId={restaurantCreditId}
    />
  );

  return (
    <div>
      No payment gateway configuration found
    </div>
  );
}

type Flow = {
  flow_status: string
  flow_type: string
};

type PaymentGatewayHandlerProps = {
  myTotalUpdated:         number,
  event?:                 any,
  restaurantCreditId?:    number
  setFlow:                React.Dispatch<React.SetStateAction<Flow>>,
  setPaymentResult:       React.Dispatch<React.SetStateAction<string>>,
  onOutOfStock:           (newOutOfStockProducts: any, newOutOfStockModifiers: any) => void,
  setRedsysChallenge?:     any
  disabled:               boolean
}
