import { postError } from '../../../shared/utils/postError';
import { applePaySession } from '../redsys.service';
import { merchantIdentifier, APPLE_PAY_VERSION_NUMBER } from './PaymentConf';

export const isPaymentRequestAvailable = () =>  {
    return new Promise((resolve, reject) => {
        try {
            const enabled = window.PaymentRequest ? true : false;
            resolve(enabled);
        }
        catch (err) {
            postError(err)
            reject(err);
        }
    })
}

const getPaymentDetails = (currencyCode, items, label, amount) => {
    return {
        displayItems: items.map((item) => {
            return { 
                label: item.label,
                amount: { 
                    value: `${Number(item.amount).toFixed(2)}`,
                    currency: currencyCode
                }
            }
        }),
        total: {
            label: label,
            amount: { value: `${Number(amount).toFixed(2)}`, currency: currencyCode }
        }
    }
}

const applePayMethod = {
    supportedMethods: "https://apple.com/apple-pay",
    data: {
        version: APPLE_PAY_VERSION_NUMBER,
        merchantIdentifier: merchantIdentifier,
        merchantCapabilities: ["supports3DS", "supportsCredit", "supportsDebit"],
        supportedNetworks: ["masterCard", "visa"],
        countryCode: "ES"
    }
};

const paymentOptions = {
    requestPayerName: false,
    requestPayerEmail: false,
    requestPayerPhone: false,
    requestShipping: false,
};

const paymentRequestValidateMerchant = (event) => {
    const restaurantId = Number(sessionStorage.getItem('restaurantId'));
    const sessionPromise = applePaySession(restaurantId)
    .then(res => res.json())
    .catch(err => {
        postError(err)
        console.error("Error fetching merchant session", err);
    });
    event.complete(sessionPromise);  
}

/*const paymentRequestComplete = (resolve, reject, instrument, isError) => {
    const resolveStatus = !isError ? PaymentStatus.SUCCESS : PaymentStatus.FAILURE;
    const completeStatus = !isError ? "success" : "failure";
    instrument.complete(completeStatus)
        .then(() => {
            resolve(resolveStatus);  
        })
        .catch ((err) => {
            postError(err)
            console.log("Complete error:", err);
            reject(err);  
        })
}*/

const paymentRequestPerformPayment = (showPromise, redsysIntiatePayment) => {
    showPromise.then((instrument) => {
        redsysIntiatePayment("", undefined, undefined, undefined, undefined, undefined, instrument.details.token.paymentData)
    })
}

export const performApplePayPayment = (currencyCode, items, label, amount, redsysIntiatePayment) => {
    try {
        const paymentDetails = getPaymentDetails(currencyCode, items, label, amount);  
        const paymentRequest = new PaymentRequest([applePayMethod], paymentDetails, paymentOptions);

        paymentRequest.onmerchantvalidation = paymentRequestValidateMerchant;
        
        const showPromise = paymentRequest.show();

        paymentRequestPerformPayment(showPromise, redsysIntiatePayment);
    } catch (err) {
        postError(err)
    }
}