import { useContext } from 'react';
import { AppContext } from '../../../context/AppContext';

export const useCalculateCheckout = () => {
  const {
    state: {
      restaurant: {
        bizumPromotionCents,
      },
      checkout: {
        paymentSplitType,
        myTotal,
        codeCouponCents,
        loyaltyDiscountCents,
        generalDiscountCents,
        productDiscountCents,
        deliveryFeeCents,
        paymentMethod,
        zeroSixSelectedVoucher,
        creditCents,
        clientCommission,
        tip: {
          amount: tipAmount,
        },
      }
    },
  } = useContext(AppContext);

  const isRechargePayment = paymentSplitType === 'recharge'

  const subtotal = myTotal - tipAmount;

  const serviceCommision =  Math.floor((subtotal * clientCommission)/ 100);

  const bizumDiscountCents = paymentMethod === 'bizumpay' && subtotal >= 1500 ? bizumPromotionCents : 0;
  const zeroSixDiscountCents = zeroSixSelectedVoucher.code != ""
    ? (!zeroSixSelectedVoucher.is_percentage
      ? zeroSixSelectedVoucher.value * 100
      : Math.round(((subtotal *  zeroSixSelectedVoucher.value) / 100)))
    : 0;

  const discounts = generalDiscountCents + productDiscountCents + codeCouponCents + bizumDiscountCents + zeroSixDiscountCents;

  const amountMinusDiscounts = Math.max(subtotal - discounts, 0);
  const amountMinusDiscountsAndCredit = Math.max(amountMinusDiscounts - creditCents, 0);
  const amountMinusDiscountsAndCreditAndLoyalty = Math.max(amountMinusDiscountsAndCredit - loyaltyDiscountCents, 0);
  
  const creditUsed = Math.min(creditCents, amountMinusDiscounts);
  const loyaltyAfterDiscounts = Math.min(loyaltyDiscountCents, amountMinusDiscountsAndCredit);

  const total = (isRechargePayment ? subtotal : amountMinusDiscountsAndCreditAndLoyalty) + serviceCommision + deliveryFeeCents + tipAmount;

  return {
    isRechargePayment,
    subtotal,
    serviceCommision,
    bizumDiscountCents,
    zeroSixDiscountCents,
    amountMinusDiscounts,
    amountMinusDiscountsAndCredit,
    amountMinusDiscountsAndCreditAndLoyalty,
    creditUsed,
    loyaltyAfterDiscounts,
    total,
  };
};