import styled from "styled-components";

const ButtonsContainer = styled.div<ContainerProps>`
  max-width: 100%;
  overflow-x: auto;
  display: flex;
  justify-content: space-between;
  gap: 12px;
  flex-shrink: 0;
  flex-grow: 0;
  opacity: ${({$disabled}) => $disabled ? 0.5 : 1};
  cursor: pointer;
`;

const RadioButton = styled.label<ButtonProps>`
  padding: 4px 8px;
  border: 2px solid ${({theme}) => theme.gray_30};
  border-radius: 9999px;
  font-size: ${({theme}) => theme.font_xs};
  color: ${({theme, $checked}) => $checked ? theme.background : theme.text};
  position: relative;
  flex: 1;
  text-align: center;
  white-space: nowrap;
  height: min-content;
  background-color: ${({theme, $checked}) => $checked ? theme.primary_40 : 'transparent'};
  border-color: ${({theme, $checked}) => $checked ? theme.primary : theme.gray_30};
  font-weight: ${({$checked}) => $checked ? 600 : 400};

  @media (orientation: landscape){
    height: 2rem; 
    align-content: center;
  }

  input {
    opacity: 0;
    width: 0;
    position: absolute;;
  }
`;

type ContainerProps = {
  $disabled?: boolean;
}

type ButtonProps = {
  $checked: boolean;
}

export {ButtonsContainer, RadioButton};