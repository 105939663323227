import { t } from 'i18next';
import { useContext, useEffect } from 'react';
import { Trans } from 'react-i18next';
import 'react-toastify/dist/ReactToastify.css';
import { Icon, IconType, RegisterOrLogin } from '../../elements';
import { RegisterBenefitsContainer } from './RegisterContainers';
import { PopupContext } from '../../context/PopupContext';
import { eventTrack } from '../../../useGaTracker';
import { AppContext } from '../../context/AppContext';
import { MahouLogin } from '../../elements/register/MahouLogin';

const MAIL_BY_DEFAULT = ['cashback_register_popup', 'master_C2P_promo'];

export function RegisterPopup ({from} : props) {
    const {
        state: {
            restaurant: {
                salesforce_login_url
            }
        },
    } = useContext(AppContext);

    const { popup: { params } } = useContext(PopupContext);

    const restaurantId = sessionStorage.getItem('restaurantId');
    const tableNumber = sessionStorage.getItem('tableNumber');
    const state = JSON.stringify({"restaurant": restaurantId, "table": tableNumber})
    
    const defaultValues = {
        lopd: params?.lopd || false,
        news: params?.news || false,
    };

    const registerBenefits = {
        loyalty: (
            <>
                <h4>
                    {t('earn cashback and points')}
                    <Icon type={IconType.LoyaltyColor}/>
                </h4>
                <p>
                    <Trans i18nKey={'register or login and dont lose them'}>
                        register or login and <b>dont lose them</b>!
                    </Trans>
                </p>
            </>
        ),
        receipts: (
            <>
                <h4>{t('save your receipts')}</h4>
                <p>{t('register or login in one click')}</p>
            </>
        ),
    };

    const benefitsContent = !!params?.openBy && registerBenefits?.[params.openBy as keyof typeof registerBenefits];

    useEffect(() => {
        return () => eventTrack('close Yummclub popup', 'close-Yummclub-popup', 'Close Yummclub register/login popup without loign');
    }, []);

    return (
        <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'center', gap: '16px', margin: '24px'}}>
            {benefitsContent &&
                <RegisterBenefitsContainer>
                    {benefitsContent}
                </RegisterBenefitsContainer>
            }
            {salesforce_login_url ?
                <MahouLogin
                    login_url={salesforce_login_url + `&state=${state}`}
                    giftedCreditAmount={params?.giftedCreditAmount}
                />
                :
                <RegisterOrLogin
                    defaultAction={params?.defaultAction}
                    showTitle={!benefitsContent}
                    from={params?.openBy || from}
                    showFormByDefault={MAIL_BY_DEFAULT.includes(params?.openBy || '')}
                    defaultValues={defaultValues}
                />
            }
        </div>
    );
}

type props={
    from: string;
};
