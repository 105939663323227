import styled, { keyframes } from 'styled-components';
import React, { ReactChild, ReactChildren, ReactNode } from 'react';

const FloatingDiv = styled.div<FloatingDivProps>`
    display: flex;
    justify-content: center;
    flex-direction: column;
    position: ${({outsidePopup, fixed, top}) => outsidePopup ? 'absolute' : fixed ? '' : top ? '' : 'fixed'};
    background: #05C0C7;
    border-radius: 13.4006px;
    bottom: ${({outsidePopup, top}) => outsidePopup ? "" : top ? '' : "80px"};
    top: ${({outsidePopup}) => outsidePopup ? "-72px" : ""};
    padding: 8px;
    color: #FFF;
    width: ${({ fixed, top }) => fixed||top ? "100%" : "90%"};
    z-index: 2;
    box-sizing: border-box;
    animation: ${({ fixed }) => fixed ? 'none' : ''};
    animation-name: ${({ isOpen }) => (isOpen ? fadeInUp : fadeOutDown)};
    animation-duration: 1.5s;
    animation-timing-function: ease-out;
    animation-delay: 0.5s;
    animation-fill-mode: both;
    opacity: 0;
    white-space: ${({ fixed }) => (fixed ? 'nowrap' : '')};
`;

const BizumDiscountBox = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 9px 43px 9px 36px;
    gap: 10px;
    color: #05C0C7;
    font-weight: 600;
    line-height: 16px;
    background: rgba(5, 192, 199, 0.1);
    border-radius: 5px;
    white-space: nowrap;
    margin-bottom: 15px;
    margin-top: -8px;
    font-size: 14px;
`;

const fadeInUp = keyframes`
    from {
        opacity: 0;
        transform: translate3d(0, 100%, 0);
    }
    
    to {
        opacity: 1;
        transform: none;
    }
`;

const fadeOutDown = keyframes`
    from {
        opacity: 1;
        transform: none;
    }
    
    to {
        opacity: 0;
        transform: translate3d(0, 100%, 0);
    }
`;

const PromoConditions = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 4px;
    width: 100%;
    font-size: ${({theme}) => theme.font_xs};
    
    button {
        display: flex;
        align-items: center;
        gap: 4px;
        font-family: ${({theme}) => theme.typography};
        font-size: ${({theme}) => theme.font_xs};
        line-height: 1em;
        color: ${({theme}) => theme.background};
        background-color: transparent;
        border: 2px solid currentColor;
        border-radius: ${({theme}) => theme.radius_round};
        padding: 4px 6px;
    }
`;

const BizumOffer = styled.span`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4px;

    strong {
        color: #05C0C7;
        background-color: ${({theme}) => theme.background};
        border-radius: 4px;
        padding: 0 4px;
    }

    svg{
        margin-bottom: 2px;
    }
`;

const InfoPopupContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
    color: ${({theme}) => theme.text};
    padding: 16px 24px;

    h2 {
        font-size: ${({theme}) => theme.font_lg};
        font-weight: 700;
        margin: 0 0 -8px;
    }

    p {
        font-size: ${({theme}) => theme.font_md};
        line-height: 1.3em;
        text-align: center;
        max-width: 28ch;
        margin: 0;
    
        b {
            font-weight: 700;
        }
    
        span {
            font-weight: 600;
        }
    }

    & > span {
        font-size: ${({theme}) => theme.font_xs};
        color: ${({theme}) => theme.gray_70};
    }

    hr {
        width: 100%;
        border: none;
        border-top: 2px solid ${({theme}) => theme.gray_40};
        margin: 0;
    }
`;

const InfoPopupHeader = styled.h3`
    font-size: ${({theme}) => theme.font_lg};
    font-weight: 400;
    line-height: 1.3em;
    text-align: center;
    margin: 0;

    b {
        font-weight: 600;
    }
`;

type FloatingDivProps = {
  children: ReactChild | ReactChildren | Element | ReactNode | string;
  onClick?: () => void;
  isOpen: boolean;
  outsidePopup?: boolean;
  fixed?: boolean;
  top?: boolean;
}

const BannerBizum = ({
  children,
  onClick,
  isOpen, 
  outsidePopup = false,
  fixed,
  top
}: FloatingDivProps): JSX.Element => {
  return (
    <div 
        onClick={onClick}
        style={{
            padding: outsidePopup ? '0 20px' : !fixed ? "16px 20px" : "", 
            pointerEvents: isOpen ? 'all' : 'none', 
            marginTop: fixed ? "10px" : 0
        }}
    >
        <FloatingDiv isOpen={isOpen} outsidePopup={outsidePopup} fixed={fixed} top={top}>
        {children}
        </FloatingDiv>
    </div>
  )
};

export {
    BannerBizum,
    PromoConditions,
    BizumOffer,
    BizumDiscountBox,
    InfoPopupContainer,
    InfoPopupHeader,
};