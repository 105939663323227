import React from 'react'
import { useTheme } from 'styled-components';
import { BadgeContainer, BlackText } from './BadgeContainters'

type Props = {
  color: string
  textColor?: string
  children: React.ReactNode
  shadow?: boolean
  className?: string
  size?: 'md' | 'sm'
};

export const BadgeComponent = ({color, textColor, children, shadow, size = 'md', className = ''}: Props) => {
  const theme: any = useTheme();

  const allClassNames = `${size} ${className}`.trim();

  return (
    <BadgeContainer textColor={textColor} color={color || theme.primary} shadow={shadow} className={allClassNames}>
      {children}
    </BadgeContainer>
  );
};

type DiscountProps = {
  discount?: number
  type?: 'percentage' | 'amount'
  className?: string
};

export const DiscountBadge = ({discount, type, className = ''}: DiscountProps) => {
  const theme: any = useTheme();
  
  if (!discount || !type) return <></>;

  return (
    <BadgeComponent color={theme.discount} className={className} size='sm'>
      <BlackText>{`-${discount}${type === 'percentage' ? '%' : '€'}`}</BlackText>
    </BadgeComponent>
  );
};