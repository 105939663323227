import styled from "styled-components";

export const OuterConainer = styled.div`
  width: 100%;
  background-color: ${({theme}) => theme.category_bar_bg};
  padding: 6px 24px;
  overflow: auto;
  box-sizing: border-box;
  
  &::-webkit-scrollbar {
    display: none;
  }
`;

export const InnerContainer = styled.div`
  width: max-content;
`;

export const TabContainer = styled.div<TabProps>`
  position: relative;
  display: flex;
  gap: 8px;
  align-items: center;
  width: fit-content;
  opacity: ${({disabled}) => disabled ? 0.5 : 1};
`;

export const Label = styled.label<{selected?: boolean}>`
  font-size: ${({theme}) => theme.font_md};
  font-weight: ${({selected}) => selected ? 600 : 500};
  line-height: 1rem;
  text-align: center;
  text-transform: lowercase;
  white-space: nowrap;
  color: ${({selected, theme}) => selected ? theme.category_button_text_selected : theme.category_button_text};
  background-color: ${({theme}) => theme.category_button_bg};
  border-radius: ${({theme}) => theme.radius_round};
  padding: 8px 16px;
  transition: 0.5s cubic-bezier(.35,.07,.22,1.04);

  span {
    position: relative;
    z-index: 2;
  }

  &::first-letter {
    text-transform: uppercase;// Capitalize doesn't work wiht all caps text
  }

  input {
    opacity: 0;
    width: 0;
    position: absolute;
  }
`;

export const ScrollButton = styled(Label).attrs({as: 'button'})`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 2rem;
  width: 2rem;
  border: none;
  padding: 0;
  position: sticky;
  z-index: 3;
  box-shadow: 0 0 0 4px ${({theme}) => theme.category_bar_bg};

  @media (pointer: coarse) {
    display: none;
  }

  &:first-of-type {
    left: 0;
  }

  &:last-of-type {
    right: 0;
  }

  svg {
    height: 1rem;
    width: 1rem;
  }
`;

export const Slider = styled.div`
  position: absolute;
  top: 0;
  height: 100%;
  background-color: ${({theme}) => theme.category_button_bg_selected};
  border-radius: ${({theme}) => theme.radius_round};
  box-shadow: ${({theme}) => theme.shadow_xs};
  z-index: 1;
  transition: 0.5s cubic-bezier(.35,.07,.22,1.04);
`;

type TabProps = {
  disabled?: boolean;
}
