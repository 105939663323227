import styled from 'styled-components';
import React from "react"; 

type CardContainerProps = {
    smallInnerWidth?: boolean;
    children: JSX.Element|JSX.Element[]|React.ReactNode;  
    onClick?: () => void;
    typeOfCard?: string;
    style?: any;
    innerHeight?: number;
    innerWidth?: number;
}

type CardProps = {
    smallInnerWidth?: boolean;
    children: JSX.Element|JSX.Element[]|React.ReactNode;
    onClick?: () => void;
    typeOfCard?: string;
    style?: any;
    innerHeight?: number;
    innerWidth?: number;
}

const CardContainer = styled.div<CardContainerProps>`
    box-shadow: ${props => props.typeOfCard === 'inCart' ? "none" : props.typeOfCard === 'o&p' ? "0px 7.24638px 18.1159px rgba(149, 157, 165, 0.1)" : props.typeOfCard === "loyalty" ? "" : props.typeOfCard ===  "feedback" ? "0px 8px 20px rgba(149, 157, 165, 0.2)" : "0px 12px 12px 15px rgb(149 157 165 / 20%)"};
    filter: ${props => props.typeOfCard === "loyalty" ? "drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))" : ""};
    background: ${props => props.typeOfCard === 'feedback' ? "#FFF7E5" : "#FFFFFF"};
    box-shadow: ${props => props.typeOfCard === 'inCart' ? "none" : props.typeOfCard === 'feedback' ? "0px 8px 20px 0px #959DA533" : props => props.typeOfCard === 'saveCard' ? "0px 8px 20px 0px #959DA533" : "0px 8px 20px rgba(149, 157, 165, 0.1);"};
    border-radius: ${props => props.typeOfCard === 'inCart' ? "none" : props => props.typeOfCard === 'o&p' ? "10px" : props.typeOfCard === 'feedback' ? "15px" : "5px"};
    margin:  ${props => props.typeOfCard === "loyalty" ? "20px 10px" : "0 16px"};
    margin-bottom: ${props => props.typeOfCard === 'bill' ||  props.typeOfCard === 'o&p' ? '' : props => props.typeOfCard === 'feedback' ? '' : '20px'};
    display: flex;
    flex-direction: ${props => props.typeOfCard === 'bill' || props.typeOfCard === 'inCart' ? "column" : props.typeOfCard === 'o&p' ? 'column' : 'row'};
    align-items: ${props => props.typeOfCard === 'bill' || props.typeOfCard === 'inCart' ? '' : props.typeOfCard === 'o&p' ? '' : 'center'};
    justify-content: ${props => props.typeOfCard === 'feedback' ? 'center' : 'space-between'};
    padding-right: ${props => props.typeOfCard === 'bill' || props.typeOfCard === 'inCart' ? '' :  props.typeOfCard === 'o&p' ? '' : props => props.typeOfCard === 'feedback' ? "" : props => props.typeOfCard === 'saveCard' ? "" : '20px'};
    border: ${props => props.typeOfCard === 'inCart' ? "none" : props.typeOfCard === 'o&p' ? "2px solid rgba(221, 221, 221, 0.4)" : props.typeOfCard === 'feedback' ? "none" : "1px solid rgba(64, 64, 64, 0.15)"};
`;

const NewCardContainer = styled.div<CardContainerProps>`
    filter: drop-shadow(0px 2.97095px 7.42737px rgba(0, 0, 0, 0.1));
    background: #FFFFFF;
    border-radius: 25px;
    margin: 0 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-right: 20px;
    border: 1.07902px solid #404040;
    text-transform: uppercase;
    width: ${props => props.innerWidth ? props.innerWidth-40+"px" : "90vw"};
    position: absolute; 
    margin-top: -60px;
`;

const EachProductFromList = styled.div<CardContainerProps>`
    margin: 0 10px 10px;
    display: flex;
    align-items: center;
    background: #FFFFFF;
    border: 0.905797px solid #D8D8D8;
    border-radius: 10px;
    flex-direction: column; 
`;


const Card = ({
    smallInnerWidth,
    children,
    onClick,
    typeOfCard, 
    style
}: CardProps)=> {
    return (
      <CardContainer
      smallInnerWidth={smallInnerWidth}
      onClick={onClick}
      style={style}
      typeOfCard={typeOfCard}
      >
          {children}
      </CardContainer>
    )
};

const NewCard = ({
    smallInnerWidth,
    children,
    innerHeight,
    innerWidth,
    onClick,
    typeOfCard, 
    style
}: CardProps)=> {
    return (
      <NewCardContainer
      smallInnerWidth={smallInnerWidth}
      innerHeight={innerHeight}
      innerWidth={innerWidth}
      onClick={onClick}
      style={style}
      typeOfCard={typeOfCard}
      >
          {children}
      </NewCardContainer>
    )
};

const ProductListCard = ({
    smallInnerWidth,
    children,
    innerHeight,
    innerWidth,
    onClick,
    typeOfCard, 
    style
}: CardProps)=> {
    return (
      <EachProductFromList
      smallInnerWidth={smallInnerWidth}
      innerHeight={innerHeight}
      innerWidth={innerWidth}
      onClick={onClick}
      style={style}
      typeOfCard={typeOfCard}
      >
          {children}
      </EachProductFromList>
    )
};

const BannerWarning = styled.div`
    background: rgba(242, 153, 133, 0.2);
    border-radius: 4px;
    font-weight: 700;
    padding: 20px 10px;
    text-align: center;
    box-shadow: 0px 8px 20px rgba(149, 157, 165, 0.2);
    margin-bottom: 10px;
    filter: drop-shadow(0px 8px 40px #EDE9E7);
    margin: 20px;
`;

export {CardContainer, BannerWarning, Card, NewCard, ProductListCard};