import React from "react";
import styled, { keyframes } from "styled-components";

type Props = {
  parentComponent: string;
  style?: React.CSSProperties,
  loaderStyle?: React.CSSProperties,
};

export const LoadingComponent = ({style={}, loaderStyle={}, parentComponent}: Props) => {
/*   useEffect(() => {
    const reportLongLoading = () => {
      console.error(new Error(`maximum loading time exceeded at component "${parentComponent}"`));
      postError(new Error(`maximum loading time exceeded at component "${parentComponent}"`));
    };

    const timeout = setTimeout(reportLongLoading, MAX_LOADING_SECONDS);

    return () => clearTimeout(timeout);
  }, []); */

  return(
    <Loading style={style}>
      <LoadingItem style={loaderStyle}/>
    </Loading>
  )
};

const Loading = styled.div`
    display: grid;
    justify-content: center; 
    align-items: center;
    margin: 40px 0;
`;

const spin = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const LoadingItem = styled.div`
  display: inline-block;
  width: 48px;
  height: 48px;
  border-radius: 9999px;
  border: 4px solid;
  border-color: ${({theme}) => theme.background_sunken || '#E1E1E1'};
  border-top-color:  ${({theme}) => theme.primary || '#9DEECB'};
  animation: ${spin} 1s linear infinite;
  -webkit-animation: ${spin} 1s linear infinite;
`;



