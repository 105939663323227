import { Fragment, useCallback, useEffect, useContext } from "react";
import ScrollToTop from "./ScrollToTop";
import * as Sentry from '@sentry/react';
import { Route, Navigate, Routes, useLocation, useNavigate } from "react-router-dom";
import { LayoutContainer } from "../shared/layout";
import { AuthContext } from "../context/auth/AuthContext";
import { HeaderBar } from "../elements/header-bar/HeaderBar";
import { QrUuidReader } from "../elements/qr-uuid-reader";
import { ZeroSixCards } from "../pages/zerosix/zerosix-cards";
import { PaymentError } from "../elements/payment-error";
import { lazyImportHandler } from "./lazyImportHandler";
import { VerificationEmail } from "../elements/register/VerificationEmail";
import { Request } from "../api/auth/Login.api";
import { postError } from "../shared/utils/postError";

const Home                  = lazyImportHandler(() => import("../pages/home"                    ), 'Home'                  );
const QrReader              = lazyImportHandler(() => import("../pages/qr-reader"               ), 'QrReader'              );
const PaymentSuccessful     = lazyImportHandler(() => import("../pages/payment-successful"      ), 'PaymentSuccessful'     );
const CompletePayment       = lazyImportHandler(() => import("../pages/complete-payment"        ), 'CompletePayment'       );
const Event                 = lazyImportHandler(() => import("../pages/event"                   ), 'Event'                 );
const EventSuccessful       = lazyImportHandler(() => import("../pages/event-payment-successful"), 'EventPaymentSuccessful');
const Profile               = lazyImportHandler(() => import("../pages/profile"                 ), 'Profile'               );
const Menu                  = lazyImportHandler(() => import("../pages/menu"                    ), 'Menu'                  );
const Legal                 = lazyImportHandler(() => import("../pages/legal"                   ), 'Legal'                 );
const ForgotPassword        = lazyImportHandler(() => import("../pages/forgot-password"         ), 'ForgotPassword'        );
const Register              = lazyImportHandler(() => import("../pages/register"                ), 'Register'              );
const SuccessForgotPassword = lazyImportHandler(() => import("../pages/success-forgot-password" ), 'SuccessForgotPassword' );
const Login                 = lazyImportHandler(() => import("../pages/auth"                    ), 'Login'                 );
const PersonalInfo          = lazyImportHandler(() => import("../pages/personal-info"           ), 'PersonalInfo'          );
const ChangePassword        = lazyImportHandler(() => import("../pages/change-password"         ), 'ChangePassword'        );
const LatestBills           = lazyImportHandler(() => import("../pages/latest-bills"            ), 'LatestBills'           );
const RestaurantBill        = lazyImportHandler(() => import("../pages/restaurant-bill"         ), 'RestaurantBill'        );
const SavedRestaurants      = lazyImportHandler(() => import("../pages/saved-restaurants"       ), 'SavedRestaurants'      );
const LoyaltyCards          = lazyImportHandler(() => import("../pages/loyalty"                 ), 'LoyaltyCards'          );
const LoyaltyDetails        = lazyImportHandler(() => import("../pages/loyalty-details"         ), 'LoyaltyDetails'        );
const SavedCards            = lazyImportHandler(() => import("../pages/saved-cards"             ), 'SavedCards'            );
const AddCard               = lazyImportHandler(() => import("../pages/add-card"                ), 'AddCard'               );
const RestaurantGroup       = lazyImportHandler(() => import("../pages/restaurant-group"        ), 'RestaurantGroup'       );
const Feedback              = lazyImportHandler(() => import("../pages/feedback"                ), 'Feedback'              );
const DeliveryTakeaway      = lazyImportHandler(() => import("../pages/delivery-takeaway"       ), 'DeliveryTakeaway'      );
const EventCatalog          = lazyImportHandler(() => import("../pages/event-catalog"           ), 'EventCatalog'          );
const Components            = lazyImportHandler(() => import("../pages/components"              ), 'Components'            );
const NotFound              = lazyImportHandler(() => import("../pages/not-found"               ), 'NotFound'              );
const SquareAuth            = lazyImportHandler(() => import("../pages/square-auth"             ), 'SquareAuth'            );
const ZeroSixLoyaltyCards   = lazyImportHandler(() => import("../pages/zerosix"                 ), 'ZeroSixLoyaltyCards'   );
const BookingLanding        = lazyImportHandler(() => import("../pages/booking-landing"         ), 'BookingLanding'        );
const Booking               = lazyImportHandler(() => import("../pages/booking"                 ), 'Booking'               );
const BookingSuccessful     = lazyImportHandler(() => import("../pages/booking-successful"      ), 'BookingSuccessful'     );
const AccountVerification   = lazyImportHandler(() => import("../pages/account-verification"    ), 'AccountVerification'   );
const MyAccount             = lazyImportHandler(() => import("../pages/my-account"              ), 'MyAccount'             );
const WebviewAndroidGpay    = lazyImportHandler(() => import("../pages/webview-android-gpay"    ), 'WebviewAndroidGpay'    );
const CreditHistory         = lazyImportHandler(() => import("../pages/credit-history"          ), 'CreditHistory'         );
const Wallet                = lazyImportHandler(() => import("../pages/wallet"                  ), 'Wallet'                );
const CashPayment           = lazyImportHandler(() => import("../pages/cash-payment"            ), 'CashPayment'           );
const MahouAuth             = lazyImportHandler(() => import("../pages/mahou-auth"              ), 'MahouAuth'             );

type AuthenticatedRouteProps = {
  children: any;
};

const AuthenticatedRoute = ({ children }: AuthenticatedRouteProps) => {
  const { isLogged } = useContext(AuthContext);
  const {isVerified, loadingVerification, error} = Request.useUserVerification(isLogged);

  const type = localStorage.getItem("type");
  const navigate = useNavigate();

  if (loadingVerification && !error) {
    return null; // Don't render anything until useUserVerification check is completed
  }

  if(error){
    navigate('/booking_landing/fdasdfas') //using this to kick users out
  }

  if (isLogged && !isVerified && type === 'internal') {
    return <Navigate
      to= '/verify_account'
      //state={location}
    />
  }
  return children;
};

export const BootstrapRouting = () => {
  const { isLogged } = useContext(AuthContext);
  const { isVerified } = Request.useUserVerification(isLogged);
  const location = useLocation();
  
  const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

  const handleRedirectRegister = useCallback((isVerified: boolean) => {
      const restaurantGroupUuid = sessionStorage.getItem("restaurantGroupUuid") || "";
      const goBackUrl = location?.state as { goBackUrl: string };
      const goBackUrlString:string = goBackUrl?.goBackUrl
      const qrUuid = sessionStorage.getItem('qrUuid') || "";
      if (goBackUrlString) {
        return <Navigate to={goBackUrlString}/>;
      } else {
        if(restaurantGroupUuid){
          return <Navigate to={`/restaurant_group/${restaurantGroupUuid}`}
          state={ isVerified } />;
        } else if (qrUuid) {
          return <Navigate to={{pathname: `/${qrUuid}`}} state= { isVerified }/>;
        }
        return <Navigate to={'/profile'}/>;
      }
  }, []);

  useEffect(() => {
    try {
      // We do not allow page translations of any type
      const root = document.getElementById("root");
      if (root && "classList" in root) {
        root.setAttribute("translate", "no");
        root.classList.add('notranslate');
      }
    } catch (e: any) {
      console.log(e)
      postError(e)
    }

    const setDynamicSizes = () => {
      const root = document.documentElement;

      root.style.setProperty(`--dvh`, `${window?.visualViewport?.height || innerHeight}px`)
      root.style.setProperty(`--dvw`, `${window?.visualViewport?.width || innerWidth}px`)
    };

    setDynamicSizes();

    if (window.visualViewport) {
      window.visualViewport.addEventListener('resize', setDynamicSizes);
    } else {
      window.addEventListener('resize', setDynamicSizes);
    }

    return () => {
      if (window.visualViewport) {
        window.visualViewport.removeEventListener('resize', setDynamicSizes);
      } else {
        window.removeEventListener('resize', setDynamicSizes);
      }
    };
  }, []);

  const RouteWrapper = (process.env.REACT_APP_ENVIRONMENT !== 'prod') ? Routes : SentryRoutes

  return (
    <Fragment>
      <ScrollToTop>
        <RouteWrapper>
          <Route path="/:qr_uuid"
                element={
                  <QrUuidReader />
                }>
          </Route>
          <Route path="/home"
                element={
                  <AuthenticatedRoute>
                    <QrReader />
                  </AuthenticatedRoute>
                }>
          </Route>
          <Route path="/checkout"
                element={
                  <AuthenticatedRoute>
                    <Home />
                  </AuthenticatedRoute>
                }>
          </Route>
          <Route path="/event"
                element={
                  <AuthenticatedRoute>
                    <Event />
                  </AuthenticatedRoute>
                }>
          </Route>
          <Route path="/restaurant_group/:uuid"
                element={
                  <AuthenticatedRoute>
                    <RestaurantGroup />
                  </AuthenticatedRoute>
                }>
          </Route>
          <Route path="/delivery_takeaway/:uuid"
                element={
                  <AuthenticatedRoute>
                    <DeliveryTakeaway />
                  </AuthenticatedRoute>
                }>
          </Route>
          <Route path="/booking_landing/:uuid"
                element={
                  <AuthenticatedRoute>
                    <BookingLanding />
                  </AuthenticatedRoute>
                }>
          </Route>
          <Route path="/booking/:uuid"
                element={
                  <AuthenticatedRoute>
                    <Booking />
                  </AuthenticatedRoute>
                }>
          </Route>
          <Route path="/booking_successful"
                element={
                  <AuthenticatedRoute>
                    <BookingSuccessful />
                  </AuthenticatedRoute>
                }>
          </Route>
          <Route path="/event_catalog"
                element={
                  <AuthenticatedRoute>
                    <EventCatalog />
                  </AuthenticatedRoute>
                }>
          </Route>
          <Route path="/event-payment-successful"
                element={
                  <AuthenticatedRoute>
                    <LayoutContainer>
                      <EventSuccessful />
                    </LayoutContainer>
                  </AuthenticatedRoute>
                }>
          </Route>
          <Route path="/cash-payment"
                element={
                  <AuthenticatedRoute>
                    <CashPayment/>
                  </AuthenticatedRoute>
                }>
          </Route>
          <Route path="/mahou-auth"
                element={
                  <AuthenticatedRoute>
                    <MahouAuth/>
                  </AuthenticatedRoute>
                }>
          </Route>
          <Route path="/payment-successful"
                element={
                  <LayoutContainer>
                    <PaymentSuccessful />
                  </LayoutContainer>
                }>
          </Route>
          <Route path="/complete-payment"
                element={
                  <AuthenticatedRoute>
                    <LayoutContainer>
                      <CompletePayment />
                    </LayoutContainer>
                  </AuthenticatedRoute>
                }>
          </Route>
          <Route path="/profile"
                element={
                  <AuthenticatedRoute>
                    <LayoutContainer>
                      <Profile />
                    </LayoutContainer>
                  </AuthenticatedRoute>
                }>
          </Route>
          <Route path="/payment-error"
                element={
                  <AuthenticatedRoute>
                      <PaymentError />
                  </AuthenticatedRoute>
                }>
          </Route>
          <Route path="/menu"
                element={
                  <AuthenticatedRoute>
                      <Menu />
                  </AuthenticatedRoute>
                }>
          </Route>
          <Route path="/legal"
                element={
                  <AuthenticatedRoute>
                      <Legal />
                  </AuthenticatedRoute>
                }>
          </Route>
          <Route path="/register"
                element={
                  isVerified ?
                    handleRedirectRegister(isVerified)
                    :
                    <LayoutContainer>
                      <Register />
                    </LayoutContainer>
                }>
          </Route>
          <Route path="/verify_account"
                element={
                  isVerified ?
                    handleRedirectRegister(isVerified)
                    :
                    <LayoutContainer>
                      <VerificationEmail />
                    </LayoutContainer>
                }>
          </Route>
          <Route path="/forgot-password"
                element={
                  <LayoutContainer>
                    <ForgotPassword />
                  </LayoutContainer>
                }>
          </Route>
          <Route path="/success-forgot-password"
                element={
                  <LayoutContainer>
                    <SuccessForgotPassword />
                  </LayoutContainer>
                }>
          </Route>
          <Route path="/login"
                element={
                  <LayoutContainer>
                    <Login />
                  </LayoutContainer>
                }>
          </Route>
          <Route path="/my-account"
                element={
                  <AuthenticatedRoute>
                    <LayoutContainer>
                      <MyAccount/>
                    </LayoutContainer>
                  </AuthenticatedRoute>
                }>
          </Route>
          <Route path="/personal-info"
                element={
                  <AuthenticatedRoute>
                    <LayoutContainer>
                      <PersonalInfo/>
                    </LayoutContainer>
                  </AuthenticatedRoute>
                }>
          </Route>
          <Route path="/change-password/:token"
                element={
                  <LayoutContainer>
                    <HeaderBar />
                    <ChangePassword />
                  </LayoutContainer>
                }>
          </Route>
          <Route path="/latest-bills"
                element={
                  <AuthenticatedRoute>
                    <LayoutContainer>
                      <LatestBills/>
                    </LayoutContainer>
                  </AuthenticatedRoute>
                }>
          </Route>
          <Route path="/wallet"
                element={
                  <AuthenticatedRoute>
                    <Wallet/>
                  </AuthenticatedRoute>
                }>
          </Route>
          <Route path="/restaurant-bill"
                element={
                  <AuthenticatedRoute>
                    <LayoutContainer>
                      <RestaurantBill/>
                    </LayoutContainer>
                  </AuthenticatedRoute>
                }>
          </Route>
          <Route path="/saved-restaurants"
                element={
                  <AuthenticatedRoute>
                    <LayoutContainer>
                      <SavedRestaurants/>
                    </LayoutContainer>
                  </AuthenticatedRoute>
                }>
          </Route>
          <Route path="/loyalty-cards"
                element={
                  <AuthenticatedRoute>
                    <LayoutContainer>
                      <LoyaltyCards/>
                    </LayoutContainer>
                  </AuthenticatedRoute>
                }>
          </Route>
          <Route path="/credit-history"
                element={
                  <AuthenticatedRoute>
                    <LayoutContainer>
                      <CreditHistory/>
                    </LayoutContainer>
                  </AuthenticatedRoute>
                }>
          </Route>
          <Route path="/zerosix"
                element={
                  <AuthenticatedRoute>
                    <LayoutContainer>
                      <ZeroSixLoyaltyCards/>
                    </LayoutContainer>
                  </AuthenticatedRoute>
                }>
          </Route>
          <Route path="/zerosix-cards"
                element={
                  <AuthenticatedRoute>
                    <LayoutContainer>
                      <ZeroSixCards/>
                    </LayoutContainer>
                  </AuthenticatedRoute>
                }>
          </Route>
          <Route path="/loyalty-details"
                element={
                  <AuthenticatedRoute>
                    <LayoutContainer>
                      <LoyaltyDetails/>
                    </LayoutContainer>
                  </AuthenticatedRoute>
                }>
          </Route>
          <Route path="/saved-cards"
                element={
                  <AuthenticatedRoute>
                    <LayoutContainer>
                      <SavedCards/>
                    </LayoutContainer>
                  </AuthenticatedRoute>
                }>
          </Route>
          <Route path="/add-card"
                element={
                  <AuthenticatedRoute>
                    <LayoutContainer>
                      <AddCard/>
                    </LayoutContainer>
                  </AuthenticatedRoute>
                }>
          </Route>
          <Route path="/webview-android-gpay"
                element={
                  <AuthenticatedRoute>
                    <LayoutContainer anotherBackground={true}>
                      <WebviewAndroidGpay />
                    </LayoutContainer>
                  </AuthenticatedRoute>
                }>
          </Route>
          <Route path="/feedback"
                element={
                  <AuthenticatedRoute>
                    <Feedback />
                  </AuthenticatedRoute>
                }>
          </Route>
          <Route path="/account-verification/:uuid"
                element={
                  <AuthenticatedRoute>
                    <AccountVerification />
                  </AuthenticatedRoute>
                }>
          </Route>
          {window.location.hostname === 'localhost' &&
            <Route path="/components"
              element={
                <AuthenticatedRoute>
                  <Components />
                </AuthenticatedRoute>
              }
            >
            </Route>
          }
          <Route path="/square-auth-result"
            element={
                <LayoutContainer>
                  <SquareAuth/>
                </LayoutContainer>
            }>
          </Route>
          <Route path="*"
            element={
              <AuthenticatedRoute>
                <LayoutContainer>
                  <HeaderBar/>
                  <NotFound/>
                </LayoutContainer>
              </AuthenticatedRoute>
            }>
          </Route>
        </RouteWrapper>
      </ScrollToTop>
    </Fragment>
  );
};
