export enum ButtonVariant {
    Primary = 'primary',
    PrimaryDark = 'primary-dark',
    Secondary = 'secondary',
    Tertiary = 'tertiary',
    SecondaryLowercase = "secondary-lowercase",
    SecondaryCapitalize = "secondary-capitalize",
    SecondaryColor = "secondary-color",
    SecondaryDark = 'secondary-dark',
    Neutral = 'neutral',
    SecondaryBgColor = 'secondary-bg-color',
    Facebook = 'facebook',
    Link = 'link',
}
