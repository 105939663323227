import * as React from 'react';
import * as ReactDOM from 'react-dom';
import cn from 'classnames';
import { confirmPayment, CardInput, CardInputProps, createToken, PaymentRequest, PaymentRequestProps, GooglePay, GooglePayProps, Bizum, BizumProps } from '@monei-js/components';
import { useContext } from 'react';
import { getSavedCards, intitateC2Payment } from './monei.service';
import { AppContext } from '../../context/AppContext';
import { toast } from 'react-toastify';
import { t } from 'i18next';
import PropTypes, {InferProps} from 'prop-types';
import { Loading } from '../loading';
import "./monei.css"
import { calculateEuros } from '../../pages/checkout/functions/calculatePrices';
import { useTheme } from 'styled-components';
import { CardIcon } from '../ayden';
import { AuthContext } from '../../context/auth/AuthContext';
import { useNavigate } from "react-router-dom";
import { Trans } from 'react-i18next';
import { Button } from '../buttons';
import { ButtonType, ButtonVariant } from '../buttons/enums';
import { Icon, IconType } from '../icons';
import { Container, SaveCardContainer, Wrapper } from './MoneiCardContainers';
import { C2PInstructive } from '../../popups';
import { UserContext, UserContextDispatchContext } from '../../context/auth/UserContext';
import { postError } from '../../shared/utils/postError';
import { checkStock } from '../../api/stock/Stock';
import { TextInput } from '../inputs';
import { EMAIL_REGEX } from '../../shared/constants/regex';
import { getEmailFunctions } from '../../shared/utils/clientInputsLogic';
import { useFeatureIsOn } from '@growthbook/growthbook-react';
import { GB_FEATS } from '../../growthbook';
import { useCommentAndAditionalInfo } from '../../shared/utils/useCommentAndAditionalInfo';
import { useViewport } from '../../context/ViewportContext';
import { usePopupNavigation } from '../../shared/utils/usePopupNavigation';
import { DARK_THEMES } from '../../shared/themes/themeGroups';

// Set React driver for Card Input Component
const CardInputComponent: React.FC<CardInputProps> = CardInput.driver('react', {
  React: React,
  ReactDOM: ReactDOM
});
const GooglePayComponent: React.FC<GooglePayProps> = GooglePay.driver('react', {
    React: React,
    ReactDOM: ReactDOM
  });
const BizumComponent: React.FC<BizumProps> = Bizum.driver('react', {
    React: React,
    ReactDOM: ReactDOM
  });

const SharedComponent: React.FC<PaymentRequestProps> = PaymentRequest.driver('react', {
  React: React,
  ReactDOM: ReactDOM
});

export function MoneiPayComponent({
  type,
  amount,
  tip,
  myName,
  onSuccess,
  howPaySelected,
  loyaltyDiscount,
  accountId,
  phoneForCollect,
  nameForCollect,
  bizumPromotionCents,
  onOutOfStock,
  tokenHandler,
  isLoading,
  setLoading,
  setPaymentResult,
  restaurantCreditId
}: MoneiPayComponentProps) {

  const {
    state: {
      checkout: {
        myTotal,
        selectedItems,
        selectedModifiers,
        clientCommission,
        orderAndPayItems,
        freeProducts,
        savedCards,
        otherCardSelected,
        paymentMethod,
        zeroSixSelectedVoucher,
        generalDiscountCents,
        productDiscountCents,
        codeCoupon,
        codeCouponCents,
        creditCents,
        credit,
        checkoutFlow,
        selectedSlot,
        deliveryFeeCents,
        moneiAccountId,
        remaining,
        otherPayments,
      },
      restaurantCustomization,
      addressData,
      tableNumber: tableNumberFromContext,
    },
    dispatch,
  } = useContext(AppContext);

  const { isLogged } = useContext(AuthContext);
  const { user: { email } } = useContext(UserContext);
  const { updateUser } = useContext(UserContextDispatchContext);
  const { closePopup } = usePopupNavigation();
  const { isMobile } = useViewport();

  const navigate = useNavigate();
  const theme: any = useTheme();

  const showYumminnLogo = useFeatureIsOn(GB_FEATS.SHOW_YUMMINN_LOGO);

  const serviceCommision =  Math.floor(((myTotal-tip) * clientCommission)/ 100);
  const restaurantId = Number(sessionStorage.getItem('restaurantId'));
  const tableNumber = sessionStorage.getItem("tableNumber") || tableNumberFromContext || "";

  const date = new Date().toISOString(). //ex: '2012-11-04T14:51:06.157Z'
      replace(/T/, '_').      // replace T with a space
      replace(/\..+/, '')     // delete the dot and everything after

  const orderId = date + '-' + restaurantId + '-' + tableNumber
  const uniqueId = () => Math.random().toString(36).substr(2, 9);

  const androidDeviceId = localStorage.getItem('androidDeviceId')
  const isAndroidWebview = Boolean(androidDeviceId);
  // Create ref for CardInputComponent
  const ref = React.useRef();
  const isEmailRequired = true;
  const bizumDiscountCents = paymentMethod === 'bizumpay' && (myTotal - tip) >= 1500 ? bizumPromotionCents : 0;
  const zeroSixDiscountCents = zeroSixSelectedVoucher.code != "" ? (!zeroSixSelectedVoucher.is_percentage ? zeroSixSelectedVoucher.value * 100 : Math.round((((myTotal - tip) *  zeroSixSelectedVoucher.value) / 100))) : 0
  const amountMinusDiscounts = Math.max(amount - generalDiscountCents - productDiscountCents - codeCouponCents, 0);
  const amountMinusDiscountsAndCredit = Math.max(amountMinusDiscounts - creditCents, 0);
  const creditUsed = Math.min(creditCents, amountMinusDiscounts);
  const amountMinusAllDiscounts = Math.max(amountMinusDiscountsAndCredit, 0);
  const finalLoyaltyDiscount = Math.max(loyaltyDiscount > amountMinusAllDiscounts ? amountMinusAllDiscounts : loyaltyDiscount, 0)
  const finalAmount = Math.max(amount - finalLoyaltyDiscount - generalDiscountCents - productDiscountCents - codeCouponCents - creditCents - bizumDiscountCents - zeroSixDiscountCents, 0) + Number(serviceCommision) + deliveryFeeCents;
  const containerSaveCardsRef = React.useRef(null);

  const [isFocused, setFocused] = React.useState(false);
  const [hasLoadedCards, setHasLoadedCards] = React.useState(false);
  const [saveCardChecked, setSaveCardChecked] = React.useState(false);
  const [error, setError] = React.useState<string | null>(null);
  const [errorGoogleOrApple, setErrorGoogleOrApple] = React.useState<string | null>(null);
  const [selectedSavedCard, setSelectedSavedCard] = React.useState<any>(null);
  const [isMounted, setIsMounted] = React.useState(true);
  const [showC2PInstructive, setShowC2PInstructive] = React.useState(false);
  const [emailError, setEmailError] = React.useState('');
  const [cashPaymentDisabled, setCashPaymentDisabled] = React.useState(!!emailError || (!email || !EMAIL_REGEX.test(email)));
  const [sessionId] = React.useState(() => uniqueId());
  
  const { checkEmail, handleEmailChange } = getEmailFunctions({
    email,
    emailError,
    isEmailRequired,
    setEmailError,
    updateUser,
  });

  const paymentId: any = null;
  const paymentUuid: any = null;

  const comment = useCommentAndAditionalInfo()

  // User shouldn't be able to get to this popup if there is an error with the fee, but this is just in case.
  const invalidDeliveryFee = Number.isNaN(deliveryFeeCents);

  // Custom styling can be passed to options when creating a Card Input Component.
  const style = {
    input: {
      color: theme.text,
      fontFamily: theme.typography,
      backgroundColor: theme.input_bg,
      fontSmoothing: 'antialiased',
      fontSize: '16px',
      '::placeholder': {
        color: theme.text_a50,
      },
      '-webkit-autofill': {
        backgroundColor: theme.input_bg,
      }
    },
    invalid: {
      color: theme.danger,
      borderColor: theme.danger,
    }
  };

  React.useEffect(() => {
    if (invalidDeliveryFee) toast.error(t('calculate_delivery_fee_error'));

    return () => {
      setIsMounted(false); // Set the flag to false when the component is unmounted
    };
  }, []);

  React.useEffect(() => {
    if(isMounted){
      //console.log('isMounted')
      const saveCardsDivExist = !!(containerSaveCardsRef.current)
      getSavedCards(restaurantId).then((result) => {
        //console.log('getSavedCards answered')
        setLoading(false)
        setHasLoadedCards && setHasLoadedCards(true)
        if (saveCardsDivExist) {
            dispatch({
              type: 'UPDATE_CHECKOUT',
              payload: {savedCards: result}
            })
          }
      })
      .catch((e: any) => {
          postError(e)
          setLoading(false)
          toast.error(t('error_obtaining_saved_cards'));
        });
    }
  }, [containerSaveCardsRef, isMounted]);

  const context = useContext(AppContext)

  const saveContextToLocalStorage = () => {
    try {
      if (context && context.state) {
        const yumminnContext = JSON.stringify(context.state.checkout)
        try{
          localStorage.setItem('yumminn_context', yumminnContext);
        } catch (err: any){
          console.log(`saveContextToLocalStorage: ${err}`)
          postError(err, yumminnContext, "yumminn_context")
        }
      }
    }
    catch (e: any) {
      postError(e)
      console.error("Error setting yumminn_context, monei-pay.component.tsx : 288");
    }
  }

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event?.preventDefault?.();
    setLoading(true);

    try {
      saveContextToLocalStorage();

      // Pass current CardInputComponent ref to create token
      const result = await createToken(ref.current);
      setSelectedSavedCard(null)
      if (result.error) {
        // Inform the user if there was an error.
        setError(result.error);
      } else {
        setError(null);
        // Send the token to your server.
        tokenHandler(result.token!, sessionId);
      }
    } catch(e) {
        const error = e as Error;
        postError(error);
        toast.error(error?.message ? error.message : t('adyen_checkout_error'));
        setLoading(false);
    }
  };

  const handleSubmitOtherMethods = async (response: any) => {
    setLoading(true);

    try {
      saveContextToLocalStorage();
      setSelectedSavedCard(null);
      setSaveCardChecked(false);
      if (response.error) {
        setErrorGoogleOrApple(response.error);
      } else {
        setErrorGoogleOrApple(null);
        tokenHandler(response.token, sessionId);
      }
    } catch (e) {
      const error = e as Error;
      postError(error);
      toast.error(error?.message ? error.message : t('adyen_checkout_error'));
    }
  };

  const handleChange = () => {
    setSaveCardChecked(!saveCardChecked)
  };

  const handleSelectSavedCard = (card:any) => {
    setSelectedSavedCard(card)
  };

  const handleOtherCard = () => {
    dispatch({
      type: 'UPDATE_CHECKOUT',
      payload: {
        otherCardSelected: !otherCardSelected,
      }
    })
  }

  const openC2PInstructive = () => setShowC2PInstructive(true);
  const closeC2PInstructive = () => setShowC2PInstructive(false);

  const handleContinueC2P = async () => {
    try {
      if (invalidDeliveryFee) {
        toast.error(t('calculate_delivery_fee_error'));
        return;
      }

      closeC2PInstructive();
      setLoading(true);
      saveContextToLocalStorage();
      setSelectedSavedCard(null);
      setSaveCardChecked(false);

      const paymentData = {
        captureDelayHours: 1,
        base_amount: amount,
        tip: Number(Number(tip).toFixed(2)),
        delivery_fee_cents: deliveryFeeCents || 0,
        split_type: howPaySelected,
        name: myName,
        user: null,
        client: localStorage.getItem("client_id") != 'undefined' ? localStorage.getItem("client_id") : null,
        amount: {
          currency: 'EUR',
          value: finalAmount
        },
        restaurant: restaurantId,
        table: tableNumber,
        orderId,
        items: selectedItems,
        modifiers: selectedModifiers,
        origin: window.location.origin,
        loyalty_discount_cents: finalLoyaltyDiscount,
        credit_cents: creditUsed,
        credit_id: credit,
        general_discount_cents: generalDiscountCents,
        product_discount_cents: productDiscountCents,
        code_coupon: codeCoupon?.code ? codeCoupon.code : null,
        code_coupon_cents: codeCouponCents,
        service_commission: serviceCommision,
        session_id: sessionId,
        type,
        orderAndPayItems,
        freeProducts,
        comment,
        client_phone: phoneForCollect,
        client_name: nameForCollect,
        save_card_checked: saveCardChecked,
        selected_card: selectedSavedCard ? selectedSavedCard.id : null,
        bizum_promotion_cents: bizumDiscountCents,
        device_token: localStorage.getItem('deviceToken'),
        zerosix_discount_cents: zeroSixDiscountCents,
        selected_zerosix_voucher_code: zeroSixSelectedVoucher.code != "" ? zeroSixSelectedVoucher.code : null,
        restaurant_credit_id: restaurantCreditId
      }

      await intitateC2Payment(paymentData)
        .then((result) => {
          if ("reason" in result && result["reason"]){
            setPaymentResult("status_code" in result ? result["status_code"] : "E101")
          }
          if ("code" in result && result["code"] == 333){
            toast.error(t('Remaining has been updated'));
            setTimeout(() => navigate("/payment-error", {state: {reason: 'updatedRemaining'}}), 2000)
          } else if ("error_code" in result && result["error_code"] == 444){
            if ("uuid" in result && result["uuid"]){
              toast.error(t('The restaurant does not accept any more orders'));
              setTimeout(() => navigate('/restaurant_group/'+ result["uuid"]), 2000)
            }
          } else if ("error_code" in result && result["error_code"] == 445){
            toast.error(t(result["message"]));
            setTimeout(() => navigate('?home/id=' + restaurantId + 'table=0'), 2000)
          } else {
            const paymentId = result && "paymentId" in result ? result["paymentId"] : null
            if (paymentId) {
              confirmPayment({
                paymentId: paymentId,
                allowedPaymentMethods: ['card', 'clickToPay'],
                fullscreen: true
              })
              .then(function (result) {
                // Payment result
                window.open(result.nextAction?.redirectUrl, "_self")
            })
              .catch(function (error) {
                // Something went wrong while confirming payment
                console.log(error);
                postError(error);
                setLoading(false);
              });
            } else {
              processPaymentResponse(result)
            }
          }
        })
        .catch((e) => {
          setLoading(false);
          postError(e);
          console.log("intitateC2Payment error:", e);
          toast.error(t('adyen_checkout_error'));
        });
    } catch (e) {
      const error = e as Error;
      postError(error);
      toast.error(error?.message ? error.message : t('adyen_checkout_error'));
    } finally {
      setLoading(false);
    }
  }

  const handleWebViewAndroidGpay = async() => {

    const token = localStorage.getItem("token_yumminn");
    const type = localStorage.getItem("type");

    checkStock(token, type, {
      order_items: orderAndPayItems,
      free_products: freeProducts,
      table: tableNumber,
      restaurant: restaurantId
    })
    .then((response) => {
      if (response.data && Object.keys(response.data).length > 0) {
        const data: any = response.data;
        const outOfStockProducts = data["out_of_stock_products"];
        const outOfStockModifiers = data["out_of_stock_modifiers"];
        onOutOfStock(outOfStockProducts, outOfStockModifiers);
      }

      else {

        const paymentData = {
          captureDelayHours: 1,
          base_amount: amount,
          tip: Number(Number(tip).toFixed(2)),
          delivery_fee_cents: deliveryFeeCents || 0,
          split_type: howPaySelected,
          name: myName,
          user: null,
          client: localStorage.getItem("client_id") != 'undefined' ? localStorage.getItem("client_id") : null,
          amount: {
            currency: 'EUR',
            value: finalAmount
          },
          restaurant: restaurantId,
          table: tableNumber,
          orderId,
          items: selectedItems,
          modifiers: selectedModifiers,
          origin: window.location.origin,
          general_discount_cents: generalDiscountCents,
          product_discount_cents: productDiscountCents,
          code_coupon: codeCoupon?.code ? codeCoupon.code : null,
          code_coupon_cents: codeCouponCents,
          loyalty_discount_cents: finalLoyaltyDiscount,
          credit_cents: creditUsed,
          credit_id: credit,
          service_commission: serviceCommision,
          monei_token: null,
          session_id: sessionId,
          type,
          orderAndPayItems: checkoutFlow === 'OP' ? orderAndPayItems : [],
          freeProducts,
          comment,
          client_phone: phoneForCollect,
          client_name: nameForCollect,
          client_email: email ? email : null,
          save_card_checked: saveCardChecked,
          selected_card: selectedSavedCard ? selectedSavedCard.id : null,
          bizum_promotion_cents: bizumDiscountCents,
          device_token: localStorage.getItem('deviceToken'),
          zerosix_discount_cents: zeroSixDiscountCents,
          selected_zerosix_voucher_code: zeroSixSelectedVoucher.code != "" ? zeroSixSelectedVoucher.code : null,
          delivery_data: addressData,
          selected_slot: selectedSlot ? selectedSlot.id : null,
          moneiAccountId: moneiAccountId,
          sessionId,
          clientCommission,
          serviceCommision,
          bizumDiscountCents,
          zeroSixDiscountCents,
          finalAmount,
          myTotal,
          remaining,
          otherPayments,
          restaurantCustomization,
          restaurantId,
          tableNumber,
          androidDeviceId,
          restaurant_credit_id: restaurantCreditId
        }

        const jsonString = JSON.stringify(paymentData);
        const base64String = btoa(jsonString);

        //saveContextToLocalStorage();
        window.open(`/webview-android-gpay?data=${base64String}`, '_blank')
      }
    })
    .catch(error => {
        console.log("🚀 ~ file: monei-pay-component.tsx:528 ~ handleWebViewAndroidGpay ~ error", error)
        postError(error)
    })
  }

  const handleSubmitWithSavedCard = async () => {
    setLoading(true);
    try {
      saveContextToLocalStorage();
      setSaveCardChecked(false);
      tokenHandler("", sessionId, saveCardChecked, selectedSavedCard);
    } catch (e) {
      const error = e as Error;
      postError(error);
      toast.error(error?.message ? error.message : t('adyen_checkout_error'));
    } finally {
      setLoading(false);
    }
  }

  // ///////////////////////
  // PROCESS PAYMENT
  const processPaymentResponse = (paymentRes: any, hasStatusFlowPage=false) => {
    const response = paymentRes["response"];
    if (response.next_action && response.next_action.type.includes("CHALLENGE")) {
      window.open(response.next_action.redirect_url, "_self")
        // ///////////////////////
        // PAYMENT CONTAINS ACTION
    } else {
        // ///////////////////////
        // PAYMENT WITH NO ACTION
        if (hasStatusFlowPage) {
          setPaymentResult(response.status_code)
        }
        if (response.status == 'SUCCEEDED') {
          setTimeout(() => {
            onSuccess(paymentId, paymentUuid);
          }, 1000);
        } else {
          if (!hasStatusFlowPage) {
            toast.error("Error performing payment", {
              theme: 'colored',
          });
          }
        }
    }
  };

  const handleCashPayment = async () => {
    if (cashPaymentDisabled) return;
    setLoading(true)
    tokenHandler("");

  };

  React.useEffect(() => {
    setCashPaymentDisabled(!!emailError || (!email || !EMAIL_REGEX.test(email)));
  }, [email]);


  const onBeforeSubmitDummy = () => {null};

  return (
    <Container style={{opacity: isLoading ? 0.7 : 1, pointerEvents: isLoading ? 'none' : 'all'}}>
      {type === 'card' && hasLoadedCards && (savedCards.length <= 0 || otherCardSelected) &&
        <div ref={containerSaveCardsRef}>
          <form id="payment-form" onSubmit={handleSubmit}>
            <div className="card-field">
              <div
                id="card-input"
                className={cn({'is-focused': isFocused, 'is-invalid': error !== null})}
              >
                <CardInputComponent
                  ref={ref}
                  accountId={accountId}
                  sessionId={sessionId}
                  style={style}
                  onFocus={() => setFocused(true)}
                  onBlur={() => setFocused(false)}
                  onChange={(event: any) => {
                    // Handle real-time validation errors.
                    if (event.isTouched && event.error) {
                      setError(event.error);
                      setLoading(false);
                    } else {
                      if(event.cardType === 'amex'){
                        setError(t("this card type is not allowed"));
                        setLoading(false);
                      } else {
                        setError(null);
                      }
                    }
                  }}
                />
              </div>
              <div id="card-error">{error}</div>
            </div>
            <div>
              {isLogged &&
                <SaveCardContainer>
                  <label>
                    <input type="checkbox" onChange={handleChange} checked={saveCardChecked} style={{width: "20px"}}/>
                  </label>
                  <span>
                    <Trans i18nKey={'save your card for future payments'}>
                      <b>Save</b> your card and <b>save time</b> on your next payments
                    </Trans>
                  </span>
                  <Icon type={IconType.CardsThemed} colorIcon='primary'/>
                </SaveCardContainer>
              }
              {savedCards.length > 0 && isLogged &&
                <Button
                  title={
                    <span style={{display: 'flex', alignItems: 'center', gap: '4px'}}>
                      {t("select a saved card")}
                      <Icon type={IconType.Arrowforward} size={12}/>
                    </span>
                  }
                  variant={ButtonVariant.Link}
                  handleClick={handleOtherCard}
                  style={{marginTop: '16px'}}
                />
              }
              <div style={{marginTop: '16px'}}>
                <Button
                  loading={isLoading}
                  type={ButtonType.Submit}
                  disabled={(isLoading || !!error)}
                  title={`${t('pay')} ${calculateEuros((finalAmount).toString())}€`}
                />
              </div>
            </div>
          </form>
        </div>
      }
      <div ref={containerSaveCardsRef}>
        {type === 'card' && hasLoadedCards && (savedCards.length > 0 && !otherCardSelected) &&
          <div>
            {savedCards.map((item: any, index: number) => (
              <Wrapper onClick={() => handleSelectSavedCard(item)} key={index} selected={selectedSavedCard?.id == item.id}>
                <CardIcon brand={item.brand} style={{marginRight: '10px'}} />
                <h1><span>****</span> {item.last4}</h1>
                <div style={{flex: 1}} />
                <h2><b>{`${item.expiration.split("-")[1] + ' / ' + item.expiration.split("-")[0].substring(2,4)}`}</b></h2>
              </Wrapper>
            ))}
            {savedCards.length > 0 && isLogged &&
              <Button
                title={
                  <span style={{display: 'flex', alignItems: 'center', gap: '4px'}}>
                    {t("use a new card")}
                    <Icon type={IconType.Arrowforward} size={12}/>
                  </span>
                }
                variant={ButtonVariant.Link}
                handleClick={handleOtherCard}
                style={{marginTop: '16px'}}
              />
            }
          </div>
        }
        {type === 'card' && hasLoadedCards && (savedCards.length > 0 && !otherCardSelected) && isLogged &&
          <div style={{marginTop: '16px'}}>
            <Button
              loading={isLoading}
              handleClick={() => handleSubmitWithSavedCard()}
              disabled={!selectedSavedCard}
              title={`${t('pay')} ${calculateEuros((finalAmount).toString())}€`}
            />
          </div>
        }
      </div>
      {type == "zero_payment" &&
        <Button
          title={t('send_order')}
          handleClick={handleSubmitOtherMethods}
        />
      }
      {type === "paywithgoogle" &&
        (!isLoading && !isAndroidWebview ?
          <GooglePayComponent
            amount={finalAmount}
            currency={'EUR'}
            accountId={accountId}
            sessionId={sessionId}
            onSubmit={(result: any) => handleSubmitOtherMethods(result)}
            onBeforeSubmit={onBeforeSubmitDummy}
            style={{
              height: isMobile ? 44 : undefined,
              color: DARK_THEMES.includes(restaurantCustomization.theme) ? "white" : "black",
            }}
          />
        : !isLoading && isAndroidWebview ?
          <Button
            title={t('continue')}
            handleClick={() => handleWebViewAndroidGpay()}
          />
        :
          <Loading
            parentComponent='MoneiPayComponent paywithgoogle'
            loaderStyle={{height: '36px', width: '36px'}}
            style={{height: '44px', margin: '0'}}
          />
        )
      }
      {type === "applepay" &&
        (!isLoading ?
          <SharedComponent
            amount={finalAmount}
            currency={'EUR'}
            accountId={accountId}
            sessionId={sessionId}
            onSubmit={(result: any) => handleSubmitOtherMethods(result)}
            style={{
              height: 44,
              color: DARK_THEMES.includes(restaurantCustomization.theme) ? "white" : "black",
            }}
          />
        :
          <Loading
            parentComponent='MoneiPayComponent applepay'
            loaderStyle={{height: '36px', width: '36px'}}
            style={{height: '44px', margin: '0'}}
          />
        )
      }
      <div id="google-error">{errorGoogleOrApple}</div>
      {type === 'bizumpay' &&
        (!isLoading ?
          <BizumComponent
            amount={finalAmount}
            currency={'EUR'}
            accountId={accountId}
            sessionId={sessionId}
            onSubmit={(result: any) => handleSubmitOtherMethods(result)}
            style={{height: 44}}
            onOpen={() => closePopup()}
          />
        :
          <Loading
            parentComponent='MoneiPayComponent bizumpay'
            loaderStyle={{height: '36px', width: '36px'}}
            style={{height: '44px', margin: '0'}}
          />
        )
      }
      {type === 'C2P' &&
        (!isLoading ?
          <Button
            title={t('continue')}
            handleClick={openC2PInstructive}
          />
        :
          <Loading
            parentComponent='MoneiPayComponent C2P'
            loaderStyle={{height: '36px', width: '36px'}}
            style={{height: '44px', margin: '0'}}
          />
        )
      }
      <C2PInstructive
        onContinue={handleContinueC2P}
        isOpened={showC2PInstructive}
        header={showYumminnLogo && <Icon type={IconType.LogoColor}/>}
        onClose={closeC2PInstructive}
        noReturn
      />
      {type === 'cash' &&
        <>
          <TextInput
            label={t('mail')}
            name="email"
            placeholder={t('email')}
            onChange={handleEmailChange}
            onBlur={() => checkEmail()}
            value={email}
            error={emailError ? t('valid_email').toString() : undefined}
            required={true}
          />
          <p>{t('We will send you the order code')}</p>
          {!isLoading ?
            <Button
              title={t('continue')}
              handleClick={() => handleCashPayment()}
              disabled={cashPaymentDisabled}
            />
          :
            <Loading
              parentComponent='RedsysPay cash'
              loaderStyle={{height: '36px', width: '36px'}}
              style={{height: '44px', margin: '0'}}
            />
          }
        </>
      }
    </Container>
  );
};

MoneiPayComponent.propTypes = {
  type: PropTypes.string.isRequired,
  amount: PropTypes.number.isRequired,
  loyaltyDiscount:PropTypes.number.isRequired,
  tip: PropTypes.number.isRequired,
  onSuccess: PropTypes.func.isRequired,
  onFailure: PropTypes.func.isRequired,
  howPaySelected: PropTypes.string.isRequired,
  myName: PropTypes.string.isRequired,
};

type MoneiPayComponentProps = {
  accountId: string;
  phoneForCollect?: string;
  nameForCollect?: string
  bizumPromotionCents: number;
  hasLoadedCards?: boolean
  setHasLoadedCards?: React.Dispatch<React.SetStateAction<boolean>>
  tokenHandler:      (paymentToken: string, sessionId?: string, saveCardChecked?: boolean, selectedSavedCard?: any, redsysOrder?: string, googlePayToken?: string, applePayData?: string) => void
  event?: any
  onOutOfStock?: any
  isLoading: boolean,
  setLoading: React.Dispatch<React.SetStateAction<boolean>>
  setPaymentResult?: any
  restaurantCreditId?: number
} & InferProps<typeof MoneiPayComponent.propTypes>;

