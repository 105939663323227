import { t } from 'i18next';
import React from 'react';
import { TextAreaContainer } from './InputContainers';

type Props = {
  name: string
  value: string
  label?: string | React.ReactNode
  placeholder?: string
  disabled?: boolean
  rows?: number
  error?: string | false
  onChange: React.ChangeEventHandler
  onFocus?: React.FocusEventHandler
  onBlur?: React.FocusEventHandler
  varForTranslation?: string
  required?: boolean
};

export const TextArea = ({
  name,
  value,
  label,
  placeholder = '',
  disabled = false,
  rows = 1,
  error,
  onChange,
  onFocus,
  onBlur,
  varForTranslation,
  required
}: Props) => {

  const translateWithInterpolation = (key: string, interpolation?: { [key: string]: any }) => {
    return interpolation ? t(key, interpolation) : t(key);
  };


  return (
    <TextAreaContainer>
      <label>
        {label &&
          typeof label === 'string' ? <h6 className={required ? 'required' : ''}>{t(label)}</h6> : label
        }
        <textarea
          className={name.slice(0,7)}
          id={name}
          name={name}
          value={value}
          placeholder={t(placeholder)}
          disabled={disabled}
          rows={rows}
          onChange={onChange}
          onFocus={onFocus}
          onBlur={onBlur}
        />
      </label>
      {error &&
        <i>{typeof error === 'string' ? translateWithInterpolation(error, { varForTranslation }) : error}</i>
      }
    </TextAreaContainer>
  );
};