import styled from 'styled-components';
import { css,keyframes } from 'styled-components'
import iconBackground from '../../assets/images/redesign/icon_background.svg'
import { ResponsiveSheet } from '../../elements';

const ProfileOuterContainer = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
`;

const ProfileInnerContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: clamp(8px, 3vh, 32px);
    flex: 1;
    margin: 0 24px;

    @media (orientation: landscape){
        flex: 0;
    }
`;

export const ProfilePopup = styled(ResponsiveSheet).attrs({
    landscapePosition: 'left',
})`
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 24px;
    box-sizing: border-box;
    margin: 42px 0 0;

    @media (orientation: landscape) {
        margin: 0 0 0 68px;
        overflow: hidden;
        width: calc(35%);
    
        @media (max-width: 1024px){
            width: calc(45%);
        }
    }

    > header {
        padding: 0;
    }
`;

const PersonalInfoSteps = styled.div<StepsProps>`
    background: ${({theme, completed}) => completed ? theme.primary_10 : theme.danger_10};
    color: ${({theme}) => theme.text};
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 20px;
    margin: 20px 24px 0 24px;
    position: relative;

    b {
        font-weight: 600;
    }
`;

const PointsPositioner = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    translate: 15% -30%;
`;

const fill = keyframes`
100% {
    background-position: left bottom;
  }
`
const animation = () => css`
  animation: ${fill} 1s forwards;
`;

const Rectangle = styled.div<RectangleProps>`
    height: 5px;
    width: 100px;
    position: relative;
    background: ${props => props.green ? 'linear-gradient(to right, #5FB894 50%, #C4C4C4 0)' : '#C4C4C4'};
    background-position: right bottom;
    margin-right: 10px;
    ${props => props.green ? animation : ""}
    transition: ${props => props.green ? 'all .5s ease-out' : 'none'};
    background-size: ${props => props.green ? '200% 100%' : ""};
    display: block;
    border-radius: 9999px;
`;


type RectangleProps = {
    green: boolean;
}

type IconProps = {
    loyalty?: boolean;
}


const ClickButton = styled.div`
    display: grid; 
    grid-template-columns: repeat(6, minmax(0, 1fr));
    padding: 20px 0;
    align-items: center;
`;

const TextWrapper = styled.div`
    grid-column-start: 2; 
    grid-column-end: 6;
    width: 100%;
`;

const TextWrapperFinal = styled.div`
    grid-column-start: 2; 
    grid-column-end: 5;
`;

const IconWrapper = styled.div<IconProps>`
    display: grid;
    justify-content: end;
    margin-right: ${props => props.loyalty ? 0 : "12px"};
`;

const SeparatorLine = styled.div`
    border-width: 2px;
    width: 100%;
    border-top-color: lightgray; 
    border-top-style: solid;
`;

const LightSeparatorLine = styled.div`
    border-width: 2px;
    width: 100%;
    border-top-color: #F5F5F5; 
    border-top-style: solid;
`;

const LogoutButtonWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 12px 32px;
    margin: auto auto 0 0;
    text-decoration: underline;
    color: ${({theme}) => theme.text};
    font-size: ${({theme}) => theme.font_sm};
    font-weight: 500;

    @media (orientation: landscape){
        margin: 40px auto 0 0;
        font-size: ${({theme}) => theme.font_md};
    }
`;

const ClientCardContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: clamp(16px, 2vh, 24px);
    color: ${({theme}) => theme.text};
    text-align: center;
    padding: 24px;

    @media (orientation: landscape) {
        padding: 0 24px 24px 24px;
    }

    h3 {
        font-size: ${({theme}) => theme.font_lg};
        font-weight: 600;
        line-height: 1.3em;
        margin: 0 0 8px 0;

        @media (orientation: landscape) {
            font-size: ${({theme}) => theme.font_l_xl};
        }
    }

    p {
        font-size: ${({theme}) => theme.font_sm};
        margin: 8px 0 0 0;
    }
`;

const ClientCardInnerWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    column-gap: 10px;
    row-gap: 12px;
`;

const ProgressBadgeContainer = styled.div<progressBadge>`
    display: flex;
    align-items: center;
    flex: 40% 1 1;
    gap: 8px;
    padding: ${({type}) => type === 'credit' ? '6px 16px' : '6px 10px'};
    color: #404040;
    background-color: ${({theme, type}) => type === 'credit' ? '#F2FFF2': theme.cash_10};
    border: 1px solid ${({type}) => type === 'credit' ? '#B4DEB4' : '#F4B300'};
    border-radius: 14px;
    text-align: start;
    box-shadow: ${({type}) => type  === 'credit' ? '3px 4px 0px 0px #DFFFDF' : '3px 4px 0px 0px #FFEAB1'};
    justify-content: space-between;
    margin-bottom: ${({type}) => type === 'credit' ? '6px' : ''};
    cursor: pointer;


    img {
        height: 24px;
        width: 24px;
    }

    b {
        display: block;
        font-size: ${({theme}) => theme.font_l_xl};
        line-height: ${({theme}) => theme.font_md};
    }
    
    span {
        display: block;
        font-size: 10px;
        letter-spacing: -0.3px;
        white-space: nowrap;
    }

    svg {
        flex-shrink: 0;
    }

    svg:last-child {
        margin: 0 2px 0 auto ;
    }
`;

const jump = keyframes`
    0% {
        translate: 0 0%;
        transform: scale(1);
    }
    40% {
        transform: scale(0.95, 1.05);
    }
    100% {
        translate: 0 -15%;
        transform: scale(0.95, 1.02);
    }
`;

const ClientStatsContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 4px;
    flex: 1;
    background-color: ${({theme}) => theme.background_color};
    border-radius: ${({theme}) => theme.radius_md};
    border: 1px solid #E6E6E6;
    box-shadow: 3px 4px 0px 0px #E6E6E699;
    padding: 8px;
    color: ${({theme}) => theme.text};
    text-align: start;
    margin: 10px 0;

    h6 {
        font-size: 10px;
        font-weight: 400;
        line-height: 1.1em;
        width: min-content;
        margin: 0;
        z-index: 2;
    }

    div {
        display: flex;
        justify-content: space-between;
        align-items: end;
    }

    i {
        font-size: ${({theme}) => theme.font_md};
        font-style: normal;
        font-weight: 700;
        padding: 0 1px;
        color: ${({theme}) => theme.blue};
        animation: ${jump} 1s ease-in-out alternate infinite;

        &:nth-of-type(2) {
            animation-delay: 0.33s;
        }

        &:nth-of-type(3) {
            animation-delay: 0.66s;
        }
    }

    b {
        color: ${({theme}) => theme.blue};
        font-size: ${({theme}) => theme.font_md};
    }

    span {
        font-size: 10px;
    }

    img {
        box-sizing: border-box;
        height: 30px;
        width: 30px;
        background-image: url(${iconBackground});
        background-size: cover;
        padding: 6px;
        margin: -4px;
    }
`;

const InnerButtonWrapper = styled.span`
    display: flex;
    align-items: center;
    gap: 18px;
    width: 100%;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1em;
    text-align: start;
    color: ${({theme}) => theme.text};
    margin: -20px 8px -20px 8px;

    svg:last-child {
        margin-left: auto;
    }
`;

type progressBadge = {
    type: 'credit' | 'cashback',
    onClick: () => void,
}

type StepsProps = {
    completed: boolean
}

export {
    PersonalInfoSteps,
    Rectangle,
    LightSeparatorLine,
    TextWrapperFinal,
    ProfileOuterContainer,
    ClickButton,
    ProfileInnerContainer,
    TextWrapper,
    IconWrapper,
    SeparatorLine,
    LogoutButtonWrapper,
    PointsPositioner,
    ClientCardContainer,
    ClientCardInnerWrapper,
    ProgressBadgeContainer,
    ClientStatsContainer,
    InnerButtonWrapper,
}
