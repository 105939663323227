import React, { useContext } from 'react'
import { t } from 'i18next';
import { AppContext } from '../../context/AppContext';
import { PriceBadge } from '../../pages/checkout/components/MyTotal';
import { calculateEuros } from '../../pages/checkout/functions/calculatePrices';
import { NO_TIPS_RESTAURANTS } from '../../shared/constants/restaurantIds';
import { ResumedDetailsContainer } from './PaymentDetailContainers'
import { useRestaurantData } from '../../shared/utils/useRestaurantData';

const tipType = {
  custom: "custom",
  good: "5%",
  "very good": "10%",
  amazing: "15%",
  brilliant: "20%",
  default: "0%",
};

const discountTypeToSymbol = {
  "amount": "€",
  "percentage": "%"
};

export const ResumedPaymentDetails = () => {
  const {
    state: {
      checkout: {
        myTotal,
        clientCommission,
        generalDiscount,
        generalDiscountCents,
        productDiscountCents,
        deliveryFeeCents,
        tip: {
          selected,
          amount,
        },
      },
    },
  } = useContext(AppContext);

  const { restaurantId } = useRestaurantData();
  
  const serviceCommision = Math.floor(((myTotal) * clientCommission) / 100);
  const discounts = generalDiscountCents + productDiscountCents;
  const subtotal = myTotal - amount;
  const total = Math.max(subtotal + serviceCommision - discounts, 0) + deliveryFeeCents + amount;

  return (
    <ResumedDetailsContainer>
      <p>
        <span>{t('subtotal')}</span>
        <span>{`${calculateEuros((subtotal).toString())}€`}</span>
      </p>
      {!!amount && !NO_TIPS_RESTAURANTS.includes(restaurantId) &&
        <p>
          <span>
            {t('tip') + ` (${t(tipType?.[(selected as keyof typeof tipType)] || tipType.default)})`}
          </span>
          <span>{calculateEuros(amount) + '€'}</span>
        </p>
      }
      {generalDiscountCents > 0 && generalDiscount &&
        <p>
          <span className='discount'>
            {`${generalDiscount?.payment_details_text} (-${generalDiscount?.value + discountTypeToSymbol[generalDiscount!.discount_type]})`}
          </span>
          <span className='discount'>{`- ${calculateEuros(generalDiscountCents)}€`}</span>
        </p>
      }
      {productDiscountCents > 0 &&
        <p>
          <span className='discount'>{t('product_discount')}</span>
          <span className='discount'>{`- ${calculateEuros(productDiscountCents)}€`}</span>
        </p>
      }
      {serviceCommision > 0 &&
        <p>
          <span className='discount'>{t('service fee')}</span>
          <span className='discount'>{`${calculateEuros(serviceCommision)}€`}</span>
        </p>
      }
      {deliveryFeeCents > 0 &&
        <p>
          <span className='discount'>{t('delivery_fee')}</span>
          <span className='discount'>{`${calculateEuros(deliveryFeeCents)}€`}</span>
        </p>
      }
      <b>
        <span>{t('total')}</span>
        <PriceBadge type='total'>
          {`${calculateEuros((total).toString())}€`}
        </PriceBadge>
      </b>
    </ResumedDetailsContainer>
  );
};