import React, { useContext } from "react";
import { Icon, IconType } from "../../elements/icons";
import { t } from "i18next";
import { PopupType } from "../../context/popups.enum";
import { AuthContext } from "../../context/auth/AuthContext";
import { noHeader, noReturnPopup } from "./ModalOptions";
import { PopupContext } from "../../context/PopupContext";
import { AppContext } from "../../context/AppContext";
import { useFeatureIsOn } from "@growthbook/growthbook-react";
import { GB_FEATS } from "../../growthbook";
import { ResponsiveSheet } from "../../elements";
import styled from "styled-components";

type Props = {
    isOpened: boolean
    header?: React.ReactNode
    children: React.ReactNode
    className?: string
};

const Modal = ({ isOpened, header, children, className }: Props) => {
    const { popup } = useContext(PopupContext);
    const { isLogged } = useContext(AuthContext);
    const { dispatch } = useContext(AppContext);

    const historyLength = popup.history.length;
    const lastPopup = popup.history[historyLength - 1]?.popup;

    const hideHeader = !!noHeader.includes(popup.current);
    const returnButtonOff = !!(popup.current === PopupType.Cart && lastPopup === "NOT_PRODUCTS_IN_TABLE");

    const noReturn = noReturnPopup.includes(popup.current)
        || isLogged && lastPopup === PopupType.Register && historyLength === 1;

    const showCloseButton = popup.current !== PopupType.TipsSmall;


    const afterGoBack = () => {
        if (popup.current === PopupType.TipsSmall) {
            dispatch({
                type: 'UPDATE_TIP',
                payload: {
                    selected: "",
                    amount: 0,
                },
            });
        }
    
        if (popup.current === PopupType.PaymentDetail) {
            const keepDataOnBack = popup.params?.keepDataOnBack;

            !keepDataOnBack && dispatch({
                type: 'UPDATE_CHECKOUT',
                payload: {
                    loyaltyDiscountCents: 0,
                },
            })
        }

        dispatch({
            type: 'UPDATE_PHONE_COORDS_WITHIN_RADIUS',
            payload: null
        });
    };

    const findLandscapePosition = () => {
        let response: "right" | "center" | "left" | "top" | "bottom" | undefined = 'right'

        switch(popup.current){
            case PopupType.NotEnoughCredits:
                response = 'center'
                break;
            case PopupType.Register:
                response = "left";
                if (window.location.pathname !== '/payment-successful') {
                    className= 'isReceiptListOrWalletPopup'
                }
                break;
            case PopupType.WifiData:
                response = 'left';
                className= 'isReceiptListOrWalletPopup'
                break;
            default:
                response = "right";
        }

        return response;
    }

    return (
        <ResponsiveSheet
            landscapePosition={findLandscapePosition()}
            isOpened={isOpened}
            header={header && !hideHeader && (
                typeof header === 'string' ? <h2>{t(header)}</h2> : header
            )}
            afterGoBack={afterGoBack}
            noReturn={noReturn || returnButtonOff}
            className={className}
            showCloseButton={showCloseButton}
        >
            {children}
        </ResponsiveSheet>
    );
};

export const YumminnHeader = () => {
    const showYumminnLogo = useFeatureIsOn(GB_FEATS.SHOW_YUMMINN_LOGO);

    if (!showYumminnLogo) return <></>;
    
    return (
        <YumminnHeaderContainer>
            <Icon type={IconType.LogoColor} size={20}/>
        </YumminnHeaderContainer>
    );
};

const YumminnHeaderContainer = styled.div`
    display: flex;
    width: 100%;
`;

export default Modal;