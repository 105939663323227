import React, {useState } from "react";
import { toast } from "react-toastify";
import { calculateCents } from "../../pages/checkout/functions/calculatePrices";
import { useTranslation } from "react-i18next";
import { CheckboxContainer, TapArea } from "./checkboxContainers";
import { OrderItem } from "../../context/app";
import { Icon, IconType } from "../icons";

export const CheckBoxComponent = ({item, addItem, removeItem, remaining, selectedItems, disabled = false} : props) => {
  const [isChecked, setIsChecked] = useState(false);
  const {t} = useTranslation();
  const handleClick = () => {
    if (disabled) return;

    let selectedTotal = 0
    selectedItems.map((sel_item) => {
      selectedTotal += Number(calculateCents(parseFloat(sel_item.price.replace(",", "."))))
      sel_item.modifiers.map((mod: any) => {
        selectedTotal += parseInt(mod.total_price)
      })
    })
    
    selectedTotal += Number(calculateCents(parseFloat(item.price.replace(",", "."))))
    item.modifiers.map((mod: any) => {
      selectedTotal += parseInt(mod.total_price)
    })

    if (!isChecked){
      if (remaining - selectedTotal >= 0) {
        addItem(item)
        setIsChecked(!isChecked)
      } else {
        toast.error((t('Total selected higher than remaining')), {
          theme: 'colored'
      });
      }
    } else {
      removeItem(item)
      setIsChecked(!isChecked)
    }
};

return (
  <TapArea onClick={handleClick}>
    <CheckboxContainer isChecked={isChecked} isDisabled={disabled}>
      {isChecked &&
        <Icon type={IconType.Check}/>
      }
    </CheckboxContainer>
  </TapArea>
);
};

type props={
  item: any;
  addItem: any;
  removeItem: any;
  remaining: any;
  selectedItems: OrderItem[];
  disabled: boolean;
}
