import React from 'react'
import { Isotype, LogoContainer, Logotype } from './ResponsiveLogoContainers';

type Props = {
  size?: number
  className?: string
  onClick?: () => void
};

export const ResponsiveLogoComponent = ({size = 24, className='', onClick}: Props) => {
  return (
    <LogoContainer onClick={onClick} size={size} className={className}>
      <Isotype size={size}>
        <path d="M19.1948 24.7288H6.17852C3.30934 24.7288 1 22.3773 1 19.527V6.27307C1 3.3515 3.30934 1 6.17852 1H19.1948C22.064 1 24.3733 3.3515 24.3733 6.27307V19.527C24.3034 22.3773 21.994 24.7288 19.1948 24.7288Z" fill="#094553" stroke="white" strokeWidth="1.06102"/>
        <path d="M12.7575 7.27029C13.5305 7.27029 14.1571 6.66413 14.1571 5.9164C14.1571 5.16866 13.5305 4.5625 12.7575 4.5625C11.9845 4.5625 11.3579 5.16866 11.3579 5.9164C11.3579 6.66413 11.9845 7.27029 12.7575 7.27029Z" fill="white"/>
        <path d="M11.5681 21.4507C10.5884 21.7358 9.5387 21.2369 9.1888 20.3106L6.73949 13.1136C6.38959 12.1872 6.94943 11.1896 7.99913 10.8333C8.97886 10.5483 10.0286 11.0471 10.3785 11.9734L12.8278 19.1705C13.1077 20.0968 12.5478 21.0944 11.5681 21.4507Z" fill="white" strokeWidth="0.523589" strokeMiterlimit="10"/>
        <path d="M14.1553 21.4507C15.135 21.7358 16.1847 21.2369 16.4646 20.3106L18.9139 13.1136C19.1938 12.1872 18.704 11.1896 17.7243 10.8333C16.7445 10.5483 15.6948 11.0471 15.4149 11.9734L12.9656 19.1705C12.6157 20.0968 13.1756 21.0944 14.1553 21.4507Z" fill="white" strokeWidth="0.523589" strokeMiterlimit="10"/>
        <path d="M12.8972 21.5224C11.4276 21.5224 10.238 20.311 10.238 18.8146V11.1188C10.238 9.62239 11.4276 8.41101 12.8972 8.41101C14.3668 8.41101 15.5564 9.62239 15.5564 11.1188V18.8146C15.5564 20.311 14.2968 21.5224 12.8972 21.5224Z" fill="white" strokeWidth="0.523589" strokeMiterlimit="10"/>
        <path d="M18.2147 9.26518C18.7558 9.26518 19.1944 8.85044 19.1944 8.33883C19.1944 7.82722 18.7558 7.41248 18.2147 7.41248C17.6736 7.41248 17.235 7.82722 17.235 8.33883C17.235 8.85044 17.6736 9.26518 18.2147 9.26518Z" fill="white"/>
        <path d="M7.50826 9.26518C8.04935 9.26518 8.48798 8.85044 8.48798 8.33883C8.48798 7.82722 8.04935 7.41248 7.50826 7.41248C6.96718 7.41248 6.52854 7.82722 6.52854 8.33883C6.52854 8.85044 6.96718 9.26518 7.50826 9.26518Z" fill="white"/>
      </Isotype>
      <Logotype size={size}>
        <path d="M4.95283 26.4325V24.3678C4.95283 23.707 5.27726 20.1557 4.14175 19.8254C3.81731 19.7428 3.08734 19.3298 2.76291 18.9995C2.11404 18.5039 1.62739 17.9258 1.30296 17.1825C0.97853 16.4392 0.816315 15.6959 0.816315 14.87V9.50174C0.816315 8.67585 1.46518 8.01514 2.27626 8.01514H2.51958C3.33067 8.01514 3.97953 8.67585 3.97953 9.50174V14.87C3.97953 15.3656 4.06064 15.7785 4.30396 16.1914C4.54729 16.6044 4.87172 16.9347 5.19615 17.0999C5.60169 17.3477 6.00724 17.4303 6.49388 17.4303C6.98053 17.4303 7.38607 17.3477 7.79161 17.0999C8.19715 16.8522 8.52159 16.5218 8.6838 16.1914C8.92713 15.7785 9.00823 15.3656 9.00823 14.87V9.50174C9.00823 8.67585 9.6571 8.01514 10.4682 8.01514H10.7115C11.5226 8.01514 12.1715 8.67585 12.1715 9.50174V14.87C12.1715 15.6959 12.0092 16.4392 11.6848 17.1825C11.3604 17.9258 10.8737 18.5039 10.306 18.9995C9.90043 19.3298 9.41378 19.5776 8.92713 19.8254C7.71051 20.3209 8.03494 23.7896 8.03494 24.3678V26.4325C8.03494 27.2584 7.38607 27.9191 6.57499 27.9191H6.33167C5.60169 27.9191 4.95283 27.2584 4.95283 26.4325Z" fill="currentColor"/>
        <path d="M20.0392 20.2384C18.8226 20.2384 17.8493 19.9906 16.9571 19.4951C16.0649 18.9995 15.4161 18.3388 15.0105 17.5129C14.5239 16.6871 14.3616 15.7786 14.3616 14.7875V9.50181C14.3616 8.59333 15.0916 7.93262 15.9027 7.93262C16.7949 7.93262 17.4438 8.67592 17.4438 9.50181V14.8701C17.4438 15.3656 17.5249 15.7786 17.7682 16.1915C18.0115 16.6045 18.3359 16.9348 18.6604 17.1C19.0659 17.3478 19.4715 17.4304 19.9581 17.4304C20.4448 17.4304 20.8503 17.3478 21.2558 17.1C21.6614 16.8522 21.9858 16.5219 22.148 16.1915C22.3913 15.7786 22.4725 15.3656 22.4725 14.8701V9.5844C22.4725 8.67592 23.2024 8.01521 24.0135 8.01521C24.9057 8.01521 25.5546 8.75851 25.5546 9.5844V14.8701C25.5546 15.8612 25.3112 16.7696 24.9057 17.5955C24.4191 18.4214 23.7702 19.0821 22.9591 19.5777C22.2291 19.9906 21.2558 20.2384 20.0392 20.2384Z" fill="currentColor"/>
        <path d="M29.3727 19.8208C28.4805 19.8208 27.8316 19.0775 27.8316 18.2516V12.7182C27.8316 11.8097 28.075 10.9012 28.4805 10.1579C28.8861 9.41461 29.5349 8.7539 30.4271 8.25837C31.3193 7.76283 32.2926 7.59766 33.4281 7.59766C33.9959 7.59766 34.4825 7.68025 34.9692 7.76283C35.4558 7.92801 35.8614 8.09319 36.348 8.34096C36.7535 8.58872 37.1591 8.91908 37.4024 9.24944C37.7268 8.91908 38.1324 8.58872 38.5379 8.34096C38.9435 8.09319 39.4301 7.92801 39.9168 7.76283C40.4034 7.59766 40.9712 7.59766 41.5389 7.59766C42.7555 7.59766 43.7288 7.84542 44.5399 8.25837C45.351 8.7539 45.9999 9.33202 46.4865 10.1579C46.8921 10.9012 47.1354 11.8097 47.1354 12.7182V18.2516C47.1354 19.1601 46.4054 19.8208 45.5943 19.8208C44.7021 19.8208 44.0533 19.0775 44.0533 18.2516V12.7182C44.0533 12.3052 43.9722 11.8923 43.7288 11.5619C43.4855 11.2316 43.2422 10.9012 42.8367 10.6534C42.4311 10.4057 42.0256 10.3231 41.62 10.3231C41.2145 10.3231 40.7278 10.4057 40.4034 10.6534C39.9979 10.9012 39.7545 11.149 39.5112 11.5619C39.2679 11.8923 39.1868 12.3052 39.1868 12.7182V18.2516C39.1868 19.1601 38.4568 19.8208 37.6457 19.8208C36.7535 19.8208 36.1047 19.0775 36.1047 18.2516V12.7182C36.1047 12.3052 36.0236 11.8923 35.7802 11.5619C35.5369 11.2316 35.2936 10.9012 34.8881 10.6534C34.4825 10.4057 34.077 10.3231 33.6714 10.3231C33.2659 10.3231 32.7792 10.4057 32.4548 10.6534C32.0493 10.9012 31.8059 11.149 31.5626 11.5619C31.3193 11.8923 31.2382 12.3052 31.2382 12.7182V18.2516C30.9138 19.1601 30.1838 19.8208 29.3727 19.8208Z" fill="currentColor"/>
        <path d="M50.7054 19.8208C49.8132 19.8208 49.1643 19.0775 49.1643 18.2516V12.7182C49.1643 11.8097 49.4076 10.9012 49.8132 10.1579C50.2187 9.41461 50.8676 8.7539 51.7598 8.25837C52.652 7.76283 53.6253 7.59766 54.7608 7.59766C55.3285 7.59766 55.8152 7.68025 56.3018 7.76283C56.7885 7.92801 57.194 8.09319 57.6807 8.34096C58.0862 8.58872 58.4918 8.91908 58.7351 9.24944C59.0595 8.91908 59.4651 8.58872 59.8706 8.34096C60.2761 8.09319 60.7628 7.92801 61.2494 7.76283C61.7361 7.59766 62.3038 7.59766 62.8716 7.59766C64.0882 7.59766 65.0615 7.84542 65.8726 8.25837C66.6837 8.7539 67.3325 9.33202 67.8192 10.1579C68.2247 10.9012 68.4681 11.8097 68.4681 12.7182V18.2516C68.4681 19.1601 67.7381 19.8208 66.927 19.8208C66.0348 19.8208 65.3859 19.0775 65.3859 18.2516V12.7182C65.3859 12.3052 65.3048 11.8923 65.0615 11.5619C64.8182 11.2316 64.5749 10.9012 64.1693 10.6534C63.7638 10.4057 63.3582 10.3231 62.9527 10.3231C62.5472 10.3231 62.0605 10.4057 61.7361 10.6534C61.3305 10.9012 61.0872 11.149 60.8439 11.5619C60.6006 11.8923 60.5195 12.3052 60.5195 12.7182V18.2516C60.5195 19.1601 59.7895 19.8208 58.9784 19.8208C58.0862 19.8208 57.4373 19.0775 57.4373 18.2516V12.7182C57.4373 12.3052 57.3562 11.8923 57.1129 11.5619C56.8696 11.2316 56.6263 10.9012 56.2207 10.6534C55.8152 10.4057 55.4096 10.3231 55.0041 10.3231C54.5986 10.3231 54.1119 10.4057 53.7875 10.6534C53.3819 10.9012 53.1386 11.149 52.8953 11.5619C52.652 11.8923 52.5709 12.3052 52.5709 12.7182V18.2516C52.2464 19.1601 51.5165 19.8208 50.7054 19.8208Z" fill="currentColor"/>
        <path d="M70.8212 18.2499V8.83475C70.8212 8.33921 71.2267 7.92627 71.7134 7.92627H73.0922C73.5789 7.92627 73.9844 8.33921 73.9844 8.83475V18.2499C73.9844 19.1584 73.2544 19.8191 72.4433 19.8191C71.5511 19.8191 70.8212 19.1584 70.8212 18.2499Z" fill="currentColor"/>
        <path d="M77.9583 19.8199C77.0661 19.8199 76.4173 19.0766 76.4173 18.2507V12.965C76.4173 11.974 76.6606 11.0655 77.0661 10.2396C77.5528 9.41371 78.2017 8.753 79.0127 8.25746C79.9049 7.76193 80.8782 7.51416 82.0948 7.51416C83.3115 7.51416 84.3659 7.76193 85.1769 8.25746C86.0691 8.753 86.718 9.41371 87.1236 10.2396C87.6102 11.0655 87.7724 11.974 87.7724 12.965V18.2507C87.7724 19.1592 87.0424 19.8199 86.2314 19.8199C85.3392 19.8199 84.6903 19.0766 84.6903 18.2507V12.965C84.6903 12.4695 84.6092 12.0566 84.3659 11.6436C84.1225 11.2307 83.7981 10.9003 83.4737 10.7351C83.0681 10.4874 82.6626 10.4048 82.176 10.4048C81.6893 10.4048 81.2838 10.4874 80.8782 10.7351C80.4727 10.9829 80.1482 11.3133 79.986 11.6436C79.7427 12.0566 79.6616 12.4695 79.6616 12.965V18.2507C79.5805 19.1592 78.8505 19.8199 77.9583 19.8199Z" fill="currentColor"/>
        <path d="M91.5028 19.9854C90.6106 19.9854 89.9617 19.2421 89.9617 18.4163V13.1306C89.9617 12.1395 90.2051 11.231 90.6106 10.4051C91.0973 9.57923 91.7461 8.91852 92.5572 8.42299C93.4494 7.92745 94.4227 7.67969 95.6393 7.67969C96.8559 7.67969 97.9103 7.92745 98.7214 8.42299C99.6136 8.91852 100.262 9.57923 100.668 10.4051C101.155 11.231 101.317 12.1395 101.317 13.1306V18.4163C101.317 19.3247 100.587 19.9854 99.7758 19.9854C98.8836 19.9854 98.2348 19.2421 98.2348 18.4163V13.1306C98.2348 12.635 98.1537 12.2221 97.9103 11.8091C97.667 11.3962 97.3426 11.0658 97.0182 10.9007C96.6126 10.6529 96.2071 10.5703 95.7204 10.5703C95.2338 10.5703 94.8282 10.6529 94.4227 10.9007C94.0172 11.1484 93.6927 11.4788 93.5305 11.8091C93.2872 12.2221 93.2061 12.635 93.2061 13.1306V18.4163C93.0439 19.2421 92.3139 19.9854 91.5028 19.9854Z" fill="currentColor"/>
        <path d="M72.4411 4.04484C71.8733 4.04484 71.4678 3.87966 71.0622 3.46672C70.6567 3.05377 70.4945 2.55824 70.4945 2.0627C70.4945 1.48458 70.6567 1.07163 71.0622 0.658689C71.4678 0.245744 71.9544 0.0805664 72.4411 0.0805664C73.0088 0.0805664 73.4144 0.245744 73.8199 0.658689C74.2254 1.07163 74.3877 1.56717 74.3877 2.0627C74.3877 2.64083 74.2254 3.05377 73.8199 3.46672C73.4144 3.87966 72.9277 4.04484 72.4411 4.04484Z" fill="currentColor"/>
      </Logotype>
    </LogoContainer>
  );
};