import { useContext } from 'react';
import { AppContext } from '../../context/AppContext';

export const useCommentAndAditionalInfo = () => {
  const {
    state: {
      checkout: {
        additionalInfoInputs,
      },
    },
  } = useContext(AppContext);

  const additionalInfo = additionalInfoInputs?.map(item => `${item.name}: ${item.value}`).join(', ');
  const comment = sessionStorage.getItem('comment');

  const result = [];

  additionalInfo && result.push(additionalInfo);
  comment && result.push(comment);

  return result.join(' - ');
};