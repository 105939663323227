import React from 'react';
import { InputContainer } from './ProfileInputContainers';
import { RoundCheckbox } from '../round-checkbox';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';
import { parseISO } from 'date-fns';
import { t } from 'i18next';
import { Icon, IconType } from '../icons';
import { DateInput } from '../inputs';

const setDate = (birthday: any) => {
  if (birthday) {
     
    const date: any = moment(parseISO(birthday));
    return date._d;
  }

  return null;
};

export const ProfileDatepickerComponent = ({label, value, placeholder, isCompleted, error, onChange}: props) => {
  value = value ? setDate(moment(value).format("YYYY-MM-DD")) : null;

  return (
    <InputContainer>
      <h4>
        <RoundCheckbox isChecked={isCompleted || false}/>
        {t(label)}
      </h4>
      <DateInput
        id={'profile-input-' + label}
        value={value}
        placeholder={placeholder}
        onChange={onChange}
        rightIcon={<Icon type={IconType.Edit}/>}
        error={error}
      />
    </InputContainer>
  );
};

type props = {
  label: string,
  value: Date | null,
  placeholder: string,
  isCompleted: boolean,
  error?: string,
  onChange: any,
};