import React from 'react'
import { LatestBillsContainer } from './latest-bills.container';
import { ProfilePopup } from '../profile/ProfileContainers';

type Props = {
    isOpened: boolean
    close: () => void
};

export const LatestBillsContainerPopup = ({isOpened, close} : Props) => {
    return (
        <ProfilePopup noReturn isOpened={isOpened} onClose={close} showCloseButton={false} isReceiptListOrWalletPopup={true}>
            <LatestBillsContainer/>
        </ProfilePopup>   
    )
}