import { useContext } from 'react';
import { t } from 'i18next';
import { Button, Icon, IconType } from '../../elements';
import { calculateCashbackCents, calculateEuros, calculatePoints } from '../../pages/checkout/functions/calculatePrices';
import { getProgramsFromStorage } from '../../pages/checkout/functions/getProgramsFromStorage';
import { RegisterContainer, RewardBadge, SpeechBubbleWithText } from './RegisterContainers';
import { AppContext } from '../../context/AppContext';
import { Trans } from 'react-i18next';
import { usePopupNavigation } from '../../shared/utils/usePopupNavigation';
import { PopupType } from '../../context/popups.enum';

type Props = {
  payment?: number
};

export const RegisterSmall = ({payment}: Props) => {
  const {state: {
    checkout: {
      remaining,
      myTotal,
      loyaltyDiscountCents,
    }
  }} = useContext(AppContext);
  
  const { goToPopup } = usePopupNavigation();
  const { noProgram } = getProgramsFromStorage();
  
  const path = location.pathname;
  const paymentInProgress = Math.max(myTotal - loyaltyDiscountCents, 0);
  const points = calculatePoints(payment || paymentInProgress || remaining);
  const cashback = calculateCashbackCents(payment || paymentInProgress || remaining);
  const euros = calculateEuros(cashback);
  const earningPercentage = sessionStorage.getItem('loyalty_earning_percentage') || "0";

  return (
    <div style={{margin: "8px"}}>
    <RegisterContainer>
      <h3>
        <span style={{marginBottom: "10px"}}>{t(path === '/checkout' ? 'get your reward' : 'save your reward')}</span>
        {/*t('register now and win')*/}
        <Trans i18nKey={"register for free, pay your bill and you will get"}>
          register for <b>free</b>, <b>pay</b> your bill and you <b>will get</b>
        </Trans>
      </h3>
      {noProgram || (!points && !cashback) ?
        <div>
          <Icon type={IconType.CashbackColor} size={30}/>
          <strong>{t('exclusive benefits')}</strong>
          <Icon type={IconType.PointColor} size={30}/>
        </div>
      :
        <div>
          {points > 0 &&
            <RewardBadge type={'points'}>
              <Icon type={IconType.PointColor} size={30}/>
              <span>
                <b>{points}</b>
                {t('points')}
              </span>
            </RewardBadge>
          }
          {cashback > 0 &&
            <RewardBadge style={{position: 'relative'}} type={'cashback'}>
              <Icon type={IconType.CashbackColor} size={30}/>
              <span>
                <b>{euros + '€'}</b>
                {t('cashback')}
              </span>
              <SpeechBubbleWithText text={`${earningPercentage}%`} />
            </RewardBadge>
          }
        </div>
      }
      <p>{'*' + t('on your next visit')}</p>
      <Button
        title={t('register')}
        handleClick={() => goToPopup(PopupType.Register, {openBy: 'loyalty'})}
      />
    </RegisterContainer>
    </div>
  );
};