import { useContext } from 'react';
import { Trans } from 'react-i18next';
import { BannerContainer } from './LoyaltyContainers';
import { Icon, IconType } from '../../elements/icons';
import { usePopupNavigation } from '../../shared/utils/usePopupNavigation';
import { PopupType } from '../../context/popups.enum';
import { AppContext } from '../../context/AppContext';
import { useFeatureIsOn } from '@growthbook/growthbook-react';
import { GB_FEATS } from '../../growthbook';

export const ZeroSixVoucherSelector = () => {
  const {
    state: {
      checkout: {
        zeroSixInfo,
        zeroSixSelectedVoucher,
        myTotal,
        tip: {
          amount,
        },
      }
    },
    dispatch
  } = useContext(AppContext);
  
  const { goToPopup } = usePopupNavigation();

  const showZerosix = useFeatureIsOn(GB_FEATS.SHOW_ZEROSIX);

  const filteredZeroSixVouchers = zeroSixInfo?.vouchers?.filter(voucher => {
    return voucher.min_amount * 100 <= myTotal - amount && (
      voucher.is_percentage ||
      (!voucher.is_percentage && myTotal - amount >= voucher.value * 100)
    );
  });

  const availableVouchersKey = filteredZeroSixVouchers.length > 1
    ? "You have zerosix available vouchers"
    : "You have zerosix one available voucher";
  
  const handleClick = () => goToPopup(PopupType.ZeroSixVouchers);

  const handleDeleteSelectedVoucher = () => {
    dispatch({
      type: 'UPDATE_CHECKOUT',
      payload: {
        zeroSixSelectedVoucher: {
          name: "",
          code: "",
          value: 0,
          is_percentage: false,
          expiration: "",
          min_amount: 0
        },
      }
    });
  };

  if (!showZerosix || !filteredZeroSixVouchers?.length) return <></>;

  if (!zeroSixSelectedVoucher.name) return (
    <BannerContainer>
      <Icon type={IconType.ZerosixColor}/>
      <p>
        <Trans
          i18nKey={availableVouchersKey}
          count={filteredZeroSixVouchers.length}
        />
      </p>
      <div onClick={handleClick}>
        <Icon type={IconType.Arrowforward}/>
      </div>
    </BannerContainer>
  );

  return (
    <BannerContainer>
      <Icon type={IconType.ZerosixColor}/>
      <p>{zeroSixSelectedVoucher.name}</p>
      <div onClick={handleDeleteSelectedVoucher}>
        <Icon type={IconType.Close}/>
      </div>
    </BannerContainer>
  );
};