import { ProgressBadgeContainer } from '../ProfileContainers';
import { t } from 'i18next';
import { Icon, IconType } from '../../../elements/icons';
import { calculateEuros } from '../../checkout/functions/calculatePrices';
import { useNavigate } from 'react-router-dom';
import { eventTrack } from '../../../../useGaTracker';
import { useViewport } from '../../../context/ViewportContext';
import { usePopupNavigation } from '../../../shared/utils/usePopupNavigation';
import { PopupType } from '../../../context/popups.enum';

export const ProgressBadge = ({type, amount}: Props) => {
  const navigate = useNavigate();
  const { isMobile } = useViewport();
  const { goToPopup } = usePopupNavigation();

  const handleClick = () => {
    if(isMobile){
      navigate('/loyalty-cards', {state: type})
    } else {
      goToPopup(PopupType.LoyaltyCards, {type: type})
    }
    if(type === 'cashback'){
      eventTrack("00_03_profile_button_07_cashbackPage", "00_03_profile_button_07_cashbackPage", "00_03_profile_button_07_cashbackPage")
    } else {
      eventTrack("00_03_profile_button_06_pointsPage", "00_03_profile_button_06_pointsPage", "00_03_profile_button_06_pointsPage")
    }
  }

  return (
    <ProgressBadgeContainer type={type} onClick={handleClick}>
      <div>
         <div style={{display: 'flex', alignItems: 'center', border: type === 'credit' ? "5px solid transparent" :  'none', gap: '8px'}}>
          <Icon type={type === 'credit' ? IconType.CreditBanner : IconType.CashbackWithBg} size={type === 'credit' ? 30 : 48}/>
          <p style={{marginLeft: type === 'credit' ? "5px" :  ''}}>
            <b>{calculateEuros(amount) + ' €'}</b>
            <span>{t(type === 'credit' ? 'total credits' : 'total cashback')}</span>
          </p>
         </div>
      </div>
      <div style={{display: 'flex', alignItems: 'center', gap: '8px'}}>
        <span>{t(type === 'credit' ? 'see my credits' : 'see my cashback')}</span>
        <Icon type={IconType.Arrowforward} size={12}/>
      </div>
    </ProgressBadgeContainer>
  );
};

type Props = {
  type: 'credit' | 'cashback';
  amount: number;
};