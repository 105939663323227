import { useState, useCallback, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { PageHeaderComponent } from "../../elements/page-header/page-header.component";
import { RequestUser } from "../../api/users/Users.api";
import { Loading, Scrollbar, SquareIcon } from "../../elements";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { PersonalInfoWrapper, PersonalInfoFooter } from "./PersonalInfoContainers";
import { PersonalInfoForm } from "../../forms/personal-info/PersonalInfoForm";
import shieldIcon from '../../assets/images/redesign/shield.svg'
import moment from "moment";
import { postError } from "../../shared/utils/postError";
import { UserContext } from '../../context/auth/UserContext';
import { PopupType } from "../../context/popups.enum";
import { useViewport } from "../../context/ViewportContext";
import { usePopupNavigation } from "../../shared/utils/usePopupNavigation";

const PersonalInfoContainer = () => {
  const { user } = useContext(UserContext);
  const { isMobile } = useViewport();
  const { goToPopup } = usePopupNavigation();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const token: any = localStorage.getItem("token_yumminn");
  const type: any = localStorage.getItem("type");

  const [content, setContent] = useState<any>({});
  const [loading, setLoading] = useState(true);
  const [steps, setSteps] = useState(Array(6));
  //const [completed, setCompleted] = useState(false);

  const [initialValues, setInitalValues] = useState({
    first_name: '',
    last_name: '',
    city: '',
    phone: '',
    birthday: null,
    genre: '',
  });

  useEffect(() => {
    if(token){
      setLoading(true);
      RequestUser.user(token, type)?.then((data: any) => {
        setContent(data.data);

        const phoneData = data?.data?.phone || user.phone;

        const profileData = {
          first_name: data?.data?.first_name || '',
          last_name: data?.data?.last_name || '',
          city: data?.data?.city || '',
          phone: phoneData.startsWith("+") ? phoneData : '',
          birthday: data?.data?.birthday || null,
          genre: data?.data?.genre || '',
        }

        const initialSteps = Object.values(profileData).map(value => !!value)

        setInitalValues(profileData);
        setSteps(initialSteps);
        //setCompleted(initialSteps.filter(value => value === true).length === 6)
        setLoading(false);
      }).catch((error: any) => {
        postError(error)
        console.log(error)
      })
    }
  }, [token, type]);

  useEffect(() => {
    setSteps(Object.values(initialValues).map(value => !!value));
  }, [initialValues]);

   
  const handleUpdate = useCallback((values: any, setSubmitting: any) => {
    const changes = Object.entries(values).reduce((obj, [key, value]) => {
      value && key === 'birthday' && (value = moment(value).format("YYYY-MM-DD"));
      value && value !== initialValues[key as keyof typeof initialValues] && (obj[key] = value as any);
      return obj;
    }, {} as Record<string, string>);

    if (!Object.keys(changes).length) {
      setSubmitting(false);
      return;
    }

      RequestUser.updateuser(token, type, changes)
        .then(() => onSuccess(changes))
         
        .catch((error: any) => onFailure(error))
        .finally(() => setSubmitting(false))
  }, [initialValues]);

  const onSuccess = useCallback((values) => {
    toast.success(t("profile updated"));
    setInitalValues(prevValues => ({...prevValues, ...values}));
  }, []);

   
  const onFailure = useCallback((error: any) => {
    postError(error)
    toast.error(`${error}`);
  }, []);

  const goBack = () => {
    if(isMobile){
      navigate("/my-account")
    } else {
      goToPopup(PopupType.MyAccount)
    }
  }

  return loading? (
    <Loading parentComponent='PersonalInfoContainer'/>
  ) : (
    <Scrollbar maxHeight={"100%"}>
      <PageHeaderComponent
        text={t("personal_info")}
        onGoBack={() => goBack()}
      />
      <PersonalInfoWrapper>
        <SquareIcon className='initials'>
          {user.getInitials()}
        </SquareIcon>
        <b>{content?.email}</b>
        <PersonalInfoForm initialValues={initialValues} steps={steps} onSubmit={handleUpdate} />
        <PersonalInfoFooter>
          {t('confidetial info')}
          <img src={shieldIcon} alt='secured'/>
        </PersonalInfoFooter>
      </PersonalInfoWrapper>
    </Scrollbar>
  );
};

export default PersonalInfoContainer;
