import React from 'react';
import { Icon, IconType } from '../icons';

type Props = {
  paymentMethod: string
  cardBrand?: string | null
  size?: number
};

export const PaymentMethodLogo = ({ paymentMethod, cardBrand, size }: Props) => {
  if (paymentMethod.toLowerCase() === "apple") return <Icon type={IconType.ApplePay} size={size}/>
  if (paymentMethod.toLowerCase() === "gpay") return <Icon type={IconType.GooglePay} size={size}/>
  if (paymentMethod.toLowerCase() === "bizum") return <Icon type={IconType.BizumColor} size={size}/>
  if (cardBrand?.toLowerCase() === "visa") return <Icon type={IconType.Visa} size={size}/>
  if (cardBrand?.toLowerCase() === "mc") return <Icon type={IconType.Master} size={size}/>
  return <></>
};