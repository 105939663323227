import React, { useContext } from 'react'
import styled from 'styled-components';
import { UserContext } from '../../context/auth/UserContext';
import { useTranslation } from 'react-i18next';
import { Payment } from '../../context/app';

type Props = {
  payment?: Payment | null
};

export const ClientData = ({ payment }: Props) => {
  const {
    user: {
      name: contextName,
      phone: contextPhone,
      email: contextEmail,
    },
  } = useContext(UserContext);

  const { t } = useTranslation();

  const name = payment?.client_name || contextName;
  const phone = payment?.client_phone || contextPhone;
  const email = payment?.client_email || contextEmail;
  
  return (
    <ClientDataContainer>
      {name &&
        <p data-after={name}>
          {t('client')}
          <span>{name}</span>
        </p>
      }
      {phone &&
        <p data-after={phone}>
          {t('phone')}
          <span>{phone}</span>
        </p>
      }
      {email &&
        <p data-after={email}>
          {t('mail')}
          <span>{email}</span>
        </p>
      }
    </ClientDataContainer>
  );
};

const ClientDataContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 16px;
  padding: 16px;
  border-radius: ${({theme}) => theme.radius_sm};
  background-color: ${({theme}) => theme.text_a05};

  p {
    display: flex;
    flex-direction: column;
    margin: 0;
    position: relative;
    flex: auto;
    width: 0;
    max-width: min-content;
    font-size: ${({theme}) => theme.font_xs};

    &:after {
      content: attr(data-after);
      position: absolute;
      top: 100%;
      width: fit-content;
      white-space: nowrap;
      border-radius: 8px;
      box-shadow: 0 4px 6px -1px #0001, 0 2px 4px -1px #0001;
      background-color: #FFF;
      padding: 8px;
      pointer-events: none;
      opacity: 0;
      z-index: 9999;
      transition: opacity 0.2s ease-in-out;
    }

    &:first-child::after {
      left: 0;
    }

    &:nth-child(2)::after {
      left: 50%;
      translate: -50% 0;
    }

    &:last-child::after {
      right: 0;
    }

    &:hover::after {
      opacity: 1;
    }
  }

  span {
    font-size: ${({theme}) => theme.font_sm};
    font-weight: 500;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 100%;
  }
`;