import React from "react";
import { ISavedButton } from "./interfaces";
import styled, { useTheme } from 'styled-components';
import { t } from "i18next";
import { Icon, IconType } from "../icons";

export const SavedButtonComponent = ({
  state = false,
  type = 'icon',
  isLoading,
  handleClick,
}: ISavedButton) => {
  const theme: any = useTheme();

  const onClick = (event: any) => {
    event.stopPropagation();
    !isLoading && handleClick(event);
  }

  return (
    type === 'icon' ?
      <ButtonHeart disabled={isLoading} onClick={onClick}>
        <Icon type={IconType.Favorite} size={22} fill={state ? 'currentColor' : 'transparent'}/>
      </ButtonHeart>
    :
      <FavoriteButton disabled={isLoading} state={state} onClick={onClick}>
        <Icon type={IconType.Favorite} size={16} fill={state ? theme.primary : 'transparent'}/>
        {t('favorite')}
      </FavoriteButton>
  );
};

type props = {
  state?: boolean
  functionality?: string
}

const ButtonHeart = styled.button<props>`
  display: flex;
  justify-content: center;
  align-items: center;
  color: currentColor;
  background: transparent;
  border: none;
  border-radius: 9999px;
  padding: 0;
  
  &:disabled {
    opacity: 0.7;
    pointer-events: all;
  }

  @media (orientation: landscape) {
    svg {
      width: 25px;
      height: 25px;
    }
  }
`;

const FavoriteButton = styled.button<props>`
  display: flex;
  align-items: center;
  gap: 4px;
  font-size: ${({theme}) => theme.font_sm};
  font-weight: 600;
  color: ${({theme, state}) => state ? theme.text : '#FFF'};
  background-color: ${({state}) => state ? '#FFF' : 'transparent'};
  border: 2px solid #FFF;
  border-radius: 9999px;
  padding: 4px 8px;
  
  &:disabled {
    opacity: 0.7;
    pointer-events: all;
  }

  
`;