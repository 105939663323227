import styled from "styled-components";

const WelcomeContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  padding: 24px;


  h2 {
    font-size: ${({theme}) => theme.font_xl};
    font-weight: 600;
    line-height: 1;
    margin: 0;
  
  }
  
  & > p, b, span {
    font-size: ${({theme}) => theme.font_lg};
    line-height: 1.3;
    text-align: center;
    max-width: 24ch;
    margin: 0;

    @media (orientation: landscape){
      font-size: ${({theme}) => theme.font_l_xl};
    }
    
    b {
      font-weight: 600;
    }
  }
  
  & > b {
    font-weight: 600;
  }

  & > span {
    font-size: ${({theme}) => theme.font_xs};
    max-width: 30ch;
    margin-bottom: 16px;

    svg {
      margin: 0 0 -2px 2px;
    }
  }

  & > div {
    display: flex;
    //gap: 16px;
  }

  .no_credits_message {
    @media (orientation: landscape){
      font-size: ${({theme}) => theme.font_lg};
    }
  }
`;

export {
  WelcomeContainer,
}