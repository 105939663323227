import React, { useEffect, useContext, useState } from "react";
import { CardsContainer, WalletOuterContainer, WalletCover } from "./WalletContainers";
import { useTranslation, Trans } from 'react-i18next';
import { AppContext } from '../../context/AppContext';
import { RegisterPopup } from '../../popups/register/RegisterPopup';
import Modal from "../modal/Modal";
import { PopupType } from "../../context/popups.enum";
import { Loading, Button } from "../../elements";
import { PopupContext } from "../../context/PopupContext";
import { ThemeProvider } from "styled-components";
import { restaurantTheme } from "../../shared/themes";
import { WalletHeader } from "./WalletHeader";
import WalletCard from "./WalletCard";
import { useDiscounts } from "../../shared/utils/useDiscounts";
import { PaymentMethodsPopup } from "../checkout/components/PaymentMethodsPopup";
import { UseRestaurantTopUpInfo } from "../../api/restaurants/Restaurants.api";
import { FlowStatus } from "../../elements/flow-status";
import { PaymentDetailPopup } from "../checkout/components/PaymentDetailPopup";
import AbsoluteFooter from "../../common/AbsoluteFooter";
import { usePopupNavigation } from "../../shared/utils/usePopupNavigation";
import { useViewport } from "../../context/ViewportContext";

export const WalletContainer = () => {
  const { goToPopup } = usePopupNavigation();
  const { isMobile } = useViewport();
  const restaurantId = Number(sessionStorage.getItem('restaurantId'));
  const { t } = useTranslation();
  const { prevMyTotal, discountApplied, setCreditApplied, setDiscountApplied } = useDiscounts();
  const { content: topUpValues, loading: loadingTopups } = UseRestaurantTopUpInfo(restaurantId);
  const [paymentResult, setPaymentResult] = useState<string | null>(null);
  const [flow, setFlow] = React.useState(null);
  const [redsysChallenge, setRedsysChallenge] = useState<any | null>(null);
  const [restaurantCreditId, setRestaurantCreditId] = useState(0)

  type topUpValueType = {
    id: number
    credit__id: number
    credit__amount: number
    credit__bonus: number
    credit__bonus_type: string
    credit__name: string
  };

  const { state: {
    restaurantCustomization,
    restaurant : {
      name: restaurantName,
      bizumPromotionCents,
    },
    checkout : {
      myTotal
    }
  }, dispatch } = useContext(AppContext);

  const { popup } = useContext(PopupContext);

  const isModalOpen = [
    PopupType.Register,
    PopupType.PaymentMethods,
    PopupType.PaymentDetail,
  ].includes(popup.current);

  useEffect(() => {
    dispatch({
      type: 'UPDATE_ORDER_AND_PAY_ITEMS',
      payload: []
    });

    dispatch({
      type: 'UPDATE_CHECKOUT',
      payload: {
        myTotal: 0,
        remaining: 0,
        orderAndPayItems: [],
        freeProducts: [],
        leftTopay: 0,
        tip: {
          selected: '',
          amount: 0,
        },
        allModifierCategories: [],
        allProductCategories: [],
        allDishesOrder: [],
        selectedModifiers: [],
        selectedItems: [],
        tempOrderAndPayItems: [],
        productList: [],
        selectedProduct: null,
        extrasChosen: [],
        orderItems: [],
        tempTotal: 0,
        customerName: '',
        customerVAT: '',
        totalBill: 0,
        paymentMethod: '',
        savedCards: [],
        otherCardSelected: false,
        orderId: 0,
        otherPayments: [],
        availableLoyaltyCents: 0,
        availableCreditCents: 0,
        zeroSixInfo: {
          points_balance: null,
          points_total: null,
          vouchers: []
        },
        zeroSixSelectedVoucher: {
          name: "",
          code: "",
          value: 0,
          is_percentage: false,
          expiration: "",
          min_amount: 0
        },
        loyaltyDiscountCents: 0,
        generalDiscountCents: 0,
        productDiscountCents: 0,
        codeCouponCents: 0,
        credit: null,
        codeCoupon: null,
        creditCents: 0,
        cardTokenized: false,
        selectedSlot: null,
        splitEvenlyPeopleNumber: 2,
      }
    });
  }, []);

  if (loadingTopups) return (
    <WalletOuterContainer>
      <Loading style={{margin: 'auto'}} parentComponent='WalletContainer'/>
    </WalletOuterContainer>
  );

  if (flow || paymentResult) {
    return (
      <FlowStatus
        flow={flow}
        paymentResult={paymentResult}
        setFlow={setFlow}
        setPaymentResult={setPaymentResult}
        redsysChallenge={redsysChallenge}
        setRedsysChallenge={setRedsysChallenge}
        recharge={true}
      />
    );
  }

  const handleContinue = () => {
    goToPopup(PopupType.PaymentDetail);
  };

  const findExtraCredits = () => {
    const firstTopUpWithBonus  = topUpValues.find((topUp: topUpValueType) => topUp.credit__bonus > 0)
    if (firstTopUpWithBonus) {
      const percentageBonus = Number(firstTopUpWithBonus.credit__bonus)
      return percentageBonus;
    }
    return 0;
  }

  return (
    <ThemeProvider theme={restaurantTheme(restaurantCustomization)}>
      {isMobile && <WalletHeader />}
      <WalletOuterContainer>
        <WalletCover>
          <h1>
            {t('topup_your_wallet')}
          </h1>
          <h2>
            <Trans
              i18nKey="top up your wallet in order to pay for products in this restaurant"
              values={{ restaurantName }}
            >
              Recarga tu monedero para poder pagar los productos de {{ restaurantName }}
            </Trans>
            {findExtraCredits() > 0 &&
              <Trans
                i18nKey="and you will obtain"
                values={{ extraCredits: `${findExtraCredits()}%` }}
              >
                y obtendrás extra
              </Trans>
            }
          </h2>
        </WalletCover>

        <CardsContainer>
          {topUpValues?.sort((a: topUpValueType, b: topUpValueType) => a.credit__id - b.credit__id).map((item: any, index: number) =>
            <WalletCard key={'topUp' + index} selected={item.id === restaurantCreditId} item={item} setRestaurantCreditId={setRestaurantCreditId}/>
          )}
        </CardsContainer>
        {myTotal > 0 &&
          <AbsoluteFooter>
            <Button
              title={t("continue")}
              disabled={false}
              handleClick={() => handleContinue()}
            />
          </AbsoluteFooter>
        }

        <Modal isOpened={isModalOpen}>
        {popup.current === PopupType.Register &&
          <RegisterPopup from={'wallet'}/>
        }
        {popup.current === PopupType.PaymentMethods &&
          <PaymentMethodsPopup
            prevMyTotal={prevMyTotal}
            discountApplied={discountApplied}
            restaurantCreditId={restaurantCreditId}
            setFlow={setFlow}
            setPaymentResult={setPaymentResult}
            setRedsysChallenge={setRedsysChallenge}
          />
        }
        {popup.current === PopupType.PaymentDetail &&
          <PaymentDetailPopup
            setCreditApplied={setCreditApplied}
            setDiscountApplied={setDiscountApplied}
            bizumDiscount={bizumPromotionCents > 0}
            prevMyTotal={prevMyTotal}
            discountApplied={discountApplied}
            setFlow={setFlow}
            setPaymentResult={setPaymentResult}
            setRedsysChallenge={setRedsysChallenge}
          />
        }
        </Modal>
      </WalletOuterContainer>
    </ThemeProvider>
  );
};