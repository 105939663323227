import * as React from 'react';
import { CreditCard, PaymentForm, ApplePay, GooglePay} from 'react-square-web-payments-sdk';
import { useContext } from 'react';
import { AppContext } from '../../context/AppContext';
import PropTypes, {InferProps} from 'prop-types';
import { Container} from './SquarePayContainers';
import { useTheme } from 'styled-components';
import { calculateEuros } from '../../pages/checkout/functions/calculatePrices';
import { t } from 'i18next';

export function SquarePayComponent({
  type,
  amount,
  tip,
  loyaltyDiscount,
  bizumPromotionCents,
  tokenHandler,
  onFailure
}: SquarePayComponentProps) {

  const {
    state: {
      checkout: {
        myTotal,
        clientCommission,
        paymentMethod,
        zeroSixSelectedVoucher,
        generalDiscountCents,
        codeCouponCents,
        creditCents,
        deliveryFeeCents,
        squareLocationId,
        squareAppId
      },
    },
  } = useContext(AppContext);

  const theme: any = useTheme();
  const serviceCommision =  Math.floor(((myTotal-tip) * clientCommission)/ 100);
  const bizumDiscountCents = paymentMethod === 'bizumpay' && (myTotal - tip) >= 1500 ? bizumPromotionCents : 0;
  const zeroSixDiscountCents = zeroSixSelectedVoucher.code != "" ? (!zeroSixSelectedVoucher.is_percentage ? zeroSixSelectedVoucher.value * 100 : Math.round((((myTotal - tip) *  zeroSixSelectedVoucher.value) / 100))) : 0
  const amountMinusDiscounts = Math.max(amount - generalDiscountCents - codeCouponCents, 0);
  const amountMinusDiscountsAndCredit = Math.max(amountMinusDiscounts - creditCents, 0);
  const amountMinusAllDiscounts = Math.max(amountMinusDiscountsAndCredit, 0);
  const finalLoyaltyDiscount = Math.max(loyaltyDiscount > amountMinusAllDiscounts ? amountMinusAllDiscounts : loyaltyDiscount, 0)
  const finalAmount = Math.max(amount - finalLoyaltyDiscount - generalDiscountCents - codeCouponCents - creditCents - bizumDiscountCents - zeroSixDiscountCents, 0) + Number(serviceCommision) + deliveryFeeCents;

  return (
    <Container>
      <PaymentForm
        locationId={squareLocationId}
        applicationId={squareAppId}
        createVerificationDetails={() => ({
          currencyCode: "EUR",
          amount: `${calculateEuros(finalAmount).toString().replace(",", ".")}`,
          intent: 'CHARGE',
          billingContact: {}
        })}
        createPaymentRequest={() => ({
          countryCode: "ES",
          currencyCode: "EUR",
          total: {
            amount: `${calculateEuros(finalAmount).toString().replace(",", ".")}`,
            label: "Total",
          },
        })}
        cardTokenizeResponseReceived={(tokenResult:any, verifyBuyer:any) => {
          if (tokenResult.status && tokenResult.status === "OK" && tokenResult.token) {
            tokenHandler(tokenResult.token, undefined, undefined, undefined, undefined, undefined, undefined, verifyBuyer?.token)
          } else {
            let errorMessage = `Tokenization failed with status: ${tokenResult.status}`;
            if (tokenResult.errors) {
              errorMessage += ` and errors: ${JSON.stringify(
                tokenResult.errors
              )}`
            }
            onFailure(errorMessage)
          }
        }}>
       {type == "applepay" &&
        <div style={{minWidth: "50vw"}}>
          <ApplePay />
        </div>
        }
        {type == "paywithgoogle" && 
          <GooglePay />           
        }
        {type === 'card' &&
          <CreditCard 
            buttonProps={{
              css: {
                color: theme.text,
                fontFamily: theme.typography,
                backgroundColor: theme.primary,
                fontSmoothing: 'antialiased',
                fontSize: '16px',
                '::placeholder': {
                  color: theme.text_a50,
                },
                '::after': {
                  content: 'whatever it is you want to add'
                },
                '-webkit-autofill': {
                  backgroundColor: theme.primary,
                }
              },
            }}
          >{`${t('pay')} ${calculateEuros((finalAmount).toString())}€`}
          </CreditCard>
        }
      </PaymentForm>
    </Container>
  );
}

SquarePayComponent.propTypes = {
  type: PropTypes.string.isRequired,
  amount: PropTypes.number.isRequired,
  loyaltyDiscount:PropTypes.number.isRequired,
  tip: PropTypes.number.isRequired,
  onFailure: PropTypes.func.isRequired,
};

type SquarePayComponentProps = {
  bizumPromotionCents: number;
  tokenHandler:      (paymentToken: string, sessionId?: string, saveCardChecked?: boolean, selectedSavedCard?: any, redsysOrder?: string, googlePayToken?: string, applePayData?: string, squareVerifyToken?: string) => void
} & InferProps<typeof SquarePayComponent.propTypes>;

