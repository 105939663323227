import styled from "styled-components";

const ReviewPointsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;

  div {
    display: flex;
    gap: 8px;
  }

  svg:nth-of-type(2) {
    opacity: 0.5;
  }

  h3 {
    font-size: ${({theme}) => theme.font_lg};
    font-weight: 600;
    line-height: 1em;
    white-space: nowrap;
    margin: 0;
  }
  
  p {
    font-size: ${({theme}) => theme.font_sm};
    line-height: 1em;
    white-space: nowrap;
    color: ${({theme}) => theme.yumminn};
    margin: 0;
  }

  b {
    font-weight: 600;
  }
`

export {
  ReviewPointsContainer,
}