import { WalletContainer } from './wallet.container';
import { WalletPopup } from './WalletContainers';

type Props = {
    isOpened: boolean
    close: () => void
};

export const WalletContainerPopup = ({isOpened, close} : Props) => {
    return (
        <WalletPopup isOpened={isOpened} onClose={close} showCloseButton={false} isReceiptListOrWalletPopup={true}>
            <WalletContainer/>
        </WalletPopup>
    );
};

