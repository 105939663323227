import React from 'react'
import { ProfilePopup } from '../profile/ProfileContainers';
import PersonalInfoContainer from './personal-info.container';

type Props = {
    isOpened: boolean
    close: () => void
};

export const PersonalInfoContainerPopup = ({isOpened, close} : Props) => {
    return (
        <ProfilePopup noReturn isOpened={isOpened} onClose={close} showCloseButton={false} isReceiptListOrWalletPopup={true}>
            <PersonalInfoContainer />
        </ProfilePopup>   
    )
}