import { t } from 'i18next'
import React from 'react'
import { Icon, IconType } from '../../elements'
import { ReviewPointsContainer } from './ReviewPointsContainers'

export const ReviewPoints = () => {
  return (
    <ReviewPointsContainer>
      <div>
        <Icon type={IconType.Amazing}/>
        <Icon type={IconType.Bad}/>
      </div>
      <h3>{t('do you like the service')}</h3>
    </ReviewPointsContainer>
  )
}