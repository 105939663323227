import React, { useContext, useState } from 'react';
import { t } from 'i18next';
import { AppContext } from '../../../context/AppContext';
import { Button, PaymentDetail } from '../../../elements';
import { PaymentDetailContainer } from './PaymentDetailContainer';
import { DashedSeparator } from '../../payment-successful/PaymentSuccessfulContainers';
import BizumDiscountBanner from '../../../campaigns/bizum/BizumDiscountBanner';
import { eventTrack } from '../../../../useGaTracker';
import { useNavigate } from 'react-router-dom';
import { UserContext } from '../../../context/auth/UserContext';
import { saveEmptyUserFields } from '../../../shared/utils/clientInputsLogic';
import { toast } from 'react-toastify';
import { Notice, DisplayLinks } from '../../../elements/payment-detail/PaymentDetailContainers';
import { formatHour } from '../functions/calculatePrices';
import { usePaymentDetails } from './usePaymentDetails';
import PaymentMethods from '../PaymentMethods';
import { UserData } from './UserData';

type Props = {
  bizumDiscount?:      boolean
  discountApplied:     boolean
  prevMyTotal:         number
  event?:              any
  onOutOfStock?:       any
  creditApplied?: boolean
  setFlow?:            any
  setPaymentResult?:   any,
  setRedsysChallenge?: any,
  restaurantCreditId?: number
  setCreditApplied: React.Dispatch<React.SetStateAction<boolean>>
  setDiscountApplied: React.Dispatch<React.SetStateAction<boolean>>
};

export const PaymentDetailPopup = ({
  prevMyTotal,
  discountApplied,
  creditApplied,
  bizumDiscount,
  event,
  onOutOfStock,
  setFlow,
  setPaymentResult,
  setRedsysChallenge,
  restaurantCreditId,
  setCreditApplied,
  setDiscountApplied,
}: Props) => {
  const {
    state: {
      restaurant: {
        isMockup,
        preparationMinutes,
        address,
        companyCIF,
        companyName,
      },
      checkout: {
        myTotal,
        paymentMethods,
        paymentMethod,
        deliveryFeeCents,
        termsAndConditionsLink,
        privacyPolicyLink,
        legalNoticeLink,
        selectedSlot,
      },
      addressData: {
        locality,
        street,
        streetNumber,
        floorAndDoor,
      },
    },
  } = useContext(AppContext);

  const { user: { id: userId, name, phone } } = useContext(UserContext);
  const navigate = useNavigate();

  const [disabled, setDisabled] = useState(true);

  const {
    isTotalDiscounted,
    goToPayment,
  } = usePaymentDetails({ creditApplied, setDiscountApplied });

  const functionality = sessionStorage.getItem('functionality') || '';
  const isDeliTake = ['delivery', 'takeaway'].includes(functionality);
  const isDelivery = functionality === 'delivery'
  const isCashTotem = functionality === 'kiosk' && paymentMethods.includes("Cash");
  const showPaymentMethodsButton = isTotalDiscounted || isCashTotem;

  const [isContinueButtonDisabled, setIsContinueButtonDisabled] = useState(false);

  const addressParts = [street, streetNumber, floorAndDoor, locality];
  const nonEmptyAddressParts = addressParts.filter(part => part.trim() !== '');
  const delivery_address = nonEmptyAddressParts.join(', ');
  const deliveryOrTakeawayInfo = functionality === 'delivery' ? "delivery_info" : 'takeaway_info';
  const address_to_show = functionality === 'delivery' ? delivery_address : address;
  const prep_time_str = functionality === 'delivery' ? `${preparationMinutes}-${preparationMinutes + 15}` : preparationMinutes;

  const continueToPayment = async () => {
    eventTrack("Continue from PaymentDetails", "Continue from PaymentDetails", "Continue from PaymentDetails");
    if (isContinueButtonDisabled) return;

    saveEmptyUserFields(userId, name, phone);
    goToPayment();
  };

  const handleLegalClick = (link_prop:string, link:string) => {
    if (link.endsWith(".pdf")) {
      navigate("/legal", {state: {link: link_prop}})
    } else {
      window.open(link, '_blank');
    }
  };

  const toastContinueError = () => toast.error(t('calculate_delivery_fee_error'));

  return (
    <>
      {bizumDiscount && !isMockup && paymentMethods.includes('Bizum') && paymentMethod !== 'bizumpay' &&
        <BizumDiscountBanner outsidePopup={true}/>
      }
      <PaymentDetailContainer>
        {isDeliTake && !selectedSlot &&
          <p className='delivery-or-takeway-info'>
            <span style={{fontWeight: 600, marginRight: "4px"}}>
              {t(deliveryOrTakeawayInfo, {preparationMinutes: prep_time_str})}
            </span>
            <span style={{wordBreak: 'break-all'}} >{address_to_show}</span>
          </p>
        }
        {!isDelivery && selectedSlot &&
          <p className='delivery-or-takeway-info'>
            <span style={{fontWeight: 600, marginRight: "4px"}}>
              {t("order_program_info", {time: formatHour(selectedSlot.start_minute)})}
            </span>
            <span style={{wordBreak: 'break-all'}} >{address_to_show}</span>
          </p>
        }
        {(!isDeliTake) &&
          <DashedSeparator/>
        }
        <PaymentDetail
          setCreditApplied={setCreditApplied}
          setDiscountApplied={setDiscountApplied}
          bizumDiscount={bizumDiscount}
        />
        <UserData setIsContinueButtonDisabled={setIsContinueButtonDisabled} />
        {termsAndConditionsLink && legalNoticeLink && privacyPolicyLink && companyCIF && companyName && 
          <Notice>
            <span>{companyName} - {companyCIF}</span>
            <DisplayLinks>
              <div style={{display: 'flex', justifyContent: 'center'}}>
                <p>{t('see')}
                <span onClick={() => handleLegalClick('terms_and_conditions_link', termsAndConditionsLink)}>{t('terms and conditions')}</span>
                ,
                <span onClick={() => handleLegalClick('legal_notice_link', legalNoticeLink)}>{t('legal_notice')}</span>
              </p>
              </div>
              <div style={{display: 'flex', justifyContent: 'center'}}>
                <p>{t('or')}
                <span onClick={() => handleLegalClick('privacy_policy_link', privacyPolicyLink)}>{t('privacy policy')}</span>
                </p>
              </div>
            </DisplayLinks>
          </Notice>
        }
        {showPaymentMethodsButton &&
          <PaymentMethods
            bizumDiscount={bizumDiscount}
            disabled={disabled || isContinueButtonDisabled}
            myTotalUpdated={prevMyTotal >= 0 && discountApplied ? prevMyTotal : myTotal}
            event={event}
            onOutOfStock={onOutOfStock}
            setDisabled={setDisabled}
            setFlow={setFlow}
            setPaymentResult={setPaymentResult}
            setRedsysChallenge={setRedsysChallenge}
            restaurantCreditId={restaurantCreditId}
          />
        }
        {!showPaymentMethodsButton &&
          <Button
            title={(['wallet', 'zero_payment'].includes(paymentMethod)) ? t('send order') : t('continue')}
            disabled={isContinueButtonDisabled}
            $disabled={Number.isNaN(deliveryFeeCents)}
            handleClick={continueToPayment}
            disabledClick={toastContinueError}
          />
        }
      </PaymentDetailContainer>
    </>
  );
};
