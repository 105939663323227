import styled from 'styled-components';
import { ResponsiveSheet } from '../popup';

type StepContainerProps = {
  flowStatus: string
};
type HeaderContainerProps = {
  isSuccess: boolean
};

export const FlowStatusComponentContainer = styled.div`
  width: 100%;
  background-color: ${({theme}) => theme.background};
  z-index: 999;
  position: absolute;
  color: ${({theme}) => theme.text_light};

  @media (orientation: landscape) and (min-width: 769px) {
    position: relative;
    z-index: 1;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: 100vh;
    overflow: hidden;
  }
`;

export const HeaderContainer = styled.div<HeaderContainerProps>`
  position: fixed;
  top: 0;
  left: 0;
  overflow: hidden;
  z-index: 1;
  height: 20vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({theme}) => theme.flow_status_header_bg_color};
  color: ${({theme, isSuccess}) => isSuccess ? "transparent" : theme.text_light};
  animation-duration: .7s;
  animation-name:  ${({isSuccess}) => !isSuccess ? "headerDeploy" : "headerOut"};
  animation-fill-mode: forwards;
  transition: height .7s;
  pointer-events: all;
  border-radius: ${({theme}) => `0 0 ${theme.radius_xl} ${theme.radius_xl}`};

  @keyframes headerDeploy {
    from {
      height: 0;
    }
    to {
      height: 20vh;
    }
  }

  @keyframes headerOut {
    from {
      border-radius: ${({theme}) => `0 0 ${theme.radius_xl} ${theme.radius_xl}`};
      height: 20vh;
    }
    to {
      border-radius: 0;
      height: 100vh;
    }
  }

  p {
    font-size: ${({theme}) =>theme.font_l_xl};
    font-weight: 600;

    @media (orientation: landscape) and (min-width: 769px) {
      font-size: ${({theme}) =>theme.font_xxl};
      margin: 0;
    }
  }

  @media (orientation: landscape) and (min-width: 769px) {
    position: relative;
    z-index: 1;
    padding: 0;
    background-color: ${({theme}) => theme.flow_status_header_bg_color};
    color: ${({theme}) => theme.text_light};
    border-radius: 0; 
    max-height: 15vh;
    margin-bottom: 10vh;

    p{
      margin: 0;
    }
  }
`;
export const FlowContainer = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 70vh;
  margin-top: 20vh;

  @media (orientation: landscape) and (min-width: 769px) {
    height: 100%;
    margin-top: 0;
  }
`;

export const StepContainer = styled.div<StepContainerProps>`
  width: 100%;
  display: grid;
  grid-template-rows: 1fr 2fr 1fr;
  grid-template-columns: .5fr 150px; 
  justify-items: center;
  justify-content: center;
  align-items: center;

  @media (orientation: landscape) and (min-width: 769px) {
    display: flex;
    gap: 20px;
    align-items: start;

    .wrap-step{
      display: flex; 
      flex-direction: column; 
      align-items: center;
      justify-content: center;
    }

    p {
      text-align: center;
    }
  }

  p {
    color: ${({theme}) => theme.text};
    font-size: 20px;
    font-weight: 600;
    width: 150px;
    justify-self: left;
  }

  #outline_circle {
    stroke-width: 2px;
    stroke-linejoin: round;
    paint-order: stroke;
    animation-duration: .7s;
    animation-name: blink_outline;
    animation-iteration-count: infinite;
    animation-direction: alternate;
  }

  @keyframes blink_outline {
    from {
       fill: #F2F2F2;
       stroke: #F2F2F2;
    }
    to {
      stroke: #0E95B4;
      fill: #9DEECB;
    }
  }

  path {
    animation-duration: .7s;
    animation-name: blink_path;
    animation-iteration-count: infinite;
    animation-direction: alternate;
  }

  @keyframes blink_path {
    from {
       fill: #DCDCDC;
    }
    to {
       fill: #41D195;
    }
  }

  #processing_payment_icon {
    path {
      animation-name: ${({flowStatus}) => flowStatus == "sending_to_pos" ? "none" : "blink_path"};
    }
    #outline_circle {
      stroke: #0E95B4;
      animation-name: ${({flowStatus}) => flowStatus == "sending_to_pos" ? "none" : "blink_outline"};
    }
  }

  #sending_to_pos_icon {
    path {
      fill: ${({flowStatus}) => flowStatus == "sending_to_pos" ? "#41D195" : "#DCDCDC"};
      animation-name: ${({flowStatus}) => flowStatus == "sending_to_pos" ? "blink_path" : "none"};
    }
    #outline_circle {
      fill: ${({flowStatus}) => flowStatus == "sending_to_pos" ? "#9DEECB" : "#F2F2F2"};
      stroke: ${({flowStatus}) => flowStatus == "sending_to_pos" ? "#0E95B4" : "#F2F2F2"};
      animation-name: ${({flowStatus}) => flowStatus == "sending_to_pos" ? "blink_outline" : "none"};
    }
  }

  #sending_to_pos_text {
    color: ${({flowStatus, theme}) => flowStatus== "sending_to_pos" ? "auto" : theme.gray_20};
  }
`;

export const VerticalLine = styled.div<StepContainerProps>`
  height: 100%;
  justify-self: center;
  width: 3px;
  animation-name: ${({flowStatus}) => flowStatus == "sending_to_pos" ? "makeItfadeIn" : "none"};
  animation-duration: .7s;
  animation-fill-mode: forwards;
  animation-timing-function: ease-in-out;
  background: #F2F2F2;

  @media (orientation: landscape) and (min-width: 769px) {
    height: 3px; //horizontal line now
    width: 200px; //horizontal line now
    margin-top: 38px;
  }
  

  @keyframes makeItfadeIn {
    0% {
      background: #F2F2F2;
    }
    100% {
      background: #0E95B4;
    }
  }
`;
export const BottomInfo = styled.div`
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 10vh;
  background-color: ${({theme}) => theme.background};;

  p {
    color: ${({theme}) => theme.text};
    text-align: center;
  }

  @media (orientation: landscape) and (min-width: 769px) {
    position: relative;
  }
`;

export const PatFlowContainer = styled.div`
width: 100%;
display: flex;
justify-content: center;
align-items: center;
flex-direction: column;
p {
  color: ${({theme}) => theme.text};
  font-size: 20px;
  font-weight: 600;
  margin: 0;
}
#outline_circle {
  stroke-width: 2px;
  stroke-linejoin: round;
  paint-order: stroke;
  animation-duration: .7s;
  animation-name: blink_outline;
  animation-iteration-count: infinite;
  animation-direction: alternate;
}
@keyframes blink_outline {
  from {
     fill: #F2F2F2;
     stroke: #F2F2F2;
  }
  to {
    stroke: #0E95B4;
    fill: #9DEECB;
  }
}
path {
  animation-duration: .7s;
  animation-name: blink_path;
  animation-iteration-count: infinite;
  animation-direction: alternate;
}
@keyframes blink_path {
  from {
     fill: #DCDCDC;
  }
  to {
     fill: #41D195;
  }
}
`;

export const FlowStatusModal = styled(ResponsiveSheet).attrs({landscapePosition: 'center'})`
  header{
    display: none; 
  } 
`;

export const ErrorContainer = styled.div`
  height: 100vh;
  background-color: ${({theme}) => theme.background};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 10%;
  color: ${({theme}) => theme.text};
  gap: 10px;
  z-index: 999;
  width: 80%;

  @media (orientation: landscape) and (min-width: 1025px) {
    height: 50vh;
  }

  @media (orientation: landscape) and (max-width: 1399px) {
    height: 60vh;
  }
`;

export const ErrorTitle = styled.p`
  font-weight: 600;
  font-size: 20px;
  text-align: center;
`;

export const ErrorReason = styled.p`
  font-size: 15px;
  text-align: center;
  span {
    font-weight: 600;
  }

  @media (orientation: landscape) and (min-width: 769px) {
    width: 75vw;
  }
`;
