import React, { useContext } from 'react';
import { t } from 'i18next';
import { AppContext } from '../../context/AppContext';
import { calculateCashbackCents, calculateEuros } from '../../pages/checkout/functions/calculatePrices';
import { Trans } from 'react-i18next';
import { BigBadge } from '../register/RegisterContainers';
import { Button, Icon, IconType } from '../../elements';
import { WelcomeContainer } from './WelcomeContainers';
import { usePopupNavigation } from '../../shared/utils/usePopupNavigation';
import { PopupType } from '../../context/popups.enum';
import { toast } from 'react-toastify';

type Props = {
  goToMenu: () => void
};

export const WelcomeCashback = ({goToMenu: handleMenu}: Props) => {
  const {
    state: {
      restaurant: {
        allow_to_pay_now,
        allow_to_pay_later,
      },
      checkout: {
        remaining,
      },
    }
  } = useContext(AppContext);

  const { goToPopup, closePopup } = usePopupNavigation();

  const restaurantName = sessionStorage.getItem('restaurantName') || "0";
  const earningPercentage = sessionStorage.getItem('loyalty_earning_percentage') || "0";

  const cashback = remaining ? calculateCashbackCents(remaining) : 0;
  const euros = calculateEuros(cashback);

  const isOP = allow_to_pay_now || allow_to_pay_later;

  const goToProfile = () => goToPopup(PopupType.Checkout);

  const goToMenu = () => closePopup().then(handleMenu).catch((err: any) => {
    console.log(err)
  });

  const toastInfo = () => toast.info(t('cashback_short_info'), {theme: 'colored'});

  return (
    <WelcomeContainer>
      <h2>{t('welcome')}</h2>
      {isOP &&
        <p>
          <Trans i18nKey={'welcome_order_and_pay'}>
            <b>Pide y paga la cuenta</b> a través del <b>QR</b> y obtén
          </Trans>
        </p>
      }
      {!isOP && !remaining &&
        <p>
          <Trans i18nKey={'welcome_without_products'}>
            Cuando termines tu comida, <b>paga la cuenta a través del QR</b> y obtén
          </Trans>
        </p>
      }
      {!isOP && !!remaining &&
        <b>
          <Trans i18nKey={'welcome_with_products'}>
            <b>Paga la cuenta aquí</b> y obtén
          </Trans>
        </b>
      }
      <div>
        <BigBadge>
          <Icon type={IconType.CashbackColor} size={36}/>
          <p>
            <b>{remaining ? euros + '€ ' : earningPercentage + '% '}</b>
            {!remaining && t('of')}
            <br/>cashback
          </p>
          <div>
            <Icon type={IconType.QR}/>
          </div>
        </BigBadge>
      </div>
      <span onClick={toastInfo}>
        {'*' + t('for_your_next_visit_in_restaurant', {restaurantName})}
        <Icon type={IconType.Info}/>
      </span>
      {!remaining &&
        <Button
          title={t('great_see_menu')}
          handleClick={goToMenu}
        />
      }
      {!!remaining &&
        <Button
          title={t('pay your bill')}
          handleClick={goToProfile}
        />
      }
    </WelcomeContainer>
  );
};