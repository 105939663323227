import React from 'react';
import { Trans } from 'react-i18next';
import { NotFoundContainer, NotFoundIcon } from './NotFoundContainers';
import { IconType } from '../../elements';

export const UnexpectedError = () => {

  return (
    <NotFoundContainer>
      <NotFoundIcon type={IconType.QrScan}/>
      <p>
        <Trans i18nKey={"unexpected_error_scan_again"}>
          Ha ocurrido un problema inesperado
          <b>vuelve a intentarlo o contacta con el personal del local.</b>
        </Trans>
      </p>
    </NotFoundContainer>
  );
};