import React from 'react';
import { Icon, IconType } from '../icons';
import styled from 'styled-components';

type Props = {
  children?: React.ReactNode
  type?: IconType
  src?: string
  alt?: string
  color?: string
  size?: number
  fill?: string
  emoji?: string
  extraIcon?: IconType
  className?: string
};

export const SquareIcon = ({ children, type, src, alt = '', size = 44, color = 'currentColor', emoji, fill, extraIcon, className = '' }: Props) => {
  return (
    <Square size={size} color={color} data-emoji={emoji} className={className}>
      {children &&
        children
      }

      {type &&
        <Icon type={type} fill={fill}/>
      }

      {extraIcon &&
        <span>
          <Icon type={extraIcon} fill={fill}/>
        </span>
      }
      
      {src &&
        <img src={src} alt={alt}/>
      }
    </Square>
  );
};

const Square = styled.div<{size: number, color?: string}>`
  --shadow: clamp(4px, ${({size}) => `${size / 20}px, 8px`});
  --padding: ${({size}) => size / 10}px;
  --shadow_color: ${({theme, color}) => color ? color : theme.text};
  
  display: flex;
  justify-content: center;
  align-items: center;
  height: ${({size}) => size}px;
  width: ${({size}) => size}px;
  color: ${({color}) => color};
  border: ${({theme}) => `${theme.border_md} var(--shadow_color)`};
  outline: ${({theme}) => `2px solid ${theme.text}`}; // this covers some white pixels in conrers
  outline-offset: -1.6px;
  border-radius: clamp(4px, ${({size}) => size / 5}px, 20px);
  font-size: ${({size}) => size / 1.6}px;
  box-shadow:
    calc(var(--shadow) * 0.3) calc(var(--shadow) * 0.3) var(--shadow_color),
    calc(var(--shadow) * 0.6) calc(var(--shadow) * 0.6) var(--shadow_color),
    var(--shadow) var(--shadow) var(--shadow_color);
  padding: var(--padding);
  box-sizing: border-box;
  position: relative;

  svg {
    //height: 100%;
    //width: 100%;
  }
  
  img {
    height: calc(100% + var(--padding) * 2);
    width: calc(100% + var(--padding) * 2);
    margin: calc(var(--padding) * -1);
    object-fit: cover;
    border-radius: clamp(4px, ${({size}) => size / 4.4}px, 15px);
  }

  &:has(> img):not(:has(> span)) {
    overflow: hidden;
  }

  span{
    position: absolute;
    display: flex;
    top: 0;
    right: 0;
    translate: 45% -45%;
    height: ${({size}) => Math.max(size / 1.8, 42)}px;
    width: ${({size}) => Math.max(size / 1.8, 42)}px;
  }

  &::after {
    content: attr(data-emoji);
    position: absolute;
    top: calc(var(--padding) / 4);
    right: calc(var(--padding) / 4);
    font-size: clamp(4px, ${({size}) => size / 3.3}px, 44px);
  }
`;