import styled from "styled-components";

const PaymentDetailContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  color: ${({theme}) => theme.text};
  padding: 16px 24px;

  input {
    margin: 0;
  }

  & > button:last-of-type {
    margin-top: 8px;
  }
  
  p {
    font-size: ${({theme}) => theme.font_sm};
    margin: 0;
    
    &.delivery-or-takeway-info {
      padding: 16px;
      border-radius: ${({theme}) => theme.radius_md};
      background-color: #FFF8E2;
      color: ${({theme}) => theme.text_dark};

      span {
        margin-right: 4px;
      }
    }
  }
`;

const DetailCard = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  
  h4 {
    font-size: ${({theme}) => theme.oFont};
    font-weight: 600;
    margin: 0;
    white-space: nowrap;
  }
`;

const UserDataContainer = styled(DetailCard)`
  flex-direction: column;
  align-items: stretch;
  
  h3 {
    font-size: ${({theme}) => theme.font_md};
    font-weight: 400;
    margin: 0;

    &::after {
      content: '*';
      color: ${({theme}) => theme.danger}
    }
  }

  @media(orientation: landscape){
    padding-bottom: 25px;
  }
  
  p {
    font-size: ${({theme}) => theme.font_xs};
    font-weight: 500;
    text-align: center;
    width: 100%;
    margin: 0;

    span {
      font-size: ${({theme}) => theme.font_xs};
      line-height: 1em;
      color: ${({theme}) => theme.danger};
      margin-right: 2px;
    }
  }
`;

const ErrorContainer = styled.i`
  font-size: ${({theme}) => theme.font_xs};
  line-height: 1;
  color: ${({theme}) => theme.danger};
  display: flex;
  margin: -10px 0 0;
`;


export {
  PaymentDetailContainer,
  DetailCard,
  UserDataContainer,
  ErrorContainer
}