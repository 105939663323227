import React from 'react';
import styled from 'styled-components';
import { Icon, IconType } from '../icons';
import { useFeatureIsOn } from '@growthbook/growthbook-react';
import { GB_FEATS } from '../../growthbook';

type Props = {
  style?: React.CSSProperties
};

export const PoweredByYumminn = ({style}: Props) => {
  const showYumminnLogo = useFeatureIsOn(GB_FEATS.SHOW_YUMMINN_LOGO);

  if (!showYumminnLogo) return <></>;

  return (
    <Container style={style}>
      <span>powered by</span>
      <Icon type={IconType.Logo} size={16}/>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  
  span {
    font-size: ${({theme}) => theme.font_xxs};
    color: ${({theme}) => theme.text};
  }
`;