import styled, { keyframes } from 'styled-components';
import React, { ReactChild, ReactChildren, ReactNode } from 'react';

const FloatingDiv = styled.div<FloatingDivProps>`
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 8px;
    position: ${({outsidePopup, fixed, top}) => outsidePopup ? 'absolute' : fixed ? '' : top ? '' : 'fixed'};
    color: ${({theme}) => theme.text_light};
    background: black;
    border-radius: 13.4006px;
    bottom: ${({outsidePopup, top}) => outsidePopup ? "" : top ? '' : "80px"};
    top: ${({outsidePopup}) => outsidePopup ? "-72px" : ""};
    padding: 12px;
    color: #FFF;
    width: ${({ fixed, top }) => fixed||top ? "100%" : "90%"};
    z-index: 2;
    box-sizing: border-box;
    animation: ${({ fixed }) => fixed ? 'none' : ''};
    animation-name: ${({ isOpen }) => (isOpen ? fadeInUp : fadeOutDown)};
    animation-duration: 1s;
    animation-timing-function: ease-out;
    animation-delay: 0.2s;
    animation-fill-mode: both;
    opacity: 0;
`;


const fadeInUp = keyframes`
    from {
        opacity: 0;
        transform: translate3d(0, 100%, 0);
    }
    
    to {
        opacity: 1;
        transform: none;
    }
`;

const fadeOutDown = keyframes`
    from {
        opacity: 1;
        transform: none;
    }
    
    to {
        opacity: 0;
        transform: translate3d(0, 100%, 0);
    }
`;

const PromoConditions = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 4px;
    width: 100%;
    font-size: ${({theme}) => theme.font_xs};
    
    button {
        display: flex;
        align-items: center;
        gap: 4px;
        font-family: ${({theme}) => theme.typography};
        font-size: ${({theme}) => theme.font_xs};
        line-height: 1em;
        color: ${({theme}) => theme.background};
        background-color: transparent;
        border: 2px solid currentColor;
        border-radius: ${({theme}) => theme.radius_round};
        padding: 4px 6px;
    }
`;

const MasterOffer = styled.span`
    font-size: ${({theme}) => theme.font_md};
    line-height: 1.3;

    strong {
        font-size: ${({theme}) => theme.font_lg};
        color: #FFF;
        border-radius: 4px;
        font-weight: 500;
    }
`;

const InfoPopupContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
    color: ${({theme}) => theme.text};
    padding: 16px 24px;

    h2 {
        font-size: ${({theme}) => theme.font_lg};
        font-weight: 700;
        margin: 0 0 -8px;
    }

    & > p {
        font-size: ${({theme}) => theme.font_md};
        line-height: 1.3em;
        text-align: center;
        max-width: 28ch;
        margin: 0;
    
        & > b {
            font-weight: 700;
        }
    
        & > span {
            font-weight: 600;
        }
    }

    & > span {
        font-size: ${({theme}) => theme.font_xs};
        color: ${({theme}) => theme.gray_70};
    }

    hr {
        width: 100%;
        border: none;
        border-top: 2px solid ${({theme}) => theme.gray_40};
        margin: 0;
    }

    h3 {
        font-size: ${({theme}) => theme.font_md};
        font-weight: 500;
        line-height: 1.3em;
        text-align: center;
        max-width: 26ch;
        margin: 0;

        span {
            font-weight: 600;
            color: ${({theme}) => theme.yumminn}
        }
    }
`;

const InfoPopupHeader = styled.h3`
    font-size: ${({theme}) => theme.font_lg};
    font-weight: 400;
    line-height: 1.3em;
    text-align: center;
    margin: 0;

    b {
        font-weight: 600;
    }
`;

type FloatingDivProps = {
  children: ReactChild | ReactChildren | Element | ReactNode | string;
  onClick?: () => void;
  isOpen: boolean;
  outsidePopup?: boolean;
  fixed?: boolean;
  top?: boolean;
}

const BannerMaster = ({
  children,
  onClick,
  isOpen, 
  outsidePopup = false,
  fixed,
  top
}: FloatingDivProps): JSX.Element => {
  return (
    <div 
        onClick={onClick}
        style={{
            padding: outsidePopup ? '0 20px' : !fixed ? "16px 20px" : "", 
            pointerEvents: isOpen ? 'all' : 'none', 
            marginTop: fixed ? "10px" : 0
        }}
    >
        <FloatingDiv isOpen={isOpen} outsidePopup={outsidePopup} fixed={fixed} top={top}>
        {children}
        </FloatingDiv>
    </div>
  )
};

export {
    BannerMaster,
    PromoConditions,
    MasterOffer,
    InfoPopupContainer,
    InfoPopupHeader,
};