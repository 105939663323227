import React, { useContext, useEffect, useState } from 'react';
import { TextInput } from '../inputs';
import { Button } from '../buttons';
import { t } from 'i18next';
import { CodeCouponInputContainer } from './PaymentDetailContainers';
import { Icon, IconType } from '../icons';
import { GB_FEATS } from '../../growthbook';
import { useFeatureValue } from '@growthbook/growthbook-react';
import { AppContext } from '../../context/AppContext';
import { toast } from 'react-toastify';
import { Request } from '../../api/discounts/Discounts.api';
import { postError } from '../../shared/utils/postError';
import { dispatchCodeCoupon } from '../../shared/functions';
import { useRestaurantData } from '../../shared/utils/useRestaurantData';
import { useIsMounted } from '../../shared/utils/useIsMounted';

export const CodeCouponInput = () => {
  const {
    state: {
      restaurant: {
        has_code_coupons,
        always_allow_code_coupon,
      },
      checkout: {
        myTotal,
        loyaltyDiscountCents,
        generalDiscountCents,
        productDiscountCents,
        codeCoupon,
        creditCents,
        codeCouponCents,
        paymentSplitType,
        tip: {
          amount,
        },
      },
    },
    dispatch,
  } = useContext(AppContext);

  const label = useFeatureValue(GB_FEATS.COUPON_LABEL, 'do_you_have_a_code_coupon');

  const isMountedRef = useIsMounted();
  const { restaurantId } = useRestaurantData();
  
  const [redeemingCodeCoupon, setRedeemingCodeCoupon] = useState(false);
  const [codeCouponText, setCodeCouponText] = useState('');
  const [codeCouponError, setCodeCouponError] = useState('');
  const [isAnyCouponValid, setIsAnyCouponValid] = useState(false);

  const isRechargePayment = paymentSplitType === 'recharge'
  const subtotal = myTotal - amount;
  const amountMinusDiscountsAndCreditAndLoyalty = Math.max(subtotal - generalDiscountCents - productDiscountCents -  codeCouponCents - creditCents - loyaltyDiscountCents, 0);
  const disabledCouponField = amountMinusDiscountsAndCreditAndLoyalty === 0 && codeCouponCents === 0 && isRechargePayment;

  const handleCouponChange: React.ChangeEventHandler = (event) => {
    setCodeCouponText((event.target as HTMLInputElement).value);
    setCodeCouponError('');
  };

  const codeCouponApplyClicked = (codeCouponParam?: string) => {
    try {
      const totalOrder = myTotal - amount
      setRedeemingCodeCoupon(true);
      Request.redeemCodeCoupon(restaurantId, codeCouponText ? codeCouponText : codeCouponParam as string, totalOrder)
      .then(response => {
        dispatchCodeCoupon(response.data, myTotal - amount - generalDiscountCents - productDiscountCents, dispatch);
      })
      .catch(err => {
        if(err?.response?.data && err?.response?.data.includes('only valid for clients')){
          setCodeCouponError(t('coupon invalid for this client'));
          toast.error(t('coupon invalid for this client'), {
            theme: 'colored',
          });
        } else {
          setCodeCouponError(t('Invalid code. Try again'));
          toast.error(t('Invalid code. Try again'), {
            theme: 'colored',
          });
        }
        postError(err)
        console.log(`codeCouponApplyClicked ~ Request.redeemCodeCoupon err: ${err.toString()}`);
      })
      .finally(() => setRedeemingCodeCoupon(false));
    } catch(e: any) {
      postError(e)
      console.log(`codeCouponApplyClicked exception: ${e.toString()}`);
    }
  };

  const codeCouponDeleteClicked = () => {
    dispatch({
        type: 'UPDATE_CHECKOUT',
        payload: {
          codeCoupon: null,
          codeCouponCents: 0
        }
    });
    setCodeCouponText('');
  };

  const codeCouponCheckValidity = async() => {
    try {
      const totalOrder = myTotal - amount;
      
      Request.checkCodeCouponValidity(restaurantId, totalOrder)
      .then((response: any) => {
        setIsAnyCouponValid(response.data.valid)
        if(response.data.preassigned && response.data.valid && !disabledCouponField && !isRechargePayment){
          setCodeCouponText(response.data.code)
          codeCouponApplyClicked(response.data.code)
        }
      })
      .catch((err: any) => {
        console.log(`codeCouponCheckValidity ~ Request.checkCodeCouponValidity err: ${err.toString()}`);
      })
      .finally(() => setRedeemingCodeCoupon(false));
    } catch(e: any) {
      postError(e)
      console.log(`codeCouponCheckValidity exception: ${e.toString()}`);
    }
  };

  useEffect(() => {
    if(isMountedRef.current){
      if(has_code_coupons){
        codeCouponCheckValidity()
      }

      if(has_code_coupons && codeCouponCents > 0){
        setCodeCouponText(codeCoupon.code)
      }
    }
  }, []);

  if (!always_allow_code_coupon && (!has_code_coupons || !isAnyCouponValid)) return <></>;

  return (
    <CodeCouponInputContainer>
      <h3>
        <Icon type={IconType.Tag} size={22}/>
        {t(label)}
      </h3>
      <div>
        {codeCouponCents <= 0 &&
          <>
            <TextInput
              value={codeCouponText}
              onChange={handleCouponChange}
              error={codeCouponError}
              disabled={redeemingCodeCoupon || disabledCouponField}
            />
            <Button
              title={t('Apply')}
              disabled={disabledCouponField}
              handleClick={codeCouponApplyClicked}
              loading={redeemingCodeCoupon}
            />
          </>
        }
        {codeCouponCents > 0 &&
          <>
            <div className='fake_input'>
              <span>
                {codeCouponText}
                <button onClick={codeCouponDeleteClicked}>
                  <Icon type={IconType.Close} size={12}/>
                </button>
              </span>
            </div>
            <p>{t('Applied!')} 🎉</p>
          </>
        }
      </div>
    </CodeCouponInputContainer>
  );
};
