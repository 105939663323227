import { t } from 'i18next';
import React, { useContext, useState } from 'react';
import { Trans } from 'react-i18next';
import { AppContext } from '../../context/AppContext';
import { Icon, IconType, LoginButtons, Popup } from '../../elements';
import { Consents } from '../../elements/register/Consents';
import { termsAndConditionsRequired } from '../../forms/register/validations';
import { calculateCashbackCents, calculateEuros } from '../../pages/checkout/functions/calculatePrices';
import { BigBadge, CashbackRegisterContainer } from './RegisterContainers';
import { PopupProps } from '../../elements/popup/popup.component';

export const RegisterCashback = (popupProps: PopupProps) => {
  const {
    state: {
      checkout: {
        remaining,
      },
    }
  } = useContext(AppContext);

  const restaurantName = sessionStorage.getItem('restaurantName');
  const earningPercentage = sessionStorage.getItem('loyalty_earning_percentage') || "0";

  const cashback = remaining ? calculateCashbackCents(remaining) : 0;
  const euros = calculateEuros(cashback);

  const [consents, setConsents] = useState({lopd: false, news: false});

  const handleChange: React.ChangeEventHandler = (event) => {
    const id = event.target.id as keyof typeof consents;
    setConsents(prev => ({
      ...prev,
      [id]: !prev[id],
    }));
  };

  return (
    <Popup {...popupProps}>
      <CashbackRegisterContainer>
        <h2>{t('cashback_register_title')}</h2>
        <BigBadge>
          <Icon type={IconType.CashbackColor} size={36}/>
          <p>
            <b>{remaining ? euros + '€ ' : earningPercentage + '% '}</b>
            {!!remaining && t('of')}
            <br/>cashback
          </p>
          <div>
            <Icon type={IconType.QR}/>
          </div>
        </BigBadge>
        <p>
          <Trans i18nKey={'cashback_register_message'}>
          in <b>{{restaurantName}}</b>, with every <b>payment via QR</b>.
          </Trans>
        </p>
        <Consents values={consents} handleChange={handleChange}/>
        <LoginButtons
          disabled={!consents.lopd}
          disabledMessage={termsAndConditionsRequired}
          from='cashback_register_popup'
          lopd={consents.lopd}
          news={consents.news}
        />
      </CashbackRegisterContainer>
    </Popup>
  );
};