import React, { useEffect, useContext, useState } from "react";

import "./HeaderBar.scss";

import { PopupType } from "../../context/popups.enum";
import { AppContext } from "../../context/AppContext";
import { eventTrack } from "../../../useGaTracker";
import { useNavigate } from "react-router-dom";
import { LanguageMenu } from "../language-menu";
import { calculateEuros } from "../../pages/checkout/functions/calculatePrices";
import { Icon, IconType } from "../icons";
import { ReceiptsWidget } from "./receipts-widget/ReceiptsWidget";
import { IconsContainer } from "../main-image/MainImageContainers";
import { usePopupNavigation } from "../../shared/utils/usePopupNavigation";
import { getProgramsFromStorage } from "../../pages/checkout/functions/getProgramsFromStorage";
import { UserContext } from "../../context/auth/UserContext";
import { CreditsPopoverWrapper, HeaderBarContainer, HeaderInnerContainer, InitialsContainer, KioskHeaderBarContainer, PointsCounter, ProfileButton, ProfileHeader } from "./HeaderBarContainer";
import { AuthContext } from "../../context/auth/AuthContext";
import { useInitialUserData } from "../../shared/utils/useInitialUserData";
import miniBumerang from "../../assets/images/bumerang_mini.svg";
import { useRestaurantData } from "../../shared/utils/useRestaurantData";
import { useFeatureValue, useFeatureIsOn } from "@growthbook/growthbook-react";
import { GB_FEATS } from "../../growthbook";
import { DEFAULT_REGISTER_BUTTON_TITLES } from "../../growthbook/defaults";
import { Popover } from "../popover";
import { useViewport } from "../../context/ViewportContext";
import { Trans, useTranslation } from "react-i18next";
import { Button } from "../buttons";
import { ButtonSize, ButtonVariant } from "../buttons/enums";
import { SquareIcon } from "../square-icon";
import { ResponsiveLogo } from "../responsive-logo";
import { WalletContainerPopup } from "../../pages/wallet/WalletContainerPopup";
import { PopupContext } from "../../context/PopupContext";
import { ProfileContainerPopup } from "../../pages/profile/components/ProfileContainerPopup";
import { LatestBillsContainerPopup } from "../../pages/latest-bills/LatestBillsContainerPopup";
import { RestaurantBillsContainerPopup } from "../../pages/restaurant-bill/RestaurantBillsContainerPopup";
import { PersonalInfoContainerPopup } from "../../pages/personal-info/PersonalInfoContainerPopup";
import { useGoBack } from "../../shared/utils/useGoBack";

const RESTAURANT_PAGES = ['/checkout', '/event_catalog'];
const PROFILE_PAGES = ['/profile', '/loyalty-cards', '/loyalty-details', '/loyalty-points', '/register', '/my-account', '/forgot-password', '/event_catalog'];

type Props = {
  link?: string
  noHome?: boolean
  instagram?: string
  isCatalog?: boolean
  showBackButton?: boolean
  setShowCatalog?: (value: boolean) => void
  setShowSplashScreen?: React.Dispatch<React.SetStateAction<boolean>>
};

export const HeaderBar = (props: Props) => {
  const functionality = sessionStorage.getItem('functionality') || '';
  
  if (functionality === 'kiosk') return (
    <KioskHeaderBarContainer path={location.pathname}>
      <LanguageMenu/>
    </KioskHeaderBarContainer>
  );

  return <DefaultHeaderBar {...props}/>
};

const DefaultHeaderBar = ({
  link,
  instagram,
  isCatalog,
  noHome = false,
  showBackButton = true,
  setShowCatalog,
  setShowSplashScreen,
} : Props) => {
  const {
    state: {
      restaurant: {
        wifiData,
        show_wallet_button
      },
      checkout: {
        availableLoyaltyCents,
        zeroSixInfo
      },
      restaurant: {
        bumerang,
      },
      wallet: {
        balanceCredit
      }
    },
    dispatch
  } = useContext(AppContext);

  const { isLogged } = useContext(AuthContext);
  const { user } = useContext(UserContext);
  const { popup } = useContext(PopupContext);

  const [openedPopover, setOpenPopover] = useState(true);
  const { t } = useTranslation();

  const { restaurantGroupUuid } = useRestaurantData();
  const { isMobile } = useViewport();

  const loginButtons = useFeatureValue(GB_FEATS.REGISTER_BUTTONS, DEFAULT_REGISTER_BUTTON_TITLES);
  const showYumminnLogo = useFeatureIsOn(GB_FEATS.SHOW_YUMMINN_LOGO);
  const showZerosix = useFeatureIsOn(GB_FEATS.SHOW_ZEROSIX);

  let atLeastOneLoginAvailable = true;

  if (loginButtons) {
    atLeastOneLoginAvailable = Object.values(loginButtons).some(value => value === true);
  }

  const deliTakeUuid = sessionStorage.getItem('deliTakeUuid') || "";
  const qrUuid = sessionStorage.getItem('qrUuid') || "";
  const comesFromRestaurantGroup = !!(restaurantGroupUuid && sessionStorage.getItem('comesFromRestaurantGroup'));
  const comesFromDeliveryTakeaway = !!(deliTakeUuid && sessionStorage.getItem('comesFromDeliveryTakeaway'));


  const paramsProxy: any = new Proxy(new URLSearchParams(window.location.search), {
    get: (searchParams, prop: any) => searchParams.get(prop),
  });
  const navigate = useNavigate();
  const path = location.pathname;
  const { goToPopup, closePopup } = usePopupNavigation();
  const { loyaltyProgram, pointsProgram } = getProgramsFromStorage();
  const goBack = useGoBack();
  const updateInitialUserData = useInitialUserData()
  const { orientation } = useViewport();

  const allowToPayTable = JSON.parse(sessionStorage.getItem('allowToPayTable') ?? "false");
  const thereIsHome = !noHome;

  useEffect(() => {
    if(!isMobile && show_wallet_button){
      setOpenPopover(true);
    }
  }, [isMobile]);

  const defaultGoBack = () => {
    if (paramsProxy?.goBack == "true") {
      navigate(-1);
    } else if (link) {
      navigate(link, {state: {from : path}});
    } else {
      goBack();
    }
  };

  const goBackCatalogHeader = () => {
    setShowSplashScreen && setShowSplashScreen(false);
    closePopup();
    setShowCatalog && setShowCatalog(!isCatalog);
  };

  const handleProfileClick = () => {
    goToPopup(PopupType.Register);
  };

  const handleAccountButtonClick = () => {
    if(!isMobile){
      goToPopup(PopupType.Profile);
    } else {
      navigate('/profile', {state: {from: path}})
    }
  };

  const handleCounterClick = () => {
    eventTrack("click loyalty counter on header DA", "click-loyalty-counter-on-header-DA", "Click on loyalty counter DA in homepage")

    !isLogged && goToPopup(PopupType.Register, {openBy: 'loyalty'});
  };

  const handleTopupWallet = (e: Event) => {
    e.stopPropagation();
    setOpenPopover(false);
    goToPopup(PopupType.Wallet);
  };

  const handleGoToProfile = (e: Event) => {
    e.stopPropagation();
    setOpenPopover(false);
    if(isMobile){
      navigate('/loyalty-cards', {state: {type: 'credit', from: path}});
    } else {
      goToPopup(PopupType.LoyaltyCards, {type: 'credit', from: path});
    }
  }

  const creditsClick = () => {
    if(isMobile){
      navigate('/loyalty-cards', {state: {type: 'credit', from: path}});
    } else {
      setOpenPopover(!openedPopover);
    }
  };

  const goToProfile = () => navigate('/profile', {state: {from: path}});

  useEffect(() => {

    const token: any = localStorage.getItem("token_yumminn");
    if (!token || !isLogged) {
      dispatch({
        type: 'UPDATE_WALLET_INFO',
        payload: {
          balanceCredit: 0,
        }
      })
      return;
    }

    isLogged && updateInitialUserData();

  }, [isLogged]);

  if (PROFILE_PAGES.includes(path)) return (
    <ProfileHeader showBackButton={showBackButton}>
      <span className='back_button' onClick={defaultGoBack}>
        <Icon type={IconType.Arrowback}/>
      </span>
      {path !== '/register' && showYumminnLogo &&
        <ResponsiveLogo className='logo'/>
      }
      <LanguageMenu />
    </ProfileHeader>
  );

  const notInitialScreen = isCatalog;
  const redirection = '?redirection=' + `webapp.yumminn.com/${qrUuid}`;

  return (
    <>
      <HeaderBarContainer path={path}>
        <HeaderInnerContainer>
          {orientation === 'landscape' &&
            <ResponsiveLogo className='logo'/>
          }
          {/*back button mobile*/}
          {isMobile && (
            RESTAURANT_PAGES.includes(path) && (comesFromRestaurantGroup || comesFromDeliveryTakeaway) ?
              <IconsContainer onClick={defaultGoBack}>
                <Icon type={IconType.Arrowback}/>
              </IconsContainer>
            : ((thereIsHome && notInitialScreen)) &&
              <IconsContainer onClick={allowToPayTable ? goBackCatalogHeader : defaultGoBack}>
                <Icon type={IconType.Arrowback}/>
              </IconsContainer>
            )
          }
          {/* wifi */}
          {wifiData &&
            <button onClick={() => goToPopup(PopupType.WifiData)}>
              <Icon type={IconType.Wifi}/>
            </button>
          }
          {/*instagram*/}
          {instagram &&
            <button onClick={() => window.open(instagram, "_blank")}>
              <Icon type={IconType.Instagram} size={24}/>
            </button>
          }

          {/*receipts button*/}
          {RESTAURANT_PAGES.includes(path) && isMobile &&
            <ReceiptsWidget/>
          }
        </HeaderInnerContainer>
        <HeaderInnerContainer>
          {/*account button*/}
          {isMobile && (atLeastOneLoginAvailable ? (
            isLogged ? (
              <>
                {Boolean(bumerang) &&
                  <a href={bumerang + redirection} target="_blank" rel="noreferrer">
                    <img src={miniBumerang} alt='bumerang' />
                  </a>
                }
                <InitialsContainer onClick={goToProfile}>
                  {user.getInitials() || "Y"}
                </InitialsContainer>
              </>
              ) : (
                <>
                  <LanguageMenu />
                  <ProfileButton onClick={handleProfileClick}>
                    <Icon type={IconType.User} size={28} fill={'currentColor'}/>
                  </ProfileButton>
                </>
              )
            ) : (
            <LanguageMenu />
            ))
          }
          {/*profile in desktop*/}
          {(!isMobile && popup.current === PopupType.Profile) &&
            <ProfileContainerPopup isOpened={!!(popup.current === PopupType.Profile)} close={closePopup} />
          }
          {/*latestBills in desktop*/}
          {(!isMobile && popup.current === PopupType.LatestBills) &&
            <LatestBillsContainerPopup isOpened={!!(popup.current === PopupType.LatestBills)} close={closePopup} />
          }
          {/*restaurantBills in desktop*/}
          {(!isMobile && popup.current === PopupType.RestaurantBills) &&
              <RestaurantBillsContainerPopup isOpened={!!(popup.current === PopupType.RestaurantBills)} close={closePopup} />
          }
          {/*savedRestaurants in desktop
          (!isMobile && popup.current === PopupType.SavedRestaurants) &&
              <SavedRestaurantsContainerPopup isOpened={!!(popup.current === PopupType.SavedRestaurants)} close={closePopup} />
          */}
          {/*personalInfo in desktop*/}
            {(!isMobile && popup.current === PopupType.PersonalInfo) &&
            <PersonalInfoContainerPopup isOpened={!!(popup.current === PopupType.PersonalInfo)} close={closePopup} />
          }
          {/*points button*/}
          {pointsProgram &&
            <PointsCounter onClick={handleCounterClick}>
              <Icon type={IconType.PointColor} size={18}/>
              <span>{user.points}</span>
            </PointsCounter>
          }
          {/*cents button*/}
          {loyaltyProgram &&
            <PointsCounter onClick={handleCounterClick}>
              <Icon type={IconType.CashbackColor} size={18}/>
              <span>{calculateEuros(availableLoyaltyCents) + '€'}</span>
            </PointsCounter>
          }
          {/*credits button*/}
          {(show_wallet_button || balanceCredit > 0) && 
            <PointsCounter type='credit' onClick={() => creditsClick()}>
              <Icon type={IconType.CreditBanner} size={18}/>
              <span>{calculateEuros(balanceCredit) + '€'}</span>
              <Popover setOpened={setOpenPopover} isOpened={openedPopover} alignment="end" position='right'>
                <CreditsPopoverWrapper>
                  <SquareIcon color='#000' type={IconType.CreditBanner}/>
                  <span className='message'>
                    {balanceCredit === 0 ? 
                      <Trans i18nKey={"popover_no_credits"}>
                        <b>¡Hola!</b> Aún no tienes créditos
                      </Trans>
                    :
                      <Trans i18nKey={"popover_enough_credits"}>
                        <b>¡Hola!</b> ¿Tienes suficiente crédito?
                      </Trans>
                    }
                  </span>
                  {show_wallet_button && <Button
                    style={{pointerEvents: 'all'}}
                    title={t("topup_wallet")}
                    variant={ButtonVariant.Primary}
                    size={ButtonSize.Medium}
                    handleClick={(e) => handleTopupWallet(e as unknown as Event)}
                  />}
                  {balanceCredit > 0 &&
                    <Button
                      style={{pointerEvents: 'all'}}
                      title={t("see my credits")}
                      variant={ButtonVariant.Secondary}
                      size={ButtonSize.Medium}
                      handleClick={(e) => handleGoToProfile(e as unknown as Event)}
                    />
                  }
                </CreditsPopoverWrapper>
              </Popover>
            </PointsCounter>
          }
          {/*wallet in desktop*/}
          {(!isMobile && popup.current === PopupType.Wallet) &&
            <WalletContainerPopup isOpened={!!(popup.current === PopupType.Wallet)} close={closePopup} />
          }
          {/*zeroSix button*/}
          {(showZerosix && zeroSixInfo?.points_balance !== null && zeroSixInfo?.points_balance !== undefined) &&
            <PointsCounter onClick={() => navigate('/zerosix-cards', {state: {from: path}})}>
              <Icon type={IconType.ZerosixColor} size={18}/>
              <span>{zeroSixInfo.points_balance}</span>
            </PointsCounter>
          }
          {/*receipts button*/}
          {RESTAURANT_PAGES.includes(path) && !isMobile &&
            <ReceiptsWidget/>
          }
          {/*account button*/}
          {!isMobile && (atLeastOneLoginAvailable ? (
            isLogged ? (
              <>
                {Boolean(bumerang) && 
                  <a href={bumerang + redirection} target="_blank" rel="noreferrer">
                    <img src={miniBumerang} alt='bumerang' />
                  </a>
                }
                <InitialsContainer onClick={handleAccountButtonClick}>
                  {user.getInitials() || "Y"}
                </InitialsContainer>
              </>
              ) : (
                <>
                  <LanguageMenu />
                  <ProfileButton onClick={handleProfileClick}>
                    <Icon type={IconType.User} size={28} fill={'currentColor'}/>
                  </ProfileButton>
                </>
              )
            ) : (
            <LanguageMenu />
            ))
          }

          {/*back button desktop*/}
          {!isMobile && (
            RESTAURANT_PAGES.includes(path) && (comesFromRestaurantGroup || comesFromDeliveryTakeaway) ?
              <IconsContainer className='backButton' onClick={defaultGoBack}>
                <Icon type={IconType.Arrowback}/>
              </IconsContainer>
            : (thereIsHome && notInitialScreen) &&
              <IconsContainer className='backButton' onClick={allowToPayTable ? goBackCatalogHeader : defaultGoBack}>
                <Icon type={IconType.Arrowback}/>
              </IconsContainer>
            )
          }

        </HeaderInnerContainer>
      </HeaderBarContainer>
    </>
  );
};