import React, {useContext, useState} from 'react'
import {BannerBizum, PromoConditions, BizumOffer} from './StylesBizumCampaign';
import { Trans, useTranslation } from 'react-i18next';
import { Icon, IconType } from '../../elements';
import { AppContext } from '../../context/AppContext';
import { calculateEuros } from '../../pages/checkout/functions/calculatePrices';
import { PopupType } from '../../context/popups.enum';
import { usePopupNavigation } from '../../shared/utils/usePopupNavigation';
import { MADRE_LIEVITO_POBLE_NOU } from '../../shared/constants/restaurantIds';
import { PopupContext } from '../../context/PopupContext';

type props = {
    outsidePopup?: boolean;
    fixed?: boolean;
    top?: boolean;
    fromComponent?: string;
}

const BizumDiscountBanner = ({outsidePopup, fixed, top, fromComponent} : props) => {
    const restaurantId = Number(sessionStorage.getItem('restaurantId'));

    const [bannerOpen, setBannerOpen] = useState(true)
    const {t} = useTranslation();
    const {
        state: {
            checkout: {
                remaining,
            },
            restaurant: {
                bizumPromotionCents
            },
        },
    } = useContext(AppContext);
    const { popup } = useContext(PopupContext);
    const isPopupCheckout: boolean = popup.current === PopupType.Checkout
    const {goToPopup} = usePopupNavigation();

    const showMoreInfo = (event: React.MouseEvent) => {
        event.stopPropagation();
        goToPopup(PopupType.BizumPromoInfo);
    }

    if (restaurantId === MADRE_LIEVITO_POBLE_NOU) return <></>;

    return (
        <>
        <BannerBizum
            onClick={() => {
                if(fromComponent === 'orderedSteps' && remaining > 0){
                    goToPopup(PopupType.Checkout)
                }
                if ((!fixed && isPopupCheckout) || (fixed)) {
                    return ''; 
                } else {
                    setBannerOpen(false); 
                }
            }} 
            isOpen={bannerOpen} 
            top={top} 
            outsidePopup={outsidePopup} 
            fixed={fixed}
        >
            {(!fixed || fixed === undefined || isPopupCheckout) && !top &&
            <div style={{width: "100%", display: 'flex', justifyContent: 'flex-end', margin: "0 0 -18px 0", paddingTop: "5px"}}>
                <Icon size={10} type={IconType.Close} colorIcon={'#FFF'}/>
            </div>}
            <BizumOffer>
                <Trans i18nKey={"1 euro discount with"} values={{ discount: `${parseInt(calculateEuros(bizumPromotionCents)).toString()}` }} >
                    <strong>discount</strong>
                </Trans>
                <Icon size={20} type={IconType.Bizum} />
            </BizumOffer>
            <PromoConditions>
                <span>*{t('in payments over 15€', {amount: '15'})}</span>
                {fromComponent === 'orderedSteps' &&
                    <button onClick={showMoreInfo}>
                        {t('more info')}
                        <Icon type={IconType.Arrowforward} size={10}/>
                    </button>
                }
            </PromoConditions>
        </BannerBizum>
        </>
    )
}

export default BizumDiscountBanner;