import React, { useEffect, useContext } from "react";
import { AppContext } from "../../context/AppContext";
import { useLocation, useNavigate } from "react-router-dom";
import { AuthContext } from "../../context/auth/AuthContext";
import { usePopupNavigation } from "../../shared/utils/usePopupNavigation";
import { HeaderBarContainer, HeaderInnerContainer, PointsCounter } from "../../elements/header-bar/HeaderBarContainer";
import { Icon, IconType } from "../../elements";
import { calculateEuros } from "../checkout/functions/calculatePrices";
import { IconsContainer } from "../../elements/main-image/MainImageContainers";
import { useInitialUserData } from "../../shared/utils/useInitialUserData";
import { Trans } from "react-i18next";

export const WalletHeader = () => {  const {
    state: {
      restaurant: {
        show_wallet_button
      },
      wallet: {
        balanceCredit
      }
    },
    dispatch
  } = useContext(AppContext);

  const restaurantId = Number(sessionStorage.getItem("restaurantId"));
  const tableNumber = sessionStorage.getItem("tableNumber") || "";

  const navigate = useNavigate();
  const location = useLocation();

  const { isLogged } = useContext(AuthContext);
  const { closePopup } = usePopupNavigation();
  const updateInitialUserData = useInitialUserData(); 
  const qrUuid = sessionStorage.getItem('qrUuid') || "";

  useEffect(() => {
    const token: any = localStorage.getItem("token_yumminn");

    if (!token || !isLogged) {
      dispatch({
        type: 'UPDATE_WALLET_INFO',
        payload: {
            balanceCredit: 0,
        }
      })
      return;
    }

    updateInitialUserData()
  }, [restaurantId, isLogged]);
  
  const goBackGeneralHeader = () => {
    closePopup()
    if (qrUuid) {
        navigate(`/${qrUuid}`, {state: {from: 'wallet'}})
    } else if (restaurantId && tableNumber){    
        navigate(`/home?id=${restaurantId}&table=${tableNumber}`, {state: {from: 'wallet'}})
    } else {
        navigate(-1);
    }

  }

  return (
    <HeaderBarContainer id='headerContainer' path={location.pathname}>
      <HeaderInnerContainer>
        <IconsContainer onClick={goBackGeneralHeader}>
            <Icon type={IconType.Arrowback}/>
        </IconsContainer>
      </HeaderInnerContainer>
      <HeaderInnerContainer>
          {/*credits button*/}
          {show_wallet_button && 
            <PointsCounter type='credit' onClick={() => navigate('/loyalty-cards', {state: {type: 'credit'}})}>
              <Icon type={IconType.CreditBanner} size={18}/>
              <span>{calculateEuros(balanceCredit) + '€'}</span>
              <p>
                <Trans i18nKey='total_credits'>créditos <b>totales</b></Trans>
              </p>
            </PointsCounter>
          }
      </HeaderInnerContainer>
    </HeaderBarContainer>
  );
};

