import styled from "styled-components";

const ScrollWrapper = styled.div<Wrapper>`
  max-height: ${({$maxHeight}) => $maxHeight};
  width: 100%;
  display: flex;
  gap: 4px;
  `;

const ScrollContent = styled.div`
  flex-grow: 1;
  overflow: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;

  & > div {
    overflow: hidden;
  }

  &::-webkit-scrollbar {
    display: none;
  }
`;

const ScrollBar = styled.div<Bar>`
  display: ${({$isShown}) => $isShown ? 'block' : 'none'};
  width: 6px;
  flex: auto 0 0;
  position: relative;
  background-color: ${({theme}) => theme.background_sunken};
  border-radius: ${({theme}) => theme.radius_round};
`;

const ScrollThumb = styled.div<Thumb>`
  position: absolute;
  right: 0;
  width: 6px;
  height: ${({$height}) => $height}%;
  background-color: ${({theme, $color}) => $color ? $color : theme.card_bg}; 
  border-radius: ${({theme}) => theme.radius_round};
  box-shadow: ${({theme}) => theme.shadow_xs};
`;

type Wrapper = {
  $maxHeight: string;
};

type Bar = {
  $isShown: boolean;
  $color?: string;
}

type Thumb = {
  $height: number;
  $color?: string;
};

export {ScrollWrapper, ScrollContent, ScrollBar, ScrollThumb};