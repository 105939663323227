import styled, { css } from "styled-components";

type PopoverType = {
  position: 'top' | 'bottom' | 'right' | 'left'
  alignment: 'start' | 'center' | 'end'
  background?: string
  alwaysLight?: boolean
  disableDiscounts?: boolean
};

type Arrow = {
  position: "top" | "bottom" | "right" | "left";
  alwaysLight?: boolean
  disableDiscounts?: boolean
}

const PopoverOuterContainer = styled.div`
  position: relative;
  background-color: transparent;
  border: none;
  padding: 0;
  max-height: 0%;
  pointer-events: none;
  transition: all 0.3s ease-in-out;
`;

const PopoverWrapper = styled.div`
  position: absolute;
  width: fit-content;
  z-index: 999;
`;

const PopoverChildren = styled.div`
  z-index: 999;
  display: flex;
  background-color:  ${({theme}) => theme.dark_overlay};
  box-shadow: ${({theme}) => theme.shadow_overlay};
  transition: 0.2s ease-in-out;

  & > * {
    transition: scale 0.2s ease-in-out;
    scale: 1;
  }
`;


const positions = {
  top: css`
    bottom: 100%;
    margin-bottom: 20px;
  `,
  bottom: css`
    top: 100%;
    margin-top: 20px;
  `,
  right: css`
    left: 2%;
    margin-left: 40px;
  `,
  left: css`
    right: 100%;
    margin-right: 20px;
  `,
}

const alignments = {
  start: (position: PopoverType['position']) => css`
    transform: translate(${['top', 'bottom'].includes(position) ? '-40px, 0' : '0, -40px'});
  `,
  center: (position: PopoverType['position']) => css`
    transform: translate(${['top', 'bottom'].includes(position) ? '-50%, 0' : '0, -50%'});
    `,
  end: (position: PopoverType['position']) => css`
    transform: translate(${['top', 'bottom'].includes(position) ? 'calc(-100% + 40px), 0' : '0, -65%'});
  `,
}

const popoverPositions = {
  top: () => css`
    top: 0%;
    left: 50%;
    translate: -50% 0;
  `,
  bottom: () => css`
    top: 100%;
    left: 50%;
    translate: -50% 0;
  `,
  right: () => css`
    top: 50%;
    left: 100%;
    translate: 0 -50%;
  `,
  left: () => css`
    top: 50%;
    right: 100%;
    translate: 0 -50%;
  `,
}

const arrowPosition = {
  top: css`
    translate: -50% -140%;
    transform: skew(10deg, 10deg);
    border-radius: 4px 0;
  `,
  bottom: css`
    translate: -50% 40%;
    transform: skew(10deg, 10deg);
    border-radius: 4px 0;
  `,
  right: css`
    translate: 195% -180%;
    transform: skew(-10deg, -10deg);
    border-radius: 0 4px;
  `,
  left: css`
    translate: -140% -50%;
    transform: skew(-10deg, -10deg);
    border-radius: 0 4px;
  `,
}

const PopoverContainer = styled.div<Arrow>`
  position: absolute;
  filter: ${({theme}) => theme.shadow_drop_md};
  z-index: 999;
  width: 80svw;
  scale: 1;
  opacity: 1;
  transition-property: scale, opacity;
  transition-duration: 0.2s;
  transition-timing-function: ease-in-out;
  ${({position}) => popoverPositions[position]}

  &::before {
    position: absolute;
    content: '';
    width: 24px;
    height: 24px;
    rotate: 45deg;
    z-index: ${({disableDiscounts}) => !disableDiscounts ? 99999 : ''};
    background-color: ${({theme, alwaysLight}) => alwaysLight ? "#FFF" : theme.card_bg};
    border: ${({theme, disableDiscounts}) => !disableDiscounts ? theme.card_border : ''};
    border-left-color: ${({theme, disableDiscounts}) => !disableDiscounts ? theme.card_border_color : ''};
    border-right-color: ${({theme, disableDiscounts}) => !disableDiscounts ? theme.card_bg : ''};
    border-top-color: ${({theme, disableDiscounts}) => !disableDiscounts ? theme.card_bg : ''};
    ${({position}) => arrowPosition[position]}
  }
`;

const PopoverContent = styled.div<PopoverType>`
  color: ${({theme, alwaysLight}) => alwaysLight ? "#000" : theme.text};
  background: ${({theme, alwaysLight}) => alwaysLight ? "#FFF" : theme.card_bg};
  border-radius: ${({theme}) => theme.radius_md};
  padding: 16px;
  position: absolute;
  transition: translate 0.1s ease-in-out;
  ${({position}) => positions[position]}
  ${({position, alignment}) => alignments[alignment](position)}
  border: ${({theme, disableDiscounts}) => !disableDiscounts ? theme.card_border : ''};
  border-color: ${({theme, disableDiscounts}) => !disableDiscounts ? theme.card_border_color : ''};

  .closeButton {
    left: 88%;
    cursor: pointer;
    width: 100%;
    position: absolute;

    svg {
      width: 17px;
      height: 17px;
    }
  }
`;

export {
  PopoverOuterContainer,
  PopoverWrapper,
  PopoverChildren,
  PopoverContainer,
  PopoverContent,
}