import { useLocation, useNavigate } from "react-router-dom";
import { PopupType } from "../../context/popups.enum";
import { usePopupNavigation } from "./usePopupNavigation";
import { clearRestaurantStorage } from "../functions";
import { useRestaurantData } from "./useRestaurantData";
import { useContext } from "react";
import { AppContext } from "../../context/AppContext";

export const useGoBack = () => {  const {
  state: { restaurant: { hasHome }, checkout: { myTotal } }, clearRestaurant } = useContext(AppContext);

  const navigate = useNavigate();
  const location = useLocation();

  const { goToPopup } = usePopupNavigation();

  const { restaurantGroupUuid, restaurantUuid } = useRestaurantData();
  const functionality = sessionStorage.getItem('functionality') || "";
  const deliTakeUuid = sessionStorage.getItem('deliTakeUuid') || "";
  const allowBooking = sessionStorage.getItem('allowBooking') || "";
  const comesFromRestaurantGroup = sessionStorage.getItem('comesFromRestaurantGroup');
  const comesFromDeliveryTakeaway = sessionStorage.getItem('comesFromDeliveryTakeaway');
  const comesFromBookingLanding = sessionStorage.getItem('comesFromBookingLanding');

  const navigationState = {state: {from: location.pathname}};

  const goBackFromProfile = () => {
    const state = location.state;

    if (state?.from && state?.from !== '/register') {
      navigate(state.from, navigationState);
    } else {
      navigate(state?.from === '/register' ? -2 : -1);
    }
  };

  const goBackFromHome = () => {
    if (hasHome && location.hash === '#catalog') {
      navigate('/checkout', navigationState);
    } else if (comesFromDeliveryTakeaway && deliTakeUuid && functionality === 'delivery') {
      clearRestaurant();
      clearRestaurantStorage();
      navigate(
        `/delivery_takeaway/${deliTakeUuid}`,
        navigationState,
      );
    } else if (comesFromRestaurantGroup && restaurantGroupUuid) {
      if (myTotal === 0) {
        clearRestaurant();
        clearRestaurantStorage();
        navigate(
          `/restaurant_group/${restaurantGroupUuid}`,
          navigationState,
        );
      } else {
        goToPopup(PopupType.ExitCheckout);
      }
    } else if (comesFromBookingLanding && allowBooking && restaurantUuid) {
      clearRestaurant();
      clearRestaurantStorage();
      navigate(
        `/booking_landing/${restaurantUuid}`,
        navigationState,
      );
    }
  };

  const goBackFromPayment = () => {
    navigate(
      `/checkout${(!hasHome && !allowBooking) ? '#catalog' : ''}`,
      navigationState
    );
  };

  if (location.pathname === '/profile') return goBackFromProfile;
  if (location.pathname === '/checkout') return goBackFromHome;
  if (['/payment-successful', '/cash-payment'].includes(location.pathname)) return goBackFromPayment;

  return () => navigate(-1);
};