import styled from "styled-components";

const OrderedStepsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  
  span {
    font-size: ${({theme}) => theme.font_md};
    font-weight: 600;
    line-height: 1em;
    text-align: center;
    width: 1em;
    flex-shrink: 0;
    color: ${({theme}) => theme.background};
    background-color: ${({theme}) => theme.text};
    border-radius: ${({theme}) => theme.radius_round};
    padding: 4px;
  }
  
  & > div {
    display: flex;
    align-items: center;
    gap: 16px;
    width: max-content;
    
    & > div {
      display: flex;
      flex-direction: column;
      gap: 8px;
    }
  }
  
  p {
    font-size: ${({theme}) => theme.font_md};
    font-weight: 600;
    line-height: 1.3em;
    color: ${({theme}) => theme.text};
    max-width: 18ch;
    margin: 0;
  }

  button {
    white-space: nowrap;
  }
`;

const GiftRewardContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  width: max-content;
  min-height: 2.5em;

  img {
    align-self: flex-end;
    flex-shrink: 0;
    margin: -100px -8px 0;
  }

  p {
    font-size: ${({theme}) => theme.font_md};
    font-weight: 600;
    line-height: 1.3em;
    color: ${({theme}) => theme.text};
    max-width: 15ch;
    margin: 0;
    
    &:has(b) {
      font-size: ${({theme}) => theme.font_sm};
    }

    b {
      color: ${({theme}) => theme.yumminn};
      font-weight: 600;
    }
  }

  span {
    font-size: ${({theme}) => theme.font_sm};
    font-weight: 600;
  }
`;

const RewardReminderContainer = styled.p`
  font-size: ${({theme}) => theme.font_md};
  font-weight: 600;
  line-height: 1.2em;
  text-align: center;
  color: ${({theme}) => theme.text};
  width: 11ch;
  margin: 0;
`

export {
  OrderedStepsContainer,
  GiftRewardContainer,
  RewardReminderContainer,
}