import { Trans } from 'react-i18next';
import { NotFoundContainer, NotFoundIcon } from './NotFoundContainers';
import { IconType } from '../../elements';

/*type Props = {
  qrUuid: string
  restaurantId: string | number
  tableNumber: string | number
  data?: any
  parentComponent: string
};*/

export const TableNotFound = (/*{data, ...props}: Props*/) => {
/*   useEffect(() => {
  // Too many logs
    try {
      if (sessionStorage.getItem('tableNotFoundPosted')) return;

      const errorInfo = {...props} as Record<string, any>;

      errorInfo.hasData = !!data;
      errorInfo.hasContent = !!data?.content;
      errorInfo.functionality = data?.content?.functionality;

      const infoString = Object.entries(errorInfo).reduce((string, [key, value]) => {
        return string + ` ${key}: ${value || null};`
      }, 'Data:');

      postError(new Error(`Table not found. ${infoString}`))
        .then(() => sessionStorage.setItem('tableNotFoundPosted', 'true'));
    } catch (error) {
      console.error(error);
    }
  }, []); */

  return (
    <NotFoundContainer>
      <NotFoundIcon type={IconType.Table}/>
      <p>
        <Trans i18nKey={"Sorry we haven't found your table"}>
          <strong>Oops!</strong> Sorry, <b>we haven&apos;t found your table.</b>
        </Trans>
      </p>
      <p>
        <Trans i18nKey='scan_again_or_contact_staff'>
          <b>Scan the QR again</b>, and if the problem persists, contact the staff.
        </Trans>
      </p>
    </NotFoundContainer>
  );
};