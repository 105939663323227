import { t } from 'i18next';
import React, { InputHTMLAttributes, useEffect, useRef, useState } from 'react';
import { Icon, IconType } from '../icons';
import { InputContainer } from './InputContainers';

// ❗❗👀 Avoid unwanted line breaks in labels using this non-breaking space --> "  " <--
// Long labels should break in two lines max, don't use excessively long labels with this input variant

export type InputProps = InputHTMLAttributes<HTMLInputElement> & {
  label?: string | React.ReactNode | Element
  title?: string | React.ReactNode | Element
  placeholder?: string
  required?: boolean
  password?: boolean
  error?: string | false
  leftIcon?: React.ReactNode
  rightIcon?: React.ReactNode
  noBorder?: boolean
  varForTranslation?: string
};

export const TextInput = ({
  label,
  title,
  placeholder = ' ',
  required = false,
  password,
  error,
  leftIcon,
  rightIcon,
  varForTranslation,
  noBorder,
  ...inputProps
}: InputProps) => {
  const [hidden, setHidden] = useState(password);

  const titleRef = useRef<HTMLHeadingElement>(null);

  const toggleVisibility: React.MouseEventHandler = (event) => {
    event.preventDefault();
    setHidden(prev => !prev);
  };

  const translateWithInterpolation = (key: string, interpolation?: { [key: string]: any }) => {
    return interpolation ? t(key, interpolation) : t(key);
  };

  useEffect(() => {
    const maxWidth = titleRef.current?.clientWidth ? `${titleRef.current.clientWidth}px` : 'auto';
    titleRef.current?.style.setProperty('--max_width', maxWidth);
  }, []);

  return (
    <InputContainer noBorder={noBorder}>
      {title &&
        <h5 className={required ? 'required' : ''}>
          {typeof title === 'string' ?  t(title) : title}
        </h5>
      }
      <label>
        {label &&
          <>
            <h6 ref={titleRef} className={(required && !title) ? 'required' : ''}>
              <span>
                {typeof label === 'string' ?  t(label) : label}
              </span>
            </h6>
            <hr/>
          </>
        }
        <div className='wrapper' onClick={inputProps.onClick}>
          {leftIcon}
          <input
            {...inputProps}
            type={hidden ? 'password' : 'text'}
            id={inputProps.id || inputProps.name}
            placeholder={t(placeholder)}
            autoComplete='off'
            readOnly={inputProps.readOnly || !inputProps.onChange}
          />
          {error &&
            <div className='error'>
              <Icon type={IconType.Error}/>
            </div>
          }
          {password &&
            <button onClick={toggleVisibility} aria-label={hidden ? t('show_password') : t('hide_password')}>
              <Icon type={IconType[hidden ? 'Showpassword' : 'Hidepassword']} size={20}/>
            </button>
          }
          {rightIcon}
        </div>
      </label>
      {error &&
        <i>{typeof error === 'string' ? translateWithInterpolation(error, { varForTranslation }) : error}</i>
      }
    </InputContainer>
  );
};