import React, { useContext, useEffect } from "react";
import { Experiment, FeaturesReady, GrowthBook, GrowthBookProvider, Result } from "@growthbook/growthbook-react";
import { getDeviceInfo } from "../shared/utils/getDeviceInfo";
import { AppContext } from "../context/AppContext";
import { v4 as uuidv4 } from 'uuid';
import { Loading } from "../elements";
import { postError } from "../shared/utils/postError";
import { getViewedExperiments, setViewedExperiments } from "./viewedExperiments";
import { UserContext } from "../context/auth/UserContext";

const getClientId = () => document.cookie.match(/_ga=(.+?);/)?.[1].split('.').slice(-2).join(".");

const restaurantIdStorage = sessionStorage.getItem('restaurantId') || null;
const restaurantGroupUuidStorage = sessionStorage.getItem('restaurantGroupUuid') || null;

const gbAttributes = sessionStorage.getItem('gbAttributes');
gbAttributes && sessionStorage.removeItem('gbAttributes');

const attributes = gbAttributes ? JSON.parse(gbAttributes) : {
  // user
  clientId: getClientId(),
  userId: null,
  loggedIn: false,
  // restaurant
  restaurantId: Number(restaurantIdStorage),
  restaurantGroupUuid: restaurantGroupUuidStorage,
  isMockup: false,
  hasCashbackProgram: false,
  // device
  yumminnId:  localStorage.getItem('deviceToken'),
  browser: getDeviceInfo().browser_name,
};

const sendEvent = (experiment: Experiment<any>, result: Result<any>) => {
  const experimentData = {
    experiment_id: experiment.key,
    feature_id: result.featureId,
    variation_id: result.variationId,
    variation_value: result.value,
  };

  const newExperiments = [...getViewedExperiments(), experimentData];
  setViewedExperiments(newExperiments);

  try {
    window.dataLayer.push({
      event: 'experiment_viewed',
      event_category: 'experiment',
      experiment_id: experiment.key,
      variation_id: result.variationId,
    });
  } catch (error: any) {
    console.log('Error sending growthbook event', error);
    if (error instanceof Error) postError(error);
  }
};

export const growthbook = new GrowthBook({
  apiHost: process.env.REACT_APP_GROWTH_BOOK_API_HOST,
  clientKey: process.env.REACT_APP_GROWTH_BOOK_CLIENT_KEY,
  enableDevMode: process.env.REACT_APP_ENVIRONMENT === 'local',
  attributes,
  trackingCallback: sendEvent,
});

export const GrowthBookWrapper = ({children}: {children: React.ReactNode}) => {
  const { state: {
    restaurantGroupUuid,
    restaurant: {
      id: restaurantId,
      isMockup,
      loyalty_program
    },
  }} = useContext(AppContext);

  const { user: { id: userId } } = useContext(UserContext);

  useEffect(() => {
    if (!localStorage.getItem('deviceToken')) {
      const newToken = uuidv4();
      try {
        localStorage.setItem('deviceToken', newToken);
      } catch (err:any) {
        postError(err)
      }
    }

    const saveAttributes = () => {
      const gbAttributes = JSON.stringify(growthbook.getAttributes());
      sessionStorage.setItem('gbAttributes', gbAttributes);
    }

    window.addEventListener('beforeunload', saveAttributes);
    
    growthbook.loadFeatures().catch((error) => {
      console.error("❌ Error al cargar GrowthBook features:", error);
      postError(error);
    });

    return () => window.removeEventListener('beforeunload', saveAttributes);
  }, []);

  useEffect(() => {
    growthbook.setAttributes({
      ...growthbook.getAttributes(),
      restaurantId,
      restaurantGroupUuid,
      clientId: getClientId(),
      userId,
      loggedIn: !!userId,
      hasCashbackProgram: loyalty_program,
      isMockup: isMockup,
    });

    growthbook?.refreshFeatures();
  }, [restaurantId, isMockup, loyalty_program, restaurantGroupUuid, userId]);

  return (
    <GrowthBookProvider growthbook={growthbook}>
      <FeaturesReady timeout={3000} fallback={<Loading parentComponent='GrowthBookWrapper' style={{height: '100%'}}/>}>
      {children}
      </FeaturesReady>
    </GrowthBookProvider>
  );
};
