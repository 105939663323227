import styled, { keyframes } from 'styled-components';
import { Popup } from './popup.component';
import { useViewport } from '../../context/ViewportContext';

const TRANSLATIONS = {
  up: '0 150%',
  down: '0 -150%',
  left: '150% 0',
  right: '-150% 0',
};

const slide = (direction: keyof typeof TRANSLATIONS) => keyframes`
  from {
    translate: ${TRANSLATIONS[direction]};
  }
  to {
    translate: 0 0;
  }
`;

const Sheet = styled(Popup).attrs({
  translate: 'no',
})`
  max-width: 100%;
  max-height: 100%;
  overflow: auto;
`;

export const BottomSheet = styled(Sheet)`
  width: 100%;
  max-width: 100vw;
  height: fit-content;
  border-radius: ${({theme}) => `${theme.radius_xl} ${theme.radius_xl} 0 0`};
  margin: auto 0 0 0;
  
  &.open, &.close {
    animation-name: ${slide('up')};
  }

  @media (orientation: landscape) {
    border-radius: 0;
    border-top: ${({theme}) => `${theme.card_border} ${theme.card_border_color}`};
  }
`;

export const TopSheet = styled(Sheet)`
  width: 100%;
  max-width: 100vw;
  height: fit-content;
  border-radius: ${({theme}) => `0 0 ${theme.radius_xl} ${theme.radius_xl}`};
  margin: 0 0 auto 0;

  &.open, &.close {
    animation-name: ${slide('down')};
  }

  @media (orientation: landscape) {
    border-radius: 0;
    border-bottom: ${({theme}) => `${theme.card_border} ${theme.card_border_color}`};
  }
`;

export const LeftSheet = styled(Sheet)`
  height: 100%;
  max-height: 100vh;
  width: fit-content;
  border-radius: ${({theme}) => `0 ${theme.radius_xl} ${theme.radius_xl} 0`};
  margin: ${({className}) => className === 'isReceiptListOrWalletPopup' ? "0 auto 0 68px" : "0 auto 0 0"};

  &.open, &.close {
    animation-name: ${slide('right')};
  }

  @media (orientation: landscape) {
    border-radius: 0;
    border-right: ${({theme}) => `${theme.card_border} ${theme.card_border_color}`};
    min-width: 25rem;
  }
`;

export const RightSheet = styled(Sheet)`
  height: 100%;
  max-height: 100vh;
  width: fit-content;
  min-width: 23rem;
  max-width: 23rem;
  border-radius: ${({theme}) => `${theme.radius_xl} 0 0 ${theme.radius_xl}`};
  margin: 0 0 0 auto;

  &.open, &.close {
    animation-name: ${slide('left')};
    min-width: 23rem;
  }

  @media (orientation: landscape) {
    border-radius: 0;
    border-left: ${({theme}) => `${theme.card_border} ${theme.card_border_color}`};
  }
`;

const scaleUp = keyframes`
  from {
    scale: 0;
  }
  to {
    scale: 1;
  }
`;

export const CenterSheet = styled(Sheet)`
  display: flex;
  flex-direction: column;
  inset: 50% 0;
  //width: 100%;
  max-width: 100vw;
  height: fit-content;
  color: ${({theme}) => theme.text};
  background-color: ${({theme}) => theme.background};
  border-radius: ${({theme}) => theme.radius_lg};
  box-shadow: ${({theme}) => theme.shadow_lg};
  max-width: calc(100% - 32px);
  max-height: calc(100% - 32px);
  margin: auto;
  box-sizing: border-box;

  &.open, &.close {
    animation-name: ${scaleUp};
    animation-duration: 0.3s;
    animation-timing-function: ease-in-out;
  }

  &.open {
    animation-fill-mode: forwards;
  }

  &.close {
    animation-direction: reverse;
    animation-fill-mode: backwards;
  }

  @media (orientation: landscape) and (max-width: 1024px) {
    max-width: 90%;
    max-height: 90%;
  }
  
`;

const Sheets = {
  top: TopSheet,
  bottom: BottomSheet,
  left: LeftSheet,
  right: RightSheet,
  center: CenterSheet,
};

type ResponsiveSheetProps = Parameters<typeof Sheet>[0] & {
  portraitPosition?: keyof typeof Sheets
  landscapePosition?: keyof typeof Sheets
};

export const ResponsiveSheet = ({ portraitPosition = 'bottom', landscapePosition = 'right', ...popupProps }: ResponsiveSheetProps) => {
  
  const { orientation } = useViewport();

  const Sheet = orientation === 'landscape' ? Sheets[landscapePosition] : Sheets[portraitPosition];

  return <Sheet {...popupProps}/>
};