import React from 'react';
import { BaseCard, FlipCardContainer } from './CardContainers';

type Props = {
  front: React.ReactNode
  back: React.ReactNode
  flipped?: boolean
  className?: string
  onFlip?: React.ChangeEventHandler
};

export const FlipCard = ({ front, back, flipped, onFlip, className = '' }: Props) => {
  return (
    <FlipCardContainer className={className}>
      <input
        type='checkbox'
        checked={onFlip ? flipped : undefined}
        defaultChecked={onFlip ? undefined : flipped}
        onChange={onFlip}
      />
      <div>
        <BaseCard className='front'>
          {front}
        </BaseCard>
        <BaseCard className='back'>
          {back}
        </BaseCard>
      </div>
    </FlipCardContainer>
  );
};