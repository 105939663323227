import React from "react";
import styled, { keyframes } from "styled-components";


export const LoadingButton = ({style={}}: any) => {
  const restaurantId = Number(sessionStorage.getItem("restaurantId"))
  return(
    <Loading restaurantId={restaurantId} style={style}>
      <LoadingItem restaurantId={restaurantId}/>
    </Loading>
  )
};

const Loading = styled.div<props>`
    display: grid;
    justify-content: center; 
    align-items: center;
    color: white;
    background-color: ${({theme}) => theme.primary};
    font-family: ${({theme}) => theme.typography};
    border-radius: ${({theme}) => theme.radius_round};
    border: 4px solid ${({theme}) => theme.primary};
    text-transform: uppercase;
    font-weight: 700;
    text-align: center;
    justify-content: center;
    padding: 4px 0px;
`;

type props = {
  restaurantId?: number;
}

const spin = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const LoadingItem = styled.div<props>`
  display: inline-block;
  width: 20px;
  height: 20px;
  border-radius: ${({theme}) => theme.radius_round};
  border: 4px solid;
  border-color: white;
  border-top-color: ${({theme}) => theme.primary};
  animation: ${spin} 1s linear infinite;
  -webkit-animation: ${spin} 1s linear infinite;
`;



