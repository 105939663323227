import styled, { keyframes } from "styled-components";

type props = {
  isReceiptListOrWalletPopup?: boolean
}

export const PopupContainer = styled.dialog<props>`
  display: flex;
  background-color: transparent;
  border: none;
  padding: 0;
  opacity: 0;
  pointer-events: none;
  overflow: hidden;
  transition: opacity 0.3s ease-in-out;
  max-width: 0%;
  max-height: 0%;
  
  &[open]{
    pointer-events: all;
    opacity: 1;
    width: 100%;
    height: 100%;
    max-width: 100%;
    max-height: 100%;
    margin-left: 0px;
  }

  &::backdrop {
    background-color: ${({theme}) => theme.backdrop_color};
    backdrop-filter: blur(2px);
  }
`;

const scaleUp = keyframes`
  from {
    scale: 0;
    opacity: 0;
    pointer-events: none;
  }
  to {
    scale: 1;
    opacity: 1;
    pointer-events: all;
  }
`;

export const PopupContent = styled.div`
  display: flex;
  flex-direction: column;
  inset: 50% 0;
  color: ${({theme}) => theme.text};
  background-color: ${({theme}) => theme.background};
  border-radius: ${({theme}) => theme.radius_lg};
  box-shadow: ${({theme}) => theme.shadow_lg};
  max-width: calc(100% - 32px);
  max-height: calc(100% - 32px);
  margin: auto;
  box-sizing: border-box;

  &.open, &.close {
    animation-name: ${scaleUp};
    animation-duration: 0.3s;
    animation-timing-function: ease-in-out;
  }

  &.open {
    animation-fill-mode: forwards;
  }

  &.close {
    animation-direction: reverse;
    animation-fill-mode: backwards;
  }

  & > header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 16px;
    color: ${({theme}) => theme.text};
    padding: 24px 24px 0;

    h2, h4 {
      font-weight: 600;
      margin: 0;
      text-wrap: balance;

      &::first-letter {
        text-transform: capitalize;
      }
    }

    h2 {
      font-size: ${({theme}) => theme.font_lg};
      text-align: center;
      
      @media (orientation: landscape) {
        font-size: ${({theme}) => theme.font_l_xl};
        width: 100%;
      }
    }

    h4 {
      font-size: ${({theme}) => theme.font_md};
      
      @media (orientation: landscape) {
        font-size: ${({theme}) => theme.font_lg};
      }
    }
  }
`;

export const BackButton = styled.button`
  display: flex;
  color: ${({theme}) => theme.text};
  background-color: transparent;
  border: none;
  padding: 16px;
  margin: -16px;
  z-index: 99;
  background: radial-gradient(50% 50%, ${({theme}) => theme.background} 70%, transparent 70%);

  &:focus {
    outline: none;
  }

  &:disabled {
    opacity: 0;
    pointer-events: none;
  }
`;

export const CloseButton = styled(BackButton)`
  justify-self: flex-end;

  &:first-child {
    margin-left: auto;
  }

  &:disabled {
    opacity: 0;
    pointer-events: none;
  }
`;