import { t } from 'i18next';
import React, { useContext, useEffect, useState } from 'react';
import { ClientCardContainer, ClientCardInnerWrapper } from '../ProfileContainers';
import { ClientStats } from './ClientStats';
import { ProgressBadge } from './ProgressBadge';
import { UserContext, UserContextDispatchContext } from '../../../context/auth/UserContext';
import { postError } from '../../../shared/utils/postError';
import { useReceipts } from '../../../shared/utils/useReceitps';
import { getProgramsFromStorage } from '../../checkout/functions/getProgramsFromStorage';
import { useFeatureIsOn } from '@growthbook/growthbook-react';
import { GB_FEATS } from '../../../growthbook/features';

export const ClientCard = ({user}: Props) => {
  const token: any = localStorage.getItem("token_yumminn");
  const token_type: any = localStorage.getItem("type");
  const showStats = useFeatureIsOn(GB_FEATS.SHOW_PROFILE_STATS);
  
  const [isLoading, setIsLoading] = useState(false);

  const {
    user: {
      cashbackCents,
      restaurantsVisited,
      moneySavings,
      timeSavings,
      totalCredits,
      creditDetails
    },
  } = useContext(UserContext);

  const { loyaltyProgram } = getProgramsFromStorage();

  const { updateUser } = useContext(UserContextDispatchContext);

  const userName = (user.first_name || user.last_name)
    ? `${user.first_name || ''} ${user.last_name || ''}`.trim()
    : user.email.split('@')[0];

  const payments = useReceipts({});

  useEffect(() => {
    let isMounted = true;
    setIsLoading(true)

    if(!token || !token_type){
      return
    }

    try {
      const newRestaurantsVisited = new Set(payments.map(payment => payment.restaurant_id)).size;  // new Set() "removes" duplicated values in array,
      const newMoneySavings = payments.reduce((totalDiscount, payment) => {
        const discount = isNaN(payment.loyalty_discount_cents) ? 0 : payment.loyalty_discount_cents;
        return totalDiscount + discount;
      }, 0);
      const newTimeSavings = payments.reduce((totalTime, payment) => payment.functionality === 'just_pay' ? totalTime + 10 : totalTime + 15, 0); // 10 minutes x just pay, 15 minutes x order and pay,
      if (
        newRestaurantsVisited !== restaurantsVisited ||
        newMoneySavings !== moneySavings ||
        newTimeSavings !== timeSavings
      ) {
        updateUser({
          restaurantsVisited: newRestaurantsVisited,
          moneySavings: newMoneySavings,
          timeSavings: newTimeSavings
        })
      }
    } catch (error) {
      console.log({error})
      postError(error as Error)
    } finally {
      if (isMounted) {
        setIsLoading(false)
        isMounted = false;
      }
    }

    return () => {
      isMounted = false; // Cleanup function
    };
  }, [payments, restaurantsVisited, moneySavings, timeSavings, updateUser]);

  return (
    <ClientCardContainer>
      <div>
        <h3>
          {t('hello')} {userName}!
        </h3>
      </div>
      
      <ClientCardInnerWrapper>
        {creditDetails && creditDetails.length > 0 &&
          <ProgressBadge type='credit' amount={totalCredits}/>
        }
        {loyaltyProgram &&
          <ProgressBadge type='cashback' amount={cashbackCents}/>
        }
      </ClientCardInnerWrapper>
      
      {showStats &&
      <ClientCardInnerWrapper>
        <ClientStats type='restaurant' stat={restaurantsVisited} isLoading={isLoading}/>
        <ClientStats type='money' stat={moneySavings} isLoading={isLoading}/>
        <ClientStats type='time' stat={timeSavings} isLoading={isLoading}/>
      </ClientCardInnerWrapper>
      }
    </ClientCardContainer>
  );
};

type Props = {
  user: any;
  restaurantId: number,
  tableNumber: string,
};