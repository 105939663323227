import { useContext } from 'react';
import { t } from 'i18next';
import { calculateCashbackCents, calculateEuros, calculatePoints } from '../../pages/checkout/functions/calculatePrices';
import { getProgramsFromStorage } from '../../pages/checkout/functions/getProgramsFromStorage';
import { Trans } from 'react-i18next';
import { RewardBadge } from '../register/RegisterContainers';
import { Button, Icon, IconType } from '../../elements';
import { ButtonVariant } from '../../elements/buttons/enums';
import { WelcomeContainer } from './WelcomeContainers';
import { useNavigate } from 'react-router-dom';
import { usePopupNavigation } from '../../shared/utils/usePopupNavigation';
import { UserContext } from '../../context/auth/UserContext';
import { postError } from '../../shared/utils/postError';
import { useViewport } from '../../context/ViewportContext';
import { PopupType } from '../../context/popups.enum';

type Props = {
  payment?: number;
};

export const WelcomeReward = ({payment}: Props) => {
  const { user } = useContext(UserContext);

  const navigate = useNavigate();
  const { goToPopup, closePopup, goBackPopup } = usePopupNavigation();
  const { loyaltyProgram } = getProgramsFromStorage()
  const { isMobile } = useViewport();

  const points = calculatePoints(payment ? payment : 10000);
  const cashback = loyaltyProgram ? payment ? calculateCashbackCents(payment) : 100 : 0;
  const euros = calculateEuros(cashback);

  const goToProfile = () => {
    closePopup().then(() => {
      if(!isMobile){
        goToPopup(PopupType.Profile);
      } else {
        navigate('/profile')
      }
    }).catch((err: any) => {
      postError(err);
      console.log(err)
  });
  };

  return (
    <WelcomeContainer>
      <h3>{t('welcome user', { name: user.getUsername() })}</h3>
      {!payment &&
        <p>
          <Trans i18nKey={'youve won this reward'}>
            <b>Wow! 🎉</b> You&apos;ve won this reward:
          </Trans>
        </p>
      }
      {!!payment &&
        <b>{t('check your reward')}</b>
      }
      <div>
        {points > 0 &&
          <RewardBadge type={'points'}>
            <Icon type={IconType.PointColor} size={30}/>
            <span>
              <b>{points}</b>
              {t('points')}
            </span>
          </RewardBadge>
        }
        {cashback > 0 &&
          <RewardBadge type={'cashback'}>
            <Icon type={IconType.CashbackColor} size={30}/>
            <span>
              <b>{euros + '€'}</b>
              {t('cashback')}
            </span>
          </RewardBadge>
        }
      </div>
      {!payment &&
        <>
          <b>{t('do you want to get more')}</b>
          <Button
            title={t('pay your bill')}
            handleClick={goBackPopup}
          />
        </>
      }
      {!!payment &&
        <>
          <Button
            title={t('see my profile')}
            handleClick={goToProfile}
          />
          <Button
            title={t('not now')}
            variant={ButtonVariant.Secondary}
            handleClick={goBackPopup}
          />
        </>
      }
    </WelcomeContainer>
  );
};