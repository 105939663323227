import styled from "styled-components";

export const C2PContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 24px;

  h2 {
    font-size: ${({theme}) => theme.font_lg};
    text-align: center;
    line-height: 1.2;
    margin: 0;

    svg {
      vertical-align: middle;
    }
  }

  p {
    line-height: 1.3;
    padding: 8px 8px 16px;
    margin: 0;
  }

  b {
    font-weight: 600;
  }

  div {
    background-color: ${({theme}) => theme.cash_a10};
    padding: 8px;
    border-radius: ${({theme}) => theme.radius_sm};

    div {
      background-color: #FFF; //always white
      color: ${({theme}) => theme.text_dark};

      img {
        margin: -8px 0 -26px -16px;
      }

      p {
        font-size: ${({theme}) => theme.font_xxs};
        padding: 0 0 0 28px;
      }
    }
  }

  button {
    span {
      display: flex;
      align-items: center;
      gap: 8px;
    }
  }
`;