import { useNavigate } from 'react-router-dom';
import { PageHeader } from '../../elements';
import ZeroSixLoyaltyCards from '../zerosix';
import { useViewport } from '../../context/ViewportContext';
import { usePopupNavigation } from '../../shared/utils/usePopupNavigation';
import { PopupType } from '../../context/popups.enum';


export const ZeroSixCards = () => {
  const navigate = useNavigate();
  const { isMobile } = useViewport();
  const { goToPopup } = usePopupNavigation();
  
  const goToProfile = () => {
    if(!isMobile){
      goToPopup(PopupType.Profile);
    } else {
      navigate('/profile')
    }
  };

  return (
    <>
      <PageHeader text='ZeroSix' onGoBack={() => goToProfile()}/>
      <ZeroSixLoyaltyCards />
    </>
  )
};