import { MouseEventHandler, useRef } from 'react'

type Props = {
  callback: (...args: any) => void
  delay?: number
  interval?: number
  speedUp?: number
  minInterval?: number
};

export const useHold = ({ callback, delay = 0, interval = 50, speedUp = 1, minInterval = 10 }: Props) => {
  const timeoutRef = useRef<NodeJS.Timeout>();

  const repeat = (initialInterval: number) => {
    callback();
    
    timeoutRef.current = setTimeout(() => {
      repeat(Math.max(initialInterval / speedUp, minInterval))
    }, initialInterval);
  };

  const onMouseDown: MouseEventHandler = () => {
    timeoutRef.current = setTimeout(() => {
      repeat(Math.max(interval / speedUp, minInterval))
    }, delay);
  };

  const onMouseUp: MouseEventHandler = () => clearTimeout(timeoutRef.current!);

  return { onMouseDown, onMouseUp };
}