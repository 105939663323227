import React, { useEffect } from 'react';
import { getProgramsFromStorage } from '../../pages/checkout/functions/getProgramsFromStorage';
import { WelcomeCashback } from './WelcomeCashback';
import { WelcomeReward } from './WelcomeReward';
import { useFeatureIsOn } from '@growthbook/growthbook-react';
import { GB_FEATS } from '../../growthbook';
import { WelcomeNoCashback } from './WelcomeNoCashback';
import { eventTrack } from '../../../useGaTracker';
import { PopupProps } from '../../elements/popup/popup.component';
import { Popup } from '../../elements';

type Props = PopupProps & {
  productsInTable?: boolean;
  payment?: number;
  goToMenu?: () => void;
};

export const WelcomePopup = ({ productsInTable, payment, goToMenu, ...popupProps }: Props) => {
  const { loyaltyProgram } = getProgramsFromStorage();
  const showCashbackPopup = useFeatureIsOn(GB_FEATS.PPUP_CASHBACK_HOME) && loyaltyProgram;

  const showNoCashbackPopup = useFeatureIsOn(GB_FEATS.PPUP_REGISTER_NO_CASHBACK);

  useEffect(() => {
    eventTrack('view_welcome-popup', 'view_welcome-popup', 'view_welcome-popup')
  }, []);

  const getChildren = () => {
    if (showCashbackPopup && location.pathname === '/checkout') return (
      <WelcomeCashback goToMenu={goToMenu as () => void}/>
    );
  
    if(showCashbackPopup && location.pathname !== '/checkout') return (
      <WelcomeReward payment={payment}/>
    )
  
    //Pop up bienvenida / sin o con productos cargados / en la home (PAT)
    if (showNoCashbackPopup && location.pathname === '/checkout') return (
      <WelcomeNoCashback productsInTable={productsInTable} goToMenu={goToMenu as () => void}/>
    );
  
    //Pop up bienvenida / sin cashback / en la pantalla de pago realizado (mismo para PAT y OP) + popup bienvenida con cashback
    if (showNoCashbackPopup && location.pathname !== '/checkout') return (
      <WelcomeNoCashback payment={payment} productsInTable={productsInTable} goToMenu={goToMenu as () => void}/>
    );
  
    return <WelcomeNoCashback productsInTable={productsInTable} goToMenu={goToMenu as () => void}/>;
  };

  return (
    <Popup {...popupProps}>
      {getChildren()}
    </Popup>
  );
};