import styled from "styled-components";

type props = {
  path?: string,
  ticketToolTipOpen?: boolean;
}

const DEMO_GROUP = '/restaurant_group/5c10ee0fd2a9411988ebc21bc333837f';

export const HeaderBarContainer = styled.header.attrs({
  id: 'headerContainer',
})<props>`
  --header_bar_bg: ${({path, theme}) => path && path.includes('/restaurant_group') ? 'transparent': theme.header_bg};

  position: ${props => props.path == '/checkout' ? 'fixed' : 'sticky'};
  display: ${props => props.path == '/login' ? 'none' : 'flex'};
  justify-content: space-between;
  align-items: center;
  gap: 8px;
  color: ${({path, theme}) => path && path.includes(DEMO_GROUP) ? theme.text_light : path && path.includes('/restaurant_group') ? theme.text : theme.text_light};
  background-color: var(--header_bar_bg);
  padding: 8px 16px;
  z-index:  ${({ticketToolTipOpen}) => ticketToolTipOpen? '9999' : '15'};
  width: fill-available;
  box-sizing: border-box;
  top: 0;

  @media (orientation: landscape) {
    --header_bar_bg: ${({theme}) => theme.header_bg};
    flex-direction:  ${({path}) => path && path.includes('/restaurant_group') ? 'column' : 'column-reverse'};
    gap: 8px;
    width: min-content;
    height: 100%;
    padding: 16px 8px;
    min-width: 68px;
    color: #FFF;
  }

  .logo {
    @media (orientation: landscape) {
      max-width: 24px;
      margin-top: 48px;
    }
  }
`;

export const KioskHeaderBarContainer = styled(HeaderBarContainer)`
  justify-content: flex-end;
  height: 44px;

  &:not(:has(*)) {
    display: none;
  }

  @media (orientation: landscape) {
    flex-direction: column-reverse;
    height: 100%;
    width: 68px;
  }
`;

export const HeaderInnerContainer = styled.div<props>`
  display: flex;
  gap: 8px;
  align-items: center;

  &.backButton {
    svg {
      margin-right: 2px;
      background: #404040;
      padding: 10px;
      border-radius: 9999px;
      color: #FFF;
    }
  }

  > a {
    display: flex;
    align-items: center;
  }

  > button {
    display: flex;
    background-color: transparent;
    border: none;
    padding: 0;
    color: currentColor;

    &:focus {
      outline: none;
    }

    @media (orientation: landscape) {
      color: ${({path}) => path && path.includes('/restaurant_group') ? '#FFF' : 'currentColor'};
      cursor: pointer;
    }
  }

  dialog {
    clip-path: inset(42px 0 0 0);

    &::backdrop {
      margin: 42px 0 0 0;
    }
    
    @media (orientation: landscape) {
      clip-path: inset(0 0 0 68px);

      &::backdrop {
        margin: 0 0 0 67px;
      }
    }
  }

  @media (orientation: landscape) {
    flex-direction: column-reverse;
    gap: 27px;
  }
`;

export const ProfileButton = styled.button`
  display: flex;
  color: currentColor;
  background-color: transparent;
  border: none;
  padding: 0;

  @media (orientation: landscape){
    cursor: pointer;
  }
`;

type PointsCounterProps = {
  type?: string;
}

export const PointsCounter = styled.div<PointsCounterProps>`
  background: ${({theme, type}) => type === 'credit' ? '#696969' : theme.header_counter_bg};
  border: 2px solid ${({theme, type}) => type === 'credit' ? '#848484' : theme.header_counter_border};
  box-shadow: ${({theme}) => theme.shadow_inset_sm};
  border-radius: ${({theme}) => theme.radius_round};
  display: flex;
  align-items: center;
  font-size: ${({theme}) => theme.font_xs};
  line-height: 1em;
  height: 20px;
  padding: 2px 4px;

  @media (orientation: landscape) {
    flex-direction: column;
    border-radius: 8px;
    height: 100%;
    background: none;
    border: none;
    padding: 0;
    position: relative;
    cursor: pointer;

    svg {
      width: 27px;
      height: 27px;
    }
  }

  span {
    margin: 0 4px;
    font-weight: 700;
    padding: 8px 0px;
  }
`;

export const CreditsPopoverWrapper = styled.div`
  display: flex; 
  flex-direction: column;
  gap: 15px;
  align-items: center;
  justify-content: center;

  .message {
    font-size: ${({theme}) => theme.font_md};
    font-weight: 400;
  }
`;

export const InitialsContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: ${({theme}) => theme.font_sm};
  font-weight: 700;
  line-height: 1em;
  height: 20px;
  width: 20px;
  padding: 2px;
  border-radius: ${({theme}) => theme.radius_round};
  color:  currentColor;
  border: 2px solid currentColor;
  flex: 0 0 20px;

  @media (orientation: landscape) {
    padding: 6px;
    cursor: pointer;
  }
`;

export const ProfileHeader = styled.div<{showBackButton?: boolean}>`
  position: sticky;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: ${({theme}) => theme.text_light};
  padding: 8px 16px;
  display: flex;
  min-height: 28px;
  z-index: 10;
  background-color: ${({theme}) =>  theme.header_bg};

  @media (orientation: landscape) {
    flex-direction: column-reverse;
    gap: 8px;
    width: min-content;
    padding: 16px;
  }

  & > span {
    display: flex;
    text-align: center;
    align-items: center;
  }

  .back_button {
    opacity: ${({showBackButton}) => showBackButton ? '1' : '0'};
    pointer-events: ${({showBackButton}) => showBackButton ? 'all' : 'none'};
    color: ${({theme}) =>  theme.text_light};
  }

  .logo {
    @media (orientation: landscape) {
      max-width: 24px;
      margin-top: 48px;
    }
  }
`;

export const ImgLogoBizum = styled.img`
  white-space: nowrap;
  width: 70px;
  left: 10px;
  margin-top: 3.5px;
  margin-left: 2px;
`;