import React, {useState, useEffect } from "react";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { CheckboxContainer } from "./checkboxContainers";
import { OrderItem } from "../../context/app";
import { Icon, IconType } from "../icons";

export const CheckBoxLineModifierComponent = ({modifier, enableCheckboxTrigger, enableModifierCheckboxTrigger, addModifier, removeModifier, remaining, selectedLineItems, selectedLineModifiers, disabled = false} : props) => {
  
  const [isChecked, setIsChecked] = useState(false);
  const {t} = useTranslation();

  useEffect(() => {
    if (enableCheckboxTrigger) {
        const triggerLineJSON = JSON.stringify(enableCheckboxTrigger.line);
        const modifierLineJSON = JSON.stringify(modifier.line);
        if (triggerLineJSON === modifierLineJSON) {
            setIsChecked(true);
        }
    }
  }, [enableCheckboxTrigger]);

  useEffect(() => {
    if (enableModifierCheckboxTrigger && enableModifierCheckboxTrigger["modifierKey"] == modifier["key"]) {
      setIsChecked(true);
    }
  }, [enableModifierCheckboxTrigger]);

  const handleClick = () => {
    if (disabled) return;
    
    let selectedTotal = 0;
    selectedLineItems.map((sel_item) => {
        selectedTotal += Number(sel_item.price);
    })
    selectedLineModifiers.map((sel_modifier: any) => {
        selectedTotal += Number(sel_modifier.total_price);
    });
    selectedTotal += Number(modifier.total_price);

    if (!isChecked) {
        if (remaining - selectedTotal >= 0) {
            addModifier(modifier)
        setIsChecked(!isChecked)
        } else {
        toast.error((t('Total selected higher than remaining')), {
            theme: 'colored'
        });
        }
    } else {
        removeModifier(modifier)
        setIsChecked(!isChecked)
    }
  };

  return (
    <CheckboxContainer isChecked={isChecked} onClick={handleClick} isDisabled={disabled}>
      {isChecked &&
        <Icon type={IconType.Check}/>
      }
    </CheckboxContainer>
  );
};

type props={
  modifier: any;
  enableCheckboxTrigger: any;
  enableModifierCheckboxTrigger: any;
  addModifier: any;
  removeModifier: any;
  remaining: any;
  selectedLineItems: OrderItem[];
  selectedLineModifiers: any[];
  disabled: boolean;
}
