
export const calculateExtraCredits = (bonus: number, credit_amount: number, type: string) => {
  let extraCredits = 0;    
  if(type === 'percentage'){
      extraCredits = bonus * credit_amount / 100
  } else {
      extraCredits = bonus
  }
  return beautifyDecimalsAmount(extraCredits); 
}

export const calculateExtraCreditsNumber = (bonus: number, credit_amount: number, type: string) => {
  let extraCredits = 0;    
  if(type === 'percentage'){
      extraCredits = bonus * credit_amount / 100
  } else {
      extraCredits = bonus
  }
  return extraCredits; 
}


export const beautifyDecimalsAmount = (amount: number) => {
  return amount.toString().replace('.', ',').endsWith(',00') ? amount.toString().replace('.', ',').slice(0, -3) : amount.toString().replace('.', ',')
}