import React from "react";
import { ILayout } from "./interfaces";
import { RenderWhenContainer } from "../render-when";
import styled from 'styled-components';

export const LayoutContainer = ({ children, background, anotherBackground }: ILayout) => {
  return(
    <LayoutOuterContainer background={background} anotherBackground={anotherBackground}>
      <LayoutInnerContainer>
        <RenderWhenContainer>{children}</RenderWhenContainer>
      </LayoutInnerContainer>
    </LayoutOuterContainer>
  )
};

const LayoutOuterContainer = styled.div<{background?: string, anotherBackground?: boolean}>`
  display: flex;
  flex-direction: column;
  min-height: var(--dvh); /* fallback */
  min-height: 100dvh;
  justify-content: space-between;
  scroll-behavior: smooth;
  background-color: ${({anotherBackground}) => anotherBackground ? 'var(--background_lighter)' : 'var(--background)'};
  background-image: url(${({background}) => background});
  background-attachment: fixed;
  color: var(--text);

  @media (orientation: landscape) {
    flex-direction: row;
    background-repeat: no-repeat;
    background-position: center;
    background-blend-mode: color;
  }
`;

const LayoutInnerContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  
  @media (orientation: landscape) {
    flex-direction: row;
    overflow-y: auto //must be conditional or it creates horizontal scroll in payment successful in mobile
  }
`;
