import React from 'react'
import styled from 'styled-components';
import { Trans, useTranslation } from 'react-i18next';
import MahouPlaza from '../../assets/images/MahouPlaza.svg'
import MahouBeers from '../../assets/images/mahouBeers.webp'
import TicketsMahou from '../../assets/images/tickets_mahou.svg'
import { Button } from '../buttons';
import { removeUnusefulDecimals, calculateEuros } from '../../pages/checkout/functions/calculatePrices';
import { Icon, IconType } from '../icons';

type Props = {
  login_url: string
  giftedCreditAmount?: number
};

export const MahouLogin = ({login_url, giftedCreditAmount}: Props) => {
  const { t } = useTranslation();
  const path = window.location.pathname
  const isEventsCatalog = path === '/event_catalog'

  const handleLogin = () => {
    window.location.href = login_url;
  };

  return (
    <Container>
      <img src={MahouPlaza} alt='Mahou Plaza'/>
      <img width={isEventsCatalog ? "35%" : "50%"} src={isEventsCatalog ? TicketsMahou : MahouBeers} alt='Mahou Beers'/>
      {!isEventsCatalog && <h4>{t('welcome')}</h4>}
      {!isEventsCatalog && <p>{t('sign_up_and_earn_prizes')}</p>}
      {isEventsCatalog && 
        <p>
          <Trans i18nKey="events_login_sentence">
            <b>¡Accede</b> con Mahou para <b>comprar</b> este pack y sumaremos <b>los créditos a tu perfil!</b>
          </Trans>
        </p>
      }
      {giftedCreditAmount && 
        <CreditBox>
          <span style={{marginLeft: "5px"}}>
            <b>
              <Icon type={IconType.CreditCashbackRed} /> {removeUnusefulDecimals(calculateEuros(giftedCreditAmount))} €
            </b>
            {t('of_consumption_credits')}
          </span>
        </CreditBox>
      }
      <Button
        color='#B62F36'
        title={t('sign up')}
        handleClick={() => handleLogin()}
      />
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: center;

  img {
    //max-height: 60vh;
  }

  & > h4 {
    font-size: ${({theme}) => theme.font_xl};
    font-weight: 600;
    line-height: 1.2;
    text-align: center;
    text-wrap: balance;
    margin: 0;
  }
  
  & > p {
    font-size: ${({theme}) => theme.font_md};
    line-height: 1.3;
    text-align: center;
    text-wrap: balance;
    margin: 0;
  }
`;

const CreditBox = styled.div`
  background: ${({theme}) => theme.background_credits_tickets}; 
  border-radius: 6px; 
  padding: 10px 15px;
  margin: 16px 0;
  font-size: ${({theme}) => theme.font_sm};


  & > div {
    font-weight: 400;
    font-size: ${({theme}) => theme.font_sm};
  }

  span {
    font-weight: 400;
    font-size: ${({theme}) => theme.font_md};
    color: #${({theme}) => theme.text_credits_tickets};  
    display: flex; 
    align-items: center; 
    gap: 5px;
  }

  b {
    display: flex; 
    align-items: center; 
    gap: 3px;
  }
`;