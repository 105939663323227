import React from 'react';
import { t } from 'i18next';
import { Trans } from 'react-i18next';
import { Button, Icon, IconType, Popup } from '../../elements';
import { C2PContainer } from './C2PInstructiveContainers';
import fakeInput from './fake_monei_input.svg';
import { PopupProps } from '../../elements/popup/popup.component';

type Props = PopupProps & {
  onContinue: () => void
};

export const C2PInstructive = ({ onContinue, ...popupProps }: Props) => {
  return (
    <Popup {...popupProps}>
      <C2PContainer>
        <h2>
          <Trans i18nKey={'how_to_pay_c2p_title'}>
            How to pay with <br/><Icon type={IconType.C2P} size={18}/> Click to Pay?
          </Trans>
        </h2>
        <div>
          <p>
            <Trans i18nKey={'how_to_pay_c2p_explanation'}>
              On the next screen, <b>enter your card details</b> and, if this is your first time with Click to Pay, <b>remember to check the box</b> below.
            </Trans>
          </p>
          <div>
            <img src={fakeInput}/>
            <p>
              <Trans i18nKey={'how_to_pay_c2p_checkbox'}>
                By continuing, MONEI will share your card details, billing address, and email with Mastercard to securely allow you to enroll <Icon type={IconType.C2P} size={8}/> Click to Pay for faster checkouts.
              </Trans>
            </p>
          </div>
        </div>
        <Button
          title={
            <span>
              {t('continue_with')}
              <Icon type={IconType.C2P} size={20}/>
            </span>
          }
          handleClick={onContinue}
        />
      </C2PContainer>
    </Popup>
  );
};