import styled from "styled-components";
import { ResponsiveSheet } from "../popup";

export const NavigateAllSheet = styled(ResponsiveSheet)`
  @media (orientation: portrait) {
    border-radius: 0;
    height: 100%;
  }

  @media (orientation: landscape) {
    width: 26rem;
  }

  &.open, &.close {
    animation-duration: 0.4s;
  }

  & > header {
    padding: 16px 24px;
    
    h4 {
      font-size: ${({theme}) => theme.font_lg};
    }

    button {
      color: currentColor;
      background: transparent;
      margin: -16px;
    }
    
    @media (orientation: portrait) {
      color: ${({theme}) => theme.text_light};
      background-color: ${({theme}) => theme.header_bg};
    }

    @media (orientation: landscape) {
      border-bottom: ${({theme}) => `${theme.card_border} ${theme.card_border_color}`};
    }
  }

  & > div {
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 24px;
    overflow: auto;

    h3 {
      font-size: ${({theme}) => theme.font_lg};
      font-weight: 600;
      line-height: 1.3;
      margin: 0 0 4px;
      text-align: center;
    }

    .breadcrumb {
      display: flex;
      gap: 8px;
      flex-shrink: 0;
      height: fit-content;
      max-width: 100%;

      &:not(:has(*)) {
        opacity: 0;
        position: absolute;
        pointer-events: none;
        z-index: -99;
      }

      > span {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        cursor: pointer;
        flex-grow: 1;
        flex-shrink: 10;
        width: 0.5rem;
        min-width: 1.5rem;
        max-width: fit-content;

        &:last-of-type {
          width: fit-content;
          flex-shrink: 1;
        }
      }

      .slash {
        overflow: visible;
        flex-grow: 0;
        flex-shrink: 0;
        cursor: default;
        min-width: unset;
        width: fit-content;
      }
  
      > button {
        display: none;
        background-color: transparent;
        border: none;
        color: currentColor;
        padding: 0;
        align-items: center;
        cursor: pointer;
  
        &:last-of-type {
          display: inline-flex;
          order: -1;
        }
      }
    }

    ul {
      --button_text_selected: ${({theme}) => theme.text};
      --button_bg_selected: ${({theme}) => theme.primary_a40};
      --button_border_selected: ${({theme}) => `${theme.border_xs} ${theme.text}`};

      &:first-of-type {
        --button_text_selected: ${({theme}) => theme.background};
        --button_bg_selected: ${({theme}) => theme.text};
        --button_border_selected: ${({theme}) => `${theme.border_xs} ${theme.background}`};
      }

      list-style: none;
      display: flex;
      flex-direction: column;
      gap: 16px;
      padding: 0;
      margin: 0;
    }

    hr {
      width: 100%;
      border: none;
      border-top: 1px solid ${({theme}) => theme.text_a20};
    }

    h3 + hr {
      display: none;
    }

    li button {
      font-family: ${({theme}) => theme.typography};
      font-size: ${({theme}) => theme.font_md};
      font-weight: 600;
      display: flex;
      align-items: center;
      gap: 8px;
      color: ${({theme}) => theme.text};
      background-color: ${({theme}) => theme.background_rised};
      border: ${({theme}) => `${theme.border_xs} ${theme.text_a20}`};
      border-radius: ${({theme}) => theme.radius_sm};
      width: 100%;
      height: 64px;
      padding: 0 8px;
      box-sizing: border-box;
      box-shadow: ${({theme}) => theme.shadow_xs};
      cursor: pointer;
      transition: 0.15s ease-in-out;

      &:hover {
        background-color: ${({theme}) => theme.primary_a10};
      }

      &:active, &.selected {
        color: var(--button_text_selected);
        background-color: var(--button_bg_selected);
        border: var(--button_border_selected);
      }

      img {
        height: 48px;
        width: 48px;
        object-fit: cover;
        border-radius: ${({theme}) => theme.radius_sm};
      }

      span::first-letter {
        text-transform: capitalize;
      }

      span + svg {
        margin-left: auto;
      }
    }
  }
`;
