import styled from "styled-components";
import { Popup } from "../../../elements";

export const PickUpContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  color: ${({theme}) => theme.text};
  background-color: ${({theme}) => theme.ticket_header_bg};
  border-radius: ${({theme}) => theme.radius_sm};
  padding: 8px;
  //margin: 8px;
`;

export const SmsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;

  p {
    text-align: center;
    margin: 0;
  }
`;

export const QRContainer = styled.div`
  display: flex;
  gap: 16px;

  .qr {
    flex-shrink: 0;
    height: 64px;
    width: 64px;
    padding: 8px;
    background-color: ${({theme}) => theme.qr_bg_color};
    border-radius: ${({theme}) => theme.radius_sm};
  }

  span {
    background-color: ${({theme}) => theme.primary_a20};
    padding: 0 4px;
    border-radius: 4px;
  }

  & > div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;

    p {
      font-size: ${({theme}) => theme.font_sm};
      text-align: left;
      line-height: 1.3;
      margin: 0;
    }
  }
  
  dialog {
    p {
      text-align: center;
      line-height: 1.3;
      margin: 16px 24px 0;
    }

    .qr {
      width: 100%;
      height: auto;
      width: calc(100vw - 88px);
      box-sizing: border-box;
      background-color: ${({theme}) => theme.qr_bg_color};
      border-radius: ${({theme}) => theme.radius_md};
      padding: 16px;
      margin: 24px;
    }
  }
`;

export const QRPopup = styled(Popup)`
  > svg {
    max-width: 300px;
    max-height: 300px;
  }
`;