import endpoints from "../endpoints/Endpoints.api";
import Manager from "../manager/Manager.api";
import ManagerAuth from "../manager/ManagerAuth.api";

 
const redeemCodeCoupon =  (restaurantId: number, code: string, amount: number) => {
    const token = localStorage.getItem('token_yumminn')
    const type = localStorage.getItem('type')

    if (token && type) {
        return ManagerAuth.client(token, type).post(`${endpoints.discounts.redeem_code_coupon}/${restaurantId}/${code}/${amount}/`)
    }  

    return Manager.client().post(`${endpoints.discounts.redeem_code_coupon}/${restaurantId}/${code}/${amount}/`)
}
const checkCodeCouponValidity =  (restaurantId: number, amount: number) => {
    const token = localStorage.getItem('token_yumminn')
    const type = localStorage.getItem('type')

    if (token && type) {
        return ManagerAuth.client(token, type).post(`${endpoints.discounts.check_code_coupon_validity}/${restaurantId}/${amount}/`);
    }

    return Manager.client().post(`${endpoints.discounts.check_code_coupon_validity}/${restaurantId}/${amount}/`);

}


export const Request = {
    redeemCodeCoupon,
    checkCodeCouponValidity
}
