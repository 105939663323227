import { t } from 'i18next';
import { useContext } from 'react'
import { AppContext } from '../../context/AppContext';
import { PriceBadge } from '../../pages/checkout/components/MyTotal';
import { calculateEuros, calculateCents } from '../../pages/checkout/functions/calculatePrices';
import { LoyaltyBanner } from '../../pages/loyalty/loyalty-banner';
import { NO_TIPS_RESTAURANTS } from '../../shared/constants/restaurantIds';
import { DetailsContainer, TotalCredits, ExtraCredits, TotalAmountPurchase } from './PaymentDetailContainers'
import { ZeroSixVoucherSelector } from '../../pages/zerosix/zerosix-voucher-selector';
import { CreditBanner } from '../../pages/credit/credits-banner';
import { Trans } from 'react-i18next';
import { Icon, IconType } from '../icons';
import { BadgeBonus } from '../../pages/wallet/WalletContainers';
import { DashedSeparator } from '../../pages/payment-successful/PaymentSuccessfulContainers';
import { CodeCouponInput } from './CodeCouponInput';
import { useFeatureIsOn } from '@growthbook/growthbook-react';
import { GB_FEATS } from '../../growthbook';
import { useViewport } from '../../context/ViewportContext';
import { useCalculateCheckout } from '../../pages/checkout/components/useCalculateCheckout';

type Props = {
  setCreditApplied: React.Dispatch<React.SetStateAction<boolean>>
  setDiscountApplied: React.Dispatch<React.SetStateAction<boolean>>
  bizumDiscount?: boolean
};

const tipType = {
  custom: "custom",
  good: "5%",
  "very good": "10%",
  amazing: "15%",
  brilliant: "20%",
  default: "0%",
};

export const PaymentDetailComponent = ({ setCreditApplied, setDiscountApplied, bizumDiscount }: Props) => {
  const restaurantId = Number(sessionStorage.getItem('restaurantId'));
  const {
    state: {
      restaurant: {
        bizumPromotionCents,
      },
      checkout: {
        myTotal,
        generalDiscount,
        generalDiscountCents,
        productDiscountCents,
        codeCoupon,
        creditCents,
        credit,
        codeCouponCents,
        paymentMethod,
        deliveryFeeCents,
        tip: {
          selected,
          amount,
        },
      },
      wallet: {
        extraCredits,
        balanceCredit,
        bonusAmount,
        bonusType
        }
      },
  } = useContext(AppContext);
  
  const { orientation } = useViewport();
  
  const showZerosix = useFeatureIsOn(GB_FEATS.SHOW_ZEROSIX);

  const discountTypeToSymbol: any = {
    "amount": "€",
    "percentage": "%"
  };

  const {
    isRechargePayment,
    subtotal,
    serviceCommision,
    zeroSixDiscountCents,
    amountMinusDiscountsAndCredit,
    amountMinusDiscountsAndCreditAndLoyalty,
    creditUsed,
    loyaltyAfterDiscounts,
    total,
  } = useCalculateCheckout();
  
  const finalExtraCredits = extraCredits ? Number(calculateCents(extraCredits)) : 0;
  const newCreditBalance = balanceCredit + subtotal + finalExtraCredits;

  if(isRechargePayment) return(
    <DetailsContainer>
      <p>
        <span>{t('credits_to_pay')}:</span>
        <TotalAmountPurchase>{`${calculateEuros((subtotal).toString())}€`}</TotalAmountPurchase>
      </p>
      <p>
        <span>{t('credits_you_will_obtain')}:</span>
        <span style={{fontSize: "18px", fontWeight: 700}}>{`${calculateEuros(subtotal + finalExtraCredits).toString()}€`}</span>
      </p>
      {extraCredits > 0 && 
        <ExtraCredits>
          <Icon type={IconType.TabArrow} size={10}/>
          <i>
            <b style={{marginRight: "5px"}}>+{`${calculateEuros(finalExtraCredits).toString()}€`}</b>
            {t('of extra credits')}
          </i>
          <BadgeBonus style={{top: '0px'}}>
            {bonusType === 'percentage' && bonusAmount > 0 && <span>+{Number(bonusAmount)}%</span>}
          </BadgeBonus>
        </ExtraCredits>
      }
      <DashedSeparator/>
      <i style={{margin: "10px 0"}}>{t('when topping up your total wallet balance will be')}:</i>
      <TotalCredits>
        <b style={{fontSize: "20px"}}>{calculateEuros(newCreditBalance)}€</b> 
        <div style={{marginLeft: "4px"}}>{t('of')}</div>              
        <Trans i18nKey='total_credits'>créditos <b>totales</b></Trans>
      </TotalCredits>
    </DetailsContainer>
  );

  return (
    <DetailsContainer>
      <p>
        <span>{t('subtotal')}</span>
        <span>{`${calculateEuros((subtotal).toString())}€`}</span>
      </p>
      {!NO_TIPS_RESTAURANTS.includes(restaurantId) &&
        <p>
          <span>{t('tip') + ` (${t(tipType?.[(selected as keyof typeof tipType)] || tipType.default)})`}</span>
          <span>{calculateEuros(amount) + '€'}</span>
        </p>
      }
      {generalDiscountCents > 0 && generalDiscount &&
        <p>
          <span className='discount'>{generalDiscount?.payment_details_text + " (-" + (generalDiscount?.discount_type == "percentage" ? generalDiscount?.value : generalDiscount?.value) + discountTypeToSymbol[generalDiscount!.discount_type] + ")"}</span>
          <span className='discount'>{`- ${calculateEuros(generalDiscountCents)}€`}</span>
        </p>
      }
      {productDiscountCents > 0 &&
        <p>
          <span className='discount'>{t('product_discount')}</span>
          <span className='discount'>{`- ${calculateEuros(productDiscountCents)}€`}</span>
        </p>
      }
      {codeCouponCents > 0 &&
        <p>
          <span className='discount'>{codeCoupon?.payment_details_text + " (-" + codeCoupon?.value + discountTypeToSymbol[codeCoupon?.discount_type] + ")"}</span>
          <span className='discount'>{`- ${calculateEuros(codeCouponCents)}€`}</span>
        </p>
      }
      {creditCents > 0 && credit &&
        <p>
          <span className='discount'>{t('credits')}</span>
          <span className='discount'>{`- ${calculateEuros(creditUsed)}€`}</span>
        </p>
      }
      {serviceCommision > 0 &&
        <p>
          <span className='discount'>{t('service fee')}</span>
          <span className='discount'>{`${calculateEuros(serviceCommision)}€`}</span>
        </p>
      }
      {deliveryFeeCents > 0 &&
        <p>
          <span className='discount'>{t('delivery_fee')}</span>
          <span className='discount'>{`${calculateEuros(deliveryFeeCents)}€`}</span>
        </p>
      }
      {loyaltyAfterDiscounts > 0 &&
        <p>
          <span className='discount'>{t('loyalty discount')}</span>
          <span className='discount'>{`${"-" + calculateEuros(loyaltyAfterDiscounts)}€`}</span>
        </p>
      }
      {bizumDiscount && paymentMethod === 'bizumpay' && (myTotal - amount) >= 1500  &&
        <p>
          <span className='discount'>{t('bizum discount applied')}</span>
          <span className='discount'>{`-${calculateEuros((bizumPromotionCents).toString())}€`}</span>
        </p>
      }
      {showZerosix && zeroSixDiscountCents > 0  &&
        <p style={{color: '#ED2C03'}}>
          <span>{t('zerosix discount applied')}: </span>
          <span style={{color: '#ED2C03'}}>{`-${calculateEuros((zeroSixDiscountCents).toString())}€`}</span>
        </p>
      }
      {orientation === 'portrait' &&
        <>
          <LoyaltyBanner
            setDiscountApplied={setDiscountApplied}
            amountMinusDiscountsAndCredit={amountMinusDiscountsAndCredit}
          />
          <CreditBanner
            setCreditApplied={setCreditApplied}
            creditUsed={creditUsed}
            amountMinusDiscountsAndCredit={amountMinusDiscountsAndCreditAndLoyalty}
          />
          <CodeCouponInput/>
          <ZeroSixVoucherSelector/>
        </>
      }
      <b>
        <span>{t('total')}</span>
        <PriceBadge type='total'>
          {`${calculateEuros((total).toString())}€`}
        </PriceBadge>
      </b>
    </DetailsContainer>
  );
};