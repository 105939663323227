import { t } from 'i18next';
import React, { useEffect, useRef } from 'react';
import { Icon, IconType } from '../icons';
import { DateInputContainer } from './InputContainers';
import ReactDatePicker, { registerLocale, setDefaultLocale } from 'react-datepicker';
import en from "date-fns/locale/en-GB";
import es from "date-fns/locale/es";
import de from "date-fns/locale/de";
import fr from "date-fns/locale/fr";
import i18n from '../../../i18n';

type DateInputProps = {
  id?: string
  label?: string | React.ReactNode | Element
  title?: string | React.ReactNode | Element
  value: Date | null
  placeholder?: string
  required?: boolean
  error?: string | false
  leftIcon?: React.ReactNode
  rightIcon?: React.ReactNode
  onChange: (date: Date | null, event: React.SyntheticEvent<any> | undefined) => void
};

export const DateInput = ({
  id,
  label,
  title,
  value,
  placeholder = ' ',
  required = false,
  error,
  leftIcon,
  rightIcon,
  onChange,
}: DateInputProps) => {

  const titleRef = useRef<HTMLHeadingElement>(null);

  const setLanguage = () => {
    const language = i18n.language;
    registerLocale("es", es);
    registerLocale("en", en);
    registerLocale("de", de);
    registerLocale("fr", fr);
    setDefaultLocale(language);
  
    return language;
  };

  const language = setLanguage();

  useEffect(() => {
    const maxWidth = titleRef.current?.clientWidth ? `${titleRef.current.clientWidth}px` : 'auto';
    titleRef.current?.style.setProperty('--max_width', maxWidth);
  }, []);

  return (
    <DateInputContainer>
      {title &&
        <h5 className={required ? 'required' : ''}>
          {typeof title === 'string' ?  t(title) : title}
        </h5>
      }
      <label>
        {label &&
          <>
            <h6 ref={titleRef} className={(required && !title) ? 'required' : ''}>
              <span>
                {typeof label === 'string' ?  t(label) : label}
              </span>
            </h6>
            <hr/>
          </>
        }
        <div className='wrapper'>
          {leftIcon}
          <ReactDatePicker
            id={id}
            locale={language}
            selected={value}
            placeholderText={t(placeholder)}
            dateFormat="d MMMM, yyyy"
            showMonthDropdown
            showYearDropdown
            dropdownMode="select"
            disabledKeyboardNavigation
            onChange={onChange}
            onFocus={(e) => e.target.readOnly = true}
            popperPlacement="top"
          />
          {error &&
            <div className='error'>
              <Icon type={IconType.Error}/>
            </div>
          }
          {rightIcon}
        </div>
      </label>
      {error &&
        <i>{t(error)}</i>
      }
    </DateInputContainer>
  );
};