import { OPCartProduct } from "../../context/app";
import { postError } from "../../shared/utils/postError";
import endpoints from "../endpoints/Endpoints.api";
import Manager from "../manager/Manager.api";
import ManagerAuth from "../manager/ManagerAuth.api";

 
const catalog = (restaurant:any, tableId: any) => {
    const token: any = localStorage.getItem("token_yumminn");
    const type: any = localStorage.getItem("type");
    let endpoint = endpoints.product.catalog;
    try {
        let language: any = sessionStorage.getItem("language");

        if (!language) {
            const defaultLanguage = navigator?.language || navigator?.userLanguage;
            if(defaultLanguage){
                language = defaultLanguage
            }
            else{
                language = 'es';
            }
        }
        const availableLanguages: any = {
            'es': 'spanish',
            'en': 'english',
            'fr': 'french',
            'ca': 'catalan'
        }
        language = language in availableLanguages ? availableLanguages[language] : 'spanish';
        endpoint += `&language=${language}`;
    } catch (e: any) {
        postError(e)
        console.log(`Product.api.ts catalog exception: ${e.toString()}`);
    }
    return ManagerAuth.clientProduct(restaurant, tableId, token, type).get(endpoint);
};

const getOrderStatus = (restaurantId: number, tableId: string, orderCode: string) => {
    const endpoint = endpoints.product.order_status;
    return Manager.clientProduct(restaurantId, tableId).get(`${endpoint}?restaurant=${restaurantId}&code=${orderCode}`);
};


const getProductModifiers = (restaurantId: number, tableId: string, productId: number) => {
    const token: any = localStorage.getItem("token_yumminn");
    const type: any = localStorage.getItem("type");
    let endpoint = endpoints.product.product_modifiers;
    endpoint += `&productId=${productId}`;
    try {
        let language: any = sessionStorage.getItem("language");

        if (!language) {
            const defaultLanguage = navigator?.language || navigator?.userLanguage;
            if(defaultLanguage){
                language = defaultLanguage
            }
            else{
                language = 'es';
            }
        }
        const availableLanguages: any = {
            'es': 'spanish',
            'en': 'english',
            'fr': 'french',
            'ca': 'catalan'
        }
        language = language in availableLanguages ? availableLanguages[language] : 'spanish';
        endpoint += `&language=${language}`;
    } catch (e: any) {
        postError(e)
        console.log(`Product.api.ts product modifiers exception: ${e.toString()}`);
    }
    return ManagerAuth.clientProduct(restaurantId, tableId, token, type).get(endpoint);
};

const getModifierModifiers = (restaurantId: number, tableId: string, modifierId: number) => {
    const token: any = localStorage.getItem("token_yumminn");
    const type: any = localStorage.getItem("type");
    let endpoint = endpoints.product.modifier_modifiers;
    endpoint += `&modifierId=${modifierId}`;
    try {
        let language: any = sessionStorage.getItem("language");

        if (!language) {
            const defaultLanguage = navigator?.language || navigator?.userLanguage;
            if(defaultLanguage){
                language = defaultLanguage
            }
            else{
                language = 'es';
            }
        }
        const availableLanguages: any = {
            'es': 'spanish',
            'en': 'english',
            'fr': 'french',
            'ca': 'catalan'
        }
        language = language in availableLanguages ? availableLanguages[language] : 'spanish';
        endpoint += `&language=${language}`;
    } catch (e: any) {
        postError(e)
        console.log(`Product.api.ts modifier modifiers exception: ${e.toString()}`);
    }
    return ManagerAuth.clientProduct(restaurantId, tableId, token, type).get(endpoint);
};

const getRestaurantSuggestions = (restaurantId: number, tableId: string, selectedProductsIds: number[]) => {
    const token: any = localStorage.getItem("token_yumminn");
    const type: any = localStorage.getItem("type");
    let endpoint = endpoints.product.restaurant_suggestions;
    endpoint += `?productIds=${selectedProductsIds}`;
    try {
        let language: any = sessionStorage.getItem("language");

        if (!language) {
            const defaultLanguage = navigator?.language || navigator?.userLanguage;
            if(defaultLanguage){
                language = defaultLanguage
            }
            else{
                language = 'es';
            }
        }
        const availableLanguages: any = {
            'es': 'spanish',
            'en': 'english',
            'fr': 'french',
            'ca': 'catalan'
        }
        language = language in availableLanguages ? availableLanguages[language] : 'spanish';
        endpoint += `&language=${language}`;
    } catch (e: any) {
        postError(e)
        console.log(`Product.api.ts suggestions exception: ${e.toString()}`);
    }
    return ManagerAuth.clientProduct(restaurantId, tableId, token, type).get(endpoint);
};

const verifyOrderItems = async (orderItems: OPCartProduct[]) => {
    const endpoint = endpoints.product.verify_order_items;

    const body = { orderItems: orderItems };
    
    try {
        const response = await Manager.client().post(endpoint, body);
        return response; 
    } catch (error) {
        postError(error)
        console.error("Error verifying order items:", error);
        return null;
    }
};


export const RequestProduct = {
    catalog,
    getOrderStatus,
    getProductModifiers,
    getRestaurantSuggestions,
    getModifierModifiers,
    verifyOrderItems
}
