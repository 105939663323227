import React, { createContext, useContext, useEffect, useState } from "react";

type Viewport = {
  width: number
  height: number
  orientation: 'landscape' | 'portrait';
  isMobile: boolean;
  isTablet: boolean;
  isDesktop: boolean;
};

const BREAKPOINTS = {
  mobile: 768, // Up to 768px
  tablet: 1023, // 769px to 1023px
  desktop: 1024, // Above and equal to 1024px
};

const getViewport = (): Viewport => {
  const width = window.innerWidth;
  const height = window.innerHeight;

  return {
    width,
    height,
    orientation: width > height ? 'landscape' : 'portrait',
    isMobile: width < BREAKPOINTS.mobile,
    isTablet: width >= BREAKPOINTS.mobile && width < BREAKPOINTS.desktop,
    isDesktop: width >= BREAKPOINTS.desktop,
  };
};

const viewportContext = createContext<Viewport>(getViewport());

export const ViewportProvider = ({ children }: { children: React.ReactNode }) => {
  const [viewport, setViewport] = useState(getViewport());

  const handleWindowResize = () => {
    setViewport(getViewport());
  };

  useEffect(() => {
    window.addEventListener("resize", handleWindowResize);
    return () => window.removeEventListener("resize", handleWindowResize);
  }, []);

  return (
    <viewportContext.Provider value={viewport}>
      {children}
    </viewportContext.Provider>
  );
};

export const useViewport = () => useContext(viewportContext);