import styled from "styled-components";

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: clamp(8px, 2.5vh, 32px);
  flex: 1;
`;

export const FloatingButtonContainer = styled.div`
  position: sticky;
  bottom: 0;
  left: 0;
  width: 100%;
  box-sizing: border-box;
  z-index: 99;
  
  & > * {
    box-shadow: ${({theme}) => theme.shadow_sm};
  }

  @media (orientation: landscape){
    position: relative;
  }
`;

export const TitleContainer = styled.h4`
  display: flex;

  input {
    width: 0;
    position: absolute;
  }

  svg {
    color: ${({theme}) => theme.background};
  }
`;