import { useGrowthBook } from "@growthbook/growthbook-react";
import { useContext, useEffect } from "react";
import { useLocation } from "react-router";
import { Navigate } from "react-router-dom";
import { AppContext } from "../../context/AppContext";
import { removeOrderInfo } from "../../shared/utils/orderInfoHandler";
import { eventTrack } from "../../../useGaTracker";
import { Action } from "../../context/appReducer";

export const QrReaderComponent = () => {
    const { dispatch } = useContext(AppContext);
    
    const growthbook = useGrowthBook();
    const location = useLocation();

    const urlParams = new URLSearchParams(location.search);
    const table = urlParams.get('table') || '';
    const id = urlParams.get('id') || '';
    const catalog = !!urlParams.get('catalog');
    const userIdBumerang = urlParams.get('userId');

    useEffect(() => {
        eventTrack("00_Escaneo", "00_Escaneo", "00_Escaneo");

        if (id) {
            sessionStorage.removeItem("order_id");
            removeOrderInfo(id, table);
            sessionStorage.setItem('restaurantId', id.toString());

            dispatch({
                type: 'UPDATE_RESTAURANT_ID',
                payload: Number(id)
            });
        }
        growthbook?.refreshFeatures();

    }, [id]);

    const updateTableAction : Action = {
        type: 'UPDATE_TABLE_NUMBER',
        payload: table
    }
    useEffect(() => {
        if (table !== null || table !== undefined) {
            sessionStorage.removeItem("order_id");
            removeOrderInfo(id, table)
            sessionStorage.setItem('tableNumber', table.toString())
            dispatch(updateTableAction);
        }
        growthbook?.refreshFeatures();

    }, [table])

    const state = location.state as { from: string, link: string };
    const from = state ? state.from : undefined;
    const link = state ? state.link : '';
    const search = userIdBumerang ? `?userId=${userIdBumerang}` : undefined;

    return <Navigate
        to={`/checkout${catalog ? '#catalog' : ''}`}
        state={{ from, catalog, link, search }}
    />
};
