import * as React from 'react';
import { Loading } from '../loading';
import { calculateEuros } from '../../pages/checkout/functions/calculatePrices';
import ApplePayButton from './apple-pay/ApplePayButton';
import { performApplePayPayment } from './apple-pay/PaymentRequestHandler';

type applePayComponentProps = {
  totalAmount: number,
  redsysIntiatePayment:      (paymentToken: string, sessionId?: string, saveCardChecked?: boolean, selectedSavedCard?: any, redsysOrder?: string, googlePayToken?: string, applePayData?: string) => void
};

export default function ApplePayComponent({totalAmount, redsysIntiatePayment} : applePayComponentProps) {
  const [isLoading, setIsLoading] = React.useState(true);
  const restaurantName = sessionStorage.getItem('restaurantName');

  React.useEffect(() => {
    if (window && document) {
      const script = document.createElement('script')
      const body = document.getElementsByTagName('body')[0]
      script.src = 'https://applepay.cdn-apple.com/jsapi/v1/apple-pay-sdk.js'
      body.appendChild(script)
      script.addEventListener('load', () => {
        setIsLoading(false);
      })
    }
  }, [])

  const handleClick = () => {
    const finalTotalEur = calculateEuros(totalAmount)
    performApplePayPayment(
      "EUR",
      [],
      restaurantName ? restaurantName : "Yumminn",
      finalTotalEur.toString().replace(',', '.'),
      redsysIntiatePayment
    )
  }

  return (
  <div style={{fontSize: '12px', display: 'flex', justifyContent: 'center'}}>
    {isLoading ? 
    <Loading parentComponent='RedsysCardComponent' loaderStyle={{height: '36px', width: '36px'}} style={{height: '44px', margin: '10px'}}/> : 
    <ApplePayButton onClick={handleClick}/>
    }
  </div>
  )
}