import PropTypes, {InferProps} from 'prop-types';
import React from "react";
import {SavedCard} from "./index";
import styled from "styled-components";
import {Button} from "../buttons";
import {ButtonLineHeight, ButtonSize, ButtonVariant} from "../buttons/enums";
import {useTranslation} from 'react-i18next';


async function adaptCVC() {
    const callback = (element: any) => {
        if (element) {
            element.style.marginTop = '26px';
            element.style.width = '100%';
        }
    };
    getComponentByClass('adyen-checkout__field__cvc', callback);
}

async function hideAdyenCardForm() {
    const callback = (element: any) => {
        if (element) {
            element.style.display = 'none';
        }
    };
    getComponentByClass('adyen-checkout__field', callback);
}

function getComponentByClass(klass: string, callback: any) {
    setTimeout(() => {
        try {
            const cardElems:any = document.getElementsByClassName(klass);
            if (!cardElems && cardElems.length <= 0) {
                getComponentByClass(klass, callback);
                return;
            }
            const card = cardElems[0];
            callback(card);
        } catch (e: any) {
            console.log("card style catch: " + e.toString());
        }
    }, 10);
}

export function SavedCardsComponent({
                                        initiallySavedCards,
                                        savedCardsAdaptAdyenElementsTrigger,
                                        selectedSavedCard,
                                        updateSelectedSavedCard,
                                        useNewCardClicked
}: SavedCardsComponentProps) {

    const {t} = useTranslation();

    const [savedCards, setSavedCards] = React.useState<any>([]);

    React.useEffect(() => {
        setSavedCards(initiallySavedCards);
    }, [initiallySavedCards]);

    React.useEffect(() => {
        if (savedCardsAdaptAdyenElementsTrigger > -1) {
            hideAdyenCardForm();
            adaptCVC();
        }
    }, [savedCardsAdaptAdyenElementsTrigger]);

    React.useEffect(() => {
        if (savedCards && savedCards.length > 0) {
            const newSavedCards = savedCards.map((newCard: any) => {
                newCard['checked'] = false;
                return newCard;
            });
            newSavedCards[selectedSavedCard]['checked'] = true;
            setSavedCards(newSavedCards);
        }
    }, [selectedSavedCard]);

    return (
        <Wrapper>
            <p>{t('Saved cards') + ':'}</p>
            {savedCards && savedCards.map((card: any, index: any) => {
                return <SavedCard key={card.id}
                                  brand={card.brand}
                                  isChecked={card.checked}
                                  lastFour={card.lastFour}
                                  expiryMonth={card.expiryMonth}
                                  expiryYear={card.expiryYear}
                                  checked={() => updateSelectedSavedCard(index)}
                />
            })}
            <ButtonWrapper>
                <Button title={t('Use other card')}
                        variant={ButtonVariant.Secondary}
                        size={ButtonSize.ExtraSmall}
                        lineHeight={ButtonLineHeight.ExtraSmall}
                        style='4px 6px'
                        handleClick={useNewCardClicked}
                />
            </ButtonWrapper>
        </Wrapper>
    );
}

SavedCardsComponent.propTypes = {
    initiallySavedCards: PropTypes.array.isRequired,
    savedCardsAdaptAdyenElementsTrigger: PropTypes.number.isRequired,
    selectedSavedCard: PropTypes.number.isRequired,
    updateSelectedSavedCard: PropTypes.func.isRequired,
    useNewCardClicked: PropTypes.func.isRequired
}

type SavedCardsComponentProps = InferProps<typeof SavedCardsComponent.propTypes>;

const Wrapper = styled.div`
  padding: 12px;
  //background-color: #5FB894;
  border: 1px #ddd solid;
  border-radius: 10px;
  box-shadow: 5px 5px 6px 6px rgb(149 157 165 / 18%);
  p {
    font-size: 13px;
    font-weight: 500;
    margin: 0 0 12px 0;
  }
`

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 11px;
`
