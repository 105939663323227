import styled from "styled-components";

const Container = styled.div`
  display: grid;
  justify-content: center;
  align-content: center;
  height: min-content;
`;


export {
  Container
}