// ///////////////////////
// SERVICES
// ///////////////////////

const REACT_APP_BASE_URL = process.env.REACT_APP_BASE_URL;

function addAuthorizationHeaders(headers: any) {
  const yumminnToken = process.env.REACT_APP_YUMMINN_TOKEN;
  if (yumminnToken) {
    headers["yumminn-token"] = yumminnToken;
  }
  const token: any = localStorage.getItem("token_yumminn");
  const type: any = localStorage.getItem("type");
  if (token && type && token !== "undefined" && type !== "undefined") {
    headers["Authorization"] = token;
    headers["Authorization-Type"] = type;
  }
  if(yumminnToken){
    headers['yumminn-token'] = yumminnToken
  }
  return headers;
}

// ///////////////////////
// PAYMENT METHODS AVAILABLE
export const getPaymentMethods = async (restaurantId: number) => {
  const headers = addAuthorizationHeaders({"Content-Type": "application/json"});
  const body: any = {};
  if (restaurantId > -1) {
    body["restaurant_id"] = restaurantId;
  }
  const response = await fetch(`${REACT_APP_BASE_URL}/adyen/payment-methods`, {
    method: "POST",
    body: JSON.stringify(body),
    headers
  });
  if (response.ok) {
    const result = await response.json();
    return result;
  }
  throw Error(response.statusText);
};

// ///////////////////////
// PAY
export const initiatePayment = async (data: any) => {
  const headers = addAuthorizationHeaders({"Content-Type": "application/json"});
  let response=null
  if(window.location.href.includes('event')){
    const eventId = sessionStorage.getItem('eventId')
    response = await fetch(`${REACT_APP_BASE_URL}/restaurant/events/${eventId}/initiate_payment/`, {
      method: "POST",
      body: JSON.stringify(data),
      headers
    });

  }else{
    response = await fetch(`${REACT_APP_BASE_URL}/payment/initiate-payment`, {
      method: "POST",
      body: JSON.stringify(data),
      headers
    });

  }
  if (response.ok) {
    const result = await response.json();
    return result;
  }
  throw Error(response.statusText);
};

// ///////////////////////
// PAY ADDITIONAL INFO
export const submitAdditionalDetails = async (data: any) => {
  const headers = addAuthorizationHeaders({"Content-Type": "application/json"});
  const response = await fetch(`${REACT_APP_BASE_URL}/adyen/additional-details`, {
    method: "POST",
    body: JSON.stringify(data),
    headers
  });
  if (response.ok) {
    const result = await response.json();
    return result;
  }
  throw Error(response.statusText);
};

// ///////////////////////
// ADD CARD INFO
export const getAddCardInfo = async () => {
  const headers = addAuthorizationHeaders({"Content-Type": "application/json"});
  const response = await fetch(`${REACT_APP_BASE_URL}/adyen/add-card-info`, {
    method: "GET",
    headers
  });
  if (response.ok) {
    const result = await response.json();
    return result;
  }
  throw Error(response.statusText);
};

// ///////////////////////
// ADD CARD
export const addCard = async (data: any) => {
  const headers = addAuthorizationHeaders({
    "Content-Type": "application/json"});
  const response = await fetch(`${REACT_APP_BASE_URL}/adyen/add-card`, {
    method: "POST",
    body: JSON.stringify(data),
    headers
  });
  if (response.ok) {
    const result = await response.json();
    return result;
  }
  throw Error(response.statusText);
};

export const addMoneiCard = async (data: any) => {
  const headers = addAuthorizationHeaders({
    "Content-Type": "application/json"
  });
  const response = await fetch(`${REACT_APP_BASE_URL}/payment/add-card`, {
    method: "POST",
    body: JSON.stringify(data),
    headers
  });
  if (response.ok) {
    const result = await response.json();
    return result;
  }
  throw Error(response.statusText);
};

// ///////////////////////
// ADD CARD ADDITIONAL DETAILS
export const addCardAdditionalDetails = async (data: any) => {
  const headers = addAuthorizationHeaders({"Content-Type": "application/json"});
  const response = await fetch(`${REACT_APP_BASE_URL}/adyen/add-card-additional-details`, {
    method: "POST",
    body: JSON.stringify(data),
    headers
  });
  if (response.ok) {
    const result = await response.json();
    return result;
  }
  throw Error(response.statusText);
};

// ///////////////////////
// DELETE CARD
export const deleteCard = async (paymentMethodId: any) => {
  const headers = addAuthorizationHeaders({"Content-Type": "application/json"});
  const data = {'id': paymentMethodId};
  const response = await fetch(`${REACT_APP_BASE_URL}/adyen/delete-card`, {
    method: "POST",
    body: JSON.stringify(data),
    headers
  });
  if (response.ok) {
    return response;
  }
  throw Error(response.statusText);
};

export const deleteMoneiCard = async (paymentMethodId: any) => {
  const headers = addAuthorizationHeaders({"Content-Type": "application/json"});
  const data = {'id': paymentMethodId};
  const response = await fetch(`${REACT_APP_BASE_URL}/payment/delete-card`, {
    method: "POST",
    body: JSON.stringify(data),
    headers
  });
  if (response.ok) {
    return response;
  }
  throw Error(response.statusText);
};
