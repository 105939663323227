import React, { useState } from "react";
import { Icon, IconType } from "../../elements/icons";
import { useRegister } from "../../shared/utils/useRegister";
import { usePopupNavigation } from "../../shared/utils/usePopupNavigation";
import { PopupType } from "../../context/popups.enum";
import { BigLoginButton, LoginButtonsContainer, SmallLoginButton } from "./RegisterContainers";
import { t } from "i18next";
import { GoogleButton } from "./GoogleButton";
import { AppleButton } from "./AppleButton";
import { toast } from "react-toastify";
import { useFeatureValue } from "@growthbook/growthbook-react";
import { GB_FEATS } from "../../growthbook";
import { DEFAULT_REGISTER_BUTTON_TITLES } from "../../growthbook/defaults";

type Login = 'email' | 'google' | 'facebook' | 'apple';

export type LoginButton = {
  type: Login
  renderProps?: any
};

type Props = {
  mailForm?: React.ReactNode
  from: string
  lopd?: boolean,
  news?: boolean,
  disabled?: boolean
  disabledMessage?: string | React.ReactNode
  bigButtons?: boolean
  showFormByDefault?: boolean
};

export const LoginButtons = ({ mailForm, disabled, disabledMessage, from, lopd = false, news = false, bigButtons = false, showFormByDefault = false}: Props) => {
  const {goToPopup} = usePopupNavigation();
  const loginButtons = useFeatureValue(GB_FEATS.REGISTER_BUTTONS, DEFAULT_REGISTER_BUTTON_TITLES);
  
  const [selected, setSelected] = useState<Login | null>(showFormByDefault ? 'email' : null);
  const [isSending, setIsSending] = useState(false);
  const [heigth, setHeight] = useState(showFormByDefault ? {buttons: 0, form: 360} : {buttons: 180, form: 0});

  const {onLoginSuccess, onFailure} = useRegister({setIsSending});
  
  const onLoginClick = (renderProps: any) => {
    if (isSending) return;

    if (disabled) {
      disabledMessage && toasWarning(disabledMessage);
      return;
    }

    setIsSending(true);
    renderProps.onClick();
  }

  const onMailClick = () => {
    if (isSending) return;

    if (disabled && selected !== 'email') {
      disabledMessage && toasWarning(disabledMessage);
      return;
    }
    
    setSelected(prev => prev === 'email' ? null : 'email');
    mailForm && setHeight(selected === 'email' ? {buttons: 180, form: 0} : {buttons: 0, form: 360})

    !mailForm && goToPopup(PopupType.Register, {openBy: from, lopd, news});
  };

  const handleClick = (type: Login, renderProps?: any) => type === 'email' ? onMailClick() : onLoginClick(renderProps);

  const icons = {
    email: <Icon type={IconType.MailThemed} size={24} colorIcon='primary'/>,
    google: <Icon type={IconType.Google} size={24}/>,
    facebook: <Icon type={IconType.Facebook} colorIcon={'#405A93'} size={24}/>,
    apple: <Icon type={IconType.Apple} size={24}/>,
  };

  const SmallButton = ({type, renderProps}: LoginButton) => {
    const $disabled = !(type === 'email' && selected === type) && (disabled || isSending);
    return (
      <SmallLoginButton selected={selected ===  type} onClick={() => handleClick(type, renderProps)} $disabled={$disabled}>
        {icons[type]}
      </SmallLoginButton>
    );
  };

  const BigButton = ({type, renderProps}: LoginButton) => {
    const $disabled = !(type === 'email' && selected === type) && (disabled || isSending);
    return (
      <BigLoginButton selected={selected ===  type} onClick={() => handleClick(type, renderProps)} $disabled={$disabled}>
        {icons[type]}
        {t(`continue_with_${type}`)}
        <Icon type={IconType.Arrowforward} size={14}/>
      </BigLoginButton>
    );
  };

  const RenderButton = bigButtons ? BigButton : SmallButton;

  return(
    <LoginButtonsContainer bigButtons={bigButtons} showForm={!!mailForm && selected === 'email'}>
      <div style={{maxHeight: heigth.buttons}}>
        {(loginButtons?.google) &&
          <GoogleButton
            Render={RenderButton}
            from={from}
            lopd={lopd}
            news={news}
            onSuccess={onLoginSuccess}
            onFailure={onFailure}
          />
        }
        {(loginButtons?.apple) && 
          <AppleButton
            Render={RenderButton}
            from={from}
            lopd={lopd}
            news={news}
            disabled={disabled || isSending}
            onSuccess={onLoginSuccess}
            onFailure={onFailure}
          />
        }
      </div>
      {loginButtons?.email &&
        <>
          <RenderButton type='email'/>
          <div style={{ maxHeight: heigth.form }}>
            {mailForm}
          </div>
        </>
      }
    </LoginButtonsContainer>
  );
};

const toasWarning = (message: string | React.ReactNode) => {
  toast.warning(message, {
    theme: 'colored',
    icon: () => <Icon type={IconType.Info} colorIcon="var(--warning)" size={22}/>
  });
};