import React, { useCallback, useEffect, useState } from 'react';
import { Receipt } from '../../../context/app';
import { Icon, IconType } from '../../icons';
import { eventTrack } from '../../../../useGaTracker';
import { ReceiptTicket } from './ReceiptTicket';
import { LeftSheetReceipt, ReceiptButton } from './ReceiptsContainers';
import { toast } from 'react-toastify';
import { LastReceiptToast, closeReceiptToast, toastOptions } from './LastReceiptToast';
import { ReceiptsList } from './ReceiptList';
import { postError } from '../../../shared/utils/postError';
import { useReceipts } from '../../../shared/utils/useReceitps';
import { useViewport } from '../../../context/ViewportContext';

type Props = {
  hidden?: boolean,
};

export const ReceiptsWidget = ({ hidden = false}: Props) => {
  const { orientation, isMobile } = useViewport();
  const functionality = sessionStorage.getItem('functionality') || '';
  const restaurantId = sessionStorage.getItem('restaurantId') || '';

  const receipts = useReceipts({restaurantId});

  const isTable = ['just_pay', 'order_at_table'].includes(functionality);
  const widgetIcon = isTable ? IconType.Receipt : IconType.OrderBag;

  const [showReceiptList, setShowReceiptList] = useState(false)
  const [selectedReceipt, setSelectedReceipt] = useState<Receipt | null>(null)
  const [notifyUuid, setNotifyUuid] = useState<string | null>(null);

  const handleButtonClick = () => {
    eventTrack('00_icon_03_receipt', '00_icon_03_receipt', '00_icon_03_receipt')
    toggleReceipts();
  };
  
  const toggleReceipts = () => setShowReceiptList(prev => !prev);

  const closeReceiptList = () => setShowReceiptList(false);
  
  const openTicket = useCallback((receipt: Receipt) => {
    closeReceiptList();
    setSelectedReceipt(receipt);
    
    if (receipt.uuid === notifyUuid) {
      setNotifyUuid(null);
      try {
        localStorage.setItem('lastReceiptNotificated', receipt.uuid);
      } catch (err: any) {
        postError(err)
      }
    }
  }, [notifyUuid]);

  const closeTicket = () => setSelectedReceipt(null);

  const goBackTicket = () => {
    setSelectedReceipt(null);
    setShowReceiptList(true);
  };

  useEffect(() => {
    if (!receipts?.length) return;
    
    const MAX_MINUTES = 20;
    const MAX_MS = MAX_MINUTES * 60000;
    
    receipts.sort((a, b) => Date.parse(b.date) - Date.parse(a.date));

    const lastReceipt = receipts[0];
    const lastReceiptNotificated = localStorage.getItem('lastReceiptNotificated');
    const newNotification = Date.now() - Date.parse(lastReceipt.date) < MAX_MS ? lastReceipt.uuid : null;

    if (!newNotification || newNotification === lastReceiptNotificated) return;
    
    setNotifyUuid(newNotification);
  }, [receipts]);

  useEffect(() => {
    if (functionality === 'just_pay' || !notifyUuid || !receipts) return;
    
    if(!isMobile){
      setTimeout(() => toggleReceipts(), 1500);
      return;
    }

    toast(
      <LastReceiptToast openLastTicket={() => openTicket(receipts[0])}/>,
      toastOptions,
    );

    return closeReceiptToast;
  }, [notifyUuid]);
  
  return (
    <>
      {!hidden &&
        <>
          <ReceiptButton notify={!!notifyUuid} onClick={handleButtonClick}>
            <Icon type={widgetIcon} size={24}/>
          </ReceiptButton>
          <ReceiptsList
            receipts={receipts}
            notifyUuid={notifyUuid}
            isShown={showReceiptList}
            openTicket={openTicket}
            close={closeReceiptList}
          />
        </>
      }
      {orientation === 'portrait' && selectedReceipt &&
        <ReceiptTicket
          receipt={selectedReceipt}
          closeTicket={closeTicket}
          tickets={null}
        />
      }
      {orientation === 'landscape' &&
        <LeftSheetReceipt isOpened={!!selectedReceipt} onClose={closeTicket}>
          {selectedReceipt &&
            <ReceiptTicket
              receipt={selectedReceipt}
              closeTicket={goBackTicket}
              tickets={null}
              fullPage
            />
          }
        </LeftSheetReceipt>
      }
    </>
  );
};