import React, { useContext } from 'react';
import { toast } from 'react-toastify';
import { Request } from '../../../api/receipt/Receipt.api';
import { AppContext } from '../../../context/AppContext';
import { UserContext } from '../../../context/auth/UserContext';
import { postError } from '../../../shared/utils/postError';

export const useSendBill = (
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>> = () => null,
  onSuccess: (data?: any) => void = () => null,
  billRestaurantIsMockup?: boolean
) => {
  const {
    state: {
      tpvId,
      checkout: {
        customerName,
        customerVAT,
      },
      restaurant: {
        isMockup,
      },
    }
  } = useContext(AppContext);
  
  const { user: { email } } = useContext(UserContext);

  const onFailure = (error: any) => {
      postError(error)
      console.log(`error`, error);
      toast.error(`${error}`);
  };

  const sendBill = (restaurantId: number, paymentId: number | string) => {
    if (!email) return;
    setIsLoading(true);

    const customerNameStorage = sessionStorage.getItem('customerName');
    const customerVATStorage = sessionStorage.getItem('customerVAT');
    const tpv = tpvId ? tpvId : JSON.parse(sessionStorage.getItem('tpvId') || '{}');
    
    const obj: any = {
      email: email,
      restaurant_id: restaurantId,
    };

    if (tpv === 13) {
      obj.customer_name = customerName ? customerName : customerNameStorage;
      obj.customer_VAT = customerVAT ? customerVAT : customerVATStorage;
    }

    if (billRestaurantIsMockup || (billRestaurantIsMockup === undefined && isMockup)) {
      obj.isMockup = true;
    }

    Request.receipt(obj, paymentId ? paymentId : null)
      .then((data: any) => onSuccess(data))
      .catch((error: any) => onFailure(error))
      .finally(() => setIsLoading(false));
  };
  
  return sendBill;
};