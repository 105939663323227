import styled from "styled-components";

const RewardContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: center;

  h3 {
    font-size: ${({theme}) => theme.font_lg};
    font-weight: 400;
    line-height: 1.4em;
    text-align: center;
    margin: 0;
    
    b {
      font-weight: 600;
    }
  }

  & > div {
    display: flex;
    align-items: center;
    gap: 16px;

    svg {
      flex-shrink: 0;
    }

    strong {
      text-align: center;
    }
  }
`;

export {
  RewardContainer,
}