import * as React from 'react';
import { useContext } from 'react';
import { AppContext } from '../../context/AppContext';
import { t } from 'i18next';
import PropTypes, {InferProps} from 'prop-types';
import { Loading } from '../loading';
import "../monei/monei.css"
import RedsysCardComponent from '../redsys/RedsysCardComponent';
import { CardContainer } from './redsysContainters';
import GooglePayComponent from './GooglePayComponent';
import ApplePayComponent from './ApplePayComponent';
import { UserContext, UserContextDispatchContext } from '../../context/auth/UserContext';
import { Button } from '../buttons';
import { TextInput } from '../inputs';
import { getEmailFunctions } from '../../shared/utils/clientInputsLogic';
import { EMAIL_REGEX } from '../../shared/constants/regex';

export function RedsysPayComponents({
  type,
  amount,
  tip,
  loyaltyDiscount,
  bizumPromotionCents,
  tokenHandler
}: RedsysPayComponentsProps) {

  const {
    state: {
      checkout: {
        myTotal,
        clientCommission,
        paymentMethod,
        zeroSixSelectedVoucher,
        generalDiscountCents,
        productDiscountCents,
        creditCents, 
        codeCouponCents,
        redsysMerchantCode,
        redsysEnv,
        redsysMerchantTerminal,
        deliveryFeeCents,
      }
    },
  } = useContext(AppContext);
  const { user: { email } } = useContext(UserContext);
  const [emailError, setEmailError] = React.useState('');
  const [cashPaymentDisabled, setCashPaymentDisabled] = React.useState(!!emailError || (!email || !EMAIL_REGEX.test(email)));
  const { updateUser } = useContext(UserContextDispatchContext);
  const isEmailRequired = true;
  const {checkEmail, handleEmailChange} = getEmailFunctions({email, emailError, isEmailRequired, setEmailError, updateUser});
  const serviceCommision =  Math.floor(((myTotal-tip) * clientCommission)/ 100);
  const [isLoading, setLoading] = React.useState(false);
  const bizumDiscountCents = paymentMethod === 'bizumpay' && (myTotal - tip) >= 1500 ? bizumPromotionCents : 0;
  const zeroSixDiscountCents = zeroSixSelectedVoucher.code != "" ? (!zeroSixSelectedVoucher.is_percentage ? zeroSixSelectedVoucher.value * 100 : Math.round((((myTotal - tip) *  zeroSixSelectedVoucher.value) / 100))) : 0
  const amountMinusDiscounts = Math.max(amount - generalDiscountCents - productDiscountCents -  codeCouponCents, 0);
  const amountMinusDiscountsAndCredit = Math.max(amountMinusDiscounts - creditCents, 0);
  const amountMinusAllDiscounts = Math.max(amountMinusDiscountsAndCredit, 0);
  const finalLoyaltyDiscount = Math.max(loyaltyDiscount > amountMinusAllDiscounts ? amountMinusAllDiscounts : loyaltyDiscount, 0)
  const finalAmount = Math.max(amount - finalLoyaltyDiscount - generalDiscountCents - productDiscountCents - codeCouponCents - creditCents - bizumDiscountCents - zeroSixDiscountCents, 0) + Number(serviceCommision) + deliveryFeeCents;

  const handleCashPayment = async () => {
    if (cashPaymentDisabled) return;
    setLoading(true)
    tokenHandler("");
  
  };

  React.useEffect(() => {
    setCashPaymentDisabled(!!emailError || (!email || !EMAIL_REGEX.test(email)));
  }, [email]);

  return (
    <>
      {type === 'card' &&
      <CardContainer>
          <RedsysCardComponent redsysIntiatePayment={tokenHandler} isLoading={isLoading} setLoading={setLoading} merchantCode={redsysMerchantCode} merchantTerminal={redsysMerchantTerminal} redsysEnv={redsysEnv}/>
      </CardContainer>
      }
      {type == "paywithgoogle" &&
        <GooglePayComponent merchantCode={redsysMerchantCode} redsysEnv={redsysEnv} totalAmount={finalAmount} redsysIntiatePayment={tokenHandler}/>
      }
      {(type == "applepay") &&
      (!isLoading ?
        <ApplePayComponent totalAmount={finalAmount} redsysIntiatePayment={tokenHandler}/>
      :
        <Loading parentComponent='RedsysPay applepay' loaderStyle={{height: '36px', width: '36px'}} style={{height: '44px', margin: '0'}}/>
      )}
      {type === 'cash' &&
          <>
          <TextInput
            label={t('mail')}
            name="email"
            placeholder={t('email')}
            onChange={handleEmailChange}
            onBlur={() => checkEmail()}
            value={email}
            error={emailError ? t('valid_email').toString() : undefined}
            required={true}
          />
          <p>{t('We will send you the order code')}</p>
          {!isLoading ?
            <Button
            title={t('continue')}
            handleClick={() => handleCashPayment()}
            disabled={cashPaymentDisabled}/>
            : 
          <Loading parentComponent='RedsysPay cash' loaderStyle={{height: '36px', width: '36px'}} style={{height: '44px', margin: '0'}}/>}</>
          
      }
    </>
  );
}

RedsysPayComponents.propTypes = {
    type: PropTypes.string.isRequired,
    amount: PropTypes.number.isRequired,
    loyaltyDiscount:PropTypes.number.isRequired,
    tip: PropTypes.number.isRequired,
};

type RedsysPayComponentsProps = {
    bizumPromotionCents: number
    restaurantCreditId?: number
    tokenHandler:      (paymentToken: string, sessionId?: string, saveCardChecked?: boolean, selectedSavedCard?: any, redsysOrder?: string, googlePayToken?: string, applePayData?: string) => void
} & InferProps<typeof RedsysPayComponents.propTypes>;
