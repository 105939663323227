import React from 'react';
import { Badge, Icon, IconType } from '../../../elements';

type Props = {
  balance?: string,
  disabled?: boolean
}

export const CreditBadge = ({balance, disabled}: Props) => {

  return (
    <Badge color={disabled ? "#F6F6F6" : "#EAFEEA"} textColor={"#404040"} shadow={false}>
      {balance === undefined && <span>-</span>}
      <Icon type={disabled ? IconType.CreditBannerDisabled : IconType.CreditBanner} size={24}/>
      <span>{balance}</span>
    </Badge>
  )
};
