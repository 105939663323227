import endpoints from "../endpoints/Endpoints.api";
import Manager from "../manager/Manager.api";
import ManagerAuth from "../manager/ManagerAuth.api";

 
const receipt = (values:any, id:any) => Manager.client().post(endpoints.payments.payment_raw + `${id}/receipt/`, values)
const receiptAuth = (token:any, type:any, values: any, id:any) => ManagerAuth.client(token, type).post(endpoints.payments.payment_raw + `${id}/receipt/`, values)


export const Request = {
    receipt,
    receiptAuth
}