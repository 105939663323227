import { useContext, useState } from 'react'
import { AppContext } from '../../../context/AppContext'
import PaymentMethods from '../PaymentMethods'
import PortugueseInput from '../PortugueseInput'
import { PaymentPopup } from './PaymentMethodsBoxes'
import RedsysRedirection from '../../../elements/redsys/RedsysRedirection'

type Props = {
  bizumDiscount?:      boolean
  discountApplied:     boolean
  prevMyTotal:         number
  event?:              any
  onOutOfStock?:       any
  setFlow?:            any
  setPaymentResult?:   any,
  setRedsysChallenge?: any,
  restaurantCreditId?: number
}


export const PaymentMethodsPopup = ({
  prevMyTotal,
  discountApplied,
  bizumDiscount,
  event,
  onOutOfStock,
  setFlow,
  setPaymentResult,
  setRedsysChallenge,
}: Props) => {

  const {
    state: {
      restaurant: {
        country,
      },
      checkout: {
        myTotal,
        paymentGateway
      }, 
      wallet: {
        restaurantCreditId
      }
    },
  } = useContext(AppContext);

  const [disabled, setDisabled] = useState(true);
  const [customerName, setCustomerName] = useState("");
  const [customerVAT, setCustomerVAT] = useState("");

  return (
    <PaymentPopup>
      {country === 'PT' &&
        <PortugueseInput
          customerName={customerName}
          setCustomerName={setCustomerName}
          customerVAT={customerVAT}
          setCustomerVAT={setCustomerVAT}
          disabled={disabled}
        />
      }
      {paymentGateway == "redsys-redirect" ?
        <RedsysRedirection 
          myTotalUpdated={prevMyTotal >= 0 && discountApplied ? prevMyTotal : myTotal}
          restaurantCreditId={restaurantCreditId}
        />
      :
        <PaymentMethods
          bizumDiscount={bizumDiscount}
          disabled={disabled}
          myTotalUpdated={prevMyTotal >= 0 && discountApplied ? prevMyTotal : myTotal}
          event={event}
          setDisabled={setDisabled}
          onOutOfStock={onOutOfStock}
          setFlow={setFlow}
          setPaymentResult={setPaymentResult}
          setRedsysChallenge={setRedsysChallenge}
          restaurantCreditId={restaurantCreditId}
        />
      }
    </PaymentPopup>
  )
};
