// id
export const MADRE_LIEVITO_EIXAMPLE = 4;
export const MADRE_LIEVITO_POBLE_NOU = 9;
export const VUELVE_CAROLINA = 19;
export const URBAN_POKE_BAR = 30;
export const PORXO_SANT_MARTI = 135;
export const TRESPUNTOCERO = 140;
export const MO_DE_MOVIMIENTO = 249;
export const BROKER_OP=307
export const VIDA_Y_COMIDA = 315;
export const VILLA_INDIANO_TAPAS = 338;
export const MADRE_LIEVITO_EIXAMPLE_OP = 345;
export const BEIRUT_ARAGON = 346;
export const BASTARD_COFFEE_XUQUER = 376;
export const BASTARD_COFFEE_RUZAFA = 377;
export const MANTECA_BURGERS = 378;
export const MALLALO = 407;
export const VILLA_INDIANO_BEBIDAS = 623;

// uuid
export const SKAPA_UUID = '4f9e6fe0cbc7426a996d175bb4001f1a';

// restuarant_group uuid
export const DESIGUAL_GROUP_UUID = 'a1b2fd4b4d034648a72868339f3a9b81';
export const NORRKSEN_GROUP_UUID = 'd64774a9f7a2423382d8727039998c12';

// groups
export const FOUR_STARS_REVIEW_RESTAURANTS = [MALLALO, BEIRUT_ARAGON];
export const NO_TIPS_RESTAURANTS = [URBAN_POKE_BAR, TRESPUNTOCERO, BASTARD_COFFEE_XUQUER, BASTARD_COFFEE_RUZAFA];