import React, { useCallback, useEffect, useRef, useState } from 'react'
import { ScrollContent, ScrollWrapper, ScrollBar, ScrollThumb } from './ScrollbarContainers';

type Props = {
  children: React.ReactNode;
  maxHeight: string;
  scrollBarColor?: string;
  delay?: number;
};

export const ScrollbarComponent = ({maxHeight, scrollBarColor, children, delay}: Props) => {
  const [thumbHeight, setThumbHeight] = useState(0);
  const [thumbPosition, setThumbPosition] = useState(0);
  const scrollRef = useRef<HTMLDivElement>(null);
  const contentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const timeOut = setTimeout(() => setThumbHeight((scrollRef.current?.clientHeight || 0) * 100 / (contentRef.current?.clientHeight || 0)), delay)
    return () => clearTimeout(timeOut);
  }, [children]);

  const handleScroll = useCallback(() => {
    setThumbPosition((scrollRef.current?.scrollTop || 0) * 100 / (contentRef.current?.clientHeight || 0));
  }, []);

  return (
    <ScrollWrapper $maxHeight={maxHeight} >
      <ScrollContent ref={scrollRef} onScroll={handleScroll}>
        <div ref={contentRef}>
          {children}
        </div>
      </ScrollContent>
      <ScrollBar $isShown={thumbHeight > 0 && thumbHeight < 100} $color={scrollBarColor}>
        <ScrollThumb $color={scrollBarColor} $height={thumbHeight} style={{top: thumbPosition + '%'}}/>
      </ScrollBar>
    </ScrollWrapper>
  );
};