import styled from 'styled-components';
import React from "react";

  const TableStatusTitle = styled.div`
  display: flex;
  color: ${props => props.theme.text};
  font-weight: 600;
  font-size: ${props => props.theme.font_md};
  padding: 8px 10px 0;
`;

type TableStatusWrapperProps = {
  maxHeight?: boolean;
}

const TableStatusWrapper = styled.div<TableStatusWrapperProps>`
  display: flex;
  flex-direction: row;
  color: ${props => props.theme.text};
  font-weight: 700;
  flex-wrap: wrap;
  font-size: ${props => props.theme.font_md};
  padding: 1.15rem 0.5rem 0.5rem 0.5rem;
  max-height: ${props => props.maxHeight ? '50px' : '100%'};
  overflow: hidden;
  `;

const TableStatusItem = styled.div`
  color: ${props => props.theme.primary};
  font-weight: 500;
  font-size: ${props => props.theme.font_xs};
  padding: 0.25rem 0.5rem 0.25rem 1rem;
  margin-bottom: 0.75rem;
  margin-left: 0.5rem;
  background: #EFF8F4;

`;

type TableStatusProps = {
    children: JSX.Element|JSX.Element[];
  }
  
  //list item row
  const TableStatus = ({
      children 
    }: TableStatusProps): JSX.Element => {
      return (
        <TableStatusItem>
          {children}
        </TableStatusItem>
      )
    };


export {TableStatusTitle, TableStatusWrapper, TableStatusItem, TableStatus};

