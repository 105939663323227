import { useContext } from 'react'
import { useTranslation } from 'react-i18next';
import { BottomInfo, ErrorContainer, ErrorReason, ErrorTitle, FlowContainer, FlowStatusComponentContainer, HeaderContainer, PatFlowContainer, StepContainer, VerticalLine } from './FlowStatusComponentContainers';
import { Icon, IconType } from '../icons';
import { ButtonVariant } from '../buttons/enums';
import { Button } from '../buttons';
import { useNavigate } from 'react-router-dom';
import { statusCodes } from '../../shared/constants/statusCodes';
import RedsysChallengeComponent from '../redsys/RedsysChallengeComponent';
import { AppContext } from '../../context/AppContext';
import { usePopupNavigation } from '../../shared/utils/usePopupNavigation';
import { useViewport } from '../../context/ViewportContext';

type Props = {
  flow: any,
  paymentResult: any,
  setFlow: any,
  setPaymentResult: any,
  redsysChallenge?: any,
  setRedsysChallenge?: any
  recharge?: boolean
};

export const FlowStatusComponent = ({flow, paymentResult, setFlow, setPaymentResult, redsysChallenge, setRedsysChallenge, recharge} : Props) => {
  const { state: { checkout: { checkoutFlow } } } = useContext(AppContext);
  const { closePopup } = usePopupNavigation();
  const { isMobile } = useViewport();

  const { t } = useTranslation();
    closePopup();
    const navigate = useNavigate();

    const functionality = sessionStorage.getItem('functionality') || '';

    let flowStatus = null
    let flowType = null

    if (flow) {
      flowStatus = flow["flow_status"]
      flowType = flow["flow_type"]
    }

    if (!flowType) {
      flowType = checkoutFlow;
    }

    let error_code = paymentResult && !["E000", "0000"].includes(paymentResult) ? paymentResult.toString() : null;
    if (error_code && statusCodes[error_code] as any === undefined) {
      error_code = "E101"
    }
    const error = error_code && statusCodes && statusCodes[error_code] !== undefined ? statusCodes[error_code][0] : null
    const error_advice = error_code && statusCodes && statusCodes[error_code] !== undefined ? statusCodes[error_code][1] : null
    const error_return_home = error_code && statusCodes && statusCodes[error_code] !== undefined ? statusCodes[error_code][2] : null
    const isSuccess = ["E000", "0000"].includes(paymentResult)

    const handleClick = () => {
      if ( window.location.href.includes('complete')) {
        navigate('/checkout', {state: {from: 'paymentErrorUpdatedRemaining'}});
      }
      else if (error_return_home) {
        window.location.reload()
      } else {
        setPaymentResult(null)
        setFlow(null)
        setRedsysChallenge(null)
      }
    }

    return (
      <>
        {error ?
          <ErrorContainer>
            <Icon size={80} type={IconType.Warning} />
            <ErrorTitle>{t("no_posible_complete_payment")}</ErrorTitle>
            <ErrorReason>⚠️ {t("reason")}: <span>{t(error as string)}</span></ErrorReason>
            {error_advice &&
              <ErrorReason>{t(error_advice as string)}</ErrorReason>
            }
            <Button
              title={t('go back')}
              variant={ButtonVariant.Primary}
              handleClick={handleClick}
              width='fit-content'
              style={{padding: "0 40px"}}
            />
          </ErrorContainer>
        : redsysChallenge && "acsURL" in redsysChallenge && "CReq" in redsysChallenge ?
          <RedsysChallengeComponent acsURL={redsysChallenge["acsURL"]} CReq={redsysChallenge["CReq"]} />
        : flowStatus && flowType &&
            <FlowStatusComponentContainer>
            <HeaderContainer isSuccess={isSuccess}>
                <p>{t("lets_go")} 🚀</p>
              </HeaderContainer>
              <FlowContainer>
                {(flowType == "OP" && functionality !== 'kiosk') ?
                  <StepContainer flowStatus={flowStatus}>
                    {isMobile ?
                      <>
                        <div id='processing_payment_icon'>
                          <Icon size={80} type={IconType.Verified} />
                        </div>
                        <p>{t("processing_payment")}</p>
                        <VerticalLine flowStatus={flowStatus}/>
                        <div></div>
                        <div id="sending_to_pos_icon">
                          <Icon size={80} type={IconType.Verified} />
                        </div>
                        <p id="sending_to_pos_text">{recharge || flow?.recharge ? t("recharging") : t("sending_to_kitchen")}</p>
                      </>
                    :
                      <>
                        <div className='wrap-step'>
                          <div id='processing_payment_icon'>
                            <Icon size={80} type={IconType.Verified} />
                          </div>
                          <p>{t("processing_payment")}</p>
                        </div>
                        <VerticalLine flowStatus={flowStatus}></VerticalLine>
                        <div className='wrap-step'>
                          <div></div>
                          <div id="sending_to_pos_icon">
                            <Icon size={80} type={IconType.Verified} />
                          </div>
                          <p id="sending_to_pos_text">{recharge || flow?.recharge ? t("recharging") : t("sending_to_kitchen")}</p>
                        </div>
                      </>
                    }
                  </StepContainer>
                :
                  <PatFlowContainer>
                    <div id='processing_payment_icon'>
                      <Icon size={80} type={IconType.Verified} />
                    </div>
                    <p>{t(functionality === 'kiosk' ? 'processing_order' : "processing_payment")}</p>
                  </PatFlowContainer>
                }
              </FlowContainer>
              <BottomInfo>
                <p>{t("please_not_reload")}</p>
              </BottomInfo>
        </FlowStatusComponentContainer>}
      </>
    )
}