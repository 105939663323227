import { PopupType } from "../../context/popups.enum";

export const noReturnPopup = [
  PopupType.CardSaved,
  PopupType.NotInRestaurant,
  PopupType.OrderSentToKitchen,
  PopupType.NavigateAll,
  PopupType.Bumerang,
  PopupType.LatestBills
];

export const noHeader = [
  PopupType.ConfirmOrderPayLater,
  PopupType.NavigateAll,
  PopupType.Bumerang,
  PopupType.LatestBills
];
