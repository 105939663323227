type StatusCodes = {
  [code: string]: (string | boolean | null)[];
};

export const statusCodes: StatusCodes = 
  {
  "E000": ["payment_successfull", null, false],
  "E101": ["general_payment_gateway_error", null, false],
  "E102": ["general_payment_gateway_error", null, false],
  "E103": ["general_payment_gateway_error", null, false],
  "E104": ["general_payment_gateway_error", null, false],
  "E105": ["general_payment_gateway_error", null, false],
  "E150": ["general_payment_gateway_error", null, false],
  "E151": ["general_payment_gateway_error", null, false],
  "E152": ["general_payment_gateway_error", null, false],
  "E153": ["general_payment_gateway_error", null, false],
  "E154": ["general_payment_gateway_error", null, false],
  "E155": ["general_payment_gateway_error", null, false],
  "E156": ["general_payment_gateway_error", null, false],
  "E200": ["general_payment_gateway_error", null, false],
  "E201": ["refused_payment_by_card_issuer_bank", "try_with_other_card", false],
  "E202": ["refused_payment_by_bank", "try_with_other_card", false],
  "E203": ["not_valid_payment_method", "try_with_other_payment_method", false],
  "E204": ["not_allowed_currency", null, false],
  "E205": ["general_payment_gateway_error", null, false],
  "E206": ["not_valid_payment_method", "try_with_other_payment_method", false],
  "E207": ["transaction_time_exceeded", "try_again", false],
  "E208": ["processing_payment", null, false],
  "E209": ["duplicated_operation", null, false],
  "E210": ["not_allowed_payment_amount", null, false],
  "E211": ["general_payment_gateway_error", null, false],
  "E212": ["operation_already_captured", null, false],
  "E213": ["payment_canceled", null, false],
  "E214": ["amount_captured_exceed_amount_preauthorized", null, false],
  "E215": ["payment_not_preauthorized", null, false],
  "E216": ["payment_not_preauthorized", null, false],
  "E217": ["general_payment_gateway_error", null, false],
  "E218": ["general_payment_gateway_error", null, false],
  "E219": ["refund_exceeded_amount", null, false],
  "E220": ["full_refund_already_done", null, false],
  "E221": ["insufficient_funds", "try_with_other_payment_method", false],
  "E222": ["payment_canceled", null, false],
  "E223": ["processing_payment", null, false],
  "E224": ["general_payment_gateway_error", null, false],
  "E225": ["general_payment_gateway_error", null, false],
  "E226": ["general_payment_gateway_error", null, false],
  "E227": ["payment_canceled", null, false],
  "E228": ["payment_requires_SCA", "try_with_other_payment_method", false],
  "E229": ["expired_payment_method", "try_with_other_payment_method", false],
  "E300": ["payment_declined_security_reasons", null, false],
  "E301": ["3DS_auth_failed", "try_again", false],
  "E302": ["auth_timeout", "try_again", false],
  "E303": ["3DS_auth_failed", "try_again", false],
  "E304": ["3DS_auth_failed",  "try_again", false],
  "E305": ["general_payment_gateway_error", null, false],
  "E306": ["general_payment_gateway_error", null, false],
  "E307": ["general_payment_gateway_error", null, false],
  "E308": ["general_payment_gateway_error", null, false],
  "E309": ["general_payment_gateway_error", null, false],
  "E500": ["refused_card", "fix_or_try_with_other_card", false],
  "E501": ["invalid_card_number", "fix_or_try_with_other_card", false],
  "E502": ["wrong_expiration_date", "fix_or_try_with_other_card", false],
  "E503": ["wrong_CVV", "fix_or_try_with_other_card", false],
  "E504": ["card_number_not_registered", "try_with_other_card", false],
  "E505": ["expired_payment_method", "try_with_other_payment_method", false],
  "E506": ["general_payment_gateway_error", null, false],
  "E507": ["payment_canceled", null, false],
  "E508": ["AMEX_cards_not_allowed", "try_with_other_card", false],
  "E509": ["payment_declined_security_reasons", null, false],
  "E510": ["card_not_allow_preauth", "try_with_other_card", false],
  "E511": ["CVV_compulsory", "fix_or_try_with_other_card", false],
  "E512": ["unsupported_card_type", "try_with_other_card", false],
  "E513": ["card_not_allowed_for_transaction", "try_with_other_card", false],
  "E514": ["payment_declined_by_card_issuer", "try_with_other_card", false],
  "E515": ["implausible_card_data", "fix_or_try_with_other_card", false],
  "E516": ["incorrect_PIN", "fix_or_try_with_other_card", false],
  "E517": ["payment_not_allowed_for_cardholder", "try_with_other_card", false],
  "E518": ["amount_exceeds_card_limit", "try_with_other_card", false],
  "E600": ["refused_operation", null, false],
  "E601": ["general_payment_gateway_error", null, false],
  "E620": ["refused_operation", null, false],
  "E621": ["general_payment_gateway_error", null, false],
  "E640": ["payment_refused_after_3_attempts", "try_with_other_payment_method", false],
  "E641": ["3DS_auth_failed", "try_again", false],
  "E642": ["insufficient_funds", "try_with_other_payment_method", false],
  "E643": ["payment_canceled", null, false],
  "E644": ["refused_payment_by_bank", "try_with_other_payment_method", false],
  "E645": ["refused_payment_by_bank", "try_with_other_payment_method", false],
  "E646": ["refused_operation", null, false],
  "E647": ["bizum_transaction_failed", "try_with_other_payment_method", false],
  "E648": ["general_payment_gateway_error", null, false],
  "E649": ["general_payment_gateway_error", null, false],
  "E650": ["general_payment_gateway_error", null, false],
  "E651": ["3DS_auth_failed", "try_with_other_payment_method", false],
  "E652": ["bizum_transaction_failed", "try_with_other_payment_method", false],
  "E680": ["general_payment_gateway_error", null, false],
  "E681": ["general_payment_gateway_error", null, false],
  "E700": ["general_payment_gateway_error", null, false],
  "E701": ["general_payment_gateway_error", null, false],
  "EY01": ["unexpected_error_happened", null, false],
  "EY02": ["not_valid_payment_method", "try_with_other_payment_method", false],
  "EY03": ["impossible_to_connect_with_payment_gateway", null, false],
  "EY04": ["impossible_to_connect_with_payment_gateway", null, false],
  "EY05": ["configuration_error", null, false],
  "EY06": ["error_while_applying_discount", "try_again_without_discount", false],
  "EY07": ["error_while_applying_discount", "try_again_without_discount", false],
  "EY08": ["error_while_applying_discount", "try_again_without_discount", false],
  "EY09": ["min_amount_to_apply_discount_not_reached", "try_again_without_discount", false],
  "EY10": ["error_while_applying_discount", "try_again_without_discount", false],
  "EY11": ["not_possible_to_apply_cashback_and_coupon_same_time", null, false],
  "EY12": ["configuration_error", null, false],
  "EY13": ["configuration_error", null, false],
  "EY14": ["configuration_error", null, false],
  "EY15": ["configuration_error", null, false],
  "EY16": ["error_while_applying_discount", "try_again_without_discount", false],
  "EY17": ["error_while_applying_discount", "try_again_without_discount", false],
  "EY18": ["error_while_applying_discount", "try_again_without_discount", false],
  "EY19": ["error_while_applying_discount", "try_again_without_discount", false],
  "EY20": ["error_while_applying_discount", "try_again_without_discount", false],
  "EY21": ["payment_canceled", null, false],
  "EY22": ["unexpected_error_happened_with_payment_gateway", null, false],
  "EY23": ["unexpected_error_happened_with_payment_gateway", null, false],
  "EY24": ["unexpected_error_happened_with_payment_gateway", null, false],
  "EY25": ["restaurnat_not_active", null, true],
  "EY26": ["selected_product_out_of_stock", "redirect_home_to_info_update", true],
  "EY27": ["check_remaining_updated", "redirect_home_to_info_update", true],
  "EY28": ["bill_item_already_paid", "redirect_home_to_info_update", true],
  "EY29": ["error_while_applying_credit", "try_again_without_credit", false],
  "EY30": ["error_while_applying_credit", "try_again_without_credit", false],
  "EY31": ["error_while_applying_credit", "try_again_without_credit", false],
  "EY32": ["error_while_applying_credit", "try_again_without_credit", false],
  "EY33": ["error_while_applying_discount", "redirect_home_to_info_update", true],
  "EY34": ["error_while_retrieving_free_product", "try_again", true],
  "EY35": ["free_product_promo_expired", "redirect_home_to_info_update", true],
  "EY36": ["error_while_retrieving_free_product", "try_again", true],
  "EY37": ["free_product_promo_expired", "redirect_home_to_info_update", true],
  }