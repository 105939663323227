import { createContext, useContext, useState } from "react";
import { useEffect } from "react";
import PropTypes, { InferProps } from "prop-types";
import { Request } from "../../api/auth/Login.api";
import { postError } from "../../shared/utils/postError";
import { useIsMounted } from "../../shared/utils/useIsMounted";

export type AuthContextType = {
  isLogged: boolean;
};

export type AuthContextDispatchType = {
  setToken: (token: string | null, type: string | null) => void;
  logout: () => void;
};

export const AuthContext = createContext<AuthContextType>({
  isLogged: false,
});

export const AuthContextDispatchContext =
  createContext<AuthContextDispatchType>({
    setToken: () => console.log("no token provider"),
    logout: () => console.log("delete"),
  });

export const useAuthContext = () => useContext(AuthContext);
export const useAuthContextDispatchContext = () => useContext(AuthContextDispatchContext);

function AuthProvider({ children }: InferProps<typeof AuthProvider.propTypes>) {
  const [isLogged, setIsLogged] = useState(false);
  const isMountedRef = useIsMounted();

  const getToken = () => {
    const parseStorage = (value: string | null) => (value && !['undefined', 'null'].includes(value)) ? value : null;
    return {
      token: parseStorage(localStorage.getItem("token_yumminn")),
      type: parseStorage(localStorage.getItem("type")),
    };
  };

  const { token, type } = getToken();

  const setToken = async (token: string | null, type: string | null) => {
    if (!token || !type) return;

    try {
      const isValid = !!token && (await Request.validateToken(token, type));

      if (isMountedRef.current) {
        if (isValid) {
          localStorage.setItem("token_yumminn", token);
          localStorage.setItem("type", type);
          setIsLogged(true);
        } else {
          logout();
        }
      }

    } catch (error: any) {
      postError(error);
      console.log(`Error in setToken: ${error}`);
    }
  }

  const logout = () => {
    localStorage.removeItem("token_yumminn");
    localStorage.removeItem("type");
    localStorage.removeItem("client_id");
    sessionStorage.removeItem("paymentId");

    setIsLogged(false);
  };

  useEffect(() => {
    if (token && type) {
      setToken(token, type);
    }
  }, []);


  return(
    <AuthContext.Provider value={{ isLogged }}>
      <AuthContextDispatchContext.Provider value={{ setToken, logout }}>
        {children}
      </AuthContextDispatchContext.Provider>
    </AuthContext.Provider>
  );
}

AuthProvider.propTypes = {
  children: PropTypes.object,
};

export default AuthProvider;
