import styled, { keyframes } from 'styled-components';

const InformationBody = styled.div`
    display: flex;
    justify-content: center;
    padding: 0 20px 20px 20px;
    flex-direction: column;
    text-align: center;
`;

const LoyaltyCardContent = styled.div`
    display: flex;
    padding: 0 0px 0 10px;
    align-items: flex-start;
    gap: 25px;
`;
const LoyaltyBannerContainer = styled.div<LoyaltyBannerProps>`
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 12px;
    color: ${({theme}) => theme.text};
    background: ${({theme, hasLoyalty, hasPoints}) => (hasLoyalty || !hasPoints) ? theme.cash_gradient : theme.yumminn_gradient};
    border-radius: ${({theme}) => theme.radius_md};
    padding: 12px;
    margin: 0 10px;
    min-height: 41.6px;

    svg {
        flex-shrink: 0;
    }
`;

const LoyaltyBannerContainerNoRedeem = styled.div<LoyaltyBannerProps>`
    display: flex;
    background: ${({theme, isLogged, hasLoyalty, hasPoints}) => isLogged ? theme.yumminn_10 : (hasLoyalty || !hasPoints) ? theme.cash_gradient : theme.yumminn_gradient};
    flex-direction: column;
    font-weight: 600;
    font-size: ${props => props.theme.font_sm};
    padding: 12px;
    gap: 8px;
    margin: 20px;
    border-radius: ${({theme}) => theme.radius_md};
`;

const LoyaltyRedeemInfo = styled.div`
    display: flex;
    flex-direction: column;
    font-weight: 400;
    font-size: ${props => props.theme.font_sm};
`;

const CardsMenu = styled.div`
    padding: 8px 0;
    display: flex;
    justify-content: space-around;
    gap: 55px;
    margin-bottom: 10px;
    border-bottom-width: 2px;
    overflow-x: auto;
    padding-right: 20px;
`;

const HowItWorksContainer = styled.div`
    display: flex;
    padding: 0 20px;
    flex-direction: column;
    line-height: 21px;
    margin-top: 25px;
`;

const CardsContainer = styled.div`
    display: flex;
    color: #767676;
    padding: "40px";
    flex-direction: column;
    line-height: 23.4px;
`;

const Circle = styled.div`
    border-radius: 50%;
    width: 20px;
    height: 20px;
    padding: 10px;
    background: #fff;
    background: #EFF8F4;
    text-align: center;
    margin-right: 20px;
    margin-bottom: 10px;
`;

const LoyaltyCard = styled.div`
    background: #FFFFFF;
    box-shadow: 0px 8px 20px rgba(149, 157, 165, 0.2);
    border-radius: 20px;
    display: flex;
    position: relative;
    margin-bottom: 40px;
    max-width: 360px;
    min-width: 300px;
`;

const animatePop = keyframes`
    0% {
        opacity: 0;
        transform: scale(0.5, 0.5);
    }
    100% {
        opacity: 1;
        transform: scale(1, 1);
    }
`;

const SalmonBanner = styled.div`
    font-size: 13px;
    font-weight: 400;
    color: #404040;
    animation-duration: 0.5s;
    animation-name: ${animatePop};
    animation-timing-function: cubic-bezier(.26, .53, .74, 1.48);
    height: 30;
    width: 300px;
    flex-grow: 1;

    button {
        margin: -5px 0;
        min-width: 100px;
    }
`;

const OuterWrapper = styled.div`
    max-height: 100vh;
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 16px 24px 0 24px;
    color: ${({theme}) => theme.text};

    h2 {
        font-size: ${({theme}) => theme.font_md};
        margin: 16px 0;
    }

    & > p {
        font-size: ${({theme}) => theme.font_sm};
        font-weight: 600;
    }

    hr {
        margin: 0;
        border: none;
        border-top: 1px solid ${({theme}) => theme.gray_50};
    }

    span {
        font-size: ${({theme}) => theme.font_md};
        font-weight: 400;
        text-align: center;
        margin-bottom: 8px;
    }

    button {
        margin: auto 24px 16px;
    }
`;

const Link = styled.a`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    width: 100%;
    color: ${({theme}) => theme.text};
    font-size: ${({theme}) => theme.font_xs};
    font-weight: 500;
    text-decoration: underline;
    margin: 16px 0 8px;
`;

const ListContainer = styled.div`
    padding: 0 5% 5% 5%;    
    flex: auto;
    position: relative;
`;

const RestaurantList = styled.ul`
    list-style: none;
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding: 0;
    margin: 0 0 16px;
`;

const EmptyList = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 16px;
    height: 100%;

    & > img {
        height: 115px;
        width: 115px;
        object-fit: contain;
    }

    & > p {
        font-weight: 600;
        text-align: center;
        margin: 0;
        max-width: 26ch;
    }
`;
const VocuhersContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 2px;
    padding: 8px;
    border: 2px solid #F8F8F8;
    background-color: #FFF;
    border-radius: 0 0 16px 16px;
    box-shadow: 0px 8px 8px #00000015;
    margin-top: -15px;
    position: relative;
    z-index: 0;
    padding-top: 20px;

`;

const VocuhersPopUpContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 2px;
    padding: 16px 24px;
    background-color: #FFF;
    border-radius: 0 0 16px 16px;
    padding: 5px 5px;
    justify-content: center;
    p {
    color: black;
    font-size: 12px;
    font-weight: 600;
}

`;

const InfoPopupContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
    color: ${({theme}) => theme.text};
    padding: 16px 24px 50px 24px;

    h2 {
        font-size: ${({theme}) => theme.font_lg};
        font-weight: 700;
        margin: 0 0 -8px;
    }

    p {
        font-size: ${({theme}) => theme.font_md};
        line-height: 1.3em;
        text-align: center;
        max-width: 28ch;
        margin: 0;
    
        b {
            font-weight: 700;
        }
    
        span {
            font-weight: 600;
        }
    }

    & > span {
        font-size: ${({theme}) => theme.font_xs};
        color: ${({theme}) => theme.gray_70};
    }

    hr {
        width: 100%;
        border: none;
        border-top: 2px solid ${({theme}) => theme.gray_40};
        margin: 0;
    }
`;

const InfoPopupHeader = styled.h3`
    font-size: ${({theme}) => theme.font_lg};
    font-weight: 400;
    line-height: 1.3em;
    text-align: center;
    margin: 0;

    b {
        font-weight: 600;
    }
`;

const RestaurantCardContainerVouchers = styled.div`
    display: flex;
    padding: 8px;
    background-color: #FFF8F5;
    border-radius: 16px;
    box-shadow: 0px 8px 8px #00000015;
    position: relative;
    margin-top: 5px;
    width: 100%;
`;

const RegisterContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: #FFF;
  font-weight: 600;
  padding-bottom: 50px;
`;

const RegisterExternalContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: #FFF;
  font-weight: 600;
  font-size: ${props => props.theme.font_md};
  padding: 0 0.5rem 0.4rem 0.5rem;
  color: ${props => props.theme.primary};
`;

const RegisterInternalContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-weight: 600;
  font-size: ${props => props.theme.font_md};
  margin: 1.25rem;
  width: 100%;
`;

const BannerContainer = styled.div`
display: flex;
justify-content: space-between;
align-items: center;
gap: 12px;
color: #ED2C03;
background: #FFF8F5;
border-radius: ${({theme}) => theme.radius_md};
padding: 12px;
min-height: 32px;
svg {
    flex-shrink: 0;
}
p {
    font-size: ${({theme}) => theme.font_sm};
    font-weight: 600;
    margin: 0 auto 0 0;
}
& > label {
    pointer-events: none;
}
`;

const RestaurantCardContainer = styled.div<RestaurantCardProps>`
    display: flex;
    flex-wrap: wrap;
    position: relative;
    z-index: 1;
    gap: 8px;
    padding: 8px;
    color: ${({theme}) => theme.text};
    border: 2px solid #F8F8F8;
    background-color: #FFF;
    border-radius: 16px;
    box-shadow: 0px 8px 8px #00000015;
    justify-content: center;

    h2 {
        font-size: 10px;
    }
    h3 {
        margin: 0;
        font-size: 14px;
    }
    h4 {
        margin: 0;
        color: #ED2C03;
        font-size: 18px;
    }
    h5 {
        margin: 0 0 0 .5rem;
        color: #ED2C03;
        font-weight: 1;
        font-size: 10px;
    }
    h6 {
        margin: 0;
        text-align: center
    }
   b {
        font-size: 15px;
    }

    
`;

const RestaurantCardImg = styled.img`
    width: 70px;
    height: 70px;
    object-fit: contain;
    border: 2px solid #F8F8F8;
    border-radius: 8px;
`;

const ZeroSixCoinImg = styled.img`
    width: 22px;
    height: 22px;
    object-fit: contain;
    border-radius: 15px;
    margin-top: 7px;
    margin-right: 0;
`;

const ZeroSixCoinImg2 = styled.img`
    width: 20px;
    height: 20px;
    object-fit: contain;
    border-radius: 15px;
`;

const RestaurantInfoList = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
`;

const VoucherInfoContainer = styled.div`
    width: 80px;
    margin: 0;
    justify-content: center;
    background-color: #FFE1D7;
    display: flex;
    align-items: center;
    border-radius: 8px;
`;

const PaymentSuccessfullBanner = styled.div`
    background-color: #FFF8F5;
    width: 100%;
    box-sizing: border-box;
    border-radius: ${({theme}) => theme.radius_md};
    align-self: center;
    display: flex;
    gap: 16px;
    padding: 8px 16px;
`;

type LoyaltyBannerProps = {
    isLogged: boolean,
    hasLoyalty: boolean,
    hasPoints: boolean,
}

type RestaurantCardProps = {
    isRedeemable: boolean,
}

export {
    InformationBody,
    RestaurantCardImg,
    ZeroSixCoinImg,
    ZeroSixCoinImg2,
    VocuhersContainer,
    LoyaltyBannerContainerNoRedeem,
    LoyaltyRedeemInfo,
    LoyaltyCardContent,
    SalmonBanner,
    PaymentSuccessfullBanner,
    RestaurantCardContainerVouchers,
    LoyaltyBannerContainer,CardsMenu,
    CardsContainer,
    VoucherInfoContainer,
    HowItWorksContainer,
    Circle,
    LoyaltyCard,
    OuterWrapper,
    Link,
    RegisterExternalContainer,
    RegisterInternalContainer,
    RegisterContainer,
    ListContainer,
    RestaurantInfoList,
    RestaurantList,
    EmptyList,
    RestaurantCardContainer,
    BannerContainer,
    VocuhersPopUpContainer,
    InfoPopupHeader,
    InfoPopupContainer
};