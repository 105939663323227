import React, { useState } from 'react';
import { t } from 'i18next';
import { Receipt } from '../../../context/app';
import { Scrollbar } from '../../scrollbar';
import { ListContainer, NoReceipts, ReceiptListPopup } from './ReceiptsContainers';
import moment from 'moment';
import { TabSelector } from '../../tab-selector';
import { ReceiptItem } from './ReceiptItem';
import { useViewport } from '../../../context/ViewportContext';

type Props = {
  receipts: Receipt[]
  notifyUuid: string | null
  isShown: boolean
  openTicket: (receipt: Receipt) => void
  close: () => void
};

export const ReceiptsList = ({receipts, notifyUuid, isShown, openTicket, close}: Props) => {
  const { orientation } = useViewport();

  const functionality = sessionStorage.getItem('functionality') || '';
  
  const emptyText = ['just_pay', 'order_at_table'].includes(functionality) ? t('no_receipts_yet') : t('no_orders_yet');
  
  const [tab, setTab] = useState<'today'|'history'>('today');

  const filteredReceipts = receipts.filter(receipt => {
    const isFromToday = moment(receipt.date).isSame(Date.now(), 'day');
    return (tab === 'today' && isFromToday) || (tab === 'history' && !isFromToday);
  });
  
  return (
    <ReceiptListPopup isOpened={isShown} onClose={close} isReceiptListOrWalletPopup={true}>
        <TabSelector name='receipt_list' variant='line' value={tab} options={['today', 'history']} onChange={setTab}/>
        <Scrollbar maxHeight={orientation === 'landscape' ? 'calc(100% - 48px)' : '65vh'}>
          <ListContainer>
            {!filteredReceipts.length && <NoReceipts>{emptyText}</NoReceipts>}
            {filteredReceipts?.map(receipt => (
              <ReceiptItem
                key={'receipt-' + receipt.id}
                receipt={receipt}
                notifyUuid={notifyUuid}
                variant={tab}
                openTicket={openTicket}
              />
            ))}
          </ListContainer>
        </Scrollbar>
    </ReceiptListPopup>
  );
};