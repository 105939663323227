import React, { useContext, useEffect } from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { AppContext } from '../../context/AppContext';
import { Loading } from '../loading';
import { createRedsysRedirectPayment } from './redsys.service';
import { t } from 'i18next';
import { ButtonType, ButtonVariant } from '../buttons/enums';
import { Button } from '../buttons';
import { UserContext } from '../../context/auth/UserContext';
import { CashPaymentComponent } from '../payment/CashPaymentComponent';
import { useCommentAndAditionalInfo } from '../../shared/utils/useCommentAndAditionalInfo';

const RedsysRedirection = ({
    myTotalUpdated,
    restaurantCreditId
} : props) => {
    const {
        state: {
            restaurant: {
                bizumPromotionCents,
            },
            checkout: {
                paymentMethod,
                paymentMethods,
                paymentSplitType,
                orderAndPayItems,
                freeProducts,
                loyaltyDiscountCents,
                zeroSixSelectedVoucher,
                generalDiscountCents,
                productDiscountCents,
                codeCoupon,
                codeCouponCents,
                creditCents,
                credit,
                clientCommission,
                deliveryFeeCents,
                selectedSlot,
                redsysMerchantCode,
                redsysEnv,
                tip: {
                    amount,
                },
                selectedItems,
                selectedModifiers
            },
            addressData
        },
    } = useContext(AppContext);

    const base_amount = myTotalUpdated && myTotalUpdated - amount || 0
    const { user: { name, phone, email } } = useContext(UserContext);
    const [isLoading, setLoading] = React.useState(!paymentMethods.includes('Cash'));
    const [continueRedirect, setContinueRedirect] = React.useState(false);
    const [merchantParams, setMerchantParams] = React.useState("");
    const [signatureVersion, setSignatureVersion] = React.useState("HMAC_SHA256_V1");
    const [signature, setSignature] = React.useState("");
    const serviceCommision =  Math.floor(((base_amount) * clientCommission)/ 100);
    const bizumDiscountCents = paymentMethod === 'bizumpay' && (base_amount) >= 1500 ? bizumPromotionCents : 0;
    const zeroSixDiscountCents = zeroSixSelectedVoucher.code != "" ? (!zeroSixSelectedVoucher.is_percentage ? zeroSixSelectedVoucher.value * 100 : Math.round((((base_amount) *  zeroSixSelectedVoucher.value) / 100))) : 0;
    const amountMinusDiscounts = Math.max(base_amount - generalDiscountCents - productDiscountCents - codeCouponCents, 0);
    const amountMinusDiscountsAndCredit = Math.max(amountMinusDiscounts - creditCents, 0);
    const creditUsed = Math.min(creditCents, amountMinusDiscounts);
    const amountMinusAllDiscounts = Math.max(amountMinusDiscountsAndCredit, 0);
    const finalLoyaltyDiscount = Math.max(loyaltyDiscountCents > amountMinusAllDiscounts ? amountMinusAllDiscounts : loyaltyDiscountCents, 0)
    const finalAmount = Math.max(base_amount - finalLoyaltyDiscount - generalDiscountCents - productDiscountCents - codeCouponCents - creditCents - bizumDiscountCents - zeroSixDiscountCents, 0) + Number(serviceCommision) + amount + deliveryFeeCents;
    const restaurantId = Number(sessionStorage.getItem('restaurantId'));
    const tableNumber = sessionStorage.getItem("tableNumber") || "";

    const comment = useCommentAndAditionalInfo();

    const date = new Date().toISOString(). //ex: '2012-11-04T14:51:06.157Z'
      replace(/T/, '_').      // replace T with a space
      replace(/\..+/, '')   
    const orderId = date + '-' + restaurantId + '-' + tableNumber
    const paymentData = {
        captureDelayHours: 1,
        base_amount: base_amount,
        tip: Number(Number(amount).toFixed(2)),
        delivery_fee_cents: deliveryFeeCents || 0,
        split_type: paymentSplitType,
        name: name,
        user: null,
        client: localStorage.getItem("client_id") != 'undefined' ? localStorage.getItem("client_id") : null,
        amount: {
            currency: 'EUR',
            value: finalAmount
        },
        restaurant: restaurantId,
        table: tableNumber,
        orderId: orderId,
        items: selectedItems,
        modifiers: selectedModifiers,
        origin: window.location.origin,
        general_discount_cents: generalDiscountCents,
        product_discount_cents: productDiscountCents,
        code_coupon: codeCoupon?.code ? codeCoupon.code : null,
        code_coupon_cents: codeCouponCents,
        loyalty_discount_cents: finalLoyaltyDiscount,
        credit_cents: creditUsed,
        credit_id: credit,
        service_commission: serviceCommision,
        type: paymentMethod,
        orderAndPayItems: orderAndPayItems,
        freeProducts,
        comment: comment,
        client_phone: phone,
        client_name: name,
        client_email: email ? email : null,
        save_card_checked: false,
        selected_card: null,
        bizum_promotion_cents: bizumDiscountCents,
        device_token: localStorage.getItem('deviceToken'),
        zerosix_discount_cents: zeroSixDiscountCents,
        selected_zerosix_voucher_code: zeroSixSelectedVoucher.code != "" ? zeroSixSelectedVoucher.code : null,
        delivery_data: addressData,
        selected_slot: selectedSlot ? selectedSlot.id : null,
        restaurant_credit_id: restaurantCreditId
    }

    useEffect(() => {
        if (!paymentMethods.includes('Cash') || continueRedirect) {
            createRedsysRedirectPayment(paymentData)
            .then((result: any) => {
                const encodedSignature = encodeURI(result.ds_signature)
                setMerchantParams(result.ds_merchant_parameters)
                setSignature(encodedSignature)
                setSignatureVersion(result.ds_signature_version)
                setLoading(false)
            })
            .catch((e) => {
                setLoading(false)
                toast.error(t('adyen_checkout_error'));
                console.log("e", e)
            }); 
        }    
      }, [continueRedirect]);

    const deleteLocalStorageItemsInfo = () => {
        localStorage.removeItem("orderItems");
        localStorage.removeItem("orderItems_tip");
        localStorage.removeItem("orderItems_restaurant");
        localStorage.removeItem("orderItems_total");
        localStorage.removeItem("orderItems_timestamp");
        localStorage.removeItem("orderItems_productDiscount");
        localStorage.removeItem("orderItems_productDiscount_restaurant")
        localStorage.removeItem("orderItems_productDiscount_timestamp")
    }

    return (
        <div>
            {isLoading ?
                <Loading parentComponent='RedsysRdirection' loaderStyle={{height: '36px', width: '36px'}} style={{height: '44px', margin: '10px'}}/>
            :
            paymentMethods.includes('Cash') && !continueRedirect ? 
                <div style={{display: 'flex', flexDirection: 'column', gap: "10px"}}>
                    <CashPaymentComponent
                            myName={name}
                            amount={myTotalUpdated && myTotalUpdated - amount || 0}
                            loyaltyDiscount={loyaltyDiscountCents}
                            tip={amount}
                            howPaySelected={paymentSplitType}
                            phoneForCollect={phone}
                            nameForCollect={name}
                            bizumPromotionCents={bizumPromotionCents}
                            restaurantCreditId={restaurantCreditId}
                            setLoading={setLoading}
                        /> 
                        <Button
                            title={t('continue with other payment methods')}
                            handleClick={() => {setLoading(true); setContinueRedirect(true)}}/>
                </div>
            :

                (!merchantParams || !signature || !signatureVersion) ? 
                    <div>Error</div>
                :
                    <form name="from" action={redsysMerchantCode ==="999008881" || redsysEnv === "test" ? "https://sis-t.redsys.es:25443/sis/realizarPago" : "https://sis.redsys.es/sis/realizarPago"} method="POST">
                        <input type="hidden" name="Ds_SignatureVersion" value={signatureVersion}/>
                        <input type="hidden" name="Ds_MerchantParameters" value={merchantParams}/>
                        <input type="hidden" name="Ds_Signature" value={signature}/>	
                        <Button
                            handleClick={() => deleteLocalStorageItemsInfo()}
                            title={t("pay")}
                            variant={ButtonVariant.Primary}
                            type={ButtonType.Submit}
                            lowercase={true}
                            fontWeight={600}
                        />
                    </form>
            }
        </div>
    );
}

export default RedsysRedirection;

type props = {
    myTotalUpdated: number
    restaurantCreditId?: number
};
