import * as React from 'react';
import { toast } from 'react-toastify';
import { Loading } from '../loading';
import { v4 as uuidv4 } from 'uuid';
declare const getPayButton: any;
declare const getCardInput: any;
declare const getExpirationInput: any;
declare const getCVVInput: any;

type redsysCardComponentProps = {
  redsysIntiatePayment: (paymentToken: string, sessionId?: string, saveCardChecked?: boolean, selectedSavedCard?: any, redsysOrder?: string, googlePayToken?: string, applePayData?: string) => void
  setLoading: (value: boolean) => void
  isLoading: boolean,
  merchantCode: string,
  merchantTerminal: string,
  redsysEnv: string,
};

export default function RedsysCardComponent({redsysIntiatePayment, isLoading, setLoading, merchantCode, merchantTerminal, redsysEnv} : redsysCardComponentProps) {
  const [isMounted, setIsMounted] = React.useState(true);
  const [scriptLoaded, setScriptLoaded] = React.useState(false);
  const orderRef = React.useRef("");
  const buttonStyle = "color: rgb(64, 64, 64); background-color: rgb(252, 252, 252); border: 2px solid rgb(64, 64, 64); border-radius: 9999px; width: 100%; height: 44px; box-shadow: rgba(0, 0, 0, 0.094) 0px 2px 4px, rgba(0, 0, 0, 0.07) 0px 3px 12px; font-size: 1rem; font-weight: 600;"
  const inputStyle = "outline: none; gap: 5px; width: auto; border: none; font-family: 'Poppins', sans-serif; font-size: 1rem; color: #404040; background-color: #FFF; height: 40px;"
  const insideInputStyle = "font-family: 'Poppins', sans-serif; font-size: 1rem; color: #404040;"
  const restaurantId = Number(sessionStorage.getItem('restaurantId'));
  const env = merchantCode === "999008881" || redsysEnv === "test" ? "TEST" : "PROD"
  React.useEffect(() => {
    return () => {
        setIsMounted(false); // Set the flag to false when the component is unmounted
    };
  }, []);

  React.useEffect(() => {
    if (window && document) {
      const script = document.createElement('script')
      const body = document.getElementsByTagName('body')[0]
      script.src = env == "TEST" ? 'https://sis-t.redsys.es:25443/sis/NC/sandbox/redsysV3.js' : 'https://sis.redsys.es/sis/NC/redsysV3.js'
      body.appendChild(script)
      script.addEventListener('load', () => {
        setScriptLoaded(true);
      })
    }
  }, [])
  
/* 
  React.useEffect(() => {
    if (scriptLoaded && isMounted &&  document.getElementById('boton')?.innerHTML === "") {
      getPayButton('boton', buttonStyle, 'Pagar', merchantCode, merchantTerminal, pedido());
    }
  }, [document.getElementById('boton')?.innerHTML, scriptLoaded, isMounted]); */

  React.useEffect(() => {
    if(isMounted && scriptLoaded){
      loadRedsysComponent()     
      const element = document.getElementById('token');
      element?.addEventListener("change", (e) => updateValue(e));
    }
  }, [isMounted, scriptLoaded]);

  function updateValue(e: any) {
    setLoading(true)
    const targetIdOper = e.target.value
    const boton_element = document.getElementById('boton')
    if ((!targetIdOper || targetIdOper == "-1") && boton_element){
      boton_element.innerHTML = "";      
      setLoading(false)
      toast.error(`${"Configuration Error - Try again"}`);
    } else {
      redsysIntiatePayment(e.target.value, undefined, undefined, undefined, orderRef.current);
    }
  }

  function pedido() {
      const order_full = `${restaurantId}YP${uuidv4().replace('-', '')}`
      const order = order_full.slice(0, 12)
      orderRef.current = order;
      return order;
  }

  const loadRedsysComponent = () => {
      getCardInput('card-number', inputStyle, "Número de tarjeta", insideInputStyle);
      getExpirationInput('card-expiration', inputStyle, "MM/AA");
      getCVVInput('cvv', inputStyle, "CVC");
      getPayButton('boton', buttonStyle, 'Pagar', merchantCode, merchantTerminal, pedido());

  }
  

  return (
  <div style={{fontSize: '12px'}}>
    <div style={{display: 'flex', width: '100%', border: "2px solid #DDD", borderRadius: "10px", height: '46px'}}>
      <div style={{display: 'flex', width: '65%'}}>
        <div id="card-number" style={{width: '100%', margin: '-5px -8px -8px 0px'}}/>
      </div>
      <div style={{display: 'flex', width: '35%'}}>
        <div id="card-expiration" style={{width: '65%', margin: '-6px -8px -8px 0px'}}></div>
        <div id="cvv" style={{width: '35%', margin: '-6px -8px -8px 0px'}}></div>
      </div>
    </div>
    {isLoading ? 
    <Loading parentComponent='RedsysCardComponent' loaderStyle={{height: '36px', width: '36px'}} style={{height: '44px', margin: '10px'}}/> : 
    <div style={{width: '100%', height: '65px', display: 'flex', justifyContent: 'center'}}>
      <div id="boton" style={{width: '90%', marginTop: '10px'}}></div>
    </div>}
    <form name="datos">
      <input type='hidden' id="token"></input>
      <input type='hidden' id="errorCode"></input>
    </form>    
  </div>
  )
}
