import { Component, ReactNode } from "react";
import { postError } from "./postError";
import { UnexpectedError } from "../../pages/not-found/unexpected-error";
import { LayoutContainer } from "../layout";
import { ThemeProvider } from "styled-components";
import { baseTheme } from "../themes";
interface Props {
  children?: ReactNode;
}

interface State {
  hasError: boolean;
}

class ErrorBoundary extends Component<Props, State> {
  public state: State = {
    hasError: false
  };

  public static getDerivedStateFromError(): State {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  public componentDidCatch(error: Error) {
    postError(error);
  }

  public render() {


    if (this.state.hasError) {
      return (
        <ThemeProvider theme={baseTheme}>
          <LayoutContainer>
            <UnexpectedError/>
          </LayoutContainer>
        </ThemeProvider>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
