import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Request as RegisterRequest } from "../../api/register/Register.api";
import { Request as LoginRequest, Request } from "../../api/auth/Login.api";
import { eventTrack } from '../../../useGaTracker';
import { UserContextDispatchContext } from '../../context/auth/UserContext';
import { useAuthContextDispatchContext } from '../../context/auth/AuthContext';
import { usePopupNavigation } from './usePopupNavigation';
import { PopupType } from '../../context/popups.enum';
import { PopupContext } from '../../context/PopupContext';
import { useNavigate } from 'react-router-dom';
import { postError } from './postError';

type Props = {
  setIsSending?: React.Dispatch<React.SetStateAction<boolean>>;
}

export const useRegister = ({setIsSending} : Props) => {
  const {t} = useTranslation();
  const paymentId = sessionStorage.getItem("paymentId") || "";
  const restaurantId = sessionStorage.getItem('restaurantId') || "";
  const restaurantUuid = sessionStorage.getItem("restaurantUuid") || "";
  const restaurantGroupUuid = sessionStorage.getItem('restaurantGroupUuid') || '';
  const tableNumber = sessionStorage.getItem('tableNumber') || "";
  const { setToken } = useAuthContextDispatchContext();
  const [isLoading, setIsLoading] = useState(false);
  const { popup } = useContext(PopupContext);
  const {goToPopup, goBackPopup} = usePopupNavigation();
  const navigate = useNavigate();
  const { updateUser } = useContext(UserContextDispatchContext);
  const paramsProxy: any = new Proxy(new URLSearchParams(window.location.search), {
    get: (searchParams, prop: any) => searchParams.get(prop),
  });

  const onRegisterSubmit = (values: any, { setSubmitting }: any) => {
    setIsLoading(true)
    const obj = Object.assign(values, { username: values.email.toLowerCase(), paymentId, restaurantId, tableNumber, restaurantGroupUuid });
    RegisterRequest.register(obj)
      .then(() => {
        onRegisterSuccess(values, setSubmitting);
      })
      .catch((error: any) => {
        postError(error)
        onFailure(error);
        setSubmitting(false);
        setIsLoading(false);
      });
  };

  const onRegisterSuccess = (values: any, setSubmitting: any) => {
    eventTrack(`registration mail ${values.from}`, `registration-mail-${values.from}`, `Registration using mail from ${values.from}`)
    toast.success(t('registration_successful'));
    onLoginSubmit(values, { setSubmitting }, true);
  };

  const onLoginSubmit = (values: any, { setSubmitting }: any, isFirstLogin?: boolean) => {
    setIsLoading(true);

    LoginRequest.login(values.email.toLowerCase(), values.password, paymentId, restaurantId)
      .then((data: any) => {
        onLoginSuccess(data, "internal", values.from, isFirstLogin);

      })
      .catch((error: any) => onFailure(error))
      .finally(() => {
        setSubmitting(false);
        setIsLoading(false);
        setIsSending && setIsSending(false);
      });
  };

  const onLoginSuccess = async (response: any, type: any, from: string, isFirstLogin?: boolean) => {
    const { data } = response;
    eventTrack(`Login using ${type} from ${from}`, `Login using-${type} from ${from}`, `Login using ${type} from ${from}`);
    setToken(data.token, type);
    const fcmToken = localStorage.getItem('fcmToken') || null;
    let deviceId = localStorage.getItem('deviceId');
    if (!deviceId) {
        deviceId = localStorage.getItem('androidDeviceId');
    }

    if (data.token && fcmToken && deviceId) {
      Request.postFcmToken(data.token, type)
    }

    if (from === 'pop-up_save-card') {
      sessionStorage.setItem("first_card", data.first_card);

      setTimeout(() => {
        goToPopup(PopupType.CardSaved);
      }, 10);

      return;
    }

    if (!data.is_verified && type == 'internal') {

      Request.sendVerificationMail(restaurantUuid, restaurantGroupUuid, data.token, type).then(() => {
        if (popup.current === PopupType.Register) {
          goToPopup(PopupType.VerificationEmail);
        } else
          navigate('/verify_account');
        }
      ).catch((err: any) => {
        postError(err)
        console.log(err)
      });
      return;
    }

    if (data.is_verified){
      const isVerified = data.is_verified;
      updateUser({ isVerified });
    }

    if (popup.current === PopupType.Register) {
      goBackPopup();
    }

    if (isFirstLogin) {
      setTimeout(() => {
        goToPopup(PopupType.Welcome);
      }, 1000);
    }

    if (paramsProxy.returnPath?.length > 0) {
      //console.log('returnPath', paramsProxy.returnPath);
      navigate(paramsProxy.returnPath, { replace: true });
    }
  };

  const onFailure = (error: any) => {
    postError(error)
    let message = error?.response?.data?.message || error?.response?.data
    if(message === 'Invalid credentials'){
      message = 'invalid_credentials_login'
    } else if(message === 'Unexpected error'){
      message = 'unexpected_error_login'
    }
    setIsLoading(false);
    setIsSending && setIsSending(false);
    toast.error(t( message || error?.message || "no account found"));
  };

  return { onRegisterSubmit, onLoginSubmit, onLoginSuccess, onFailure, isLoading }
};
