import { Icon, IconType } from '../../elements/icons';
import { Dispatch, SetStateAction, useContext, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useLocation, useNavigate } from 'react-router-dom';
import {useTranslation } from 'react-i18next';
import { AppContext } from '../../context/AppContext';
import { PaymentMethodsBoxes, PaymentMethodsContainer, PaymentMethodsGlobalContainer, TotalAmountPurchase } from './components/PaymentMethodsBoxes';
import { PaymentMethod } from '../../context/app';
import { detectIOS } from './functions/detectIOS';
import { eventTrack } from '../../../useGaTracker';
import { Button } from '../../elements/buttons';
import { calculateEuros } from './functions/calculatePrices';
import { usePopupNavigation } from '../../shared/utils/usePopupNavigation';
import { PopupType } from '../../context/popups.enum';
import { BizumDiscountBox } from '../../campaigns/bizum/StylesBizumCampaign';
import BizumDiscountBanner from '../../campaigns/bizum/BizumDiscountBanner';
import MasterC2PBanner from '../../campaigns/masterC2P/MasterC2PBanner';
import { useFeatureIsOn } from '@growthbook/growthbook-react';
import { GB_FEATS } from '../../growthbook/features';
import { PopupContext } from '../../context/PopupContext';
import { UserContext } from '../../context/auth/UserContext';
import { postError } from '../../shared/utils/postError';
import { EMAIL_REGEX } from '../../shared/constants/regex';
import { getEmailConfig, getNameConfig, getPhoneConfig, validatePhoneNumber } from '../../shared/utils/clientInputsLogic';
import { PaymentGatewayHandler } from './PaymentGatewayHandler';
import { DashedSeparator } from '../payment-successful/PaymentSuccessfulContainers';
import { useViewport } from '../../context/ViewportContext';

const NO_PAYMENT_GATEWAY_METHOD = ['wallet', 'zero_payment'];

const EVENTS = {
    applepay:      '05_button_pay_01_applePay',
    card:          '05_button_pay_02_card',
    bizumpay:      '05_button_pay_03_bizum',
    C2P:           '05_button_pay_04_C2P',
    paywithgoogle: '05_button_pay_04_googlePay',
};

type Props = {
    bizumDiscount?:     boolean
    disabled:           boolean
    event?:             any
    myTotalUpdated:     number
    setDisabled:        Dispatch<SetStateAction<boolean>>
    onOutOfStock?:      any
    setPaymentResult?:  any
    setFlow?:           any
    setRedsysChallenge?: any
    restaurantCreditId?: number
    setOnBlurMessagesOn?: Dispatch<SetStateAction<boolean>>
};

const PaymentMethods = ({
    bizumDiscount,
    disabled,
    event,
    myTotalUpdated,
    setDisabled,
    onOutOfStock,
    setPaymentResult,
    setRedsysChallenge,
    setFlow,
    restaurantCreditId,
    setOnBlurMessagesOn
} : Props) => {
    const {
        state: {
            tpvId,
            restaurant: {
                isMockup,
                bizumPromotionCents,
                masterC2pPromotionCents,
                minMasterC2pPromotionAmountCents,
                country,
                request_client_email,
                request_client_name,
                request_client_phone,
                fast_checkout
            },
            checkout: {
                remaining,
                paymentMethod,
                paymentSplitType,
                otherPayments,
                myTotal,
                loyaltyDiscountCents,
                tip: {
                    amount,
                    selected
                },
                deliveryFeeCents,
                moneiPay,
                moneiAccountId,
                paymentMethods,
                walletPaymentMethods,
                checkoutFlow,
                clientCommission,
                zeroSixSelectedVoucher,
                generalDiscountCents,
                productDiscountCents,
                codeCouponCents,
                creditCents
            },
        },
        dispatch,
    } = useContext(AppContext);

    const { orientation } = useViewport();

    const { user: { name, phone, email } } = useContext(UserContext);

    const { popup } = useContext(PopupContext);

    const {t} = useTranslation();
    const location = useLocation();
    const { isMobile } = useViewport();

    const {goToPopup, closePopup} = usePopupNavigation();

    const navigate = useNavigate();
    const isIOS = detectIOS();
    const showFinalTipsPopup = useFeatureIsOn(GB_FEATS.PPUP_BIG_TIPS);

    const functionality = sessionStorage.getItem('functionality') || "";
    const customerNameStorage = sessionStorage.getItem('customerName');
    const customerVATStorage = sessionStorage.getItem('customerVAT');

    const state = location.state as { from: string };
    const from = state ? state.from : undefined;
    const path = location.pathname;
    const tpv = tpvId ? tpvId : JSON.parse(sessionStorage.getItem('tpvId') || '{}');

    const isTipsPopupIncluded = popup.history.some((popup) => popup.popup === PopupType.TipsBig);
    const isEventCatalog = path === '/event_catalog';
    const invalidDeliveryFee = Number.isNaN(deliveryFeeCents);

    const serviceCommision = Math.floor(((myTotal) * clientCommission) / 100);
    const bizumDiscountCents = paymentMethod === 'bizumpay' && (myTotal - amount) >= 1500 ? bizumPromotionCents : 0;
    const zeroSixDiscountCents = zeroSixSelectedVoucher.code != "" ? (!zeroSixSelectedVoucher.is_percentage ? zeroSixSelectedVoucher.value * 100 :  Math.round((((myTotal - amount) *  zeroSixSelectedVoucher.value) / 100))) : 0
    const discounts = generalDiscountCents + productDiscountCents + codeCouponCents + creditCents + loyaltyDiscountCents + bizumDiscountCents + zeroSixDiscountCents;
    const subtotal = myTotal - amount;
    const total = Math.max(subtotal + serviceCommision - discounts, 0) + deliveryFeeCents + amount;

    const isWalletRecharge = path === '/wallet' || paymentSplitType === 'recharge';
    const isTotalDiscounted = !isWalletRecharge && ((discounts > 0 || tpv === 85) && total === 0);
    const isOnlyWallet = !isWalletRecharge && paymentMethods.length > 0 && paymentMethods.every(method => method === 'Wallet');
    const isCashTotem = functionality === 'kiosk' && paymentMethods.includes("Cash");
    
    const skipGateway = NO_PAYMENT_GATEWAY_METHOD.includes(paymentMethod)
        || (isCashTotem && paymentMethod === 'cash');

    const [changePayButton, setChangePayButton] = useState(false);
    const [showPaymentButton, setShowPaymentButton] = useState(true);
    
    const paymentMethodsToCheck = isWalletRecharge ? walletPaymentMethods : paymentMethods;
    
    const buttonTitle = (isMockup || invalidDeliveryFee)
        ? t("pay")
        : isCashTotem
        ? t('pay_at_counter')
        : !changePayButton
        ? t('continue')
        : `${t("pay") + ' ' + calculateEuros((myTotal + amount).toString())}€`;

    const checkUserData = ({name, phone, email}: Record<string, string>) => {
        const { isEmailRequired } = getEmailConfig(request_client_email, path);
        const { isNameRequired } =  getNameConfig(request_client_name, path, paymentSplitType);
        const { isPhoneRequired } = getPhoneConfig(request_client_phone, path);

        return (!isNameRequired || !!name)
            && (!isPhoneRequired || validatePhoneNumber(`+${phone}`))
            && (!isEmailRequired || EMAIL_REGEX.test(email))
    };

    const selectMethod = (value: PaymentMethod, setOnBlurMessagesOn?: Dispatch<SetStateAction<boolean>>) => {

        eventTrack(EVENTS[value as keyof typeof EVENTS]);

        dispatch({
            type: 'CHANGE_PAYMENT_METHOD',
            payload: value
        });

        !isMobile && setOnBlurMessagesOn && setOnBlurMessagesOn(true)

    };

    const handleButtonPay = () => {
        eventTrack("Continue from PaymentMethods");

        if (country === "PT" && myTotal > 99900 && (!customerNameStorage || !customerVATStorage)) {
            setDisabled(true);
        } else if (fast_checkout){
            setShowPaymentButton(false);
            setChangePayButton(true);
        } else if (isMockup) {
            closePopup().then(() => {
                navigate(`/payment-successful`, {state: {
                    myTotal,
                    name,
                    leftTopay: remaining,
                    payments: otherPayments,
            }});
            })
            .catch((err: any) => {
                postError(err)
                console.log(err)
            });
        } else if (!checkUserData({ name, phone, email }) && !isWalletRecharge) {
            goToPopup(PopupType.PaymentDetail, {keepDataOnBack: true});
        } else if (selected !== "" || !showFinalTipsPopup || from === 'paymentError') {
            setShowPaymentButton(false);
            setChangePayButton(true);
        } else if (
            showFinalTipsPopup &&
            !event &&
            !isEventCatalog &&
            !isWalletRecharge &&
            orientation === 'portrait' &&
            !skipGateway
        ) {
            goToPopup(PopupType.TipsBig);
        } else {
            setShowPaymentButton(false);
            setChangePayButton(true);
        }
    };

    useEffect(() => {
        if (!checkUserData({name, phone, email})) {
            setShowPaymentButton(true);
        } else if (skipGateway) {
            setShowPaymentButton(false);
            setChangePayButton(true);
        } else if (!paymentMethod) {
            setShowPaymentButton(true);
        } else if (!isTipsPopupIncluded && showFinalTipsPopup && orientation === 'portrait') {
            setShowPaymentButton(!changePayButton || isMockup);
        } else {
            setShowPaymentButton(false);
        }

        sessionStorage.setItem("paymentMethod", paymentMethod);
        const isPaymentMethodSelected = !!(paymentMethod)

        setDisabled(!isPaymentMethodSelected);
    }, [paymentMethod, name, phone, email]);

    useEffect(() => {
        if (customerNameStorage && customerVATStorage) {
            setDisabled(false);
        }
        else if (
            ['order_and_collect', 'kiosk'].includes(functionality) && !phone ||
            functionality === 'kiosk' && !email
        ) {
            setDisabled(true);
        }
    }, [customerNameStorage, customerVATStorage, phone]);

    useEffect(() => {
        if (isTotalDiscounted) {
            selectMethod('zero_payment');
        } else if (isOnlyWallet) {
            selectMethod('wallet');
        } else if(isCashTotem) {
            selectMethod('cash');
        } else if((NO_PAYMENT_GATEWAY_METHOD.includes(paymentMethod))) {
            selectMethod('');
        };
    }, [isTotalDiscounted, isOnlyWallet])

    return (
        <div>
            {(orientation === 'portrait' && !isTotalDiscounted && !isCashTotem) &&
                <>
                    <h3 style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                        {t('payment pending')}
                        <TotalAmountPurchase>{calculateEuros(total)}€</TotalAmountPurchase>
                    </h3>
                    <DashedSeparator/>
                    <h3>
                        {t('choose payment method')}
                    </h3>
                </>
            }
            <PaymentMethodsGlobalContainer className={paymentMethod}>
                {(!isTotalDiscounted && !isOnlyWallet && !isCashTotem) &&
                    <PaymentMethodsContainer>
                        {paymentMethodsToCheck.includes("Apple Pay") && isIOS &&
                            <PaymentMethodsBoxes
                                selected={paymentMethod === 'applepay' ? true : false}
                                onClick={() => selectMethod('applepay', setOnBlurMessagesOn)}
                            >
                                <Icon type={IconType.ApplePay} size={20}/>
                            </PaymentMethodsBoxes>
                        }
                        {checkoutFlow !== 'EVENTS' && paymentMethodsToCheck.includes("C2P") &&
                            <PaymentMethodsBoxes
                                selected={paymentMethod === 'C2P' ? true : false}
                                onClick={() => selectMethod('C2P', setOnBlurMessagesOn)}
                            >
                                <Icon type={IconType.BankCard} />
                                <span>{t('Card')}</span>
                            </PaymentMethodsBoxes>
                        }
                        {moneiPay && moneiAccountId != "" && paymentMethodsToCheck.includes("Bizum") &&
                            <PaymentMethodsBoxes
                                selected={paymentMethod === 'bizumpay' ? true : false}
                                onClick={() => selectMethod('bizumpay', setOnBlurMessagesOn)}
                            >
                                <Icon type={IconType.BizumColor} size={18}/>
                            </PaymentMethodsBoxes>
                        }
                        {paymentMethodsToCheck.includes("Gpay") &&
                            <PaymentMethodsBoxes
                                selected={paymentMethod === 'paywithgoogle' ? true : false}
                                onClick={() => selectMethod('paywithgoogle', setOnBlurMessagesOn)}
                            >
                                <Icon type={IconType.GooglePay} size={20}/>
                            </PaymentMethodsBoxes>
                        }
                        {paymentMethodsToCheck.includes("Card") &&
                            <PaymentMethodsBoxes
                                selected={paymentMethod === 'card' ? true : false}
                                onClick={() => selectMethod('card', setOnBlurMessagesOn)}
                            >
                                <Icon type={IconType.BankCard} />
                                <span>{t('card')}</span>
                            </PaymentMethodsBoxes>
                        }
                        {paymentMethodsToCheck.includes("Cash") &&
                            <PaymentMethodsBoxes
                            selected={paymentMethod === 'cash' ? true : false}
                            onClick={() => selectMethod('cash', setOnBlurMessagesOn)}
                        >
                            <Icon type={IconType.In_Store} />
                            <span>{t('in_store')}</span>
                        </PaymentMethodsBoxes>
                        }
                    </PaymentMethodsContainer>
                }
                {!isMockup && (!paymentMethod || showPaymentButton) && bizumPromotionCents > 0 &&
                    <div style={{marginBottom: '16px'}}>
                        <BizumDiscountBanner fixed />
                    </div>
                }
                {bizumDiscount && !isMockup && paymentMethod === 'bizumpay' && (myTotal - amount) >= 1500  &&
                    <BizumDiscountBox onClick={() => goToPopup(PopupType.PaymentDetail, {keepDataOnBack: true})}>
                        {t("Bizum discount of -1€ applied!", {discount: `${parseInt(calculateEuros((bizumPromotionCents)))}`})}
                        <div style={{display: 'flex', alignItems: 'center'}}>
                            <div>{t('See')}</div>
                            <div style={{fontSize: "20px"}}>{">"}</div>
                        </div>
                    </BizumDiscountBox>
                }
                {!isMockup && masterC2pPromotionCents > 0 && (myTotal - amount) >= minMasterC2pPromotionAmountCents &&
                    <div style={{marginBottom: '16px'}}>
                        <MasterC2PBanner fixed />
                    </div>
                }
                {(invalidDeliveryFee || showPaymentButton) ?
                    <Button
                        title={buttonTitle}
                        disabled={disabled}
                        $disabled={invalidDeliveryFee}
                        handleClick={handleButtonPay}
                        disabledClick={() => toast.error(t('calculate_delivery_fee_error'))}
                    />
                :
                    <PaymentGatewayHandler
                        myTotalUpdated={myTotalUpdated}
                        event={event}
                        setFlow={setFlow}
                        setPaymentResult={setPaymentResult}
                        onOutOfStock={onOutOfStock}
                        restaurantCreditId={restaurantCreditId}
                        setRedsysChallenge={setRedsysChallenge}
                        disabled={disabled}
                    />
                }
            </PaymentMethodsGlobalContainer>
        </div>
    );
};

export default PaymentMethods;
