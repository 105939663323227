import styled from 'styled-components';

export const HomeOuterContainer = styled.div<Home>`
    display: flex;
    flex-direction: column;
    background-color: ${({theme}) => theme.background};
    min-height: var(--dvh); /* fallback */
    min-height: 100svh;
    height: ${props => props.orderAndPay ? "" : 'var(--dvh)'}; /* fallback */
    height: ${props => props.orderAndPay ? "" : '100svh'};

    .headerBarDesktop {
        background-color: ${({theme}) => theme.yumminn_blue};
        color: ${({theme}) => theme.text_light};
        padding: 16px 24px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        h4 {
            border-left: 2px solid currentColor;
            padding-left: 16px;
            margin: 0 0 0 16px;
        }

        > button {
            display: none;
        }
    }

    @media (orientation: landscape){
        &:has(> #headerContainer) > *:not(#headerContainer) {
            margin-left: 68px;
            max-width: calc(100% - 68px);
        }
    }
`;

export const LoaderContainer = styled.div`
    background-color: ${({theme}) => theme.background};
    height: var(--dvh); /* fallback */
    height: 100svh;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
`;

type Home = {
    orderAndPay?: boolean
    showHeaderBar?: boolean
}
