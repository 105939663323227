import styled from 'styled-components';

const PersonalInfoWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: clamp(8px, 1.5vh, 24px);
    color: ${({theme}) => theme.text};
    margin: clamp(8px, 1vh, 16px) 24px clamp(64px, 8vh, 72px);
    flex: 1;
    
    @media (min-width: 768px) {
        margin: 24px 16px 16px;
        gap: 24px;
    }

    .initials {
        align-self: center;
    }

    > b {
        font-size: ${({theme}) => theme.font_sm};
        font-weight: 600;
        text-align: center;

        @media (min-width: 768px) {
            font-size: ${({theme}) => theme.font_md};
        }
    }
`;

const PersonalInfoFooter = styled.div`
    border-radius: 9999px;
    background-color: ${({theme}) => theme.primary_10};
    font-size: ${({theme}) => theme.font_xs};
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    padding: 8px;
    color: ${({theme}) => theme.text_dark};
`;

export { PersonalInfoWrapper, PersonalInfoFooter}