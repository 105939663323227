import React, { useContext, useEffect } from 'react';
import { AppContext } from '../../../context/AppContext';
import { usePopupNavigation } from '../../../shared/utils/usePopupNavigation';
import { PopupType } from '../../../context/popups.enum';
import { eventTrack } from '../../../../useGaTracker';
import { EventCatalogContext } from '../../../context/EventCatalogContext';

type Props = {
  creditApplied?: boolean
  setDiscountApplied: React.Dispatch<React.SetStateAction<boolean>>
};

export const usePaymentDetails = ({ creditApplied, setDiscountApplied }: Props) => {
  const {
    state: {
      tpvId,
      restaurant: {
        bizumPromotionCents,
      },
      checkout: {
        loyaltyDiscountCents,
        paymentMethod,
        deliveryFeeCents,
        myTotal,
        generalDiscountCents,
        productDiscountCents,
        codeCouponCents,
        creditCents,
        zeroSixSelectedVoucher,
        clientCommission,
        tip: {
          amount: tipAmount,
        },
      },
    },
    dispatch,
  } = useContext(AppContext);

  const { state: { totalPriceCents: eventsPriceCents } } = useContext(EventCatalogContext);

  const { goToPopup } = usePopupNavigation();

  const path = location.pathname;
  const isEventCatalog = path === '/event_catalog';

  const serviceCommision = Math.floor(((myTotal) * clientCommission) / 100);
  const subtotal = myTotal - tipAmount;
  const zeroSixDiscountCents = zeroSixSelectedVoucher.code != "" ? (!zeroSixSelectedVoucher.is_percentage ? zeroSixSelectedVoucher.value * 100 :  Math.round(((subtotal *  zeroSixSelectedVoucher.value) / 100))) : 0 
  const bizumDiscountCents = paymentMethod === 'bizumpay' && subtotal >= 1500 ? bizumPromotionCents : 0;
  const discounts = generalDiscountCents + productDiscountCents + codeCouponCents + creditCents + loyaltyDiscountCents + bizumDiscountCents + zeroSixDiscountCents;
  const total = Math.max(subtotal + serviceCommision - discounts, 0) + deliveryFeeCents + tipAmount;
  const tpv = tpvId ? tpvId : JSON.parse(sessionStorage.getItem('tpvId') || '{}');

  const isTotalDiscounted = ((creditApplied || discounts > 0 || tpv === 85) && total === 0);

  const goToPayment = async () => {
    eventTrack("Continue from PaymentDetails", "Continue from PaymentDetails", "Continue from PaymentDetails");

    goToPopup(PopupType.PaymentMethods);
    dispatch({
      type: 'UPDATE_ROADMAP',
      payload: {
        step: 2,
        progress: 4,
      },
    })
  };

  useEffect(() => {
    loyaltyDiscountCents === 0 && setDiscountApplied(false);
  }, [loyaltyDiscountCents]);

  useEffect(() => {
    if (isEventCatalog) {
      dispatch({
        type: 'UPDATE_CHECKOUT',
        payload: { myTotal: eventsPriceCents }
      });
    }
  }, [eventsPriceCents]);

  return {
    isTotalDiscounted,
    goToPayment,
  };
};
