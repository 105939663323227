import styled, { css } from "styled-components";

const ToggletipWrapper = styled.div<Children>`
  position: relative;
  width: fit-content;
  z-index: ${({isVisible}) => isVisible ? 999999 : ''};
  isolation: isolate;
`;

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

const toggletipPositions = {
  top: () => css`
    top: 0%;
    left: 50%;
    translate: -50% 0;
  `,
  bottom: () => css`
    top: 100%;
    left: 50%;
    translate: -50% 0;
  `,
  right: () => css`
    top: 50%;
    left: 100%;
    translate: 0 -50%;
  `,
  left: () => css`
    top: 50%;
    right: 100%;
    translate: 0 -50%;
  `,
}

const arrowPosition = {
  top: css`
    translate: -50% -140%;
    transform: skew(10deg, 10deg);
    border-radius: 4px 0;
  `,
  bottom: css`
    translate: -50% 40%;
    transform: skew(10deg, 10deg);
    border-radius: 4px 0;
  `,
  right: css`
    translate: 40% -50%;
    transform: skew(-10deg, -10deg);
    border-radius: 0 4px;
  `,
  left: css`
    translate: -140% -50%;
    transform: skew(-10deg, -10deg);
    border-radius: 0 4px;
  `,
}

const ToggletipChildren = styled.div<Children>`
  display: flex;
  background-color:  ${({isVisible, overlay, theme}) => isVisible && overlay ? theme.dark_overlay : ''};
  box-shadow: ${({isVisible, overlay, theme}) => isVisible && overlay ? theme.shadow_overlay : ''};
  transition: 0.2s ease-in-out;

  & > * {
    transition: scale 0.2s ease-in-out;
    scale: ${({isVisible, overlay}) => isVisible && overlay ? 1.05 : 1};
  }
`;

const ToggletipContainer = styled.div<Arrow>`
  position: absolute;
  filter: ${({theme}) => theme.shadow_drop_md};
  scale: ${({isVisible}) => isVisible ? 1 : 0};
  opacity: ${({isVisible}) => isVisible ? 1 : 0};
  transition-property: scale, opacity;
  transition-duration: 0.2s;
  transition-timing-function: ease-in-out;
  ${({position}) => toggletipPositions[position]}

  &::before {
    position: absolute;
    content: '';
    width: 24px;
    height: 24px;
    rotate: 45deg;
    background-color: ${({background, theme}) => background || theme.card_bg};
    ${({position}) => arrowPosition[position]}
  }
`;

const positions = {
  top: css`
    bottom: 100%;
    margin-bottom: 20px;
  `,
  bottom: css`
    top: 100%;
    margin-top: 20px;
  `,
  right: css`
    left: 100%;
    margin-left: 20px;
  `,
  left: css`
    right: 100%;
    margin-right: 20px;
  `,
}

const alignments = {
  start: (position: Toggletip['position']) => css`
    transform: translate(${['top', 'bottom'].includes(position) ? '-40px, 0' : '0, -40px'});
  `,
  center: (position: Toggletip['position']) => css`
    transform: translate(${['top', 'bottom'].includes(position) ? '-50%, 0' : '0, -50%'});
    `,
  end: (position: Toggletip['position']) => css`
    transform: translate(${['top', 'bottom'].includes(position) ? 'calc(-100% + 40px), 0' : '0, calc(-100% + 40px)'});
  `,
}

const ToggletipContent = styled.div<Toggletip>`
  color: ${({theme}) => theme.text};
  background: ${({background, theme}) => background || theme.card_bg};
  border-radius: ${({theme}) => theme.radius_md};
  padding: 16px;
  position: absolute;
  transition: translate 0.1s ease-in-out;
  ${({position}) => positions[position]}
  ${({position, alignment}) => alignments[alignment](position)}
`;

type Children = {
  isVisible: boolean
  overlay?: boolean
}

type Toggletip = {
  position: 'top' | 'bottom' | 'right' | 'left'
  alignment: 'start' | 'center' | 'end'
  background?: string
};

type Arrow = {
  position: 'top' | 'bottom' | 'right' | 'left'
  isVisible: boolean
  background?: string
};

export {
  Overlay,
  ToggletipWrapper,
  ToggletipChildren,
  ToggletipContainer,
  ToggletipContent,
};