import React from 'react'
import { ProfileContainer } from "../profile.container"
import { ProfilePopup } from "../ProfileContainers"

type Props = {
    isOpened: boolean
    close: () => void
};

export const ProfileContainerPopup = ({isOpened, close} : Props) => {
    return (
        <ProfilePopup
            isOpened={isOpened}
            onClose={close}
            showCloseButton={false}
            isReceiptListOrWalletPopup={true}
        >
            <ProfileContainer/>
        </ProfilePopup>
    );
};