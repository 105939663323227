import styled from "styled-components";

export const OuterWrapper = styled.div`
  display: flex;
  flex-direction: column;
  z-index: 1;

  .virtual_list {
    -ms-overflow-style: none;
    scrollbar-width: none;
    &::-webkit-scrollbar {
      display: none;
    }

    &.disabled {
      overflow: hidden !important;
    }
  }
`;

export const HeaderContainer = styled.div<Tabs>`
  width: 100%;
  height: fit-content;
  box-sizing: border-box;
  position: sticky;
  top: ${({top}) => top ?? 0}px;
  z-index: 15;
  max-height: 44px;
  overflow: visible;

  @media (orientation: landscape) {
    max-height: 56px;
  }

  header {
    --height: 0;
    --bg_color: ${({theme}) => theme.category_bar_bg_variant};
    --button_text: ${({theme}) => theme.category_button_text_variant};
    --button_text_selected: ${({theme}) => theme.category_button_text_selected_variant};
    --button_text_expanded: ${({theme}) => theme.category_button_text_expanded_variant};
    --button_bg: ${({theme}) => theme.category_button_bg_variant};
    --button_bg_selected: ${({theme}) => theme.category_button_bg_selected_variant};
    --button_bg_expanded: ${({theme}) => theme.category_button_bg_expanded_variant};
    --button_border: ${({theme}) => `${theme.border_sm} ${theme.category_button_border_variant}`};
    --button_border_selected: ${({theme}) => `${theme.border_sm} ${theme.category_button_border_selected_variant}`};
    --button_border_expanded: ${({theme}) => `${theme.border_sm} ${theme.category_button_border_expanded_variant}`};

    &:first-child {
      --height: 44px;
      --bg_color: ${({theme}) => theme.category_bar_bg};
      --button_text: ${({theme}) => theme.category_button_text};
      --button_text_selected: ${({theme}) => theme.category_button_text_selected};
      --button_text_expanded: var(--button_text_selected);
      --button_bg: ${({theme}) => theme.category_button_bg};
      --button_bg_selected: ${({theme}) => theme.category_button_bg_selected};
      --button_bg_expanded: var(--button_bg_selected);
      --button_border: ${({theme}) => `${theme.border_sm} ${theme.category_button_border}`};
      --button_border_selected: ${({theme}) => `${theme.border_sm} ${theme.category_button_border_selected}`};
      --button_border_expanded: ${({theme}) => `${theme.border_sm} ${theme.category_button_border_selected}`};

      @media (orientation: landscape) {
        --height: 56px;
        align-items: center;
      }
    }

    display: flex;
    height: var(--height);
    padding: 0;
    box-sizing: border-box;
    background-color: var(--bg_color);
    transition: 0.15s ease-in-out;
    overflow: hidden;

    &.visible {
      height: 44px;

      @media (orientation: landscape) {
        height: 56px;
        align-items: center;
      }

    }

    & > div {
      display: flex;
      gap: 8px;
      overflow-x: auto;
      padding: 6px 24px;

      @media (orientation: landscape) {
        padding: 12px 24px;
      }

      -ms-overflow-style: none;
      scrollbar-width: none;
      &::-webkit-scrollbar {
        display: none;
      }
    }
    
    label {
      font-size: ${({theme}) => theme.font_md};
      font-weight: 500;
      color: var(--button_text);
      background-color: var(--button_bg);
      display: flex;
      align-items: center;
      white-space: nowrap;
      border: var(--button_border);
      border-radius: ${({theme}) => theme.radius_round};
      line-height: 1;
      gap: 8px;
      padding: 0 12px;
      height: 32px;
      box-sizing: border-box;
  
      span::first-letter {
        text-transform: uppercase;// Capitalize doesn't work wiht all caps text
      }
  
      &:has(:checked) {
        font-weight: 600;
        color: var(--button_text_selected);
        background-color: var(--button_bg_selected);
        border: var(--button_border_selected);
      }
  
      &.expanded:has(svg) {
        --button_text_selected: var(--button_text_expanded);
        --button_bg_selected: var(--button_bg_expanded);
        --button_border_selected: var(--button_border_expanded);
  
        &:has(:checked) svg {
          rotate: 180deg;
        }
      }

      &.see_all {
        position: relative;
        flex-wrap: nowrap;
        max-width: 120px;
        transition-property: max-width, padding;
        transition-duration: 0.15s;
        transition-timing-function: ease-in-out;
        overflow: hidden;

        &.collapsed {
          max-width: 32px;
          padding: 7px;
        }

        &::after {
          content: '';
          position: absolute;
          top: 0;
          right: 0;
          width: 8px;
          height: 100%;
          background: linear-gradient(90deg, transparent 0%, var(--button_bg) 70%);
        }
      }

      &.sticky {
        flex-shrink: 0;
        outline: 6px solid var(--bg_color);
        margin: 6px 0 6px 24px;
        z-index: 3;

        svg {
          flex-shrink: 0;
        }

        &:has(svg):not(:has(span)) {
          width: 32px;
          padding: 7px;
        }

        &.filtering {
          color: var(--button_text_selected);
          background-color: var(--button_bg_selected);
          border: var(--button_border_selected);
          position: relative;

          &:after {
            content: attr(data-filters);
            position: absolute;
            top: -4px;
            right: -4px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: ${({theme}) => theme.font_xs};
            color: ${({theme}) => theme.text_light};
            background-color: ${({theme}) => theme.notification};
            border-radius: ${({theme}) => theme.radius_round};
            height: 16px;
            width: 16px;
          }
        }
        
        & + .sticky {
          margin-left: 8px;
        }

        & + div {
          padding-left: 8px;
        }
      }

      & > div {
        position: sticky;
        left: 0;
        width: 116px;
      }
    }
  }


  &.collapsed {
    header {
      height: var(--height);
      padding: var(--padding);
    }

    svg {
      rotate: 0deg!important;
    }
  }

  svg {
    transition: rotate 0.15s ease-in-out;
  }

  input {
    width: 0;
    opacity: 0;
    position: absolute;
    pointer-events: none;
  }

  .scroll_btn {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 2rem;
    width: 2rem;
    flex-shrink: 0;
    color: var(--button_text);
    background-color: var(--button_bg);
    border: var(--button_border);
    border-radius: var(--radius_round);
    padding: 0;
    position: sticky;
    z-index: 3;
    box-shadow: 0 0 0 4px var(--bg_color);

    @media (pointer: coarse) {
      display: none;
    }

    &:first-of-type {
      left: 0;
    }

    &:last-of-type {
      right: 0;
    }

    svg {
      height: 1rem;
      width: 1rem;
    }
  }
`;

export const ContentWrapper = styled.div`
  flex: 1;
  background-color: ${({theme}) => theme.background};
  z-index: 1;
`;

type Tabs = {
  top?: number;
}