// ///////////////////////
// SERVICES
// ///////////////////////

import { CartEventTicket } from "../../context/app";
import { postError } from "../../shared/utils/postError";

const REACT_APP_BASE_URL = process.env.REACT_APP_BASE_URL;

function addAuthorizationHeaders(headers: any) {
  const token: any = localStorage.getItem("token_yumminn");
  const type: any = localStorage.getItem("type");
  //console.log("token: " + token);
  //console.log("type: " + type);
  if (token && type && token !== "undefined" && type !== "undefined") {
    headers["Authorization"] = token;
    headers["Authorization-Type"] = type;
  }
  return headers;
}

export function eventInitiatePayment(eventUuid: string, data: any) {
  return new Promise((resolve, reject) => {
    const headers = addAuthorizationHeaders({"Content-Type": "application/json"});
    const bodyStr = JSON.stringify(data);
    const yumminnToken = process.env.REACT_APP_YUMMINN_TOKEN;
    if (yumminnToken) {
      headers["yumminn-token"] = yumminnToken;
    }
    const response = fetch(`${REACT_APP_BASE_URL}/restaurant/event/${eventUuid}/initiate_payment/`, {
      method: "POST",
      body: bodyStr,
      headers
    });
    response.
      then((response: any) => {
        //console.log("response");
        if (response.status == 200) {
          return response.json();
        } else {
          reject(response.statusText);
        }
      })
      .then((json: any) => {
        //console.log("Resolving payment");
        resolve(json);
      })
      .catch((err) => {
        console.error("Unexpected error in eventInitiatePayment:", err);
        postError(err)
        reject(err);
      });
  });
}

const recursivelyRetryInitiatePayment = (data: any, resolve: any, reject: any, setFlow?: any, paymentId = 0) => {
  const headers = addAuthorizationHeaders({"Content-Type": "application/json"});
  const body = paymentId == 0 ? data : {paymentId};
  const bodyStr = JSON.stringify(body);
  const yumminnToken = process.env.REACT_APP_YUMMINN_TOKEN;
  if (yumminnToken) {
    headers["yumminn-token"] = yumminnToken;
  }
  const response = fetch(`${REACT_APP_BASE_URL}/payment/initiate-payment`, {
    method: "POST",
    body: bodyStr,
    headers
  });
  response.then((response: any) => {
    if (response.status == 200) {
      return response.json();
    } else {
      reject(response.statusText);
    }
  }).then((json: any) => {
    try {
      if ("retry" in json && json["retry"] && "paymentId" in json && json["paymentId"]) {
        if ("flow" in json && json["flow"] && setFlow){
          setFlow(json["flow"])
        }
        setTimeout(() => {
          recursivelyRetryInitiatePayment(data, resolve, reject, setFlow, json["paymentId"]);
        }, 800);
      } else {
        resolve(json);
      }
    } catch (error: any) {
      throw Error(error.toString());
    }
  }).catch(err => {
    reject(err)
    postError(err)
  });
};

// ///////////////////////
// PAY
export const initiatePayment = (data: any, setFlow?: any, paymentMethodParam?: string|null) => {
  if(paymentMethodParam) data["type"] = paymentMethodParam

  return new Promise((resolve, reject) => {
    recursivelyRetryInitiatePayment(data, resolve, reject, setFlow);
  });
};

export const intitateC2Payment = async (data: any) => {
  const headers = addAuthorizationHeaders({"Content-Type": "application/json"});
  const yumminnToken = process.env.REACT_APP_YUMMINN_TOKEN;
  if (yumminnToken) {
    headers["yumminn-token"] = yumminnToken;
  }
  const response = await fetch(`${REACT_APP_BASE_URL}/payment/initiate-c2p-payment`, {
    method: "POST",
    body: JSON.stringify(data),
    headers
  });
  if (response.ok) {
    const result = await response.json();
    return result;
  }
  throw Error(response.statusText);
}

const recursivelyRetryCompletePayment = (paymentUuid: any, resolve: any, reject: any, setFlow: any) => {
  const headers = addAuthorizationHeaders({"Content-Type": "application/json"});
  const yumminnToken = process.env.REACT_APP_YUMMINN_TOKEN;
  if (yumminnToken) {
    headers["yumminn-token"] = yumminnToken;
  }

  const response = fetch(`${REACT_APP_BASE_URL}/payment/redirect-response/${paymentUuid}`, {
    method: "GET",
    headers
  });
  response.then((response: any) => {
    if (response.status == 200) {
      return response.json();
    } else {
      reject(response.statusText);
    }
  }).then((json: any) => {
    if ("retry" in json && json["retry"]) {
      if ("flow" in json && json["flow"]){
        setFlow(json["flow"])
      }
      setTimeout(() => {
        recursivelyRetryCompletePayment(paymentUuid, resolve, reject, setFlow);
      }, 800);
    } else {
      resolve(json);
    }
  }).catch(err => {
    reject(err)
    postError(err)
  });
};

// ///////////////////////
// PAY
export const completePayment = (paymentUuid: any, setFlow: any) => {
  return new Promise((resolve, reject) => {
    recursivelyRetryCompletePayment(paymentUuid, resolve, reject, setFlow);
  });
};


export const getSavedCards = async (restaurantId: number) => {
  const token: any = localStorage.getItem("token_yumminn");
  if(token){
    const headers = addAuthorizationHeaders({"Content-Type": "application/json"});
    const yumminnToken = process.env.REACT_APP_YUMMINN_TOKEN;
    if (yumminnToken) {
      headers["yumminn-token"] = yumminnToken;
    }
    const response = await fetch(`${REACT_APP_BASE_URL}/payment/saved-cards?id=${restaurantId}`, {
      method: "GET",
      headers
    });
    if (response.ok) {
      const result = await response.json();
      return result;
    }
    throw Error(response.statusText);
  } else {
    return [];
  }

};


export const saveCardAfterPayment = async (data: any) => {
  const headers = addAuthorizationHeaders({"Content-Type": "application/json"});
  const response = await fetch(`${REACT_APP_BASE_URL}/payment/save-payment-token`, {
    method: "POST",
    body: JSON.stringify(data),
    headers
  });
  if (response.ok) {
    const result = await response.json();
    return result;
  }
  throw Error(response.statusText);
};


export function eventCatalogInitiatePayment(data: any, tickets: CartEventTicket[]) {
  return new Promise((resolve, reject) => {
    data.event_data_info = tickets.map(ticket => Array(ticket.amount).fill({ticket_id: ticket.id, eventDate: ticket.eventDate, recurring_event_date: ticket.recurring_event_date})).flat();
    
    const headers = addAuthorizationHeaders({"Content-Type": "application/json"});
    const bodyStr = JSON.stringify(data);
    const yumminnToken = process.env.REACT_APP_YUMMINN_TOKEN;
    if (yumminnToken) {
      headers["yumminn-token"] = yumminnToken;
    }
    const response = fetch(`${REACT_APP_BASE_URL}/event/initiate_event_payment`, {
      method: "POST",
      body: bodyStr,
      headers
    });
    response.then((response: any) => {
      if (response.status == 200) {
        return response.json();
      } else {
        return response.json().then((errorData: any) => {
          reject(errorData.detail || response.statusText);
        });
      }
    }).then((json: any) => {
      //console.log("Resolving payment");
      resolve(json);
    }).catch(err => {
      reject(err)
      postError(err)
    });
  });
}