import React from "react";
import styled from "styled-components";
import { Icon, IconType } from "../../../elements";
import { PopupType } from "../../../context/popups.enum";

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: ${({theme}) => theme.backdrop_color};
`;
const ModalContainer = styled.div<ModalContainerProps>`
  width: 100%;
  height: ${({ height }) => height};
  background-color: white;
  border-radius: ${({theme}) => `${theme.radius_xl} ${theme.radius_xl} 0 0`};
  position: absolute;
  overflow: hidden;
  top: ${({response}) =>response === 'error' ? "60%" : "30%"};
`;

const ModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
  color: ${({theme}) => theme.text};
  padding: 24px 24px 0;
  position: relative;
  z-index: 1;
`;

const MODALS_WITH_PHOTO = [
  PopupType.Product,
  PopupType.Ticket,
]

type ModalHeaderProps = {
  popup: PopupType
  isScrollingDown: boolean
}

const BackButton = styled.button<ModalHeaderProps>`
  display: flex;
  color: ${({theme}) => theme.text};
  background-color: transparent;
  border: none;
  padding: 16px;
  margin: -16px;
  z-index: 2;
  position: relative;
  isolation: isolate;
  
  &:disabled {
    opacity: 0;
    pointer-events: none;
  }

  &:focus {
    outline: none;
  }
  
  &::before {
    content: '';
    position: absolute;
    inset: 8px;
    background-color: ${({popup, theme}) => MODALS_WITH_PHOTO.includes(popup) ? theme.background : 'transparent'};
    border-radius: ${({theme}) => theme.radius_round};
    z-index: -1;
  }
`;

const CloseButton = styled(BackButton)`
  justify-self: flex-end;

  &:first-child {
    margin-left: auto;
  }
`;

type ModalContainerProps = {
  height: any;
  response: string;
}

const ZeroSixModal = ({ handleClose, children, isOpen, response }: props) => {
  return (
    <div>
      {isOpen && (
        <Overlay>
          <ModalContainer response={response} height={response != "" ? '40%': '75%'}>
          <ModalHeader>
            <CloseButton
                isScrollingDown={false}
                popup={PopupType.None}
                onClick={handleClose}
            >
                <Icon type={IconType.Close}/>
            </CloseButton>
            </ModalHeader>
            {children}
          </ModalContainer>
        </Overlay>
      )}
    </div>
  );
};

type props={
  handleClose: any,
  children: any,
  isOpen: any,
  response: string
};

export default ZeroSixModal;
