import React from 'react';
import { t } from 'i18next';
import { RewardReminderContainer } from './OnboardingContainers';
import { eventTrack } from '../../../useGaTracker';

export const RewardReminder = () => {
  return (
    <RewardReminderContainer 
      onClick={() => eventTrack("00_2scan_tooltip_reward", "00_2scan_tooltip_reward", "00_2scan_tooltip_reward")}
    >
      {t('pst! take a look')}
    </RewardReminderContainer>
  )
}