import React, { useContext } from 'react';
import { t } from 'i18next';
import { Trans } from 'react-i18next';
import cashbackGift from '../../assets/images/illustrations/credit_gift.svg'
import { AppContext } from '../../context/AppContext';
import { AuthContext } from '../../context/auth/AuthContext';
import { calculateCashbackCents, calculateEuros, calculatePoints } from '../../pages/checkout/functions/calculatePrices';
import { getProgramsFromStorage } from '../../pages/checkout/functions/getProgramsFromStorage';
import { GiftRewardContainer } from './OnboardingContainers';
import { eventTrack } from '../../../useGaTracker';

export const GiftReward = () => {
  const { isLogged } = useContext(AuthContext);
  const { state: { checkout: { remaining } } } = useContext(AppContext);
  const {loyaltyProgram} = getProgramsFromStorage();
  const percent = Number(sessionStorage.getItem('loyalty_earning_percentage'));

  const points = calculatePoints(remaining);
  const cents = calculateCashbackCents(remaining);
  const euro = calculateEuros(cents);

  return (
    <GiftRewardContainer 
      onClick={() => eventTrack("00_onboarding_tooltip_01_reward", "00_onboarding_tooltip_01_reward", "00_onboarding_tooltip_01_reward")}
    >
      <img src={cashbackGift} alt='gift'/>
      {!isLogged &&
        <p>
          {loyaltyProgram && percent > 0 ?
            t('you have % discount', {percent})
          : points ?
            t('you have points', {count: points})
          :
            t('pay with Yumminn and get exclusive discounts')
          }
        </p>
      }
      {isLogged &&
        <p>
          {cents ?
            <Trans i18nKey={'you have € discount by paying with yumminn'}>
              <b>You get {{euro}}€</b> discount by paying with Yumminn
            </Trans>
          : points ?
            <Trans i18nKey={'earn points by paying with yumminn'} count={points}>
              <b>Earn {{count: points}}€</b> by paying with Yumminn
            </Trans>
          :
            <Trans i18nKey={'get exclusive discounts'}>
              <b>Earn exclusive discounts</b> by paying with Yumminn
            </Trans>
          }
        </p>
      }
    </GiftRewardContainer>
  )
}