import { Dispatch, SetStateAction, useContext } from 'react'
import { CardDetails, WalletLinkCard, ExtraCredits, BadgeBonus, CardDetailsWrapper } from "./WalletContainers";
import { AppContext } from '../../context/AppContext';
import { Decimal } from 'decimal.js';
import { useTranslation } from 'react-i18next';
import { beautifyDecimalsAmount, calculateExtraCreditsNumber, calculateExtraCredits } from './walletFunctions';

export type Props = {
  item: {
    id: number
    credit__name: string
    credit__amount: number
    credit__bonus: number
    credit__bonus_type: string
  }
  setRestaurantCreditId: Dispatch<SetStateAction<number>>
  selected: boolean
}


const WalletCard = ({ item, setRestaurantCreditId, selected }: Props) => {
  const {
    dispatch,
  } = useContext(AppContext);
  const { t } = useTranslation();

  const handleClick = (item:any) => {
    const newTotal = new Decimal(item.credit__amount).mul(100).toFixed(0);
    setRestaurantCreditId(item.id)
    const creditsExtra = calculateExtraCreditsNumber(item.credit__bonus, item.credit__amount, item.credit__bonus_type)
    dispatch({
        type: 'UPDATE_WALLET_INFO',
        payload: {
            extraCredits: creditsExtra,
            bonusAmount: item.credit__bonus,
            bonusType: item.credit__bonus_type,
            restaurantCreditId: item.id
        }
    })
    dispatch({
        type: 'CHANGE_PAYMENT_SPLIT_TYPE',
        payload: 'recharge'
    })
    dispatch({
      type: 'UPDATE_CHECKOUT',
      payload: {
          myTotal: Number(newTotal)
      }
    });
  }

  return (
    <WalletLinkCard onClick={() => handleClick(item)} key={'topup_value' + item.id} selected={selected}>
      <CardDetailsWrapper>
          <CardDetails>
              <h4>{beautifyDecimalsAmount(item.credit__amount)}€</h4>
              <p>{t('of topup')}</p>
          </CardDetails>
          <BadgeBonus>
              {item.credit__bonus_type === 'percentage' && <span>+{Number(item.credit__bonus)}%</span>}
          </BadgeBonus>
      </CardDetailsWrapper>
      {item.credit__bonus > 0 && 
          <ExtraCredits>
              <b>+{calculateExtraCredits(item.credit__bonus, item.credit__amount, item.credit__bonus_type)}€</b> 
              <i>{t('extra')}</i>
          </ExtraCredits>
      }
  </WalletLinkCard>
  )
}


export default WalletCard


