import React from "react";
import {Formik} from "formik";
import { useRegister } from "../../shared/utils/useRegister";
import { t } from "i18next";
import { Button, TextInput, LoginButtons } from "../../elements";
import { FormContainer, InputWrapper } from "./RegisterContainers";
import { ButtonSize, ButtonType, ButtonVariant } from "../../elements/buttons/enums";
import { loginValidation } from "./validations";
import { useNavigate } from "react-router-dom";
import { usePopupNavigation } from "../../shared/utils/usePopupNavigation";

const path = window.location.pathname.replace('/', '');

type Props = {
  from?: string
  showFormByDefault: boolean
  defaultValues?: Partial<{
    email: string
    password: string
    from: string
  }>
};

export const LoginForm = ({from = path, defaultValues = {}, showFormByDefault = false}: Props) => {
  const { onLoginSubmit, isLoading } = useRegister({});
  const { closePopup } = usePopupNavigation()
  const navigate = useNavigate();

  const initialValues = {
    email: "",
    password: "",
    from: from,
    ...defaultValues,
  };

  const handleForgotPass: React.MouseEventHandler = async event => {
    event.preventDefault();
    await closePopup();
    navigate('/forgot-password', {state: { from }});
  };
  
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={loginValidation}
      onSubmit={!isLoading ? onLoginSubmit : () => null}
      validateOnChange={false}
    >{({values, handleChange, errors}) => (
      <FormContainer>
        <LoginButtons
          from={from}
          bigButtons
          disabled={isLoading}
          showFormByDefault={showFormByDefault}
          mailForm={
            <InputWrapper>
              <TextInput
                name={'email'}
                title={'email'}
                placeholder={'email'}
                value={values.email}
                error={errors.email}
                onChange={handleChange}
              />
              <TextInput
                password
                name={'password'}
                title={'password'}
                placeholder={'password'}
                value={values.password}
                error={errors.password}
                onChange={handleChange}
              />
              <Button
                variant={ButtonVariant.Link}
                title={t('forgot_password')}
                handleClick={handleForgotPass}
                size={ButtonSize.Small}
                alignSelf='start'
              />
              <Button
                title={t('login')}
                type={ButtonType.Submit}
                loading={isLoading}
              />
            </InputWrapper>
          }
        />
      </FormContainer>
    )}</Formik>
  );
};
