import React from "react";
import PropTypes, { InferProps } from "prop-types";
import { Font, FontSize, FontVariant } from "../fonts";
import { Icon, IconType } from "../icons";
import styled, { useTheme } from 'styled-components';
import lock from '../../assets/images/redesign/lock.svg'
import { useViewport } from "../../context/ViewportContext";

export function PageHeaderComponent({
  text,
  onGoBack,
  align = 'center',
}: InferProps<typeof PageHeaderComponent.propTypes>) {

  const theme: any = useTheme();
  const path = location.pathname;

  const { isMobile } = useViewport();

  return (
    <PageHeaderContainer>
      {path !== "/home" && path !== "/profile" ? 
      <div style={{padding: "5px 0 0 8px", zIndex: '2'}} onClick={onGoBack}>
        <Icon type={IconType.Arrowback}/> 
      </div> : ""}
      <div style={{flex: '1'}} onClick={onGoBack}>
          <div style={{whiteSpace: 'nowrap', paddingLeft: align === 'left' ? '50px' : '', textAlign: align}}>
            {path === '/add-card' &&
              <img src={lock} style={{verticalAlign: 'sub', marginRight: '4px'}}/>
            }
            <Font
              text={text}
              size={isMobile ? FontSize.Medium : FontSize.Large}
              color={path === '/add-card' ? theme.primary : "currentColor"}
              variant={FontVariant.SemiBold}
            />
          </div>
      </div>
      {path === '/saved-cards' && 
        <div style={{paddingRight: '10px', marginTop: '4px'}} onClick={onGoBack}>
          <Icon type={IconType.Shield} size={15} />
        </div>
      }
    </PageHeaderContainer>
  );
}

const PageHeaderContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  top: 0;
  padding: 8px 0;
  color: ${({theme}) => theme.text};
  background-color: ${({theme}) => theme.background};
  align-items: center;
  justify-content: space-between;

  @media (min-width: 768px) {
    padding: 0;
  }
`;

PageHeaderComponent.propTypes = {
  text: PropTypes.string.isRequired,
  onGoBack: PropTypes.func.isRequired,
  instagramLink: PropTypes.string,
  align: PropTypes.any,
};
