import { useEffect, ChangeEvent, useContext, useState, useRef } from 'react'; 
import { TextInput, TextArea, NumberInput, Select } from '../../../elements/inputs';
import { RestaurantAdditionalField } from '../../../context/app';
import { AppContext } from '../../../context/AppContext';
import { StylesConfig } from 'react-select';
import { useTranslation } from 'react-i18next';
import { ErrorContainer } from './PaymentDetailContainer';


interface Props {
  field: RestaurantAdditionalField;
  required: boolean;
  setFieldValidity: (isValid: boolean) => void;
}

export const AdditionalField = ({ field, required, setFieldValidity }: Props)  => {
  const { id, input_type, name, options, restaurant, sector, type, ...rest } = field;
  const [numberValue, setNumberValue] = useState('')
  const [textAreaValue, setTextAreaValue] = useState('')
  const [textInputValue, setTextInputValue] = useState('')
  const [selectValue, setSelectValue] = useState({value: "", label: ""})
  const [numberError, setNumberError] = useState(false)
  const [optionsSelect, setOptionsSelect] = useState([])
  const [textAreaError, setTextAreaError] = useState(false)
  const [selectError, setSelectError] = useState(false)
  const [textError, setTextError] = useState(false)
  const { t } = useTranslation();
  const selectValueRef = useRef(selectValue);

  const {
    state: {
      checkout: {
        additionalInfoInputs,
      }
    },
    dispatch,
  } = useContext(AppContext);
  

  useEffect(() => {
    selectValueRef.current = selectValue;
    validateField();
  }, [required, textInputValue, numberValue, textAreaValue, selectValue]);

  useEffect(() => {
    if (input_type === 'select') {
      const newOptions = extractArrayFromJSON(options).map((item: string) => ({
        value: item,
        label: item
      }));
      setOptionsSelect(newOptions);
    }
  }, [input_type, options]);

  const validateField = () => {
      const value = getValue();
      const isValid = !required || value.trim() !== '';
      setFieldValidity(isValid);
  };

  const setMessageError = (valueProp: any = null) => {
    if (required) {
      const value = valueProp ? valueProp : getValue();
      if (!value.trim()) {
        setError(true);
      } else {
        setError(false);
      }
    }
  };

  const handleBlur = () => {
    setTimeout(() => {
      const value = (input_type === 'select' && selectValueRef.current) ? selectValueRef.current.value : getValue();
      const isValid = !required || value.trim() !== '';
      setFieldValidity(isValid);
      setMessageError(value);
    }, 200); // 200ms delay
  };

  const setError = (error: boolean) => {
    switch (input_type) {
      case 'text':
        return setTextError(error);
      case 'number':
        return setNumberError(error);
      case 'text_area':
        return setTextAreaError(error);
      case 'select':
        return setSelectError(error);
      default:
        return '';
    }
  };
  
  const getValue = () => {
    switch (input_type) {
      case 'text':
        return textInputValue;
      case 'number':
        return numberValue;
      case 'text_area':
        return textAreaValue;
      case 'select':
        return selectValue.value;
      default:
        return '';
    }
  };

  const handleValueChange = (newValue: any) => {
    let selectedValue = '';

    switch (input_type) {
      case 'text':
        setTextInputValue(newValue);
        break;
      case 'number':
        // eslint-disable-next-line no-case-declarations
        const value = newValue.replace(/[^\d.,]/g, "").replace(/,/g, ".");
        setNumberValue(value);
        break;
      case 'text_area':
        setTextAreaValue(newValue);
        break;
      case 'select':
        selectedValue = newValue ? newValue.value : '';
        setSelectValue(newValue);
        setSelectError(false)
        break;
      default:
        break;
    }

    dispatch({
      type: 'UPDATE_CHECKOUT',
      payload: {
        additionalInfoInputs: [
          ...(additionalInfoInputs && additionalInfoInputs.length > 0 ? additionalInfoInputs.filter(input => input.name !== name) : []),
          { name, value: selectedValue ? selectedValue : newValue }
        ],
      }
    })


  }

  const extractArrayFromJSON = (data: any) => {
    const key = Object.keys(data)[0]; // Get the first key from JSON
    return data[key]; // Return the array associated with that key
  };

  const select_additional_field: StylesConfig<unknown> = {
    menu: (select_additional_field: any) => ({
      ...select_additional_field,
      background: 'var(--background_rised)',
      borderRadius: '0 0 var(--radius_sm) var(--radius_sm)',
      border: '2px solid var(--input_border)',
      margin: '-2px 0 0',
      boxShadow: 'var(--shadow_dropdown)',
      width: 'calc(100% - 1px)',
      overflowY: 'auto',
      maxHeight: '130px'
    }),
  };

  const translateWithInterpolation = (key: string, interpolation?: { [key: string]: any }) => {
    return interpolation ? t(key, interpolation) : t(key);
  };

  switch (input_type) {
    case 'select':
      return (
        <>
          <Select 
            name={name}
            options={optionsSelect || []} 
            required={required}
            value={selectValue.value !== '' ? selectValue : null}
            onChange={(a: any) => handleValueChange(a)}
            closeMenuOnSelect={true}
            placeholder={name}
            styles={select_additional_field}
            onMenuClose={handleBlur}
            isSearchable={false}
            {...rest} 
          />
          {selectError && 
            <ErrorContainer>{translateWithInterpolation(t('valid_field'), { varForTranslation: name })}</ErrorContainer>
          }
        </>
      );
    case 'text':
      return (
        <TextInput
          required={required}
          label={name}
          value={textInputValue} 
          onChange={(e : ChangeEvent<HTMLInputElement>) => handleValueChange(e.target.value)} 
          error={textError && 'valid_field'}
          onBlur={handleBlur}
          varForTranslation={name}
          {...rest}
        />
      );
    case 'number':
      return (
        <NumberInput
          required={required}
          label={name}
          value={numberValue} 
          onChange={(e: ChangeEvent<HTMLInputElement>) => handleValueChange(e.target.value)} 
          name={name}
          error={numberError && 'valid_field'}
          onBlur={handleBlur}
          varForTranslation={name}
          {...rest}
        />
      );
    case 'text_area':
      return (
        <TextArea
          label={name}
          value={textAreaValue} 
          onChange={(e : ChangeEvent<HTMLInputElement>) => handleValueChange(e.target.value)} 
          name={name}
          onBlur={handleBlur}
          rows={3}
          varForTranslation={name}
          error={textAreaError && 'valid_field'}
          {...rest}
        />
      );
    default:
      return null;
  }
};




  
  
  
  
  
  
  