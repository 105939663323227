import React from 'react';
import { ICONS } from "./constants";
import { allergensIcons } from "./constants/allergens";
import { colorIcons } from "./constants/color";
import { linearIcons } from "./constants/linear";
import { themedIcons } from "./constants/themed";
import { tagsIcons } from './constants/tags';
import styled from 'styled-components';
import { IconType } from './enums';

type Props = React.HTMLAttributes<HTMLButtonElement> & {
  type: IconType;
  colorIcon?: string;
  size?: number;
  fill?: string;
  style?: any;
  disabled?: boolean;
  loading?: boolean;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
};

export const IconComponent = ({
  type,
  colorIcon = 'currentColor',
  size,
  fill,
  disabled = false,
  onClick,
  ...btnProps
}: Props) => {
  const AllIcons = { ...ICONS, ...linearIcons, ...colorIcons, ...themedIcons, ...allergensIcons, ...tagsIcons };
  
  const svg = AllIcons[type];

  !svg && console.error(`"${type}" is not a valid icon type`);

  if (svg && onClick) return (
    <Btn
      onClick={onClick}
      size={size || 20}
      color={colorIcon}
      className={`${btnProps?.className} ${btnProps?.loading ? 'loading' : ''}`}
      {...btnProps}
    >
      {svg(size, colorIcon, fill, disabled)}
    </Btn>
  );
  
  return svg?.(size, colorIcon, fill, disabled) || <></>;
};

const Btn = styled.button<{size: number, color: string}>`
  --click_margin: ${({size}) => Math.max((32 - size) / 2, 0) + 'px'}; // add margin to click if svg is too small

  padding: var(--click_margin);
  margin: calc(-1 * var(--click_margin));
  border-radius: ${({size}) => Math.max(size, 32) + 'px'};
  background-color: transparent;
  flex: 0 0 auto;
  transition: background-color 0.15s ease-in-out;
  overflow: visible;
  color: ${({color}) => color || 'var(--primary)'};
  border: none;

  &:focus {
    outline: none;
  }

  &:focus-visible {
    outline: 2px solid currentColor;
    outline-offset: 3px;
  }

  &:hover, &:focus-visible {
    cursor: pointer;
  }

  &:disabled, &.loading {
    background-color: transparent;
    opacity: 0.5;
  }

  &:disabled {
    cursor: not-allowed;
  }

  &.loading {
    cursor: wait;
  }
`;