import styled from "styled-components";
import { BaseCard } from "../cards";

const CardContainer = styled.div`
  iframe {
    height: 100%;
    border-radius: 0px;
  }
`;

const Wrapper = styled(BaseCard)`
  height: 42px;
  display: flex;
  align-items: center;
  margin: 8px 0 0;
  font-size: ${({theme}) => theme.font_md};

  &:hover {
    cursor: pointer;
  }

  &:active {
    transform: translate(1px, 1px);
  }

  img {
    height: 16px;
    margin-left: 10px;
    margin-right: 10px;
  }

  input {
    margin-left: 10px;
    margin-right: 10px;
  }

  h1 {
    display: flex;
    align-items: center;
    font-size: ${({theme}) => theme.font_md};
    font-weight: 400;
    line-height: 1;

    span {
      display: inline-block;
      font-size: ${({theme}) => theme.font_lg};
      margin: 8px 0 0;
    }
  }


  h2 {
    display: flex;
    font-size: ${({theme}) => theme.font_lg};
    font-weight: 400;
    margin: 0 16px 0 0;
    line-height: 1;
  }

  h2 > b {
    font-size: 12px;
    font-weight: 500;
  }
`

const SaveCardContainer = styled.div`
  font-size: ${({theme}) => theme.font_sm};
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
  padding: 8px;
  background-color: ${({theme}) => theme.primary_a20};
  border-radius: ${({theme}) => theme.radius_sm};

  label {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 13px;
    height: 13px;
    background-color: ${({theme}) => theme.input_bg};
    border: 2px solid ${({theme}) => theme.input_border};
    border-radius: 4px;
    padding: 3px;
    flex-shrink: 0;
    
    &:has(:checked)::after {
      content: '';
      width: 100%;
      height: 100%;
      background-color: ${({theme}) => theme.primary};
      border-radius: 2px;
    }

    input {
      opacity: 0;
      width: 0;
      position: absolute;
    }
  }

  span {
    line-height: 1.3em;
  }

  b {
    font-weight: 600;
  }
  
  svg {
    flex-shrink: 0;
  }
`;

export {
  CardContainer,
  Wrapper,
  SaveCardContainer,
}