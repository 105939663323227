import styled from "styled-components";
import { ResponsiveSheet } from "../../elements";

type BaseCardProps = {
  selected?: boolean
};

export const BaseCard = styled.div<BaseCardProps>`
  font-family: ${({theme}) => theme.typography};
  font-size: ${({theme}) => theme.font_md};
  color: ${({selected, theme}) => selected ? theme.card_text_selected : theme.text};
  background-color: ${({selected, theme}) => selected ? theme.card_bg_selected : theme.card_bg};
  border: ${({selected, theme}) => `${theme.card_border} ${selected ? theme.tertiary : theme.card_border_color}`};
  border-radius: ${({theme}) => theme.radius_sm};
  box-shadow: ${({theme}) => theme.shadow_xs};
  box-sizing: border-box;
`;

export const WalletOuterContainer = styled.div<{background?: string}>`
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
  background: ${({background, theme}) => `${theme.background} url(${background})`};
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  min-height: var(--dvh);
  min-height: 100dvh;
  padding: 44px 24px 24px;
  margin-top: -44px;
  box-sizing: border-box;
`;

export const WalletPopup = styled(ResponsiveSheet).attrs({
  landscapePosition: 'left',
})`
  display: flex;
  flex-direction: column;
  gap: 16px;
  box-sizing: border-box;
  margin: 42px 0 0;

  @media (orientation: landscape) {
    margin: 0 0 0 68px;
    overflow: hidden;
    max-width: calc(35%);

    @media (max-width: 1024px){
      max-width: calc(45%);
    }
  }
`;

export const WalletCover = styled.div<{background?: string, hasCityPicker?: boolean}>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
  width: calc(100% + 48px);
  min-height: ${({background}) => background ? '30vh' : 'auto'};
  background-image:
    linear-gradient(${({background, theme}) => background && theme.cover_overlay}),
    url(${({background}) => background});
  background-size: cover;
  padding: 20px 24px 48px;
  margin: 0 -24px -24px;
  box-sizing: border-box;

  img {
    object-fit: contain;
    width: 80%;
  }

  hr {
    border: 1px solid ${({theme}) => theme.text};
    width: 100%;
    margin-top: -5px;
  }

  h1, h2, p {
    text-align: center;
    line-height: 1.3;
    margin: 0;
    color: ${({theme}) => theme.text};
  }

  p {
    font-size: ${({theme}) => theme.font_md};
  }

  h1 {
    font-size: ${({theme}) => theme.font_l_xl};
    font-weight: 600;
  }

  h2 {
    font-size: ${({theme}) => theme.font_md};
    font-weight: 500;
    padding: 0 10px;
  }
`;

export const CardsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(7rem, 1fr));
  grid-auto-rows: 1fr;
  width: 100%;
  gap: 20px;
  margin-bottom: 20px;

  @media (orientation: landscape) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-auto-rows: 1fr;
    width: 100%;
    gap: 20px;
    margin-bottom: 20px;
  }
`;

export const TotalCredits = styled.div`
    background: #F2FFF2;
    gap: 10px;
    margin-top: 20px;
    align-items: center;
    justify-content: center;
    border: 1px solid #B4DEB4;
    box-shadow: 3px 4px 0px 0px #DFFFDF;
    border-radius: 38px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    font-weight: 700;
    font-size: ${({theme}) => theme.font_lg};
    line-height: 32px;
    padding: 2px 4px;

    span {
        margin: 0;
    }
`;

export const WalletLinkCard = styled(BaseCard)`
  text-decoration: none;
  display: flex;
  flex-direction: column;
  border-radius: 1rem;
  overflow: hidden;
  transition: translate 0.15s ease-in-out;

  &:active {
    translate: 1px 2px;
  }

  img {
    width: 100%;
    height: 100%;
    max-height: 9.25rem;
    object-fit: cover;
  }

`;

export const ExtraCredits = styled.div`
    font-size: ${({theme}) => theme.font_xs};
    display: flex; 
    align-items: center;
    margin: 6px 6px 6px 12px;
    gap: 3px;

    i {
        white-space: nowrap; 
    }

    b {
        font-weight: 600;
    }

`;

export const BadgeBonus = styled.div`
    font-size: ${({theme}) => theme.font_sm};
    display: flex; 
    align-items: center;
    position: absolute; 
    top: 8px;
    right: 5px;


    i {
        font-weight: 600;
        white-space: nowrap; 
    }

    span {
        font-weight: 600;
        background-color: #E1FFF2;
        border-radius: 18px;
        padding: 1.97px 5.91px 1.97px 5.91px;
        color: #404040;
    }
`;


export const CardDetailsWrapper = styled.div`
    font-size: ${({theme}) => theme.font_xs};
    display: flex; 
    align-items: center;
    position: relative;
`;

export const CardDetails = styled.div`
  padding: 12px 12px;

  h4 {
    text-wrap: balance;
    line-height: 1.3;
    font-weight: 600;
    margin: 0;
    font-size: ${({theme}) => theme.font_xl};
    font-weight: 700;
  }

  p {
    font-size: ${({theme}) => theme.font_sm};
    margin: 0;
  }

  span {
        font-weight: 600;
        background-color: #E1FFF2;
        border-radius: 18px;
        padding: 1.97px 5.91px 1.97px 5.91px;
        color: #404040;
    }
`;