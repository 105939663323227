import axios from "axios"
import jwt_decode from "jwt-decode";

const REACT_APP_BASE_URL = process.env.REACT_APP_BASE_URL;
// Settings
const apiConfig = {
  baseURL: REACT_APP_BASE_URL,
};

const APIProduct = (restaurant: any, tableId: any, access: any, type: any) => {
  // eslint-disable-next-line
  let headers: any = {
    "Content-Type": "application/json",
    "authorization-type": "front",
  }

  const yumminnToken = process.env.REACT_APP_YUMMINN_TOKEN;
  if (yumminnToken) {
    headers["yumminn-token"] = yumminnToken;
  }

  const params: any = {
    "restaurant": restaurant,
    "tableId": tableId
  }

  const instance = axios.create({ baseURL: apiConfig.baseURL, params: params, headers: headers })
  instance.interceptors.request.use(
     
    async (config: any) => {
      config.headers["authorization"] = access;
      config.headers["authorization-type"] = type;
      const yumminnToken = process.env.REACT_APP_YUMMINN_TOKEN;
      if (yumminnToken) {
        config.headers["yumminn-token"] = yumminnToken;
      }
      config.metadata = { startTime: performance.now() }
      return config;
    },
  );
  // Add a response interceptor
  instance.interceptors.response.use(undefined, (err) => {
    const { config, message } = err;
    // Set default retry and retryDelay values if not already set in the request
    config.retry = config.retry || 3;
    config.retryDelay = config.retryDelay || 1000;
    if (!config || !config.retry) {
      return Promise.reject(err);
    }
    // retry while Network timeout or Network Error
    if (!(message?.includes("timeout") || message?.includes("Network Error"))) {
      return Promise.reject(err);
    }
    const delayRetryRequest = new Promise<void>((resolve) => {
      setTimeout(() => {
        console.log("retry the request managerAuth", config.url);
        resolve();
      }, config.retryDelay || 1000);
    });
    return delayRetryRequest.then(() => {
      config.retry -= 1;
      if (config.retry === 0) {
        // Throw an error in the last retry attempt
        if (err.response && err.response.data && err.response.data.detail) {
          throw new Error(err.response.data.detail);
        } else {
          throw new Error(err.message);
        }
      }
      return instance(config);
    });
  });

  return instance
}

// Client
const API = (access: any, type: any) => {
  const instance = axios.create({ baseURL: apiConfig.baseURL });

  instance.interceptors.request.use(
    async (config: any) => {
      config.headers["authorization"] = access;
      config.headers["authorization-type"] = type;
      const yumminnToken = process.env.REACT_APP_YUMMINN_TOKEN;
      if (yumminnToken) {
        config.headers["yumminn-token"] = yumminnToken;
      }
      config.metadata = { startTime: performance.now() }
      return config;
    },
  );

  instance.interceptors.response.use(undefined, (err) => {
    const { config, message } = err;
    // Set default retry and retryDelay values if not already set in the request
    config.retry = config.retry || 3;
    config.retryDelay = config.retryDelay || 1000;
    if (!config || !config.retry) {
      return Promise.reject(err);
    }
    // retry while Network timeout or Network Error
    if (!(message?.includes("timeout") || message?.includes("Network Error"))) {
      return Promise.reject(err);
    }
    const delayRetryRequest = new Promise<void>((resolve) => {
      setTimeout(() => {
        console.log("retry the request managerAuth", config.url);
        resolve();
      }, config.retryDelay || 1000);
    });
    return delayRetryRequest.then(() => {
      config.retry -= 1;
      if (config.retry === 0) {
        // Throw an error in the last retry attempt
        if (err.response && err.response.data && err.response.data.detail) {
          throw new Error(err.response.data.detail);
        } else {
          throw new Error(err.message);
        }
      }
      return instance(config);
    });
  });

  return instance;
};

export const checkToken = async () => {
  const userToken = localStorage.getItem("token_yumminn");

  const instance = axios.create({ baseURL: apiConfig.baseURL });

  if (!userToken) {
    return false;
  }
  const { access, refresh } = JSON.parse(userToken);
   
  const decoded: any = jwt_decode(access);

  const _decoded = Number(decoded.exp);

  if (+Date.now() >= _decoded * 1000) {

    try {
       
      const res: any = await instance.post("/api/token/refresh", { refresh });
      localStorage.setItem(
        "token_yumminn",
        JSON.stringify({ refresh, access: res.data.access })
      );
      return true;
    } catch (e) {
      // throw new Error("Session expired")
      window.location.href = "/login";
      // return false
    }
  } else {
    return true;
  }
};

const ManagerAuth = {
  apiConfig: apiConfig,
   
  client: (access: any, type: any) => API(access, type),
  clientProduct: (restaurant: any, tableId: any, access: any, type: any) => APIProduct(restaurant, tableId, access, type),

};

export default ManagerAuth;