import React, { Dispatch, SetStateAction, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { AppContext } from '../../context/AppContext';
import { Font, FontSize, FontVariant, Input } from '../../elements';
import { Card } from '../../elements/main-image/components/CardContainer';
import { TableStatusTitle } from './components/TableStatus';

const PortugueseInput = ({disabled, showOrder, setCustomerName, setCustomerVAT, customerName, customerVAT} : props) => {

    const { t } = useTranslation();

    const {
        state: {
          checkout: {
            myTotal
          }
        },
        dispatch
      } = useContext(AppContext);
      
    const handleChangeName = (e: any) => {
        setCustomerName(e.target.value);
        sessionStorage.setItem('customerName', e.target.value)
        dispatch({
            type: 'CHANGE_CUSTOMER_NAME',
            payload: e.target.value
        });
    };

    const handleChangeVAT = (e: any) => {
        setCustomerVAT(e.target.value);
        dispatch({
            type: 'CHANGE_CUSTOMER_VAT',
            payload: e.target.value
        });
        sessionStorage.setItem('customerVAT', e.target.value)
    };

    return(
        <Card
          typeOfCard={"bill"}
          style={{marginTop: showOrder ? "20px" : 0}}
          smallInnerWidth={window.innerWidth < 380 ? true : false}
        >
          <TableStatusTitle>
            <div style={{display: 'flex', alignItems: 'center'}}>
              <span style={{paddingRight: "5px"}}>Detalhe do recibo</span>
              {myTotal < 99900 && <Font text={"(opcional)"} size={FontSize.Smaller} />}
            </div>
          </TableStatusTitle>
          <div style={{marginBottom: "20px", margin: "0 15px 10px"}}>
            {myTotal > 99900 && 
            <Input  
                name="Nome"
                fontSize='16px'
                placeholder='Nome'
                onChange={(e: string) => handleChangeName(e)}
                value={customerName}
            />}
            {disabled && !customerName &&
             <div style={{padding: "0 10px"}}>
             <Font
               text={t("is_required")}
               variant={FontVariant.Normal}
               underline={false}
               color="red"
             />
            </div>}
            <Input  
                name="NIF"
                fontSize='16px'
                placeholder='NIF'
                onChange={(e: string) => handleChangeVAT(e)}
                value={customerVAT}
            />
            {disabled && !customerVAT &&
            <div style={{padding: "0 10px"}}>
            <Font
              text={t("is_required")}
              variant={FontVariant.Normal}
              underline={false}
              color="red"
            />
           </div>}
          </div>
        </Card>
    )

}


type props={
    disabled: boolean
    showOrder?: boolean
    setCustomerName: Dispatch<SetStateAction<string>>;
    setCustomerVAT: Dispatch<SetStateAction<string>>;
    customerName: string
    customerVAT: string
}


export default PortugueseInput;