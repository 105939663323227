import styled from "styled-components";
import React from 'react';

const RegisterContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  color: ${({theme}) => theme.text};
  min-width: 230px;

  h3 {
    font-size: ${({theme}) => theme.font_md};
    font-weight: 500;
    line-height: 1.4em;
    text-align: center;
    margin: 0;

    span {
      font-size: ${({theme}) => theme.font_lg};
      font-weight: 600;
      color: ${({theme}) => theme.primary};
      display: block;
      margin: 0;
    }
  }

  p {
    font-size: ${({theme}) => theme.font_xs};
    margin: -6px 0;
  }

  & > div:first-of-type {
    display: flex;
    gap: 8px;
    width: 100%;
    justify-content: center;
    align-items: center;
  }

  strong {
    font-size: ${({theme}) => theme.font_lg};
    font-weight: 600;
    max-width: 50%;
    text-align: center;
  }
`;

const RewardBadge = styled.div<Reward>`
  display: flex;
  gap: 8px;
  color: ${({theme}) => theme.text_dark};
  background-color: ${({type, theme}) => type === 'points' ? theme.yumminn_10 : theme.cash_10};
  border-radius: ${({theme}) => theme.radius_round};
  padding: 8px;

  & > span {
    font-size: ${({theme}) => theme.font_xxs};
    font-weight: 500;
    line-height: 1.3em;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    white-space: nowrap;
    padding-right: 8px;
  }

  b {
    font-size: ${({theme}) => theme.font_md};
    font-weight: 700;
    width: 100%;
    display: block;
  }
`;

type Reward = {
  type: 'points' | 'cashback';
}

const SpeechBubble = styled.div`
  width: 43px;
  height: 27px;
  background-color: ${({theme}) => theme.background};
  border:  ${({theme}) => `1px solid ${theme.primary}`};
  border-radius: 8px;
  position: absolute;
  right: -26.69%;
  bottom: 83.72%;
`;

const BubbleTipLeft = styled.div`
  border-left: ${({theme}) => `1px solid ${theme.primary}`};
  border-top: ${({theme}) => `1px solid ${theme.primary}`};
  width: 12.68px;
  height: 12.68px;
  position: absolute;
  background-color: ${({theme}) => theme.background};
  -webkit-transform: rotate(239.4deg);
  -ms-transform: rotate(239.4deg);
  -webkit-transform: rotate(239.4deg);
  -ms-transform: rotate(239.4deg);
  transform: rotate(239.4deg);
  border-radius: 3px 0 0 0;
  position: absolute;
  left: 20.47%;
  top: 67.71%;
  bottom: 45.72%;
`;

const BubbleTipTop = styled.div`
  width: 20px;
  height: 9px;
  left: 14.5px;
  bottom: -10px;
  rotate: 16deg;
  position: absolute;
  background-color: #FFF;
  border-radius: 4px;
  -webkit-translate: -50% -140%;
  -webkit-translate: -50% -140%;
  -webkit-translate: -50% -140%;
  -webkit-translate: -61% -100%;
  translate: -61% -100%;
`;

const Text = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  color: ${({theme}) =>theme.primary};
  font-weight: 500;
  font-size:  ${({theme}) =>theme.font_xs};
  position: relative;
  z-index: 2;
`;

const RegisterBenefitsContainer = styled.div`
  color: ${({theme}) => theme.text};
  
  h4 {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 4px;
    font-size: ${({theme}) => theme.font_lg};
    font-weight: 600;
    margin: 0;
  }
  
  p {
    font-size: ${({theme}) => theme.font_md};
    text-align: center;
    margin: 0;

    b {
      font-weight: 600;
    }
  }
`;

type props = {
  text: string
}

const SpeechBubbleWithText = ({ text } : props) => {
  return (
    <SpeechBubble>
      <BubbleTipLeft />
      <BubbleTipTop />
      <Text>{text}</Text>
    </SpeechBubble>
  );
};

const CashbackRegisterContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 8px 24px 24px;
  
  h2 {
    font-size: ${({theme}) => theme.font_lg};
    margin: 0;
    text-align: center;
  }

  p {
    text-align: center;
    margin: 0;
  }
`;

const PopupTitle = styled.div`
  font-size: ${({theme}) => theme.font_l_xl};
  margin: 0;
  text-align: center;
  display: inline;
  padding: 0 20px;
  line-height: 26px;
`

const NoCashbackMessage = styled.p`
  font-size: ${({theme}) => theme.font_md};
  font-weight: 700;
  text-align: center;
  margin: 0;
  line-height: 20.8px;
`

const BigBadge = styled.div`
  display: flex;
  align-items: center;
  align-self: center;
  gap: 8px;
  color: ${({theme}) => theme.text_dark};
  background-color: ${({theme}) => theme.cash_10};
  border-radius: ${({theme}) => theme.radius_round};
  position: relative;
  padding: 14px 26px;

  b {
    font-size: ${({theme}) => theme.font_xxl}!important;
    font-weight: 700;
    line-height: 1;
  }
  
  p {
    font-size: ${({theme}) => theme.font_lg};
    line-height: 1;
    margin: 0;
  }

  div {
    display: flex;
    color: ${({theme}) => theme.badge_qr_color};
    background-color: ${({theme}) => theme.badge_qr_bg};
    padding: 4px;
    border-radius: ${({theme}) => theme.radius_xs};
    position: absolute;
    right: 95%;
    top: 15%;
    isolation: isolate;
    filter: ${({theme}) => theme.shadow_drop_xs};

    &::after {
      content: '';
      height: 16px;
      width: 16px;
      background-color: inherit;
      border-radius: inherit;
      transform: skew(40deg);
      position: absolute;
      right: -4px;
      bottom: 8px;
      z-index: -1;
    }
  }
`;

const PostPayRegisterContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  padding: 8px 24px 24px;
  
  h2, h3 {
    font-size: ${({theme}) => theme.font_xl};
    font-weight: 600;
    margin: 0;
    text-align: center;
  }

  h3 {
    font-size: ${({theme}) => theme.font_lg};
    max-width: 20ch;
    margin: 8px 0;
    
    span {
      color: ${({theme}) => theme.yumminn};
    }
  }
  
  & > p {
    font-size: ${({theme}) => theme.font_sm};
    text-align: center;
    margin: 0;
  }
`;

const QRContainer = styled.div`
  display: flex;
  align-items: center;
  align-self: center;
  gap: 8px;
  color: ${({theme}) => theme.text_dark};
  border-radius: ${({theme}) => theme.radius_round};
  position: relative;
  padding: 6px 26px;
  margin-left: 45px;

  div {
    display: flex;
    color: ${({theme}) => theme.badge_qr_color};
    background-color: ${({theme}) => theme.badge_qr_bg};
    padding: 4px;
    border-radius: ${({theme}) => theme.radius_xs};
    position: absolute;
    right: 95%;
    top: 15%;
    isolation: isolate;
    filter: ${({theme}) => theme.shadow_drop_xs};

    &::after {
      content: '';
      height: 16px;
      width: 16px;
      background-color: inherit;
      border-radius: inherit;
      transform: skew(40deg);
      position: absolute;
      right: -4px;
      bottom: 8px;
      z-index: -1;
    }
  }

  p {
    font-size: ${({theme}) => theme.font_lg};
    line-height: 1;
    margin: 0;
  }
`;

export {
  RegisterContainer,
  RewardBadge,
  RegisterBenefitsContainer,
  SpeechBubbleWithText,
  CashbackRegisterContainer,
  BigBadge,
  PostPayRegisterContainer,
  PopupTitle,
  NoCashbackMessage,
  QRContainer
}