import styled from "styled-components";

const Label = styled.label<{color?: string, size?: number}>`
  color: ${({theme}) => theme.background};
  background-color: ${({theme}) => theme.input_bg};
  border: ${({theme}) => `${theme.border_sm} ${theme.input_border}`};
  border-radius: ${({theme}) => theme.radius_round};
  box-shadow: ${({theme}) => theme.shadow_inset_xs};
  box-sizing: border-box;
  height: ${({size}) => (size || 24) + 'px'};
  width: ${({size}) => (size || 24) + 'px'};
  padding: 0.3%;
  flex-grow: 0;
  flex-shrink: 0;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: 0.15s ease-in-out;
  transition-property: border-color, background-color;
  
  &:has(:focus-visible) {
    outline: 2px solid ${({theme}) => theme.primary};
    outline-offset: 2px;
    box-shadow: 0 2px 0 #0001;
  }

  svg {
    opacity: 0;
    scale: 0.7;
    transition: opacity, scale;
    transition-duration: 0.2s, 0.4s;
    transition-timing-function: ease-in-out, cubic-bezier(.19,2,.83,-2);
  }

  &.checked {
    border-color: ${({color, theme}) => color || theme.text};
    background-color: ${({color, theme}) => color || theme.text};

    svg {
      opacity: 1;
      scale: 1;
    }
  }

  &:has(:focus-visible) {
    outline: ${({theme}) => `${theme.border_sm} ${theme.primary}`};
    outline-offset: 2px;
  }

  &:has(:disabled) {
    cursor: not-allowed;
    box-shadow: none;
    opacity: 0.65;
    filter: grayscale(0.8) brightness(0.9);
  }
`;

const Checkbox = styled.input.attrs({
  type: 'checkbox'
})`
  position: absolute;
  width: 0;
  height: 0;
  opacity: 0;
`;

export {Label, Checkbox}