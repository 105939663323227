import React, { useContext, useEffect, useState } from 'react';
import { SquareIcon } from '../square-icon';
import styled from 'styled-components';
import { Button } from '../buttons';
import { ButtonVariant } from '../buttons/enums';
import { t } from 'i18next';
import { Request } from '../../api/auth/Login.api';
import { usePopupNavigation } from '../../shared/utils/usePopupNavigation';
import { PopupType } from '../../context/popups.enum';
import { UserContextDispatchContext } from "../../context/auth/UserContext";
import { useLocation, useNavigate } from 'react-router-dom';
import { useAuthContextDispatchContext } from '../../context/auth/AuthContext';

export const VerificationEmail = () => {
  const { removeUser } = useContext(UserContextDispatchContext)
  const { logout } = useAuthContextDispatchContext();
  const { goToPopup, goBackPopup, closePopup } = usePopupNavigation()
  const navigate = useNavigate();
  const isVerified = Request.useCheckVerification();
  const location = useLocation();

  const [isSending, setIsSending] = useState(false);

  const state = location.state as { restaurantUuid: string }
  const restaurantUuidParam = state ? state.restaurantUuid : undefined

  const restaurantUuid = sessionStorage.getItem("restaurantUuid") || restaurantUuidParam || "";
  const restaurantGroupUuid = sessionStorage.getItem("restaurantGroupUuid") || "";

  const resendMail = async () => {
    setIsSending(true);
    await Request.sendVerificationMail(restaurantUuid, restaurantGroupUuid);
    setIsSending(false);
  };

  useEffect(() => {
    closePopup();
    
    let timeout: NodeJS.Timeout;

    const updateUserAndShowPopup = async () => {
      try {
        // Show the Welcome popup after a delay
        timeout = setTimeout(() => {
          goToPopup(PopupType.Welcome);
        }, 2000);
      } catch (error) {
        // Handle errors during updateUser
        console.error("Error updating user:", error);
      }
    };
  
    if (isVerified) {
      goBackPopup().then(() => {
        // Call the async function to update user and show popup
        updateUserAndShowPopup();
      });
    }
  
    // Cleanup function
    return () => {
      clearTimeout(timeout);
    };
  }, [isVerified]);

  const registerAgain = () => {
    logout();
    removeUser();
    setTimeout(() => navigate("/register"), 500)
  }

  return (
    <Container>
      <SquareIcon>📩</SquareIcon>
      <h3>{t('verification_mail_title')}</h3>
      <p>{t('verification_mail_message')}</p>
      <div style={{display: 'flex', flexDirection: 'column', gap: "10px"}}>
        <Button
          title={t('send_again')}
          variant={ButtonVariant.Link}
          handleClick={resendMail}
          loading={isSending}
        />
        <Button
          title={t('want_to_change_mail')}
          variant={ButtonVariant.Link}
          handleClick={() =>registerAgain()}
        />
      </div>
    </Container>
  );
};

const Container = styled.div`

  text-align: center;
  padding: 0 24px 24px;
  margin: auto;
  background-color: ${({theme}) => theme.background};

  & > div {
    margin: auto;
  }

  h3 {
    font-size: ${({theme}) => theme.font_xl};
    font-weight: 600;
    margin: 16px auto;
    color: ${({theme}) => theme.text};
    max-width: 20ch;
    text-align: center;
    text-wrap: balance;
  }
  
  p {
    font-size: ${({theme}) => theme.font_md};
    margin: 16px auto 24px;
    color: ${({theme}) => theme.text};
    max-width: 34ch;
    text-align: center;
    text-wrap: balance;
  }
`;