import React from 'react'
import { ProfilePopup } from '../profile/ProfileContainers';
import { RestaurantBillsContainer } from './restaurant-bills.container';

type Props = {
    isOpened: boolean
    close: () => void
};

export const RestaurantBillsContainerPopup = ({isOpened, close} : Props) => {
    return (
        <ProfilePopup noReturn isOpened={isOpened} onClose={close} showCloseButton={false} isReceiptListOrWalletPopup={true}>
            <RestaurantBillsContainer />
        </ProfilePopup>   
    )
}