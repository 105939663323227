import styled from "styled-components";

const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  font-weight: 500;

  > h4 {
    font-size: ${({theme}) => theme.font_md};
    font-weight: 500;
    display: flex;
    align-items: center;
    gap: 8px;
    margin: 0;

    label {
      height: 16px;
      width: 16px;
    }
  }
`;

export { InputContainer };