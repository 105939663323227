import React from 'react';
import { t } from 'i18next';
import { Button } from '../../elements';
import { usePopupNavigation } from '../../shared/utils/usePopupNavigation';
import { InfoPopupContainer } from '../../campaigns/bizum/StylesBizumCampaign';
import { ButtonVariant } from '../../elements/buttons/enums';
import { eventTrack } from '../../../useGaTracker';
import { useNavigate } from 'react-router-dom';
import { useFeatureIsOn } from '@growthbook/growthbook-react';
import { GB_FEATS } from '../../growthbook';

export const LoyaltyPopup = () => {
  const navigate = useNavigate();
  const {closePopup} = usePopupNavigation();

  const showZerosix = useFeatureIsOn(GB_FEATS.SHOW_ZEROSIX);

  const zerosixClick = () => {
    closePopup();
    eventTrack("00_03_profile_button_05_loyaltyPage", "00_03_profile_button_05_loyaltyPage", "00_03_profile_button_05_loyaltyPage");
    setTimeout(() => navigate("/zerosix-cards"), 500)
  }

  const pointsClick = () => {
    closePopup();
    eventTrack("00_03_profile_button_06_pointsPage", "00_03_profile_button_06_pointsPage", "00_03_profile_button_06_pointsPage")
    setTimeout(() => navigate("loyalty-cards", {state: {type: 'points'}}), 500)
  }

  const cashbackClick = () => {
    closePopup();
    eventTrack("00_03_profile_button_07_cashbackPage", "00_03_profile_button_07_cashbackPage", "00_03_profile_button_07_cashbackPage")
    setTimeout(() => navigate("/loyalty-cards", {state: {type: 'cashback'}}), 500)
  }

  return (
    <InfoPopupContainer>
        <Button variant={ButtonVariant.SecondaryCapitalize} title={t('cashback')} handleClick={cashbackClick}/>
        <Button variant={ButtonVariant.SecondaryCapitalize} title={t('points')} handleClick={pointsClick}/>
        {showZerosix &&
          <Button variant={ButtonVariant.SecondaryCapitalize} title={t('zerosix')} handleClick={zerosixClick}/>
        }
    </InfoPopupContainer>
  )
};

