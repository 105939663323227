import styled from "styled-components";

const FooterContainer = styled.footer`
  display: flex;
  flex-direction: column;
  align-items: center;

  & > div {
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
  }
  
  & > div:first-of-type {
    width: calc(100% - 48px);
    color: ${({theme}) => theme.text};
    background-color: ${({theme}) => theme.background};
    border-top: ${({theme}) => `${theme.card_border} ${theme.card_border_color}`};
    padding: 8px;
    width: 100%;
  }
  
  & > div:last-of-type {
    font-size: ${({theme}) => theme.font_xxs};
    width: 100%;
    color: ${({theme}) => theme.text_light};
    background-color: ${({theme}) => theme.header_bg};
    border: none;
    padding: 4px;
    gap: 4px;
  }
`;

export {
  FooterContainer,
}