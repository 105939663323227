import { useContext, useEffect, useState } from 'react';
import { AppContext } from '../../context/AppContext';
import { dispatchGeneralDiscountCents } from '../functions';

export const useDiscounts = () => {
  const {
    state: {
      checkout: {
        paymentSplitType,
        myTotal,
        creditCents,
        availableCreditCents,
        generalDiscount,
        loyaltyDiscountCents,
        availableLoyaltyCents,
        zeroSixSelectedVoucher,
        tip: {
          amount,
        },
      },
    },
    dispatch,
  } = useContext(AppContext);

  const [prevMyTotal, setPrevMyTotal] = useState(-1);
  const [discountApplied, setDiscountApplied] = useState(false);
  const [creditApplied, setCreditApplied] = useState(false);

  useEffect(() => {

    if (discountApplied || loyaltyDiscountCents) {
      prevMyTotal === -1 && setPrevMyTotal(myTotal)
      
      dispatch({
        type: 'UPDATE_CHECKOUT',
        payload: {
          ...(loyaltyDiscountCents > 0
            ? {
              loyaltyDiscountCents: 0
            }
            : {
              loyaltyDiscountCents: availableLoyaltyCents,
            })
        }
      });
    } else if(!discountApplied){
      dispatch({
          type: 'UPDATE_CHECKOUT',
          payload: {
              loyaltyDiscountCents: 0,
          }
      });
      setDiscountApplied(false)
    }
  }, [discountApplied, myTotal, amount, zeroSixSelectedVoucher])

  useEffect(() => {

    if (creditApplied) {
      prevMyTotal === -1 && setPrevMyTotal(myTotal)
      
      dispatch({
        type: 'UPDATE_CHECKOUT',
        payload: {
          ...(creditCents > 0
            ? {
              creditCents: 0
            }
            : {
              creditCents: availableCreditCents,
            })
        }
      });
    } else if(!creditApplied){
      dispatch({
          type: 'UPDATE_CHECKOUT',
          payload: {
              creditCents: 0,
          }
      });
      setCreditApplied(false)
    }
  }, [creditApplied, myTotal, amount, zeroSixSelectedVoucher])

  useEffect(() => {
    if(paymentSplitType !== "recharge"){
      dispatchGeneralDiscountCents(generalDiscount, myTotal - amount, dispatch);
    }
  }, [myTotal]);

  useEffect(() => {
    setPrevMyTotal(-1)
    setDiscountApplied(false)
  }, [amount]);

  return { prevMyTotal, discountApplied, setDiscountApplied, setPrevMyTotal, setCreditApplied, creditApplied };
};