import React from 'react'
import * as yup from "yup";
import { Formik } from "formik";
import { ProfileDatePicker, ProfileInput } from '../../elements/profile-input';
import { t } from 'i18next';
import { FloatingButtonContainer, Form } from './PersonalInfoFormContainers';
import { Button } from '../../elements';
import { ButtonType } from '../../elements/buttons/enums';
import { validatePhoneNumber } from '../../shared/utils/clientInputsLogic';

const validationSchema = yup.object().shape({
  first_name: yup.string().trim().notRequired(),
  last_name: yup.string().trim().notRequired(),
  city: yup.string().trim().notRequired(),
  phone: yup.string().trim().test('valid_phone', 'valid_phone', validatePhoneNumber).notRequired(),
  birthday: yup.date().nullable().default(null).notRequired(),
  genre: yup.string().trim().notRequired(),
});

export const PersonalInfoForm = ({initialValues, steps, onSubmit}: props) => {
  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(values, { setSubmitting }) => {
        onSubmit(values, setSubmitting);
      }}
    >{({values, errors, isSubmitting, handleChange, handleSubmit, setFieldValue}) => (
      <Form onSubmit={handleSubmit}>
        <ProfileInput
          name="first_name"
          placeholder={t('complete')}
          value={values.first_name}
          isCompleted={steps[0]}
          error={errors.first_name}
          onChange={handleChange}
        />
        <ProfileInput
          name="last_name"
          placeholder={t('complete')}
          value={values.last_name}
          isCompleted={steps[1]}
          error={errors.last_name}
          onChange={handleChange}
        />
        <ProfileInput
          name="city"
          placeholder={t('complete')}
          value={values.city}
          isCompleted={steps[2]}
          error={errors.city}
          onChange={handleChange}
        />
        <ProfileInput
          name="phone"
          type="phone"
          placeholder={t('complete')}
          value={values.phone}
          isCompleted={steps[3]}
          error={errors.phone}
          onChange={(phone: any) => {
            setFieldValue("phone", phone);
          }}
        />
        <ProfileDatePicker
          label="birthday"
          value={values.birthday}
          placeholder={t('select')}
          isCompleted={steps[4]}
          error={errors.birthday}
          onChange={(date: any) => {
            setFieldValue("birthday", date);
          }}
        />
        <ProfileInput
          name="genre"
          type="select"
          placeholder={t('select')}
          value={values.genre}
          isCompleted={steps[5]}
          error={errors.genre}
          onChange={handleChange}
          options={[
            {
              value: 'male',
              label: t('Male')
            },
            {
              value: 'female',
              label: t('Female')
            },
            {
              value: 'others',
              label: t('Others')
            },
          ]}
        />
        <FloatingButtonContainer>
          <Button
            type={ButtonType.Submit}
            title={t('update profile')}
            loading={isSubmitting}
            shadow
            disabled={!!errors.phone}
          />
        </FloatingButtonContainer>
      </Form>
    )}</Formik>
  );
};

type props = {
  initialValues: {
    first_name: string,
    last_name: string,
    city: string,
    phone: string,
    birthday: null | Date,
    genre: string,
  };
  steps: boolean[];
  onSubmit: (...args: any) => void
};