import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { PageHeaderComponent } from "../../elements/page-header/page-header.component";
import { Font, FontSize } from "../../elements/fonts";
import { OuterContainer, PaymentsList } from "./LatestBillsContainers";
import 'react-toastify/dist/ReactToastify.css';
import { HelpLink, Scrollbar, TabSelector } from "../../elements";
import { LatestBillsCard } from "./LatestBillsCard";
import { useReceipts } from "../../shared/utils/useReceitps";
import { getProgramsFromStorage } from "../checkout/functions/getProgramsFromStorage";
import { useViewport } from "../../context/ViewportContext";
import { PopupType } from "../../context/popups.enum";
import { usePopupNavigation } from "../../shared/utils/usePopupNavigation";

export const LatestBillsContainer = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { isMobile } = useViewport();
  const { goToPopup } = usePopupNavigation();
  const receipts = useReceipts({});
  const { loyaltyProgram } = getProgramsFromStorage();

  const [filter, setFilter] = useState<'all' | 'with cashback' | 'with credit'>('all');

  const receiptsWithCashback = receipts.filter(payment => payment.loyalty_cents > 0);
  const receiptsWithCredit = receipts.filter(payment => payment.credit_cents > 0);

  const filteredReceipts = filter === 'with cashback'
    ? receiptsWithCashback
    : filter === 'with credit'
    ? receiptsWithCredit
    : receipts;

  const goBack = () => {
    if(isMobile){
      navigate("/profile")
    } else {
      goToPopup(PopupType.Profile)
    }
  };

  return (
    <>
      <PageHeaderComponent
        text={t(isMobile ? "invoices" : 'payments with Yumminn')}
        onGoBack={goBack}
      />
      <OuterContainer>
        {isMobile &&
          <h2>{t('payments with Yumminn')}</h2>
        }
        <TabSelector
          name="filter"
          value={filter}
          options={loyaltyProgram ? ['all', 'with cashback', 'with credit'] : ['all', 'with credit']}
          onChange={setFilter}
          className='tabs'
        />
        <Scrollbar maxHeight="100%">
          <PaymentsList>
            <>
              {receipts && receipts.length > 0 && filteredReceipts.map(payment =>
                <LatestBillsCard
                  payment={payment}
                  key={'payment-' + payment.id}
                  showBadge
                />
              )}
              <p>{t('no more payments')}</p>
            </>
            {(!receipts || !receipts.length) &&
              <Font text={t("no bills available yet")} size={FontSize.Medium}/>
            }
          </PaymentsList>
        </Scrollbar>
        <HelpLink/>
      </OuterContainer>
    </>
  );
};
