import React, { useLayoutEffect, useRef, useState } from 'react';
import { InnerContainer, Label, OuterConainer, ScrollButton, Slider, TabContainer } from './ScrollTabsContainers';
import { Icon, IconType } from '../icons';
import { useHold } from '../../shared/utils/useHold';

export const ScrollTabsComponent = ({name, value, options, disabled, onChange}: Props) => {
  const tabsRef = useRef<Array<HTMLLabelElement | null>>(new Array(options.length));
  const scrollRef = useRef<HTMLDivElement | null>(null);

  const valueIndex = options.indexOf(value);
  const sliderWidth = tabsRef.current[valueIndex]?.clientWidth || 0;
  const sliderPosition = tabsRef.current[valueIndex]?.offsetLeft || 0;

  const [isOverflowing, setIsOverflowing] = useState(false);

  const handleChange = (event: any) => {
    onChange(event.target.value);
  };

  const scroll = (amount: number) => {
    scrollRef.current?.scrollBy({
      left: amount,
    });
  };

  const holdOptions = {
    interval: 100,
    speedUp: 1.25,
  };

  const leftScrollHandlers = useHold({ callback: () => scroll(-100), ...holdOptions });
  const rightScrollHandlers = useHold({ callback: () => scroll(100), ...holdOptions });

  useLayoutEffect(() => {
    scrollRef.current?.scrollTo({
      left: sliderPosition - scrollRef.current.clientWidth / 2 + sliderWidth / 2,
      behavior: 'smooth'
    });
  }, [value]);

  useLayoutEffect(() => {
    const scrollDiv = scrollRef.current;

    const checkOverflow = () => scrollDiv && setIsOverflowing(scrollDiv.scrollWidth > scrollDiv.clientWidth);

    if (scrollDiv) {
      checkOverflow();

      const resizeObserver = new ResizeObserver(checkOverflow);
  
      resizeObserver.observe(scrollDiv);

      return () => resizeObserver.disconnect();
    }
  }, [options]);

  return (
    <OuterConainer ref={scrollRef}>
      <InnerContainer>
        <TabContainer disabled={disabled}>
          {isOverflowing &&
            <ScrollButton {...leftScrollHandlers}>
              <Icon type={IconType.Arrowback}/>
            </ScrollButton>
          }
          {options.map((option, index) => (
            <Label key={'option-' + option} ref={tab => tabsRef.current[index] = tab} selected={option === value}>
              <span>{option}</span>
              <input
                type="radio"
                name={name}
                value={option}
                checked={value === option}
                disabled={disabled}
                onChange={handleChange}
              />
            </Label>
          ))}
          {isOverflowing &&
            <ScrollButton {...rightScrollHandlers}>
              <Icon type={IconType.Arrowforward}/>
            </ScrollButton>
          }
          <Slider style={{width: sliderWidth + 'px', left: sliderPosition + 'px'}}/>
        </TabContainer>
      </InnerContainer>
    </OuterConainer>
  );
};

type Props = {
  name: string;
  value: any;
  options: string[];
  disabled?: boolean;
  onChange: React.Dispatch<React.SetStateAction<any>>;
};