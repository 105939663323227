const hexToRGB = (hex:string) => {
  if (!hex.match(/#?[0-9A-Fa-f]{6}/)) {
    throw new Error(`"${hex}" is not a valid hex color.`);
  }

  const channels = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex) as RegExpExecArray;

  const r = channels ? parseInt(channels[1], 16) : 0;
  const g = channels ? parseInt(channels[2], 16) : 0;
  const b = channels ? parseInt(channels[3], 16) : 0;

  return [r, g, b];
};

const hexToHSL = (hex: string) => {
  let [r, g, b] = hexToRGB(hex);

  r /= 255;
  g /= 255;
  b /= 255;

  const max = Math.max(r, g, b);
  const min = Math.min(r, g, b);

  let h = 0;
  let s = 0;
  let l = (max + min) / 2;

  if (max !== min) {
    const d = max - min;

    s = l > 0.5 ? d / (2 - max - min) : d / (max + min);

    switch(max) {
      case r:
        h = ((g - b) / d + (g < b ? 6 : 0)) / 6;
        break;
      case g:
        h = ((b - r) / d + 2) / 6;
        break;
      case b:
        h = ((r - g) / d + 4) / 6;
        break;
    }
  }

  h = Math.round(h * 360);
  s = Math.round(s * 100);
  l = Math.round(l * 100);

  return { h, s, l };
};

export const getContrastRatio = (color1: string, color2: string) => {
  const getLuminance = (rgb: number[]) => {
    const lum = rgb.map((v) => {
      v /= 255;
      
      return v <= 0.03928
        ? v / 12.92
        : Math.pow((v + 0.055) / 1.055, 2.4);
    })
    
    return lum[0] * 0.2126 + lum[1] * 0.7152 + lum[2] * 0.0722;
  };

  const luminance1 = getLuminance(hexToRGB(color1));
  const luminance2 = getLuminance(hexToRGB(color2));
  
  const brightest = Math.max(luminance1, luminance2);
  const darkest = Math.min(luminance1, luminance2);

  return (brightest + 0.05) / (darkest + 0.05);
};

export const getTextColor = (color: string,) => {
  return getContrastRatio(color, '#212121') > getContrastRatio(color, '#FFFFFF') ? '#212121' : '#FFFFFF';
};

export const generatePalette = (color: string, prefix: string) => {
  const {h, s, l} = hexToHSL(color)

  const valid = (number: number) => Math.min(Math.max(number, 0), 100);

  return {
    [prefix]: color,
    [prefix + '_90']: `hsl(${h}, ${s && valid(s - 35)}%, ${Math.min(l, 30)}%)`,
    [prefix + '_80']: `hsl(${h}, ${s && valid(s + 15)}%, ${valid(l - 25)}%)`,
    [prefix + '_70']: `hsl(${h}, ${s && valid(s + 15)}%, ${valid(l - 20)}%)`,
    [prefix + '_60']: `hsl(${h}, ${s && valid(s - 10)}%, ${valid(l - 10)}%)`,
    [prefix + '_50']: `hsl(${h}, ${s && valid(s - 5)}%, ${valid(l + 10)}%)`,
    [prefix + '_40']: `hsl(${h}, ${s && valid(s - 5)}%, ${Math.max(l, 80)}%)`,
    [prefix + '_30']: `hsl(${h}, ${s && valid(s + 20)}%, ${Math.max(l, 90)}%)`,
    [prefix + '_20']: `hsl(${h}, ${s && valid(s + 20)}%, ${Math.max(l, 95)}%)`,
    [prefix + '_10']: `hsl(${h}, ${s && valid(s - 15)}%, ${Math.max(l, 95)}%)`,
    [prefix + '_00']: `hsl(${h}, ${s && Math.min(valid(s - 30), 35)}%, ${Math.max(l, 70)}%)`,
    [prefix + '_a05']: `hsla(${h}, ${s}%, ${l}%, 0.05)`,
    [prefix + '_a10']: `hsla(${h}, ${s}%, ${l}%, 0.1)`,
    [prefix + '_a20']: `hsla(${h}, ${s}%, ${l}%, 0.2)`,
    [prefix + '_a30']: `hsla(${h}, ${s}%, ${l}%, 0.3)`,
    [prefix + '_a40']: `hsla(${h}, ${s}%, ${l}%, 0.4)`,
    [prefix + '_a50']: `hsla(${h}, ${s}%, ${l}%, 0.5)`,
    [prefix + '_a60']: `hsla(${h}, ${s}%, ${l}%, 0.6)`,
    [prefix + '_a70']: `hsla(${h}, ${s}%, ${l}%, 0.7)`,
    [prefix + '_a80']: `hsla(${h}, ${s}%, ${l}%, 0.8)`,
    [prefix + '_a90']: `hsla(${h}, ${s}%, ${l}%, 0.9)`,
  };
};

/*
hsl(hue(0-360)🌈, saturation(0-100)💥, lightness(0-100)💡)

Darker colors tend to be slightly more saturated than lighter ones.

If you need a very dark/light variation of a color, use a fixed
lightness value instead of subtraction/addition to avoid
pure black/white
*/