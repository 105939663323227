import React from 'react';
import styled from 'styled-components';
import { ResponsiveSheet } from '../../elements';

const OuterContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
    height: 100%;
    max-height: 100vh;
    color: ${({theme}) => theme.text};
    padding: 0 20px;
    overflow: hidden;

    @media (min-width: 768px) {
        gap: 24px;
    }

    h2 {
        font-size: ${({theme}) => theme.font_md};
        font-weight: 600;
        text-align: center;
        margin: 16px 0 0;

        @media (min-width: 768px) {
            font-size: ${({theme}) => theme.font_lg};
            margin: 0;
        }
    }

    .tabs {


        label {
            font-weight: 500;
            white-space: nowrap;
            flex: 1;
            padding: 8px 16px;
        }
    }

    & > div:last-of-type {
        flex: 1;
        height: 0;
        margin: -8px -8px 0 0;
        width: calc(100% + 8px);
    }
`;

const PaymentsList = styled.ul`
    list-style: none;
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 8px 8px 0 0;
    margin: 0 0 16px;

    & > p {
        font-size: ${({theme}) => theme.font_xs};
        font-weight: 400;
        text-align: center;
        margin: 0;
    }
`;

const PaymentListItem = styled.li`
    display: flex;
    align-items: center;
    gap: 12px;
    border: 1px solid ${({theme}) => theme.gray_30};
    border-radius: ${({theme}) => theme.radius_sm};
    padding: 12px;
    box-shadow: ${({theme}) => theme.shadow_xs};
    margin: 0;
    position: relative;
    cursor: pointer;

    h3 {
        font-size: ${({theme}) => theme.font_md};
        line-height: 1.2em;
        font-weight: 600;
        margin: 0 0 8px;
    }
    
    span {
        font-size: ${({theme}) => theme.font_xs};
        font-weight: 600;
        display: flex;
        align-items: center;
        gap: 4px;
        color: ${({theme}) => theme.gray_70};
    }
    
    b {
        font-size: ${({theme}) => theme.font_md};
        font-weight: 700;
        margin-left: auto;
    }

    svg {
        flex-shrink: 0;
    }
`;

const CashbackBadge = styled.div`
    position: absolute;
    top: -8px;
    right: -8px;
    font-size: ${({theme}) => theme.font_md};
    font-weight: 600;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 4px;
    height: 20px;
    width: 38px;
    color: #FFF;
    background-color:#F4B300;
    border-radius: ${({theme}) => theme.radius_round};
    padding: 4px;
`;

const AmountWrapper = styled.div`
    display: grid;
    grid-column-start: 4;
    grid-column-end: 6;
    justify-content: end;
`;

const LatestBillsInnerContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(6, minmax(0, 1fr));
`;

const DateWrapper = styled.div`
    grid-column-start: 1;
    grid-column-end: 4;
`;

const ArrowIconDiv = styled.div<ArrowIconWrapperProps>`
    display: grid;
    justify-content: end;
    margin-right: 12px;
    margin-top: 4px;
`;

const CreditHistoryPopupContainer = styled(ResponsiveSheet).attrs({
    landscapePosition: 'left',
  })`
  
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 16px 0 0;
    box-sizing: border-box;
    margin: 42px 0 0;
  
    @media (orientation: landscape) {
      margin: 0 0 0 68px;
      overflow: hidden;
      width: calc(35%);
  
      @media (max-width: 1024px){
        width: calc(45%);
      }
    }
  
`;


const ArrowIconWrapper = ({
    onClick,
    children
}: ArrowIconDivProps)=> {
    return (
      <ArrowIconDiv
      onClick={onClick}
      >
          {children}
      </ArrowIconDiv>
    )
};

type ArrowIconDivProps = {
    onClick: () => void;
    children: JSX.Element|JSX.Element[]
}

type ArrowIconWrapperProps = {
    onClick: () => void;
    children: JSX.Element|JSX.Element[]
}


export {OuterContainer, PaymentsList, PaymentListItem, CashbackBadge, AmountWrapper, LatestBillsInnerContainer, DateWrapper, ArrowIconWrapper, CreditHistoryPopupContainer};

