import styled, { css } from "styled-components";

const resetInput = css`
  font-family: ${({theme}) => theme.typography};
  font-size: ${({theme}) => theme.font_md};
  color: currentColor;
  background-color: transparent;
  border: none;
  height: 100%;
  margin: 0;
  padding: 0;
  
  &::placeholder {
    color: ${({theme}) => theme.text_a50};
  }

  &:focus {
    outline: none;
  }

  &[type=number], &[type=tel] {
    text-align: right;
  }

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    appearance: none;
  }
`;

const errorStyles = css`
  font-size: ${({theme}) => theme.font_xs};
  line-height: 1;
  color: ${({theme}) => theme.danger};
  display: flex;
  margin: 4px 0 0;
`;

const focusStyles = css`
  outline: ${({theme}) => `${theme.border_sm} ${theme.primary}`};
  outline-offset: 2px;
`;

const disabledStyles = css`
  color: ${({theme}) => theme.text_a30};
  box-shadow: none;
  opacity: 0.65;
  filter: brightness(0.9);
  cursor: not-allowed;
  
  input {
    cursor: not-allowed;
  }
`;

const buttonStyles = css`
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: ${({theme}) => theme.typography};
  font-weight: 600;
  color: currentColor;
  background-color: transparent;
  border: none;
  border-radius: ${({theme}) => theme.radius_round};
  overflow: visible;
  padding: 0;
  margin: 0;

  &:focus {
    outline: none;
  }

  &:focus-visible {
    outline: 2px solid ${({theme}) => theme.primary};
    outline-offset: 2px;
    box-shadow: 0 2px 0 #0001;
    filter: brightness(1.03)
  }
`;

const BaseContainer = styled.div<{component?: string|null, noBorder?: boolean}>`
  position: relative;
  flex: 1;

  label {
    position: relative;
    width: 100%;
  }

  h5 {
    font-size: ${({theme}) => theme.font_md};
    font-weight: 600;
    margin: 0 0 16px 0;

    &::first-letter {
      text-transform: capitalize;
    }
  }
  
  h6 {
    font-size: ${({theme}) => theme.font_md};
    font-weight: 300;
    margin: 0 0 8px;

    &::first-letter {
      text-transform: capitalize;
    }
  }

  i {
    ${errorStyles}
  }

  .required::after {
    content: '*';
    color: ${({theme}) => theme.danger};
  }

  .error {
    color: ${({theme}) => theme.danger};
  }
`;

export const InputContainer = styled(BaseContainer)`
  flex: 1;
  
  label {
    display: flex;
    width: 100%;
    height: 48px;
    color: ${({theme}) => theme.text};
    background-color: ${({theme}) => theme.input_bg};
    border: ${({theme, noBorder}) => !noBorder ? `${theme.border_sm} ${theme.input_border}` : ''};
    border-radius: ${({theme}) => theme.radius_sm};
    padding: 8px 12px;
    box-sizing: border-box;
    box-shadow: ${({theme}) => theme.shadow_inset_xs};
  }
  
  h6 {
    font-size: ${({theme}) => theme.font_md};
    font-weight: 500;
    margin: 0;
    width: min-content;
    line-height: 1.2;
    max-width: var(--max_width);
    align-self: center;
    overflow: visible;
    position: relative;
    transition: 0.2s ease-in-out;

    span {
      background: linear-gradient(transparent 30%, ${({theme}) => theme.input_bg} 30%);
      padding: 0 0.25em;
      margin: 0 -0.25em;
    }
  }
  
  hr {
    display: block;
    border: none;
    border-right: ${({theme}) => `${theme.border_sm} ${theme.input_border}`};
    margin: 0 12px;
    width: 0;
    transition: 0.2s ease-in-out;
  }
  
  .wrapper, div.error {
    flex: 1;
    display: flex;
    align-items: center;
    gap: 8px;
  }
  
  input {
    ${resetInput}
    width: 100%;
  }

  button {
    ${buttonStyles}
  }

  &:has(i) label {
    border-color: ${({theme}) => theme.danger};
  }

  &:has(input:not(:read-only):focus) label {
    ${focusStyles}
  }
  
  &:has(input:not(:read-only):focus), &:has(input:not(:placeholder-shown)) {
    h6 {
      font-size: ${({theme}) => theme.font_sm};
      translate: 0 -24px;
      max-width: 0;
      white-space: nowrap;
    }

    &:has(input:read-only) {
      filter: brightness(0.96);
      cursor: default; 
    }
  
    hr {
      margin: 0 -1px;
      opacity: 0;
    }
  }

  &:has(:disabled) label {
    ${disabledStyles}
  }
`;

export const PhoneInputContainer = styled(BaseContainer)`
  .react-tel-input {
    width: 100%;
    height: ${({component}) => component ? '' : "48px"};
    padding: ${({component}) => component ? '0 12px' : "8px 12px;"};
    background-color: ${({theme}) => theme.input_bg};
    border: ${({theme, component}) => component ? 'none' : `${theme.border_sm} ${theme.input_border}`};
    box-shadow: ${({theme, component}) => component ? '' : theme.shadow_inset_xs};
    border-radius: ${({theme}) => theme.radius_sm};
    box-sizing: border-box;
    
    input {
      font-family: ${({theme}) => theme.typography};
      font-weight: ${({component}) => component ? 600 : 500};
      font-size: ${props => props.theme.font_md};
      color: ${({theme}) => theme.text};
      background-color: transparent;
      border: none;
      line-height: 20px;
      width: 100%;
      height: 100%;
      border: none;
    }

    .form-control{
      padding-left: ${({component}) => component ? '30px' : "48px"};
    }

    &:has(:not(:read-only):focus) {
      outline: ${({theme, component}) => component ? 'none' : `${theme.border_sm} ${theme.primary}`};
      outline-offset: 2px;
    }

    &:has(::placeholder) {
      font-family: ${({theme}) => theme.typography};
      color: ${({theme}) => theme.text_a50};
    }

    .flag-dropdown {
      background-color: transparent;
      border: none;
      border-right: ${({theme}) => `${theme.border_sm} ${theme.input_border}`};
      margin: 8px 0;
    }

    .country-list {
      bottom: 28px;
      border-radius: ${({theme}) => theme.radius_sm};
      border: ${({theme}) => `${theme.border_sm} ${theme.input_border}`};
      box-shadow: ${({theme}) => theme.shadow_inset_xs};
      background-color: ${({theme}) => theme.background_rised};

      .country-name {
        color: ${({theme}) => theme.text};
      }
      
      .country .dial-code {
        color: ${({theme}) => theme.text_a50};
      }

      .country:hover {
        background-color: ${({theme}) => theme.primary_a20};
      }

      .highlight {
        background-color: ${({theme}) => theme.primary_a40};
      }
    }

    .selected-flag {
      padding: 0;
      width: ${({component}) => component ? '0' : "38px"};

      .arrow {
        border-top-color: ${({theme}) => theme.text_a80};
      }
      
      .up {
        border-bottom-color: ${({theme}) => theme.text_a80};
      }
      
      &.open {
        background-color: transparent;
      }
    }
  }

  .error {
    position: absolute;
    top: 14px;
    right: 12px;
  }
  
  &:has(i) .react-tel-input {
    border-color: ${({theme}) => theme.danger};
  }
`;

export const DateInputContainer = styled(InputContainer)`
  .react-datepicker {
    font-family: ${({theme}) => theme.typography};
    font-size: ${({theme}) => theme.font_md};
    border-radius: 20px;
    border-color: ${({theme}) => theme.gray_40};
    filter: drop-shadow(0 10px 10px #00000020);

    .react-datepicker__header {
      background-color: transparent;
      border: none;
    }

    .react-datepicker__navigation {
      margin: 14px 8px 14px 8px;
      align-items: center;
      background-color: ${({theme}) => theme.background};
    }

    .react-datepicker__navigation-icon::before {
      border-color: ${({theme}) => theme.text};

      span {
        top: 3px;
      }
    }

    .react-datepicker__current-month {
      font-size: ${({theme}) => theme.font_lg};
      text-transform: capitalize;
      margin: 12px 0;
      color: ${({theme}) => theme.text_dark};
    }
    
    .react-datepicker__month-select, .react-datepicker__year-select {
      border-radius: 999px;
      color: ${({theme}) => theme.text};
      border: 2px solid ${({theme}) => theme.gray_60};
      font-size: 16px;
      padding: 6px 24px;
      margin: 0 4px;
      appearance: none;
      background-color: ${({theme}) => theme.background};
      background-image: url("data:image/svg+xml;utf8,<svg fill='var(--primary)' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
      background-repeat: no-repeat;
      background-position-x: 100%;
      background-position-y: 3px;
      text-align: center;

      &:focus-visible {
        outline: none;
        border-color: ${({theme}) => theme.primary};
      }
    }

    .react-datepicker__day, .react-datepicker__day-name {
      padding: 5px;
      color: ${({theme}) => theme.text_dark};
    }

    .react-datepicker__day-names {
      margin-top: 16px;
      background-color: ${({theme}) => theme.primary};
    }

    .react-datepicker__day-name {
      color: ${({theme}) => theme.background};
      font-weight: 600;
    }
    
    .react-datepicker__day--selected, .react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range, .react-datepicker__month-text--selected, .react-datepicker__month-text--in-selecting-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--selected, .react-datepicker__quarter-text--in-selecting-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--selected, .react-datepicker__year-text--in-selecting-range, .react-datepicker__year-text--in-range {
      background-color: ${({theme}) => theme.primary} !important;
      border-radius: 9999px;
      color: ${({theme}) => theme.background};
      font-weight: 600;
    }

    [type='text']:focus, [type='email']:focus, [type='url']:focus, [type='password']:focus, [type='number']:focus, [type='date']:focus, [type='datetime-local']:focus, [type='month']:focus, [type='search']:focus, [type='tel']:focus, [type='time']:focus, [type='week']:focus, [multiple]:focus, textarea:focus, select:focus{
      border-color: ${({theme}) => theme.primary} !important;
      --tw-ring-color: ${({theme}) => theme.primary} !important;
    }
  }
`;

export const TextAreaContainer = styled(BaseContainer)`
  textarea {
    display: flex;
    font-family: ${({theme}) => theme.typography};
    font-size: ${({theme}) => theme.font_md};
    color: ${({theme}) => theme.text};
    background-color: ${({theme}) => theme.input_bg};
    border: ${({theme}) => `${theme.border_sm} ${theme.input_border}`};
    border-radius: ${({theme}) => theme.radius_sm};
    box-shadow: ${({theme}) => theme.shadow_inset_xs};
    resize: none;
    width: 100%;
    padding: 8px 12px;
    box-sizing: border-box;
    
    &:focus-visible {
      ${focusStyles}
    }

    &::placeholder {
      color: ${({theme}) => theme.text_a50};
    }

    &:disabled {
      ${disabledStyles}
    }
  }
  
  .comment{
      height: 70px;
      
      @media (orientation: landscape) {
        height: 100px;
        width: 98%;
      }
  }
  
  &:has(i) textarea {
    border-color: ${({theme}) => theme.danger};
  }

`;

export const ClipboardInputContainer = styled(InputContainer)`
  h6 {
    font-size: ${({theme}) => theme.font_sm};
    translate: 0 -24px;
    max-width: 0;
    white-space: nowrap;
  }

  label > span {
    flex: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    line-height: 1.75;
    color: ${({theme}) => theme.text_a80};
  }

  button {
    display: flex;
    align-items: center;
    gap: 8px;

    span {
      text-decoration: underline;
      display: block;

      &::first-letter {
        text-transform: capitalize;
      }
    }
  }

  &:has(input:not(:read-only):focus), &:has(input:not(:placeholder-shown)) {
    hr {
      margin: 0 12px;
      opacity: 1;
    }
  }
`;