import { t } from 'i18next';
import React, { useEffect } from 'react'
import { PaymentErrorContainer, Message, Sorry, TryAgain, BackArrow, ButtonTryAgain } from './PaymentErrorContainers'
import paymentErrorImg from '../../assets/images/paymentError.svg';
import { Icon, IconType } from '../icons';
import FinalButtonPay from '../../pages/checkout/components/FinalButtonPay';
import { useLocation, useNavigate } from 'react-router-dom';
import { PopupType } from '../../context/popups.enum';
import { usePopupNavigation } from '../../shared/utils/usePopupNavigation';

export const PaymentErrorComponent = () => {
  const navigate = useNavigate();
  const { goToPopup, closePopup } = usePopupNavigation();
  const location = useLocation();
  const state = location.state as { reason: string }
  const reason = state ? state.reason : undefined
  
  const goBack = () => {
    navigate('/checkout', {state: {from: 'paymentErrorGoBack'}});
  }

  const handleClick = () => {
    if(reason !== 'updatedRemaining'){
      navigate('/checkout', {state: {from: 'paymentError'}});
      goToPopup(PopupType.PaymentMethods)
    } else {
      navigate('/checkout', {state: {from: 'paymentErrorUpdatedRemaining'}});
    }
  }

  useEffect(() => {
    closePopup();
  }, []);

  return (
    <PaymentErrorContainer>
      <BackArrow onClick={goBack}>
        <Icon type={IconType.Arrowback}/>
      </BackArrow>
      <Message>
        <img src={paymentErrorImg} width={160} height={160} alt= 'paymentError' />
        <Sorry>{t('We are sorry, there has been an error. But do not worry, we have not charged you')}</Sorry>
        <TryAgain>{t('You can try again or use a different payment method')}</TryAgain>
      </Message>
      <ButtonTryAgain>
        <FinalButtonPay
          title={t('try again')}
          onClick={handleClick}
          enabled={true}
        />
      </ButtonTryAgain>
    </PaymentErrorContainer>
  );
};