import React, { useContext } from 'react';
import { t } from 'i18next';
import { AppContext } from '../../context/AppContext';
import { Trans } from 'react-i18next';
import { QRContainer } from '../register/RegisterContainers';
import { Button, Icon, IconType } from '../../elements';
import { WelcomeContainer } from './WelcomeContainers';
import { usePopupNavigation } from '../../shared/utils/usePopupNavigation';
import { PopupType } from '../../context/popups.enum';
import { postError } from '../../shared/utils/postError';

type Props = {
  goToMenu: () => void,
  productsInTable?: boolean
  payment?: number
};

export const WelcomeNoCashback = ({productsInTable, goToMenu: handleMenu, payment}: Props) => {
  const {
    state: {
      restaurant: {
        allow_to_pay_now,
        allow_to_pay_later,
      },
    }
  } = useContext(AppContext);

  const { goToPopup, closePopup } = usePopupNavigation();

  const isOP = allow_to_pay_now || allow_to_pay_later;

  const goToBill = () => goToPopup(PopupType.Checkout);

  const goToMenu = () => closePopup().then(handleMenu).catch((err: any) => {
    postError(err);
    console.log(err)
  });

  return (
    <WelcomeContainer>
      {isOP && <h2>🎉🎉🎉</h2>}
      <div style={{marginTop:'30px'}}></div>
      <h2>{t('welcome')}</h2>
      {!isOP && !payment &&
        <p>
          <Trans i18nKey={'welcome_no_cashback'}>
            <b><u>Paga la cuenta aquí</u></b> y ¡ahorra tiempo!
          </Trans>🕐
        </p>
      }
      {!isOP && !productsInTable && !payment &&
        <div>
          <QRContainer>
            <div>
              <Icon type={IconType.QR}/>
            </div>
            <p>
              <Trans i18nKey={'welcome_no_cashback_no_products'}>
                <b>Escanea el QR</b> y no esperes al camarero
              </Trans>
            </p>
          </QRContainer>
        </div>
      }
      {!isOP && productsInTable && !payment &&
        <div>
            <p>
              {t('welcome_no_cashback_with_products')}
            </p>
        </div>
      }
       {isOP && !payment &&
        <div>
            <p style={{textAlign: 'center'}}>
              {t('welcome_no_cashback_op')} 😎
            </p>
        </div>
      }
      {payment &&
        <div>
        <p style={{textAlign: 'center', padding: "0 20px"}}>
          {t('welcome_no_cashback_after_payment_message')} 😎🚀
        </p>
    </div>
      }
    
      {!isOP && !productsInTable && !payment &&
        <Button
          title={t('great_see_menu')}
          handleClick={goToMenu}
        />
      }
      {!isOP && productsInTable && !payment &&
        <Button
          title={t('pay your bill')}
          handleClick={goToBill}
        />
      }
    
    </WelcomeContainer>
  );
};