import * as React from 'react';
import { useContext } from 'react';
import { initiatePayment} from '../monei/monei.service';
import { AppContext } from '../../context/AppContext';
import { toast } from 'react-toastify';
import { t } from 'i18next';
import PropTypes, {InferProps} from 'prop-types';
import { useNavigate } from "react-router-dom";
import { Button } from '../buttons';
import { UserContext, UserContextDispatchContext } from '../../context/auth/UserContext';
import { postError } from '../../shared/utils/postError';
import styled from 'styled-components';
import { TextInput } from '../inputs';
import { getEmailFunctions } from '../../shared/utils/clientInputsLogic';
import { EMAIL_REGEX } from '../../shared/constants/regex';
import { useCommentAndAditionalInfo } from '../../shared/utils/useCommentAndAditionalInfo';

const Container = styled.div`
  min-height: 44px;
  flex-shrink: 0;
  
`;

export function CashPaymentComponent({
  amount,
  tip,
  myName,
  howPaySelected,
  loyaltyDiscount,
  phoneForCollect,
  nameForCollect,
  bizumPromotionCents,
  restaurantCreditId,
  setLoading
}: CashPaymentComponentProps) {

  const {
    state: {
      checkout: {
        myTotal,
        selectedItems,
        selectedModifiers,
        clientCommission,
        orderAndPayItems,
        freeProducts,
        paymentMethod,
        zeroSixSelectedVoucher,
        generalDiscountCents,
        productDiscountCents,
        codeCoupon,
        codeCouponCents,
        creditCents,
        credit,
        checkoutFlow,
        selectedSlot,
        deliveryFeeCents,
      },
      addressData,
      tableNumber: tableNumberFromContext,
    },
  } = useContext(AppContext);
  
  const [emailError, setEmailError] = React.useState('');
  const { user: { email } } = useContext(UserContext);
  const isEmailRequired = true;
  const [cashPaymentDisabled, setCashPaymentDisabled] = React.useState(!!emailError || (!email || !EMAIL_REGEX.test(email)));
  const { updateUser } = useContext(UserContextDispatchContext);
  const {checkEmail, handleEmailChange} = getEmailFunctions({email, emailError, isEmailRequired, setEmailError, updateUser});
  const serviceCommision =  Math.floor(((myTotal-tip) * clientCommission)/ 100);
  const restaurantId = Number(sessionStorage.getItem('restaurantId'));
  const tableNumber = sessionStorage.getItem("tableNumber") || tableNumberFromContext || "";
  const date = new Date().toISOString(). //ex: '2012-11-04T14:51:06.157Z'
      replace(/T/, '_').      // replace T with a space
      replace(/\..+/, '')     // delete the dot and everything after
  const orderId = date + '-' + restaurantId + '-' + tableNumber
  const bizumDiscountCents = paymentMethod === 'bizumpay' && (myTotal - tip) >= 1500 ? bizumPromotionCents : 0;
  const zeroSixDiscountCents = zeroSixSelectedVoucher.code != "" ? (!zeroSixSelectedVoucher.is_percentage ? zeroSixSelectedVoucher.value * 100 : Math.round((((myTotal - tip) *  zeroSixSelectedVoucher.value) / 100))) : 0
  const amountMinusDiscounts = Math.max(amount - generalDiscountCents - productDiscountCents - codeCouponCents, 0);
  const amountMinusDiscountsAndCredit = Math.max(amountMinusDiscounts - creditCents, 0);
  const creditUsed = Math.min(creditCents, amountMinusDiscounts);
  const amountMinusAllDiscounts = Math.max(amountMinusDiscountsAndCredit, 0);
  const finalLoyaltyDiscount = Math.max(loyaltyDiscount > amountMinusAllDiscounts ? amountMinusAllDiscounts : loyaltyDiscount, 0)
  const finalAmount = Math.max(amount - finalLoyaltyDiscount - generalDiscountCents - productDiscountCents - codeCouponCents - creditCents - bizumDiscountCents - zeroSixDiscountCents, 0) + Number(serviceCommision) + tip + deliveryFeeCents;
  let paymentId: any = null;
  let paymentUuid: any = null;
  const navigate = useNavigate();

  const comment = useCommentAndAditionalInfo();

  // User shouldn't be able to get to this popup if there is an error with the fee, but this is just in case.
  const invalidDeliveryFee = Number.isNaN(deliveryFeeCents);

  React.useEffect(() => {
    setCashPaymentDisabled(!!emailError || (!email || !EMAIL_REGEX.test(email)));
  }, [email]);


  const moneiTokenHandler = async (paymentToken: any) => {
    if (invalidDeliveryFee) {
      toast.error(t('calculate_delivery_fee_error'));
      setLoading(false);
      return;
    }
    
    setLoading(true);
    
    const paymentData = {
      captureDelayHours: 1,
      base_amount: amount,
      tip: Number(Number(tip).toFixed(2)),
      delivery_fee_cents: deliveryFeeCents || 0,
      split_type: howPaySelected,
      name: myName,
      user: null,
      client: localStorage.getItem("client_id") != 'undefined' ? localStorage.getItem("client_id") : null,
      yumiwi_client_uuid: localStorage.getItem("yumiwiClientUuid") != 'undefined' ? localStorage.getItem("yumiwiClientUuid") : null,
      amount: {
        currency: 'EUR',
        value: finalAmount
      },
      restaurant: restaurantId,
      table: tableNumber,
      orderId: orderId,
      items: selectedItems,
      modifiers: selectedModifiers,
      origin: window.location.origin,
      general_discount_cents: generalDiscountCents,
      product_discount_cents: productDiscountCents,
      code_coupon: codeCoupon?.code ? codeCoupon.code : null,
      code_coupon_cents: codeCouponCents,
      loyalty_discount_cents: finalLoyaltyDiscount,
      credit_cents: creditUsed,
      credit_id: credit,
      service_commission: serviceCommision,
      monei_token: paymentToken ? paymentToken : null,
      session_id: null,
      type: "cash",
      orderAndPayItems: checkoutFlow === 'OP' ? orderAndPayItems : [],
      freeProducts,
      comment: comment,
      client_phone: phoneForCollect,
      client_name: nameForCollect,
      client_email: email ? email : null,
      save_card_checked: false,
      selected_card: null,
      bizum_promotion_cents: bizumDiscountCents,
      device_token: localStorage.getItem('deviceToken'),
      zerosix_discount_cents: zeroSixDiscountCents,
      selected_zerosix_voucher_code: zeroSixSelectedVoucher.code != "" ? zeroSixSelectedVoucher.code : null, 
      delivery_data: addressData,
      selected_slot: selectedSlot ? selectedSlot.id : null,
      restaurant_credit_id: restaurantCreditId,
  }



  await initiatePayment(paymentData)
    .then((result: any) => {
      setLoading(false);
      if ("cash_payment" in result && result["cash_payment"]) {
        const order = result && "order_code" in result ? result["order_code"] : 0;
        navigate(`/cash-payment?order=${order}`)
      }
      if ("reason" in result && result["reason"]){
          toast.error(result["reason"], {
            theme: 'colored',
        });
      } else {
        if ("code" in result && result["code"] == 333){
          toast.error(t('Remaining has been updated'), {
              theme: 'colored',
          });
          setTimeout(() => navigate("/payment-error", {state: {reason: 'updatedRemaining'}}), 2000)
        } else if ("error_code" in result && result["error_code"] == 444){
          if ("uuid" in result && result["uuid"]){
            toast.error(t('The restaurant does not accept any more orders'), {
              theme: "colored",
              position: "bottom-center",
              closeOnClick: true,
              draggable: true,
              progress: undefined,
            });
            setTimeout(() => navigate('/restaurant_group/'+ result["uuid"]), 2000)
          }
        } else if ("error_code" in result && result["error_code"] == 445){
            toast.error(t(result["message"]), {
              theme: "colored",
              position: "bottom-center",
              closeOnClick: true,
              draggable: true,
              progress: undefined,
            });
            setTimeout(() => navigate('?home/id=' + restaurantId + 'table=0'), 2000)
        } else {
            paymentId = result && "paymentId" in result ? result["paymentId"] : null;
            paymentUuid = result && "uuid" in result ? result["uuid"] : null;
            const loyaltyGrant = result && "loyalty" in result ? result.loyalty["grant"] : 0;
            const orderCode = result && "order_code" in result ? result["order_code"] : 0;
            const orderUuid = result && "order_uuid" in result ? result["order_uuid"] : '';
            const orderGroupCode = result && "order_group" in result ? result["order_group"]["code"] : 0;
            const orderGroupCreatedAt = result && "order_group" in result ? result["order_group"]["created_at"] : 0;
            const newOrderGroup = result && "order_group" in result ? result["order_group"]["new"] : 0;
            const zerosix_points = result && "zerosix_points" in result ? result["zerosix_points"] : 0;
            const last4 = result && "last4" in result ? result["last4"] : null;
            const show_save_card_popup = result && "show_save_card_popup" in result ? result["show_save_card_popup"] : 0;
            sessionStorage.setItem("show_save_card_popup", show_save_card_popup)
            const saved_card = result && "saved_card" in result ? result["saved_card"] : 0;
            sessionStorage.setItem("saved_card", saved_card)
            const delay_popup_seconds = result && "delay_popup_seconds" in result ? result["delay_popup_seconds"] : 0;
            sessionStorage.setItem("delay_popup_seconds", delay_popup_seconds)
            const first_card = result && "first_card" in result ? result["first_card"] : 0;
            sessionStorage.setItem("first_card", first_card)
            if (last4){
              sessionStorage.setItem("last4", last4);
            }
            sessionStorage.setItem("zerosix_points", zerosix_points);
            sessionStorage.setItem("loyaltyGrant", loyaltyGrant);
            paymentId && paymentId !== 'null' && sessionStorage.setItem("paymentId", paymentId);
            sessionStorage.setItem("orderCode", orderCode);
            sessionStorage.setItem("orderUuid", orderUuid);
            sessionStorage.setItem("orderGroupCode", orderGroupCode);
            sessionStorage.setItem("orderGroupCreatedAt", orderGroupCreatedAt);
            sessionStorage.setItem("newOrderGroup", newOrderGroup);
            sessionStorage.setItem('givenTip', tip.toString());
            processPaymentResponse(result);
        }
      }
       
    })
    .catch((e:any) => {
      setLoading(false);
      postError(e);
    })
    .finally(() => {
      setLoading(false);
    })
  };

  // ///////////////////////
  // PROCESS PAYMENT
  const processPaymentResponse = (paymentRes: any) => {
    const response = paymentRes["response"];
    if (response.next_action && response.next_action.type.includes("CHALLENGE")) {
      window.open(response.next_action.redirect_url, "_self")
        // ///////////////////////
        // PAYMENT CONTAINS ACTION
    } else {
        // ///////////////////////
        // PAYMENT WITH NO ACTION
        if (response.status == 'SUCCEEDED') {
          console.log("SUCCESS")
        } else {
            toast.error("Error performing payment", {
              theme: 'colored',
          });
          
        }
    }
  };
  

  

  return (
    <Container style={{opacity: 1, pointerEvents: 'all'}}>
        <div style={{display: 'flex', flexDirection: 'column', gap: '10px'}}>
          <TextInput
          label={t('mail')}
          name="email"
          placeholder={t('email')}
          onChange={handleEmailChange}
          onBlur={() => checkEmail()}
          value={email}
          error={emailError ? t('valid_email').toString() : undefined}
          required={true}
          />
          <Button
          title={t('continue with cash')}
          handleClick={() => moneiTokenHandler(null)}
          disabled={cashPaymentDisabled}/>
        </div>
    </Container>
  );
}

CashPaymentComponent.propTypes = {
  amount: PropTypes.number.isRequired,
  loyaltyDiscount:PropTypes.number.isRequired,
  tip: PropTypes.number.isRequired,
  howPaySelected: PropTypes.string.isRequired,
  myName: PropTypes.string.isRequired,
};

type CashPaymentComponentProps = {
  phoneForCollect?: string;
  nameForCollect?: string
  bizumPromotionCents: number;
  setPaymentResult?: any
  setFlow?: any
  restaurantCreditId?: number,
  setLoading: any
} & InferProps<typeof CashPaymentComponent.propTypes>;

