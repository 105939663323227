import { useEffect, useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { ButtonVariant } from "../../elements/buttons/enums";
import { Button, HelpLink, Loading } from "../../elements";
import { Icon, IconType } from "../../elements/icons";
import { UserContextDispatchContext } from "../../context/auth/UserContext";
import { useAuthContextDispatchContext } from "../../context/auth/AuthContext";
import { UseUserProfile } from "../../api/users/Users.api";
import { InnerButtonWrapper, LogoutButtonWrapper, ProfileInnerContainer, ProfileOuterContainer } from "./ProfileContainers";
import { ClientCard } from "./components/ClientCard";
import { eventTrack } from "../../../useGaTracker";
import { AppContext } from "../../context/AppContext";
import { PopupType } from "../../context/popups.enum";
import { usePopupNavigation } from "../../shared/utils/usePopupNavigation";
import { LoyaltyPopup } from "../loyalty/loyalty-popup";
import Modal from "../modal/Modal";
import { PopupContext } from "../../context/PopupContext";
import { postError } from "../../shared/utils/postError";
import { HeaderBar } from "../../elements/header-bar/HeaderBar";
import { useFeatureIsOn } from "@growthbook/growthbook-react";
import { GB_FEATS } from "../../growthbook";
import { useViewport } from "../../context/ViewportContext";

export const ProfileContainer = () => {
  const { logout } = useAuthContextDispatchContext();
  const { removeUser } = useContext(UserContextDispatchContext);
  const { dispatch } = useContext(AppContext);
  const { popup } = useContext(PopupContext);

  const navigate = useNavigate();
  const location = useLocation();
  const { isMobile } = useViewport();
  const { t } = useTranslation();
  const { goToPopup, closePopup } = usePopupNavigation();

  const token: any = localStorage.getItem("token_yumminn");
  const token_type: any = localStorage.getItem("type");

  const { content, loading, error } = UseUserProfile(token, token_type);

  const showZerosix = useFeatureIsOn(GB_FEATS.SHOW_ZEROSIX);
  const showMyAccount = useFeatureIsOn(GB_FEATS.SHOW_MY_ACCOUNT);

  const lastScan = JSON.parse(localStorage.getItem('lastScan') || '{}');
  const restaurantId = Number(sessionStorage.getItem('restaurantId')) || lastScan?.restaurantId;
  const tableNumber = sessionStorage.getItem("tableNumber") || lastScan?.tableNumber;
  const restaurantGroupId = sessionStorage.getItem("restaurantGroupUuid");
  const isZerosixAvailable = Number(sessionStorage.getItem("zerosix_available")) === 1;

  useEffect(() => {
    isMobile && closePopup();
  }, []);

  const onSubmitLogout = () => {
    eventTrack('00_03_profile_button_11_logout');
    logout();
    removeUser();

    dispatch({
      type: 'UPDATE_CHECKOUT',
      payload: {
        availableLoyaltyCents: 0,
        loyaltyDiscountCents: 0,
        loyaltyUnavailableCents: 0,
        savedCards: [],
        zeroSixSelectedVoucher: {
          name: "",
          code: "",
          value: 0,
          is_percentage: false,
          expiration: "",
          min_amount: 0
        },
        zeroSixInfo: {
          points_balance: null,
          points_total: null,
          vouchers: []
        },
      }
    });

    dispatch({
      type: 'UPDATE_WALLET_INFO',
      payload: {
          balanceCredit: 0,
      }
    });

    dispatch({
      type: 'UPDATE_WALLET_INFO',
      payload: {
          balanceCredit: 0,
      }
    });

    const paramsProxy: any = new Proxy(new URLSearchParams(window.location.search), {
      get: (searchParams, prop: any) => searchParams.get(prop),
    });
    
    if (paramsProxy?.goBack == "true") {
      navigate(-1);
    } else {
      const state = location.state as { from: string };
      const from = state ? state.from : undefined;

      const pathToNavigate = (restaurantGroupId && from === 'restaurant-group')
        ? `/restaurant_group/${restaurantGroupId}`
        : (restaurantId && tableNumber)
        ? `/home?id=${restaurantId}&table=${tableNumber}`
        : '/register';

      navigate(pathToNavigate, {state: { from: location.pathname }});
    }

    if (!isMobile) {
      closePopup();
    }
  };

  const zerosixClick = () => {
    eventTrack("00_03_profile_button_05_loyaltyPage");
    closePopup();
    setTimeout(() => navigate("/zerosix-cards"), 500);
  };

  const handleLatestBillsClick = () => {
    eventTrack("00_03_profile_button_03_historyReceipts");

    if (isMobile) {
      navigate("/latest-bills");
    } else {
      goToPopup(PopupType.LatestBills);
    }
  };

  const handlePaymentMethodsClick = () => {
    eventTrack("00_03_profile_button_02_methods");
    navigate("/saved-cards");
  };

  const handleMyAccountClick = () => {
    eventTrack("00_03_profile_button_06_my_account");

    if(isMobile){
      navigate("/my-account");
    } else {
      goToPopup(PopupType.MyAccount);
    }
  };

  useEffect(() => {
    try {
      localStorage.setItem("client_id", content.id);
      sessionStorage.setItem("client_phone", content.phone);
    } catch (err: any) {
      postError(err);
    }
  }, [content]);

  if (loading || error || Object.keys(content).length === 0) return (
    <Loading parentComponent='ProfileContainer'/>
  );

  return (
    <>
      {isMobile && <HeaderBar />}
      <ProfileOuterContainer>
        <ClientCard user={content} restaurantId={restaurantId} tableNumber={tableNumber} />
        <ProfileInnerContainer>
          <Button
            title={
              <InnerButtonWrapper>
                <Icon type={IconType.Receipt} size={20} />
                {t('invoices')}
                <Icon type={IconType.Arrowforward} size={14} />
              </InnerButtonWrapper>
            }
            variant={ButtonVariant.Neutral}
            icon={<Icon type={IconType.Receipt}/>}
            handleClick={handleLatestBillsClick}
          />
          {showZerosix && isZerosixAvailable &&
            <Button
              title={
                <InnerButtonWrapper>
                  <Icon type={IconType.Cards} size={20}/>
                  {t('payment_methods')}
                  <Icon type={IconType.Arrowforward} size={14}/>
                </InnerButtonWrapper>
              }
              variant={ButtonVariant.Neutral}
              icon={<Icon type={IconType.Cards} size={24}/>}
              handleClick={handlePaymentMethodsClick}
            />
          }
          {showZerosix &&
            <Button
              title={
                <InnerButtonWrapper>
                  <Icon type={IconType.Gift} size={20}/>
                  {t('Zerosix')}
                  <Icon type={IconType.Arrowforward} size={14}/>
                </InnerButtonWrapper>
              }
              variant={ButtonVariant.Neutral}
              icon={<Icon type={IconType.Gift}/>}
              handleClick={zerosixClick}
            />
          }
          {showMyAccount &&
            <Button
              title={
                <InnerButtonWrapper>
                  <Icon type={IconType.User} size={20}/>
                  {t('my_account')}
                  <Icon type={IconType.Arrowforward} size={14}/>
                </InnerButtonWrapper>
              }
              variant={ButtonVariant.Neutral}
              icon={<Icon type={IconType.User}/>}
              handleClick={handleMyAccountClick}
            />
          }
        </ProfileInnerContainer>
        <LogoutButtonWrapper onClick={onSubmitLogout}>
          <Icon type={IconType.Exit}/>
          {t('logout')}
        </LogoutButtonWrapper>
        <HelpLink />
      </ProfileOuterContainer>
      <Modal isOpened={popup.current === PopupType.LoyaltyTypes}>
        <LoyaltyPopup/>
      </Modal>
    </>
  );
};