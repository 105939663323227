import { useContext, useState } from 'react';
import { InputContainer } from './ProfileInputContainers';
import { RoundCheckbox } from '../round-checkbox';
import { t } from 'i18next';
import { Icon, IconType } from '../icons';
import { PhoneInput, Select, TextInput } from '../../elements';
import { getPhoneFunctions, useClientInptusConfig } from '../../shared/utils/clientInputsLogic';
import { UserContextDispatchContext } from '../../context/auth/UserContext';

export const ProfileInputComponent = ({
  type = 'text',
  name,
  value,
  options,
  placeholder,
  isCompleted,
  error,
  onChange,
  onBlur
}: props) => {
  const {
    isPhoneRequired,
  } = useClientInptusConfig();
  const { updateUser } = useContext(UserContextDispatchContext);

  const [isPhoneValid, setIsPhoneValid] = useState(true);
  const {handlePhoneChange, handlePhoneBlur} = getPhoneFunctions({isPhoneRequired, setIsPhoneValid, updateUser});

  const handleChange = (phoneNumber: any) =>  {
    //console.log('Phone number changed:', phoneNumber); // Log the phone number when it changes

    handlePhoneChange(phoneNumber); 
    const formattedNumber = `+${phoneNumber}`

    if (onChange) {
      onChange(formattedNumber);
    }
  };

  return (
    <InputContainer>
      <h4>
        <RoundCheckbox isChecked={isCompleted || false}/>
        {t(name)}
      </h4>
      {(type !== 'select' && name !== "phone") &&
        <TextInput
          id={'profile-input-' + name}
          type={type}
          name={name}
          value={value}
          placeholder={placeholder}
          readOnly={!onChange}
          onChange={onChange}
          onBlur={onBlur}
          error={error}
          rightIcon={<Icon type={IconType.Edit}/>}
        />
      }
      {name === "phone" &&
        <PhoneInput
          value={value as string}
          onChange={handleChange}
          onBlur={handlePhoneBlur}
          error={!isPhoneValid && 'valid_phone'}
        />
      }
      {options &&
        <Select
          id={'profile-input-' + name}
          name={name}
          value={value}
          placeholder={placeholder}
          onChange={onChange}
          onBlur={onBlur}
          options={options}
        />
      }
    </InputContainer>
  );
};

type props = {
  type?: string,
  name: string,
  value: any,
  options?: {value: string, label: string}[]
  placeholder?: string,
  isCompleted?: boolean,
  error?: string,
  onChange?: any,
  onBlur?: any,
};