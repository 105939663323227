import PropTypes, {InferProps} from 'prop-types';
import React from "react";
import styled from "styled-components";
import { CardIcon } from '.';
import checkbox_checked from "../../assets/images/checkbox_checked.svg";
import checkbox_unchecked from "../../assets/images/checkbox_unchecked.svg";

export function SavedCardComponent({ brand, isChecked, lastFour, expiryMonth, expiryYear, checked }: SavedCardComponentProps) {

  function wrapperOnClick() {
    if (!isChecked) {
      checked();
    }
  }

  return (
    <Wrapper onClick={wrapperOnClick} style={{backgroundColor: isChecked ? '#eee' : 'white'}}>
      <img src={isChecked ? checkbox_checked : checkbox_unchecked} alt='' />
      <CardIcon brand={brand} style={{marginRight: '10px'}} />
      <h1><span>****</span> {lastFour}</h1>
      <div style={{flex: 1}} />
      <h2>Exp: <b>{expiryMonth}/{expiryYear}</b></h2>
    </Wrapper>
  );
}

SavedCardComponent.propTypes = {
    isChecked: PropTypes.bool.isRequired,
    lastFour: PropTypes.string.isRequired,
    expiryMonth: PropTypes.string.isRequired,
    expiryYear: PropTypes.string.isRequired,
    checked: PropTypes.func.isRequired,
    brand: PropTypes.string.isRequired
}

type SavedCardComponentProps = InferProps<typeof SavedCardComponent.propTypes>;

const Wrapper = styled.div`
  height: 42px;
  display: flex;
  margin: 8px 0;
  font-size: 15px;
  border-radius: 10px;
  background-color: white;
  border: 1px #ddd solid;
  box-shadow: 1px 1px 1px 1px rgb(149 157 165 / 10%);
  &:hover {
  cursor: pointer;
  }
  &:active {
  transform: translate(1px, 1px);
  }
  img {
  height: 17px;
  align-self: center;
  margin-left: 10px;
  margin-right: 10px;
  }
  input {
  align-self: center;
  margin-left: 10px;
  margin-right: 10px;
  }
  h1 {
  font-size: 15px;
  font-weight: normal;
  margin: 0 10px 0 0;
  align-self: center;
  }
  span {
  vertical-align:sub;
  font-size: 17px;
  }
  h2 {
  font-size: 11px;
  font-weight: normal;
  color: gray;
  margin: 0 10px 0 0;
  align-self: center;
  }
  h2 > b {
  font-size: 12px;
  font-weight: 500;
  }
`
