import * as React from 'react';
import { Loading } from '../loading';
import { calculateEuros } from '../../pages/checkout/functions/calculatePrices';

type googlePayComponentProps = {
  merchantCode: string,
  redsysEnv: string;
  totalAmount: number,
  redsysIntiatePayment:      (paymentToken: string, sessionId?: string, saveCardChecked?: boolean, selectedSavedCard?: any, redsysOrder?: string, googlePayToken?: string, applePayData?: string) => void
};

export default function GooglePayComponent({merchantCode, redsysEnv, totalAmount, redsysIntiatePayment} : googlePayComponentProps) {
  const [isLoading, setIsLoading] = React.useState(true);
  const restaurantName = sessionStorage.getItem('restaurantName');
  const env = merchantCode === "999008881" || redsysEnv === "test" ? "TEST" : "PRODUCTION"
  const baseRequest = {
    apiVersion: 2,
    apiVersionMinor: 0
  };
  const allowedCardAuthMethods = ["PAN_ONLY", "CRYPTOGRAM_3DS"];
  const allowedCardNetworks = ["AMEX", "DISCOVER", "INTERAC", "JCB", "MASTERCARD", "VISA"];
  const tokenizationSpecification = {
    type: 'PAYMENT_GATEWAY',
    parameters: {
      'gateway': 'redsys',
      'gatewayMerchantId': merchantCode
    }
  };

  const baseCardPaymentMethod = {
    type: 'CARD',
    parameters: {
      allowedAuthMethods: allowedCardAuthMethods,
      allowedCardNetworks: allowedCardNetworks
    }
  };
  
  const cardPaymentMethod = Object.assign(
    {},
    baseCardPaymentMethod,
    {
      tokenizationSpecification: tokenizationSpecification
    }
  );
  
  let paymentsClient : any = null;
  
  function getGoogleIsReadyToPayRequest() {
    return Object.assign(
        {},
        baseRequest,
        {
          allowedPaymentMethods: [baseCardPaymentMethod]
        }
    );
  }


  function getGooglePaymentDataRequest() {
    const paymentDataRequest = Object.assign({}, baseRequest, {
    allowedPaymentMethods: [cardPaymentMethod],
    transactionInfo: getGoogleTransactionInfo(),
    merchantInfo: {
      merchantName: restaurantName,
      merchantId: "BCR2DN4TUK7PFKLZ"
    }
  });
    return paymentDataRequest;
  }

  function getGooglePaymentsClient() {
    if ( paymentsClient === null ) {
      paymentsClient = new google.payments.api.PaymentsClient({environment: env});
    }
    return paymentsClient;
  }

  function onGooglePayLoaded() {
    const paymentsClient = getGooglePaymentsClient();
    paymentsClient.isReadyToPay(getGoogleIsReadyToPayRequest())
        .then(function(response: any) {
          if (response.result) {
            addGooglePayButton();
          }
        })
        .catch(function(err: any) {
          console.error(err);
        });
  }


  function addGooglePayButton() {
    const paymentsClient = getGooglePaymentsClient();
    const button =
        paymentsClient.createButton({
          buttonColor: 'black',
          buttonType: 'plain',
          buttonLocale: 'es',
          buttonSizeMode: 'fill',
          onClick: onGooglePaymentButtonClicked,
          allowedPaymentMethods: [baseCardPaymentMethod]
        });
    document.getElementById('gpayboton')?.appendChild(button);
  }

  function getGoogleTransactionInfo() {
    const finalTotalEur = calculateEuros(totalAmount)
    return {
      countryCode: 'ES',
      currencyCode: 'EUR',
      totalPriceStatus: 'FINAL',
      totalPrice: finalTotalEur.toString().replace(',', '.')
    };
  }

  function onGooglePaymentButtonClicked() {
    const paymentDataRequest = getGooglePaymentDataRequest();  
    const paymentsClient = getGooglePaymentsClient();
    paymentsClient.loadPaymentData(paymentDataRequest)
        .then(function(paymentData: any) {
          processPayment(paymentData);
        })
        .catch(function(err: any) {
          console.error(err);
        });
  }

  function processPayment(paymentData: any) {
      if (paymentData?.paymentMethodData?.tokenizationData?.token) {
        redsysIntiatePayment("", undefined, undefined, undefined, undefined, paymentData.paymentMethodData.tokenizationData.token)
      }
  }

  React.useEffect(() => {
    if (window && document) {
      const script = document.createElement('script')
      const body = document.getElementsByTagName('body')[0]
      script.src = 'https://pay.google.com/gp/p/js/pay.js'
      body.appendChild(script)
      script.addEventListener('load', () => {
        onGooglePayLoaded();
        setIsLoading(false);
      })
    }
  }, [])

  return (
  <div style={{fontSize: '12px', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
    {isLoading ? 
    <Loading parentComponent='RedsysCardComponent' loaderStyle={{height: '36px', width: '36px'}} style={{height: '44px', margin: '10px'}}/> : 
    <div id="gpayboton" style={{width: '60%'}}></div>
    }
  </div>
  )
}