import React from 'react';
import moment from 'moment';
import { Icon, IconType } from '../../elements/icons';
import { calculateEuros } from '../checkout/functions/calculatePrices';
import { CashbackBadge, PaymentListItem } from './LatestBillsContainers';
import { useNavigate } from 'react-router-dom';
import { Receipt } from '../../context/app';
import { CreditBadge } from '../loyalty/components/CreditBadge';
import { useViewport } from '../../context/ViewportContext';
import { PopupType } from '../../context/popups.enum';
import { usePopupNavigation } from '../../shared/utils/usePopupNavigation';

type Props = {
  payment: Receipt
  showBadge?: boolean
  fromCredit?: boolean
};

export const LatestBillsCard = ({payment, showBadge, fromCredit}: Props) => {
  const navigate = useNavigate();
  const { isMobile } = useViewport();
  const { goToPopup } = usePopupNavigation();
  const date = moment(payment?.date);
  const formattedDate = date.format("DD/MM/YYYY - HH:mm");

  const handleClick = () => {
    if(isMobile){
      navigate('/restaurant-bill', {state: payment})
    } else {
      goToPopup(PopupType.RestaurantBills, {payment: payment})
    }
  };

  const total = () => {
    const tipsNotIncludedInDiscountsDay = moment("15/06/2023", "DD/MM/YYYY")
    let totalNumber = 0
    if(date.isAfter(tipsNotIncludedInDiscountsDay)){
      totalNumber = (payment.base_amount_cents - payment.general_discount_cents - payment.loyalty_discount_cents) + payment.tip_cents
    } else {
      totalNumber = (payment.base_amount_cents + payment.tip_cents) - payment.general_discount_cents - payment.loyalty_discount_cents  
    }
    return totalNumber;
  
  }

  return (
    <PaymentListItem onClick={handleClick} key={'payment-' + payment.id}>
      <Icon type={IconType.Receipt}/>
      <div>
        <h3>{payment.restaurant_name}</h3>
        <span>
          {formattedDate}
        </span>
      </div>
      <b>{calculateEuros(total()) + '€'}</b>
      {payment.credit_cents === 0 && !showBadge &&
        <Icon type={IconType.Arrowforward}/>
      }
      {showBadge && payment.loyalty_cents > 0 &&
        <CashbackBadge>
          +
          <Icon type={IconType.Cashback} size={20}/>
        </CashbackBadge>
      }
      {showBadge && payment.credit_cents > 0 &&
        <div style={{position: "absolute", top: "-8px", right: "-8px"}}>
          <CreditBadge balance={undefined}/>
        </div>
      }
      {fromCredit && payment.credit_cents > 0 &&
        <div style={{display: 'flex', alignItems: 'center', gap:'6px'}}>
          <b>{`-${calculateEuros(payment.credit_cents)}`}</b>
          <Icon type={IconType.CreditBanner} size={20}/>
          <Icon type={IconType.Arrowforward}/>
        </div>
      }
    </PaymentListItem>
  );
};