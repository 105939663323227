import { CountryData } from "react-phone-input-2";
import { InputConfig, PaymentSplitType, User } from "../../context/app";
import { PhoneNumberUtil } from 'google-libphonenumber';
import { EMAIL_REGEX } from "../constants/regex";
import { RequestUser } from "../../api/users/Users.api";
import { AxiosResponse } from "axios";
import { useContext } from "react";
import { AppContext } from "../../context/AppContext";

const phoneUtil = PhoneNumberUtil.getInstance();

export const getEmailConfig = (restaurantInputConfig: InputConfig, pathname: string) => {
  if (restaurantInputConfig !== 'default') {
    return {
      showEmailInput: restaurantInputConfig !== 'dont_request',
      isEmailRequired: restaurantInputConfig === 'required',
    };
  }

  const functionality = sessionStorage.getItem('functionality') || '';
  const requiredByFunctionality = ['delivery', 'takeaway', 'kiosk'].includes(functionality);
  const isEventCatalog = pathname === '/event_catalog';
  
  const showEmailInput = requiredByFunctionality || isEventCatalog;
  const isEmailRequired = showEmailInput;

  return {showEmailInput, isEmailRequired};
};

export const getNameConfig = (restaurantInputConfig: InputConfig, pathname: string, splitType?: PaymentSplitType) => {
  if (restaurantInputConfig !== 'default') {
    return {
      showNameInput: restaurantInputConfig !== 'dont_request',
      isNameRequired: restaurantInputConfig === 'required',
    };
  }

  const functionality = sessionStorage.getItem('functionality') || '';
  const requiredByFunctionality = ['delivery', 'takeaway', 'order_and_collect'].includes(functionality);
  const isEventCatalog = pathname === '/event_catalog';
  
  const showNameInput = requiredByFunctionality || isEventCatalog || (splitType && splitType !== 'all');
  const isNameRequired = requiredByFunctionality || isEventCatalog;
  
  return {showNameInput, isNameRequired};
};

export const getPhoneConfig = (restaurantInputConfig: InputConfig, pathname: string) => {
  if (restaurantInputConfig !== 'default') {
    return {
      showPhoneInput: restaurantInputConfig !== 'dont_request',
      isPhoneRequired: restaurantInputConfig === 'required',
    };
  }

  const functionality = sessionStorage.getItem('functionality') || '';
  const requiredByFunctionality = ['delivery', 'takeaway', 'order_and_collect', 'kiosk'].includes(functionality);
  const isEventCatalog = pathname === '/event_catalog';
  
  const showPhoneInput = requiredByFunctionality || isEventCatalog;
  const isPhoneRequired = showPhoneInput;
  
  return {showPhoneInput, isPhoneRequired};
};

export const getEmailFunctions = ({email, isEmailRequired, emailError, setEmailError, updateUser}: EmailFnProps) => {
  const checkEmail = (value?: string) => {
    const emailToCheck = value !== undefined ? value : email;
  
    if (isEmailRequired && (!emailToCheck || emailToCheck.length <= 0)) {
      setEmailError("This field is mandatory");
      return false;
    }
  
    if (emailToCheck && !EMAIL_REGEX.test(emailToCheck)) {
      setEmailError("valid_email");
      return false;
    }
  
    setEmailError('');
    return true;
  };
  
  const handleEmailChange = (event: React.ChangeEvent) => {
    if (emailError) checkEmail((event.target as HTMLInputElement).value);
  
    updateUser({ email: (event.target as HTMLInputElement).value })
  };

  return { checkEmail, handleEmailChange };
};

export const getNameFunctions = ({isNameRequired, setIsNameValid, updateUser}: NameFnProps) => {
  const handleNameBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    if (!isNameRequired) return;
  
    const clientName = e?.target?.value;
    setIsNameValid(clientName !== '');
  };
  
  const handleNameChange = (event: any) => {
    const clientName = event.target.value;
    const isValid = !isNameRequired || clientName !== '';
  
    updateUser({ name: event.target.value });
    
    isValid && setIsNameValid(true)
  }

  return { handleNameBlur, handleNameChange };
};

export const getPhoneFunctions = ({isPhoneRequired, setIsPhoneValid, updateUser}: PhoneFnProps) => {
  const handlePhoneBlur = (event: React.FocusEvent<HTMLInputElement>, country: CountryData) => {
    const number = event?.target?.value;
    const prefix = country.dialCode;
    
    if (!isPhoneRequired && number === `+${prefix}`) {
      setIsPhoneValid(true);
      updateUser({ phone: '' });
    } else {
      setIsPhoneValid(validatePhoneNumber(number));
    }
  };
  
  const handlePhoneChange = (phoneNumber: string) => {
    const isValid = validatePhoneNumber(`+${phoneNumber}`);
    
    if (isValid) {
      setIsPhoneValid(true);
      updateUser({ phone: `+${phoneNumber}`});
    }
  };

  return { handlePhoneBlur, handlePhoneChange };
};

export const validatePhoneNumber = (phoneNumber?: string): boolean => {
  if (!phoneNumber) return false;
  
  try {
    const parsedNumber = phoneUtil.parse(phoneNumber, undefined);
    return phoneUtil.isValidNumber(parsedNumber);
  } catch (error:any) {
    return false;
  }
};

export const saveEmptyUserFields = async (userId: number | null, name: string, phone: string) => {
  if (userId && (name || phone)) {
    try {
      const token: any = localStorage.getItem("token_yumminn");
      const type: any = localStorage.getItem("type");
      const { data } = await RequestUser.user(token, type) as AxiosResponse<{first_name: string, phone: string}>;
      const changes: Partial<{first_name: string, phone: string}> = {};

      if (name && !data.first_name) {
        changes.first_name = name;
      }
      
      //if DB stored phone does not start with +, then it overwrites it
      if (phone && (!data.phone || !data.phone.startsWith("+"))) {
        changes.phone = phone;
      }

      if (Object.values(changes).length) {
        RequestUser.updateuser(token, type, changes);
      }
    } catch (error) {
      console.log(error)
    }
  }
};

export const useClientInptusConfig = () => {
  const {
    state: {
      restaurant: {
        request_client_email,
        request_client_name,
        request_client_phone,
      },
      checkout: {
        paymentSplitType,
      },
    },
  } = useContext(AppContext);

  const path = location.pathname;

  const {showEmailInput, isEmailRequired} = getEmailConfig(request_client_email, path);
  const {showNameInput, isNameRequired} = getNameConfig(request_client_name, path, paymentSplitType);
  const {showPhoneInput, isPhoneRequired} = getPhoneConfig(request_client_phone, path);

  return {
    showEmailInput,
    isEmailRequired,
    showNameInput,
    isNameRequired,
    showPhoneInput,
    isPhoneRequired,
    showAnyInput: showEmailInput || showNameInput || showPhoneInput,
  };
}

type EmailFnProps = {
  email: string
  isEmailRequired: boolean
  emailError: string
  setEmailError: (error: string) => void
  updateUser: (user: Partial<User>) => void
};

type NameFnProps = {
  isNameRequired: boolean
  setIsNameValid: (isValid: boolean) => void
  updateUser: (user: Partial<User>) => void
};

type PhoneFnProps = {
  isPhoneRequired: boolean
  setIsPhoneValid: (isValid: boolean) => void
  updateUser: (user: Partial<User>) => void
};