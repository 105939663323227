import React from 'react';
import { t } from 'i18next';
import { ClientStatsContainer } from '../ProfileContainers';
import { Icon, IconType } from '../../../elements';
import { eventTrack } from '../../../../useGaTracker';

const icons = {
  restaurant: IconType.Plat,
  money: IconType.Cashback,
  time: IconType.Clock,
}

export const ClientStats = ({type, stat, isLoading}: Props) => {
  const calculateMoney = () => {
    const euros = Math.floor(stat / 100);

    return `${euros}€`;
  };

  const calculateTime = () => {
    if (stat < 60) {
      return stat + ' min'
    }

    const hours = Math.floor(stat / 60);
    const mins = Math.floor(stat % 60).toString().padStart(2, '0');

    return `${hours}:${mins} h`;
  };

  const formatStat = () => {
    if (type === 'restaurant') {
      return stat;
    }

    if (type === 'money') {
      return calculateMoney();
    }

    if (type === 'time') {
      return calculateTime();
    }
  }

  const trackEventsStats = () => {
    if(type === 'restaurant'){
      eventTrack("00_03_profile_button_08_visits", "00_03_profile_button_08_visits", "00_03_profile_button_08_visits")
    } else if (type === 'money'){
      eventTrack("00_03_profile_button_09_savings", "00_03_profile_button_09_savings", "00_03_profile_button_09_savings")
    } else {
      eventTrack("00_03_profile_button_10_time", "00_03_profile_button_10_time", "00_03_profile_button_10_time")
    }
  }

  return (
    <ClientStatsContainer onClick={() => trackEventsStats()}>
      <h6>{t(type + ' stat')}</h6>
      <div>
        {isLoading ?
          <div>
            <i>.</i>
            <i>.</i>
            <i>.</i>
          </div>
        :
          <div style={{fontWeight: 600, fontSize: "18px", lineHeight: "20px", whiteSpace: 'nowrap', marginRight: "4px"}}>
            {formatStat()}
          </div>
        }
        <Icon type={icons[type]} size={20}/>
      </div>
    </ClientStatsContainer>
  );
};

type Props = {
  type: 'restaurant' | 'money' | 'time'
  stat: number
  isLoading: boolean
};