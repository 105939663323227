import { postError } from '../shared/utils/postError';
import { AppState, Tip, PaymentMethod, PaymentSplitType, OrderItem, OPCartProduct, ExtraChosen, RestaurantCustomization, Roadmap, Restaurant, Checkout, DeliveryAddressData, Wallet, Lang } from './app';

export type Action =
	| { type: 'CHANGE_PAYMENT_METHOD', payload: PaymentMethod }
	| { type: 'CHANGE_PAYMENT_SPLIT_TYPE', payload: PaymentSplitType }
	| { type: 'UPDATE_TIP', payload: Tip }
	| { type: 'CHANGE_CUSTOMER_NAME', payload: string }
	| { type: 'CHANGE_CUSTOMER_VAT', payload: string }
	| { type: 'UPDATE_ORDER_ID', payload: number }
	| { type: 'UPDATE_RESTAURANT', payload: Partial<Restaurant> }
	| { type: 'UPDATE_RESTAURANT_ID', payload: number }
	| { type: 'CHANGE_RESTAURANT_NAME', payload: string }
	| { type: 'TPV_ID', payload: number }
	| { type: 'SUGGESTIONS_ID', payload: number }
	| { type: 'UPDATE_RESTAURANT_GROUP_UUID', payload: string}
	| { type: 'UPDATE_TABLE_NUMBER', payload: string }
	| { type: 'UPDATE_CHECKOUT', payload: Partial<Checkout> }
	| { type: 'UPDATE_SELECTED_ITEMS', payload: OrderItem[] }
	| { type: 'UPDATE_ORDER_AND_PAY_ITEMS', payload: OPCartProduct[] }
	| { type: 'UPDATE_EXTRAS_CHOSEN', payload: ExtraChosen[] }
	| { type: 'UPDATE_ORDER_ITEMS', payload: OrderItem[] }
	| { type: 'UPDATE_SELECTED_MODIFIERS', payload: any[] }
	| { type: 'UPDATE_CLIENT_COMMISSION', payload: number }
	| { type: 'UPDATE_MONEI_PAY', payload: boolean }
	| { type: 'UPDATE_MONEI_ACCOUNT_ID', payload: string }
	| { type: 'UPDATE_PAYMENT_METHODS', payload: string[] }
	| { type: 'UPDATE_RESTAURANT_CUSTOMIZATION', payload: RestaurantCustomization}
	| { type: 'UPDATE_ROADMAP', payload: Roadmap}
	| { type: 'UPDATE_PHONE_COORDS_WITHIN_RADIUS', payload: null|boolean }
	| { type: 'UPDATE_ADDRESS_DATA', payload: Partial<DeliveryAddressData> }
	| { type: 'UPDATE_WALLET_INFO', payload: Partial<Wallet> }
	| { type: 'UPDATE_LANG', payload: Lang }
	| { type: 'SET_STATE', payload: Partial<AppState> }


export const appReducer = (
	state: AppState,
	action: Action
): AppState => {
	if (action.type === 'UPDATE_ORDER_AND_PAY_ITEMS') {
		const restaurantUuid = sessionStorage.getItem("restaurantUuid") || null;
		if (restaurantUuid) {
			const orderItemsString = JSON.stringify(action.payload);
		
			try {
				localStorage.setItem("orderItems", orderItemsString);
				localStorage.setItem("orderItems_restaurant", restaurantUuid);
				localStorage.setItem("orderItems_timestamp", Date.now().toString());
			} catch(err: any){
				postError(err)
			}
		}
	} else if (action.type === 'UPDATE_CHECKOUT' && 'myTotal' in action.payload) {
		if (action.payload['myTotal']) {
			try {
				localStorage.setItem("orderItems_total", action.payload['myTotal'].toString());
			} catch(err: any){
				postError(err)
			}
		}
	} else if (action.type === 'UPDATE_TIP' && 'amount' in action.payload) {
		const tip = action.payload['amount'];
		if (tip !== null && tip !== undefined) {
			try {
				localStorage.setItem("orderItems_tip", tip.toString());
			} catch(err: any){
				postError(err)
			}
		}
	}
	if (action.type === 'UPDATE_CHECKOUT' && 'productDiscountCents' in action.payload) {
		if (action.payload['productDiscountCents']) {
			const restaurantUuid = sessionStorage.getItem("restaurantUuid") || null;
			if (restaurantUuid) {
				try {
					localStorage.setItem("orderItems_productDiscount", action.payload['productDiscountCents'].toString());
					localStorage.setItem("orderItems_productDiscount_restaurant", restaurantUuid);
					localStorage.setItem("orderItems_productDiscount_timestamp", Date.now().toString());
				} catch(err: any){
					postError(err)
				}
			}
		}
	}
	switch (action.type) {
		case 'CHANGE_PAYMENT_METHOD':
			return {
				...state,
				checkout: {
					...state.checkout,
					paymentMethod: action.payload,
				}
			}
		case 'CHANGE_PAYMENT_SPLIT_TYPE':
			return {
				...state,
				checkout: {
					...state.checkout,
					paymentSplitType: action.payload,
				}
			}
		case 'UPDATE_TIP': {
			const tip = action.payload;
			const prevTip = state.checkout.tip;
			const tipDiff = tip.amount - prevTip.amount;
			const myTotal = state.checkout.myTotal + tipDiff;

			return {
				...state,
				checkout: {
					...state.checkout,
					myTotal,
					tip,
				},
			}
		}
		case 'UPDATE_SELECTED_ITEMS':
			return {
				...state,
				checkout: {
					...state.checkout,
					selectedItems: action.payload,
				}
			}
		case 'UPDATE_ORDER_AND_PAY_ITEMS':
			return {
				...state,
				checkout: {
					...state.checkout,
					orderAndPayItems: action.payload,
				}
			}
		case 'UPDATE_EXTRAS_CHOSEN':
			return {
				...state,
				checkout: {
					...state.checkout,
					extrasChosen: action.payload,
				}
			}
		case 'UPDATE_ORDER_ITEMS':
			return {
				...state,
				checkout: {
					...state.checkout,
					orderItems: action.payload,
				}
			}
		case 'UPDATE_SELECTED_MODIFIERS':
			return {
				...state,
				checkout: {
					...state.checkout,
					selectedModifiers: action.payload,
				}
			}
		case 'CHANGE_CUSTOMER_NAME':
			return {
				...state,
				checkout: {
					...state.checkout,
					customerName: action.payload
				}
			}
		case 'CHANGE_CUSTOMER_VAT':
			return {
				...state,
				checkout: {
					...state.checkout,
					customerVAT: action.payload
				}
			}
		case 'UPDATE_ORDER_ID':
			return {
				...state,
				checkout: {
					...state.checkout,
					orderId: action.payload
				}
			}
		case 'UPDATE_CHECKOUT':
			return {
				...state,
				checkout: {
					...state.checkout,
					...action.payload
				}
			}
		case 'UPDATE_RESTAURANT':
			return {
				...state,
				restaurant: {
					...state.restaurant,
					...action.payload,
				}
			}
		case 'UPDATE_RESTAURANT_ID':
			return {
				...state,
				restaurantId: action.payload,
				restaurant: {
					...state.restaurant,
					id: action.payload,
				}
			}
		case 'CHANGE_RESTAURANT_NAME':
			return {
				...state,
				restaurantName: action.payload
			}
		case 'TPV_ID':
			return {
				...state,
				tpvId: action.payload
			}
		case 'SUGGESTIONS_ID':
			return {
				...state,
				suggestionsId: action.payload
			}
		case 'UPDATE_RESTAURANT_GROUP_UUID':
			return {
				...state,
				restaurantGroupUuid: action.payload

			}
		case 'UPDATE_TABLE_NUMBER':
			return {
				...state,
				tableNumber: action.payload
			}
		case 'UPDATE_CLIENT_COMMISSION':
			return {
				...state,
				checkout: {
					...state.checkout,
					clientCommission: action.payload
				}
			}
		case 'UPDATE_MONEI_PAY':
			return {
				...state,
				checkout: {
					...state.checkout,
					moneiPay: action.payload
				}
			}
		case 'UPDATE_MONEI_ACCOUNT_ID':
			return {
				...state,
				checkout: {
					...state.checkout,
					moneiAccountId: action.payload
				}
			}
		case 'UPDATE_PAYMENT_METHODS':
			return {
				...state,
				checkout: {
					...state.checkout,
					paymentMethods: action.payload
				}
			}
		case 'UPDATE_RESTAURANT_CUSTOMIZATION':
			return {
				...state,
				restaurantCustomization: action.payload
			}
		case 'UPDATE_ROADMAP':
			return {
				...state,
				checkout: {
					...state.checkout,
					roadmap: action.payload,
				}
			}
		case 'UPDATE_PHONE_COORDS_WITHIN_RADIUS':
			return {
				...state,
				restaurant: {
					...state.restaurant,
					phoneCoordsWithinRadius: action.payload
				}
			}
		case 'UPDATE_ADDRESS_DATA':
			return {
				...state,
				addressData: {
					...state.addressData,
					...action.payload,
				}
			}
		case 'UPDATE_WALLET_INFO':
			return {
				...state,
				wallet: {
					...state.wallet,
					...action.payload,
				}
			}
		case 'UPDATE_LANG':
			return {
				...state,
				lang: action.payload,
			}
		case 'SET_STATE':
			return {
				...state,
				...action.payload,
			}
		default:
			return state;
	}
};