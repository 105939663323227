import styled from 'styled-components';

type props = {
    path: string
}

const LanguageButton = styled.button<props>`
    display: flex;
    color: currentColor;
    padding: 0;
    background-color: transparent;
    border: none;
    height: 28px;
    width: 28px;
    
    svg {
        background-color: currentColor;
        border: 1.8px solid white;
        border-radius: ${({theme}) => theme.radius_round};
        box-sizing: border-box;
        height: 100%;
        width: 100%;
    }
`;

const LanguageList = styled.ul`
    display: flex;
    flex-direction: column;
    color: currentColor;
    gap: 8px;
    padding: 0;
    margin: 0;
`;

const LanguageOption = styled.li`
    display: flex;
    justify-content: space-between;
    gap: 8px;
    color: ${({theme}) => theme.text};
    padding: 0;
    margin: 0;
    transition: color 0.15s ease-in-out;

    &:hover {
        cursor: pointer;
        color: ${({theme}) => theme.primary};
    }
`;

const Flag = styled.div<FlagProps>`
    position: relative;
    display: flex;

    span {
        position: absolute;
        display: flex;
        height: 100%;
        width: 100%;
        color: #FFF;
        background-color: #0006;
        border-radius: ${({theme}) => theme.radius_round};
        opacity: ${({isActive}) => isActive ? 1 : 0};
        transition: opacity 0.2s ease-in-out;
        
        svg {
            margin: auto;
        }
    }
`;

type FlagProps = {
    isActive: boolean
}

export {
    LanguageButton,
    LanguageList,
    LanguageOption,
    Flag,
};