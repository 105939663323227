import { useContext, useState, useEffect, Dispatch, SetStateAction } from "react";
import { AppContext } from "../../../context/AppContext";
import { getEmailFunctions, getNameFunctions, getPhoneFunctions, useClientInptusConfig, validatePhoneNumber } from "../../../shared/utils/clientInputsLogic";
import { UserDataContainer } from './PaymentDetailContainer';
import { UserContext, UserContextDispatchContext } from "../../../context/auth/UserContext";
import { EMAIL_REGEX } from '../../../shared/constants/regex';
import { TextInput, PhoneInput } from '../../../elements';
import { useTranslation } from "react-i18next";
import { RestaurantAdditionalField } from '../../../context/app';
import { AdditionalField } from './AdditionalField';
import { BROKER_OP } from "../../../shared/constants/restaurantIds";
import { useViewport } from "../../../context/ViewportContext";

type props = {
  setIsContinueButtonDisabled: Dispatch<SetStateAction<boolean>>
  onBlurMessagesOn?: boolean
}

export const UserData = ({setIsContinueButtonDisabled, onBlurMessagesOn} : props) => {

    const {
        state: {
          restaurant: {
            smsEnabled,
            additionalFields,
          },
          checkout: {
            paymentSplitType,
          },
        },
    } = useContext(AppContext);
    const { updateUser } = useContext(UserContextDispatchContext);
    const { user: { name, phone, email } } = useContext(UserContext);
    const { t } = useTranslation();
    const { isMobile } = useViewport();
    
    const [isPhoneValid, setIsPhoneValid] = useState(true);
    const [isNameValid, setIsNameValid] = useState(true);
    const [emailError, setEmailError] = useState('');
    const [additionalFieldValidity, setAdditionalFieldValidity] = useState<boolean[]>(Array(additionalFields?.length).fill(true));

    const functionality = sessionStorage.getItem('functionality') || '';
    const isDeliTake = ['delivery', 'takeaway'].includes(functionality);
  
    const restaurantId = Number(sessionStorage.getItem('restaurantId'));
    const isRechargePayment = paymentSplitType === 'recharge'

    const {
        showEmailInput,
        isEmailRequired,
        showNameInput,
        isNameRequired,
        showPhoneInput,
        isPhoneRequired,
        showAnyInput,
    } = useClientInptusConfig();

    const {checkEmail, handleEmailChange} = getEmailFunctions({email, emailError, isEmailRequired, setEmailError, updateUser});
    const {handleNameBlur, handleNameChange} = getNameFunctions({isNameRequired, setIsNameValid, updateUser});
    const {handlePhoneBlur, handlePhoneChange} = getPhoneFunctions({isPhoneRequired, setIsPhoneValid, updateUser});

    const setFieldValidity = (index: number, isValid: boolean) => {
      setAdditionalFieldValidity(prev => {
        const newValidity = [...prev];
        newValidity[index] = isValid;
        return newValidity;
      });
    };

    useEffect(() => {
      if(!isMobile){
        if(onBlurMessagesOn){
          if(isPhoneRequired && !phone){
            setIsPhoneValid(false)
          }
          if(isNameRequired && !name){
            setIsNameValid(false)
          }
          if(isEmailRequired && !email){
            setEmailError("This field is mandatory")
          }
        }
      }

      const isAnyError = !isNameValid || !isPhoneValid || !!emailError;
      const isRequiredFieldInvalid = (isNameRequired && !name)
        || (isPhoneRequired && (!phone || !validatePhoneNumber(`+${phone}`)))
        || (isEmailRequired && (!email || !EMAIL_REGEX.test(email)));
      const isAdditionalFieldInvalid = additionalFieldValidity.some(isValid => !isValid);
  
      setIsContinueButtonDisabled(isAnyError || isRequiredFieldInvalid || isAdditionalFieldInvalid);
      if (isRechargePayment) setIsContinueButtonDisabled(false)
    }, [isNameValid, isPhoneValid, emailError, name, phone, email, additionalFieldValidity, onBlurMessagesOn]);


    return (
        <>
        {!isDeliTake && showAnyInput && !isRechargePayment &&
            <UserDataContainer>
              {showNameInput &&
                <TextInput
                  name={'name'}
                  label={t('name')}
                  placeholder={t('your name')}
                  value={name || ''}
                  onChange={handleNameChange}
                  onBlur={handleNameBlur}
                  error={!isNameValid && 'valid_name'}
                  required={isNameRequired}
                />
              }
              {showPhoneInput &&
                <>
                  <PhoneInput
                    value={phone}
                    onBlur={handlePhoneBlur}
                    onChange={handlePhoneChange}
                    error={!isPhoneValid && 'valid_phone'}
                    required={isPhoneRequired}
                  />
                  {restaurantId != BROKER_OP && smsEnabled &&
                    <p>
                      <span>*</span>
                      {t("You'll get an sms when your order is ready")}
                    </p>
                  }
                </>
              }
              {showEmailInput &&
                <>
                  <TextInput
                    label={t('mail')}
                    name="email"
                    placeholder={t('email')}
                    onChange={handleEmailChange}
                    onBlur={() => checkEmail()}
                    value={email}
                    error={emailError}
                    required={isEmailRequired}
                  />
                  <p style={{textAlign: 'left', marginLeft: '6px', marginTop: '-8px'}}>
                    {isEmailRequired &&
                      <span>*</span>
                    }
                    {t('Email to send bill to.')}
                  </p>
                </>
              }
              {additionalFields?.map((additionalField: RestaurantAdditionalField, index: number) => {
                if(additionalField.type !== 'do not request'){
                  return (
                    <AdditionalField
                      key={`${additionalField.id}-${index}`}
                      field={additionalField}
                      required={additionalField.type === 'required'}
                      setFieldValidity={(isValid: boolean) => setFieldValidity(index, isValid)}
                    />
                  )
                }
              })}
            </UserDataContainer>
          }
          </>
    )
}