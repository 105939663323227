import { InputComponent } from './input.component';

export const Input = InputComponent;

export { TextInput } from './TextInput';
export { NumberInput } from './NumberInput';
export { PhoneInput } from './PhoneInput';
export { TextArea } from './TextArea';
export { Select } from './Select';
export { ClipboardInput } from './ClipboardInput';
export { DateInput } from './DateInput';
