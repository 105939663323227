import { useLocation, useNavigate } from "react-router-dom";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { PageHeaderComponent } from "../../elements/page-header/page-header.component";
import { Payment } from "../../context/app";
import { ReceiptTicket } from "../../elements/header-bar/receipts-widget/ReceiptTicket";
import { useViewport } from "../../context/ViewportContext";
import { PopupType } from "../../context/popups.enum";
import { usePopupNavigation } from "../../shared/utils/usePopupNavigation";
import { PopupContext } from "../../context/PopupContext";

export const RestaurantBillsContainer = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const { isMobile } = useViewport();
  const { goToPopup } = usePopupNavigation();
  const { popup: { params } } = useContext(PopupContext);

  const payment = isMobile ? location.state as Payment : params?.payment;

  const goBack = () => {
    if(isMobile){
      navigate(-1)
    } else {
      goToPopup(PopupType.LatestBills)
    }
  }

  return (
    <>
      <PageHeaderComponent
        text={t('receipt')}
        onGoBack={() => goBack()}
      />
      <ReceiptTicket receipt={payment} fullPage/>
    </>
  );
};