import { useContext } from 'react'
import { t } from 'i18next'
import { useLocation, useNavigate } from 'react-router-dom'
import { Button, Icon, IconType } from '../../elements'
import { calculateCashbackCents, calculateEuros, calculatePoints } from '../../pages/checkout/functions/calculatePrices'
import { RewardBadge } from '../register/RegisterContainers'
import { RewardContainer } from './RewardContainers'
import { getProgramsFromStorage } from '../../pages/checkout/functions/getProgramsFromStorage'
import { Trans } from 'react-i18next'
import { AppContext } from '../../context/AppContext'
import { eventTrack } from '../../../useGaTracker'
import { useViewport } from '../../context/ViewportContext'
import { usePopupNavigation } from '../../shared/utils/usePopupNavigation'
import { PopupType } from '../../context/popups.enum'

type Props = {
  payment?: number
}

export const CheckReward = ({payment}: Props) => {
  const {state: {
    checkout: {
      remaining,
      myTotal,
      loyaltyDiscountCents,
    }
  }} = useContext(AppContext);
  
  const navigate = useNavigate();
  const location = useLocation();
  const { goToPopup } = usePopupNavigation();
  const { isMobile } = useViewport();
  const {noProgram} = getProgramsFromStorage();

  const isMenu = location.pathname === '/menu';
  const isPaymentSuccessful = location.pathname === '/payment-successful';
  const paymentInProgress = Math.max(myTotal - loyaltyDiscountCents, 0);
  const points = calculatePoints(payment || paymentInProgress || remaining);
  const cents = calculateCashbackCents(payment || paymentInProgress || remaining);
  const euros = calculateEuros(cents);


  const goToProfile = () => {
    if(!isMobile){
      goToPopup(PopupType.Profile);
    } else {
      navigate('/profile')
    }
    eventTrack("07_Tooltip_D/A_profile", "07_Tooltip_D/A_profile", "07_Tooltip_D/A_profile")
  };

  const goToCheckout = () => navigate('/checkout',{ state: {from: 'menu', hasSeenPopup: true}});

  return (
    <RewardContainer>
      {isPaymentSuccessful ?
        <h3>
          <b>{t('check your reward')}</b>
        </h3>
      : paymentInProgress ?
        <h3 style={{minWidth: "150px"}}>
          <Trans i18nKey={'wow! by paying with Yumminn you earn'}>
            <b>Wow!</b> by paying with Yumminn you earn
          </Trans>
        </h3>
      :
        <h3>
          <Trans i18nKey={'wow! by paying the whole bill you earn'}>
            <b>Wow!</b> by paying the whole bill you earn
          </Trans>
        </h3>
      }
      {noProgram || (!points && !cents) ?
        <div>
          <Icon type={IconType.CashbackColor} size={24}/>
          <strong>{t('exclusive benefits')}</strong>
          <Icon type={IconType.PointColor} size={24}/>
        </div>
      :
        <div>
          {points > 0 &&
            <RewardBadge type={'points'}>
              <Icon type={IconType.PointColor} size={30}/>
              <span>
                <b>{points}</b>
                {t('points')}
              </span>
            </RewardBadge>
          }
          {cents > 0 &&
            <RewardBadge type={'cashback'}>
              <Icon type={IconType.CashbackColor} size={30}/>
              <span>
                <b>{euros + '€'}</b>
                {t('cashback')}
              </span>
            </RewardBadge>
          }
        </div>
      }
      {(isMenu || isPaymentSuccessful) &&
        <Button
          title={isMenu ? t('pay your bill') : t('go to profile')}
          handleClick={isMenu ? goToCheckout : goToProfile}
          shadow
        />
      }
    </RewardContainer>
  )
}