import React, { MouseEventHandler, useEffect, useRef } from 'react';
import { Overlay, ToggletipChildren, ToggletipContainer, ToggletipContent, ToggletipWrapper } from './ToggletipContainers';

export type ToggletipProps = {
  content: React.ReactNode
  children?: React.ReactNode
  isVisible: boolean
  overlay?: boolean
  position?: 'top' | 'bottom' | 'right' | 'left'
  alignment?: 'start' | 'center' | 'end'
  background?: string
  className?: string
  onClose: () => void
};

export const ToggletipComponent = ({
  content,
  children,
  isVisible,
  overlay,
  position = 'top',
  alignment = 'center',
  background,
  className,
  onClose,
}: ToggletipProps) => {
  const toggletipRef = useRef<HTMLDivElement>(null);
  const contentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (isVisible) {
      // fix overflow if necessary
      
      setTimeout(() => {
        const contentRect = contentRef.current?.getBoundingClientRect() as DOMRect;
        const overflowTop = contentRect && Math.max(-1 * contentRect.top, 0);
        const overflowLft = contentRect && Math.max(-1 * contentRect.left, 0);
        const overflowBtm = contentRect && Math.max(contentRect.bottom - innerHeight, 0);
        const overflowRgt = contentRect && Math.max(contentRect.right - innerWidth, 0);
        
        if (['top', 'bottom'].includes(position) && (overflowRgt || overflowLft)) {
          overflowRgt && ((contentRef.current as HTMLDivElement).style.translate = `-${overflowRgt + 8}px 0`)
          overflowLft && ((contentRef.current as HTMLDivElement).style.translate = `${overflowLft + 8}px 0`)
        }

        if (['right', 'left'].includes(position) && (overflowTop || overflowBtm)) {
          overflowBtm && ((contentRef.current as HTMLDivElement).style.translate = `-0 ${overflowBtm + 8}px`)
          overflowTop && ((contentRef.current as HTMLDivElement).style.translate = `0 ${overflowTop + 8}px`)
        }
      }, 200)
    }
  
    const handleTouch = (event: MouseEvent | TouchEvent) => {
      const childNodes = toggletipRef.current?.childNodes as NodeList;
      const isPopup = childNodes ? Array.from(childNodes).some(node => node.contains(event.target as Node)) : null;

      if (isPopup) return;
      
      onClose();
    };

    if (isVisible && !overlay) {
      window.addEventListener('click', handleTouch);
      window.addEventListener('touchstart', handleTouch);
    } else {
      window.removeEventListener('click', handleTouch);
      window.removeEventListener('touchstart', handleTouch);
    }

    return () => {
      window.removeEventListener('click', handleTouch);
      window.removeEventListener('touchstart', handleTouch);
    };
  }, [isVisible]);

  const handleOverlayClick: MouseEventHandler = event => {
    event.stopPropagation();
    onClose();
  };

  return (
    <ToggletipWrapper
      ref={toggletipRef}
      isVisible={isVisible}
      className={className}
    >
      {isVisible && overlay && <Overlay onClick={handleOverlayClick}/>}
      <ToggletipContainer position={position} background={background} isVisible={isVisible}>
        <ToggletipContent ref={contentRef} position={position} alignment={alignment} background={background}>
          {content}
        </ToggletipContent>
      </ToggletipContainer>
      <ToggletipChildren isVisible={isVisible} overlay={overlay}>
        {children}
      </ToggletipChildren>
    </ToggletipWrapper>
  );
};
