import React, { SetStateAction, useEffect, useRef } from 'react';
import { PopoverOuterContainer, PopoverWrapper, PopoverContainer, PopoverContent } from './PopoverContainers';
import { useViewport } from '../../context/ViewportContext';
import { Icon, IconType } from '../icons';

export type PopoverProps = {
  isOpened: boolean
  setOpened: React.Dispatch<SetStateAction<boolean>>;
  onClose?: () => void
  position?: 'top' | 'bottom' | 'right' | 'left'
  alignment?: 'start' | 'center' | 'end'
  alwaysLight?: boolean
  disableDiscounts?: boolean
};

type Props = PopoverProps & {
  children: React.ReactNode
};

export const PopoverComponent = ({ 
  isOpened, 
  setOpened, 
  children, 
  onClose,
  position = 'top',
  alignment = 'center',
  alwaysLight = false,
  disableDiscounts = false
 }: Props) => {

  const closePopover = () => setOpened(false)
  const popoverRef = useRef<HTMLDivElement>(null);
  const { isMobile } = useViewport();

  const useClose = (event: React.MouseEvent | React.TouchEvent) => {
    event.stopPropagation(); 
    onClose ? onClose() : closePopover();
  }; 

  useEffect(() => {
    const handleClickOutside = (event: TouchEvent) => {
      if (popoverRef.current && !popoverRef.current.contains(event.target as HTMLElement)){
        useClose(event as unknown as React.TouchEvent);
      }
    };

    document.addEventListener('touchstart', handleClickOutside);

    return () => {
      document.removeEventListener('touchstart', handleClickOutside);
    };
  }, []);
  

  return (
    <PopoverOuterContainer id="popover" ref={popoverRef}>
      {isOpened &&
        <>
       
          <PopoverWrapper>
            <PopoverContainer position={position} alwaysLight={alwaysLight} disableDiscounts={disableDiscounts}>
              <PopoverContent position={position} alignment={alignment} alwaysLight={alwaysLight} disableDiscounts={disableDiscounts}>
              {!isMobile && 
                <div
                  style={{pointerEvents: disableDiscounts ? 'none' : 'all'}}
                  className='closeButton' 
                  onClick={(ev) => useClose(ev)}
                >
                  <Icon type={IconType.Close} />
                </div>
              }
              {children}
              </PopoverContent>
            </PopoverContainer>
          </PopoverWrapper>
        </>
      }
    </PopoverOuterContainer>
  );
};